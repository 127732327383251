import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga'

import { getItemModalData } from 'features/Collections/redux/collectionsActions'
import { isItemEditable, selectItemData, selectItemModalDataNotFound } from 'features/Collections/redux/collectionsSelectors'
import { ContributionItem } from 'features/Collections'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import Metatags from 'shared/components/Metatags'
import NotFound from 'features/NotFound'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import ContributionItemEditView from 'features/Contribute/components/ContributionItemEditView'

const ItemDetail = ({ match }) => {
  const dispatch = useDispatch()
  const data = useSelector(selectItemData)
  const langcode = useSelector(selectLangcodeFlag)
  const editable = useSelector(isItemEditable);
  const notFound = useSelector(selectItemModalDataNotFound)
  const userLoaded = useSelector(selectUserLoadStatus)
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit(value => !value);
  }
  const { params: { itemId } } = match
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  useEffect(() => { 
    dispatch(getItemModalData(itemId, 'item'))
   }, [dispatch, itemId, userLoaded])
   if (notFound) return <NotFound />

  return (
    <div className='c-basic-page c-layout__fill u-p32'>
      {data && (
        <>
          {data.metatag.length > 0 && <Metatags tags={data.metatag} />}
          {edit ? <ContributionItemEditView onEditClicked={setEdit}/> : <ContributionItem detail data={{ ...data, description: data.descriptionProcessed }} activeTab={langcode} onEditClicked={toggleEdit}
      editable={editable}/>}
        </>
      )}
    </div>
  )
}

export default ItemDetail
