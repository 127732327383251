import React from 'react'
import { JdaLink } from 'shared/components'

const FeatureLink = ({ title, description, link }) => {
  if (!link) return null
  const routed = link.url?.routed
  const entityType = link.url?.nodeContext?.entityBundle
  let url = link.uri
  if (routed) url = link.url.pathAlias ? `${entityType === 'page' ? '/resources' : ''}${link.url.pathAlias}` : link.url.path
  const target = link.options?.attributes?.target
  return (
    <div className='c-feature-link u-mbxs'>
      <div className='c-feature-link__left-col'>
        <div className='c-feature-link__title'>
          {title}
        </div>
        <div className='c-feature-link__description'>
          {description}
        </div>
      </div>
      <div className='c-feature-link__right-col'>
        <i className='material-icons c-feature-link__arrow'>keyboard_arrow_right</i>
        {routed ? (
          <JdaLink className='c-feature-link__url' to={url} target={target} />
        ) : (
          <a className='c-feature-link__url' href={url} target={target} rel={target === '_blank' ? 'noopener' : undefined}>
            <span className='u-hidden'>' '</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default FeatureLink
