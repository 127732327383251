import { createReducer } from '@reduxjs/toolkit'
import { mediaOptions } from '../helpers/contributeFormConfig'
import contributeTypes from './contributeTypes'
import { EN } from 'shared/constants'

const defaultState = {
  data: {
    user: {},
    multiForm: [],
    title: { en: '', ja: '' },
    description: { en: '', ja: '' },
    mediaType: mediaOptions[0].value,
    date: '',
    tags: [],
    languages: {
      english: false,
      japanese: false,
      chinese: false,
      korean: false,
      french: false,
      others: false
    },
    uri: '',
    author: '',
    realName: '',
    twitterUsername: '',
    location: {},
    from: '',
    to: '',
    residence: '',
    occupation: '',
    birthYear: '',
    file: {}
  },
  res: null,
  meta: {
    page: 1,
    language: EN,
    previewVisible: false,
    succesful: false,
    searching: false,
    submitting: false
  },
  tagResults: [],
  isMultipleUpload: false,
  // 1 for Images and Documents, 2 for Websites
  multiUploadType: 1,
  applyToAll: false,
}

export default createReducer(defaultState, {
  [contributeTypes.SET_USER]: (state, action) => {
    state.data.user = action.payload
  },
  [contributeTypes.SET_MULTIFORM_DATA]: (state, action) => {

    state.data = {
      ...state.data,
      multiForm: action.payload
    }
  },
  [contributeTypes.REMOVE_MULTIFORM_ITEM]: (state, action) => {

    state.data.multiForm = state.data.multiForm.filter(item => item.uuid != action.payload)
  },
  [contributeTypes.SET_DATA]: (state, action) => {
    state.meta.previewVisible = true
    state.data = {
      ...state.data,
      ...action.payload
    }
  },
  [contributeTypes.SET_MULTIPLE_UPLOAD]: (state, action) => {
    state.isMultipleUpload = action.payload
  },
  [contributeTypes.CLEAR_DATA]: state => {
    state.data = defaultState.data
  },
  [contributeTypes.SET_LANGUAGE]: (state, action) => {
    state.meta.language = action.payload
  },
  [contributeTypes.SET_STEP]: (state, action) => {
    state.meta.page = action.payload
  },
  [contributeTypes.ADD_TAG_SUCCESS]: (state, action) => {
    const foundTag = state.tagResults.find(result => result.name === action.payload)
    if (foundTag) state.data.tags.push(foundTag)
  },
  [contributeTypes.SET_APPLY_TO_ALL]: (state, action) => {
    state.applyToAll = action.payload;
    if (action.payload) {
      if (state.data.multiForm[0].tags.length > 0){
        state.data.multiForm[0].tags.forEach(tag => {
          for(let i = 1; i < state.data.multiForm.length; i++) {
            if (!state.data.multiForm[i].tags.find(t => t.name == tag.name)){
              state.data.multiForm[i].tags.push(tag)
            }
          }
        })
      }
    }
  },
  [contributeTypes.ADD_MULTIPLE_TAG_SUCCESS]: (state, action) => {
    const foundTag = state.tagResults.find(result => result.name === action.payload.tag)
    if (foundTag) {
      if (action.payload.applyToAll) {
        const length = state.data.multiForm?.length ?? 0;
        for (let i = 0; i < length; i++) {
          if (!state.data.multiForm[i].tags.find(t => t.name == action.payload.tag)){
            state.data.multiForm[i].tags.push(foundTag)
          }
        }
      } else {
        const index = state.data.multiForm.findIndex(item => item.uuid == action.payload.uuid);
        if (index > -1) {
          if (!state.data.multiForm[index].tags.find(t => t.name == action.payload.tag)) {
            state.data.multiForm[index].tags.push(foundTag)
          }
        }
      }
    }
  },
  [contributeTypes.ADD_TAG_FAIL]: (state, action) => {
    state.data.tags.push(action.payload)
  },
  [contributeTypes.REMOVE_TAG]: (state, action) => {
    state.data.tags = state.data.tags.filter(tag => tag.tid !== action.payload.tid)
  },
  [contributeTypes.REMOVE_MULTIPLE_TAG]: (state, action) => {
    if (action.payload.applyToAll) {
      const length = state.data.multiForm?.length ?? 0;
      for (let i = 0; i < length; i++) {
        state.data.multiForm[i].tags = state.data.multiForm[i].tags.filter(tag => tag.tid !== action.payload.tag.tid)
      }
    } else {
      const index = state.data.multiForm.findIndex(item => item.uuid == action.payload.uuid);
      if (index > -1) state.data.multiForm[index].tags = state.data.multiForm[index].tags.filter(tag => tag.tid !== action.payload.tag.tid)
    }
  },
  [contributeTypes.SET_LOCATION]: (state, action) => {
    state.data.location = action.payload
  },
  [contributeTypes.SET_LOCATION_FOR_MULTIPLE]: (state, action) => {
    const index = state.data.multiForm.findIndex(item => item.uuid == action.payload.uuid);
    if (index > -1) state.data.multiForm[index].location = action.payload.location;
  },
  [contributeTypes.TOGGLE_LANGUAGE]: (state, action) => {
    const lang = action.payload
    state.data.languages[lang] = !state.data.languages[lang]
  },
  [contributeTypes.TOGGLE_MULTIPLE_LANGUAGE]: (state, action) => {
    const lang = action.payload.language
    const index = state.data.multiForm.findIndex(item => item.uuid == action.payload.uuid);
    if (index > -1) state.data.multiForm[index].languages[lang] = !state.data.multiForm[index].languages[lang]
  },
  [contributeTypes.RESET_FORM]: state => {
    state.data = {
      user: state.data.user,
      ...defaultState.data
    }
    state.tagResults = []
    state.res = null
    state.meta = defaultState.meta
  },
  [contributeTypes.SET_MEDIA_TYPE]: (state, action) => {
    const dataReset = {
      ...defaultState.data,
      title: state.data.title,
      description: state.data.description,
      user: state.data.user,
      mediaType: action.payload
    }
    if (state.data.file.file) {
      // resets file input
      const newKey = Date.now()
      dataReset.file = { key: newKey, resetKey: newKey }
    }
    state.data = dataReset
  },
  [contributeTypes.FORM_SUBMIT_BEGIN]: state => {
    state.meta.submitting = true
  },
  [contributeTypes.FORM_SUBMIT_SUCCESS]: (state, action) => {
    state.meta.submitting = false
    state.meta.succesful = true
    state.res = action.payload
  },
  [contributeTypes.FORM_SUBMIT_FAIL]: (state, action) => {
    state.meta.submitting = false
    state.meta.succesful = false
    state.res = null
  },
  [contributeTypes.SEARCH_TAGS_BEGIN]: state => {
    state.meta.searching = true
  },
  [contributeTypes.SEARCH_TAGS_SUCCESS]: (state, action) => {
    state.tagResults = action.payload
    state.meta.searching = false
  },
  [contributeTypes.SEARCH_TAGS_FAIL]: state => {
    state.meta.searching = false
  },
  [contributeTypes.CREATE_TAG_SUCCESS]: (state, action) => {
    state.tagResults.splice(0, 1, action.payload)
  },
  [contributeTypes.UPDATE_SELECTED_ITEM_BEGIN]: state => {
    state.meta.submitting = true
  },
  [contributeTypes.UPDATE_SELECTED_ITEM_SUCCESS]: state => {
    state.meta.submitting = false
  },
  [contributeTypes.UPDATE_SELECTED_ITEM_FAIL]: state => {
    state.meta.submitting = false
  },
  [contributeTypes.SET_MULTIPLE_TYPE]: (state, action) => {
    state.multiUploadType = action.payload ?? 1;
  },
  [contributeTypes.ADD_MORE_WEBSITES]: (state, action) => {
    state.data.multiForm = [...state.data.multiForm, ...action.payload]
  },  
  [contributeTypes.UPDATE_WEBSITE_FIELD]: (state, action) => {
    const index = state.data.multiForm.findIndex(item => item.uuid == action.payload.uuid);
    if (index > -1) state.data.multiForm[index] = {...state.data.multiForm[index], ...action.payload}
  },  
})
