import React from 'react'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { useSelector } from 'react-redux'
import { EN, JA } from 'shared/constants'

const Translate = ({ en, ja }) => {
  const siteLangcode = useSelector(selectLangcodeFlag)

  if(!en && !ja) return ''
  if(!siteLangcode) return en || ja || ''
  if(siteLangcode === EN) return en || ja || ''
  if(siteLangcode === JA) return ja || en || ''

}

export default Translate