import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import defaultLogo from 'shared/assets/images/jda_default-large-logo.jpg'
import { formatItemDate } from 'shared/utils/tools/datetime'
import { useDispatch, useSelector } from 'react-redux'
import { loadItemModal } from 'features/Collections/redux/collectionsActions'
import { debounce, getLangValue } from 'utils/functions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { COLLECTION, ITEM } from 'shared/constants'
import { getBackgroundImageStyle } from 'shared/utils/tools/parse'

const CollectionItemV = ({
  title,
  publishedDate,
  img,
  owner,
  itemCount,
  className = '',
  hideHandle = false,
  deleteItemFxn,
  deleteItemVisible,
  id,
  isCollection
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const siteLangcode = useSelector(selectLangcodeFlag)
  const openModal = useCallback(debounce(() => dispatch(loadItemModal({ id, type: isCollection ? COLLECTION : ITEM })), 500, true), [dispatch, id, isCollection])
  if (!id) {
    return null
  }
  const activeTitle = getLangValue(siteLangcode, title)
  return (
    <div className={`c-collection-item c-collection-item--vertical ${className}`}>
      <button
        className={`c-collection-item__btn c-collection-item__btn--corner-tr u-bg-red ${!deleteItemVisible ? 'u-hidden' : ''}`}
        type="button"
        aria-label="Remove"
        onClick={deleteItemFxn}
      >
        <i className="material-icons">close</i>
      </button>
      <div className='c-collection-item__wrapper c-collection-item__wrapper--vertical c-collection-item__wrapper--interactable' onClick={openModal}>
        {img ? (
          <div className="c-collection-item__img c-collection-item__img--vertical" style={getBackgroundImageStyle(img)} />
        ) : <img className="c-collection-item__img c-collection-item__img--vertical" src={defaultLogo} alt='' />}
        <div className='c-collection-item__content'>
          <div>
            <h3 className='c-heading c-heading--l6'>{activeTitle}</h3>
            <div className='c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon'>
              <span className='c-collection-item__subitem'>{publishedDate && formatItemDate(publishedDate)} {t('via')} {owner}</span>
              <span className='c-collection-item__subitem c-copy__icon'>
                <i className='material-icons'>layers</i>
                  {itemCount} {t(`Item${itemCount && itemCount === 1 ? '' : 's'}`)}
              </span>
            </div>
          </div>
        </div>

        {!hideHandle && <div className='c-collection-item__drag c-collection-item__drag--vertical'>
          <i className='material-icons'>drag_handle</i>
        </div>}
      </div>
    </div>
  )
}

export default CollectionItemV
