import React, { useMemo, useCallback } from 'react'
import { Collapse } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectBasicPageMenus } from 'features/BasicPage/redux/basicPageSelectors'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { JdaLink } from 'shared/components'
const { Panel } = Collapse

const BasicPageSidebar = ({ page }) => {
  const { t } = useTranslation()
  const menus = useSelector(selectBasicPageMenus)
  const activeLangCode = useSelector(selectLangcodeFlag)
  const flatMenuItems = useMemo(() => {
    const flat = []
    const flatten = items => items.forEach(({ key, alias, relative, parentKeys, below }) => {
      flat.push({
        key,
        alias,
        relative,
        parentKeys
      })
      if (below) flatten(below)
    })
    flatten(menus)
    // reverses the array to search sub items first in the case where a parent shares a path alias with a child
    return flat.reverse()
  }, [menus])
  // if page is already selected, prevents the page from being reloaded when the link is clicked on again
  const disableLink = e => e.preventDefault()
  const scrollToTop = window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  const selectedMenuItem = page && flatMenuItems.find(item => page.path.alias === `/${item.alias}`)
  const renderMenuItem = useCallback((item, level = 1) => {
    const { below, title, key, alias, label} = item
    const relativePath = alias ? `/${alias}` : ''
    const selectedTitle = (label && label[activeLangCode]) || title
    const selected = selectedMenuItem && selectedMenuItem.key === key
    const included = selectedMenuItem && selectedMenuItem.parentKeys.includes(key)
    if (level === 1) {
      return (
        <Panel
          showArrow={false}
          header={t(selectedTitle)}
          key={key}
          className={`c-bp-accordion__panel c-bp-accordion__panel--l1 ${selected ? 'selected' : ''}`}
        >
          {below && (
            <Collapse
              accordion
              className='c-bp-accordion' bordered={false}
              defaultActiveKey={selectedMenuItem && (selectedMenuItem.parentKeys[level] || selectedMenuItem.key)}
            >
              {below.map(subItem => renderMenuItem(subItem, level + 1))}
            </Collapse>
          )}
        </Panel>
      )
    } else if (level === 2) {
      return (
        <Panel
          showArrow={false}
          header={relativePath.includes('/resources') ? <JdaLink className='c-link' to={relativePath} onClick={selected ? disableLink : scrollToTop}>{selectedTitle}</JdaLink> : selectedTitle}
          key={key}
          className={`c-bp-accordion__panel c-bp-accordion__panel--l2 ${(selected || included) ? 'selected' : ''}`}
        >
          {below && (
            <ul>
              {below.map(subItem => renderMenuItem(subItem, level + 1))}
            </ul>
          )}
        </Panel>
      )
    } else if (level === 3) {
      return (
        <li className='c-bp-accordion__item' key={key}>
          <JdaLink to={relativePath} className='c-bp-accordion__link c-link' disabled={selected} onClick={selected ? disableLink : scrollToTop}>
            <span className={`c-bp-accordion__link-text ${selected ? 'active' : ''}`}>
              {selectedTitle}
            </span>
          </JdaLink>
        </li>
      )
    }
  }, [activeLangCode, selectedMenuItem, scrollToTop])
  return (
    <aside className='c-basic-page__sidebar'>
      <div className='u-p c-heading c-heading--l6 c-heading--uppercase'>
        {t('Resources')}
      </div>
      {menus.length > 0 && (
        <Collapse
          className='c-bp-accordion'
          bordered={false}
          defaultActiveKey={selectedMenuItem ? (selectedMenuItem.parentKeys[0] || selectedMenuItem.key) : menus[0] && menus[0].key }
        >
          {menus.map(menu => renderMenuItem(menu))}
        </Collapse>
      )}
    </aside>
  )
}

export default BasicPageSidebar
