import { sortOptions } from './fields'
import { getParameter } from 'utils/functions'
import { SEARCH_ITEMS, NEW_PUBLISHED, NEWEST, SORT, ORDER } from 'shared/constants'

const getToolbarDefaultSort = (searchType, sort = {}) => {
  const sortField = getParameter(SORT) || sort.sort || false
  const sortOrder = getParameter(ORDER) || sort.order || false
  return searchType === SEARCH_ITEMS
    ? sortField && sortOrder ? `${sortField}_${sortOrder}` : NEW_PUBLISHED
    : sortField && Object.keys(sortOptions.collections).includes(sortField) ? sortField : NEWEST
}

const getSearchDefaultSort = (searchType) => {
  const sortField = getParameter(SORT) || false
  const sortOrder = getParameter(ORDER) || false
  return searchType === SEARCH_ITEMS
    ? sortField && sortOrder ? { sort: sortField, order: sortOrder } : null
    : sortField && Object.keys(sortOptions.collections).includes(sortField) ? { sort: sortField, order: '' } : null
}

export {
  getToolbarDefaultSort,
  getSearchDefaultSort
}
