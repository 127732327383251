import React from 'react'
import { Helmet } from 'react-helmet-async'
import { METATAG, METAVALUE, METALINK, PROPTITLE, LINKTAG } from 'shared/constants'

const tagMapCb = ({ tag, attributes, type, property, content }) => {
  if (tag && attributes) {
    return { tag, attributes }
  }
  if (type === METALINK) {
    return {
      tag: LINKTAG,
      attributes: {
        rel: property,
        href: content
      }
    }
  } else if (type === METAVALUE && property === PROPTITLE) {
    return {
      tag: METATAG,
      attributes: {
        name: PROPTITLE,
        content
      }
    }
  } else {
    return {
      tag: METATAG,
      attributes: {
        property,
        content
      }
    }
  }
}

const Metatags = ({ tags }) => (
  <Helmet>
    {tags.map(tagMapCb).map(({ tag, attributes }, index) => {
      const CustomTag = `${tag}`

      if(tag === METATAG && attributes.name === PROPTITLE) {
        return <title key={index}>{attributes.content}</title>
      }

      return <CustomTag key={index} {...attributes} />
    })}
  </Helmet>
)

export default Metatags
