import React, { useCallback } from 'react'
import { LanguageTabber } from 'shared/components'
import { FileInput, ReactCKEditor, TextInput } from 'features/Form/components'
import PrivacyToggle from '../PrivacyToggle'
import InputWrapper from 'features/Form/components/InputWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { selectCollectionFormData, selectCreateCollectionSubmitting } from 'features/Collections/redux/collectionsSelectors'
import { createCollection, editCollection, setCollectionForm } from 'features/Collections/redux/collectionsActions'
import { CollectionGallery } from 'features/Collections'
import toast from 'shared/utils/toast'
import { EN } from 'shared/constants'
import { useTranslation } from 'react-i18next'

const CreateCollection = ({ edit = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formData = useSelector(selectCollectionFormData)
  const submitting = useSelector(selectCreateCollectionSubmitting)
  const setFormData = useCallback(data => dispatch(setCollectionForm(data)), [dispatch])
  const setActiveTab = useCallback(lang => setFormData({ ...formData, langcode: lang }), [setFormData, formData])
  const activeTab = formData.langcode
  const onChangeWithLang = useCallback(event => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: {
        ...formData[event.currentTarget.name],
        [activeTab]: event.currentTarget.value
      }
    })
  }, [formData, activeTab, setFormData])

  const onFileChange = useCallback(file => {
    setFormData({ ...formData, image: file, imageThumbnail: null })
  }, [formData, setFormData])

  const onGallerySelect = useCallback(value => {
    const newKey = Date.now()
    setFormData({ ...formData, imageThumbnail: value, image: { key: newKey, resetKey: newKey } })
  },[setFormData, formData])

  const onPrivacyToggle = useCallback(checked => {
    setFormData({ ...formData, published: checked })
  }, [formData, setFormData])

  const validateForm = useCallback(() => {
    if (formData.title[formData.langcode] && formData.title[formData.langcode].trim().length) return true
    return false
  }, [formData])

  const onSubmit = useCallback(async (event) => {
    event.preventDefault()
    const valid = validateForm()
    if (valid) {
      if(!edit) {
        await dispatch(createCollection())
      } else {
        await dispatch(editCollection())
      }
      // window.location.reload();
    } else {
      toast.error('Collections require a title.')
    }
  }, [dispatch, validateForm, edit])

  return (
    <form className='c-form c-item-modal__sub-col' onSubmit={onSubmit}>
      <LanguageTabber
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        className='c-collection-preview__tabber'
        contentClassName='c-language-tabber'
      >
        <div className='u-p32 c-language-tabber__content'>
          <div className='c-form__item'>
            <TextInput
              onChange={onChangeWithLang}
              value={formData.title[activeTab]}
              name='title'
              label={t('Collection Title')}
              labelEx={` (${activeTab === EN ? t('English') : t('Japanese')})`}
              labelClassname='c-input-wrapper__label--create u-mb'
            />
          </div>
          <div className='c-form__item'>
            <ReactCKEditor
              id={`collection-description-${activeTab}`}
              onChange={onChangeWithLang}
              value={formData.description[activeTab]}
              name='description'
              label={t('Collection Description')}
              labelEx={` (${activeTab === EN ? t('English') : t('Japanese')})`}
              labelClassname='c-input-wrapper__label--create u-mb'
              type='textarea'
              rows={10}
            />
          </div>
          <div className='c-form__item'>
            <InputWrapper
              label={t('Collection Image')}
              labelClassname='c-input-wrapper__label--create u-mb'
            >
              {edit && formData.itemImages && !!formData.itemImages.length && (
                <CollectionGallery images={formData.itemImages} onChange={onGallerySelect} clearSelection={!formData.imageThumbnail} currentCollectionImage={formData.img} uploadedImage={formData?.image?.preview}/>
              )}
              <FileInput
                label=' '
                name='image'
                accept='image/*'
                value={formData.image}
                onChange={onFileChange}
              />
              {formData?.image?.preview && <div className='c-collection-gallery__tile current-image'>
                <img src={formData.image.preview} className='c-collection-gallery__img' alt='' />
              </div>}
            </InputWrapper>
          </div>
          <div className='c-form__item'>
            <InputWrapper
              name='privacy'
              label={t('Privacy Settings')}
              labelClassname='c-input-wrapper__label--create u-mb'
            >
              <PrivacyToggle onChange={onPrivacyToggle} defaultChecked={formData.published} />
            </InputWrapper>
          </div>
        </div>
        <button
          type='Submit'
          className={`c-btn c-btn--full c-btn--lg c-btn--sharp c-btn--hover-fx u-color-white u-bg-orange c-contribute-form__btn ${submitting ? 'is-disabled' : ''}`}
          disabled={submitting}
        >
          {edit ? t('UPDATE COLLECTION'): t('CREATE NEW COLLECTION')}
        </button>
      </LanguageTabber>
    </form>
  )
}

export default CreateCollection
