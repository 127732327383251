import { createAction } from '@reduxjs/toolkit'
import accountFormTypes from './accountFormTypes'
import { loadUser, logoutUser } from 'features/authentication/redux/authenticationAction'
import { LOGIN, SIGNUP, REQUEST_PASS } from 'shared/constants'
import * as userService from 'shared/utils/api/user'
import toast from 'shared/utils/toast'
import { renderLinkWithMessage } from 'shared/components/JdaLink'
import { ADMINISTRATOR_USER, CONTENT_EDITOR, ADMIN_SITE_PATH } from 'shared/constants'
import { validateUserAccount } from 'shared/utils/api/collection'
import i18n from 'i18n'
export const setAccountFormData = createAction(accountFormTypes.SET_DATA)
export const clearAccountFormData = createAction(accountFormTypes.CLEAR_DATA)
export const setAccountModalType = createAction(accountFormTypes.SET_MODAL_TYPE)
export const openAccountModal = createAction(accountFormTypes.OPEN_MODAL)
export const closeAccountModal = createAction(accountFormTypes.CLOSE_MODAL)
export const toggleRole = createAction(accountFormTypes.TOGGLE_ROLE)
export const setAccountFormErrors = createAction(accountFormTypes.SET_ERRORS)
export const resetAccountFormPass = createAction(accountFormTypes.RESET_PASSWORD_FIELD)
export const checkUsernameBegin = createAction(accountFormTypes.CHECK_USERNAME_BEGIN);
export const checkUsernameSuccess = createAction(accountFormTypes.CHECK_USERNAME_SUCCESS);
export const checkUsernameFail = createAction(accountFormTypes.CHECK_USERNAME_FAIL);
export const checkEmailBegin = createAction(accountFormTypes.CHECK_EMAIL_BEGIN);
export const checkEmailSuccess = createAction(accountFormTypes.CHECK_EMAIL_SUCCESS);
export const checkEmailFail = createAction(accountFormTypes.CHECK_EMAIL_FAIL);

const submitAccountFormBegin = createAction(accountFormTypes.SUBMIT_FORM_BEGIN)
const submitAccountFormSuccess = createAction(accountFormTypes.SUBMIT_FORM_SUCCESS)
const submitAccountFormFail = createAction(accountFormTypes.SUBMIT_FORM_FAIL)

export const checkDuplicatedEmail = email => async (dispatch) => {
  try {
    await dispatch(checkEmailBegin());

    if (!email) {
      await dispatch(checkEmailSuccess(false))
    } else {
      const res = await validateUserAccount(email);
      await dispatch(checkEmailSuccess(!!res))
    }
  } catch(error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      toast.error(i18n.t(responseMessage));
    }
    await dispatch(checkEmailFail());
  }
}

export const checkDuplicatedUsername = name => async (dispatch) => {
  try {
    await dispatch(checkUsernameBegin());

    if (!name) {
      await dispatch(checkUsernameSuccess(false))
    } else {
      const res = await userService.checkDuplicateUserName(name);
      const existing = (res?.account??[])[0]?.exists;
      await dispatch(checkUsernameSuccess(existing))
    }
  } catch(error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      toast.error(i18n.t(responseMessage));
    }
    await dispatch(checkUsernameFail());
  }
}

export const clearSessionCookieAndLogin = (adminRedirect) => async (dispatch, getState) => {
  try {
    // logout to clear session cookie
    await dispatch(logoutUser())

    // login again
    const { accountForm: { data } } = getState()
    let successMsg, errorMsg
    const res = await userService.loginUser(data)
    if (res.error) throw new Error(res.error)
    const succesful = await dispatch(loadUser(res.id, res.logoutToken, res.csrfToken))
    if (succesful) {
      successMsg = i18n.t('Login was successful.')
      if (adminRedirect && res.roles &&
        (res.roles.includes(ADMINISTRATOR_USER) || res.roles.includes(CONTENT_EDITOR))
      ) {
        window.location.pathname = ADMIN_SITE_PATH
      }
    } else {
      errorMsg = i18n.t('Failed Login')
    }
    if (!errorMsg) {
      dispatch(submitAccountFormSuccess(LOGIN))
      if (successMsg) toast.success(successMsg)
    } else {
      toast.error(errorMsg, { transParams: { user: (data.name || data.mail) } })
    }
    return !!successMsg
  } catch (error) {
    let submitErrors = {}
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      const status = error.request.status
      const state = getState()
      const name = state && state.accountForm && state.accountForm.data && state.accountForm.data.name
      if ([400, 403].includes(status)) {
        toast.error(responseMessage)
      } else {
        toast.error(renderLinkWithMessage(
          responseMessage,
          `/user/password?name=${name || ''}`,
          i18n.t('Have you forgotten your password?'),
          { onClick: () => dispatch(closeAccountModal()) }
        ))
      }
    } else if (error.response?.status === 500) {
      toast.error(i18n.t(`There was an error processing your request. Service is temporarily unavailable.`))
    }
    dispatch(submitAccountFormFail(submitErrors))
  }
}

export const submitAccountForm = (formType, adminRedirect = false) => async (dispatch, getState) => {
  dispatch(submitAccountFormBegin())
  try {
    const { accountForm: { data } } = getState()
    let successMsg, errorMsg
    if (formType === LOGIN) {
      const res = await userService.loginUser(data)
      if (res.error) throw new Error(res.error)
      const succesful = await dispatch(loadUser(res.id, res.logoutToken, res.csrfToken))
      if (succesful) {
        successMsg = i18n.t('Login was successful.')
        if (adminRedirect && res.roles &&
          (res.roles.includes(ADMINISTRATOR_USER) || res.roles.includes(CONTENT_EDITOR))
        ) {
          window.location.pathname = ADMIN_SITE_PATH
        }
      } else {
        errorMsg = i18n.t('Failed Login')
      }
    } else if (formType === SIGNUP) {
      const res = await userService.registerUser(data)
      if (res.error) throw new Error(res.error)
      const resReqPass = await userService.requestPassword({accountName: data.mail})
      if (resReqPass.error) throw new Error(resReqPass.error)
      successMsg = i18n.t('Successful registration')
    } else if (formType === REQUEST_PASS) {
      const res = await userService.requestPassword(data)
      if (res.error) throw new Error(res.error)
      successMsg = i18n.t('We\'ll send you further instructions if this email is registered with us.')
    } else {
      throw new Error('Error submitting account form. Unrecognized form type.')
    }
    if (!errorMsg) {
      dispatch(submitAccountFormSuccess(formType))
      if (successMsg) toast.success(successMsg)
    } else {
      toast.error(i18n.t(errorMsg), { transParams: { user: (data.name || data.mail) } })
    }
    return !!successMsg
  } catch (error) {
    let submitErrors = {}
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      const status = error.request.status
      if (formType === LOGIN) {
        const state = getState()
        const name = state && state.accountForm && state.accountForm.data && state.accountForm.data.name

        if (status === 403 &&
          responseMessage === i18n.t("This route can only be accessed by anonymous users.")) {
          await dispatch(clearSessionCookieAndLogin(adminRedirect))
          return
        }

        if ([400, 403].includes(status)) {
          toast.error(responseMessage)
        } else {
          toast.error(renderLinkWithMessage(
            responseMessage,
            `/user/password?name=${name || ''}`,
            i18n.t('Have you forgotten your password?'),
            { onClick: () => dispatch(closeAccountModal()) }
          ))
        }
      } else if (formType === SIGNUP) {
        let messages = responseMessage.split('\n')
        if (messages.length === 1) {
          toast.error(responseMessage)
        }
        messages = messages.slice(1)
        const matchedMessages = messages.reduce((msgArr, nextMsg) => {
          const match = nextMsg.match(/(\w+).*(?:value)?:\s(.+)/)
          if (match) {
            submitErrors[match[1]] = match[2]
            return msgArr.concat(match[2])
          }
          return msgArr
        }, [])
        matchedMessages.forEach(msg => toast.error(i18n.t(msg)))
      } else {
        toast.error(responseMessage)
      }
    } else if (error.response?.status === 500) {
      toast.error(i18n.t(`There was an error processing your request. Service is temporarily unavailable.`))
    }
    dispatch(submitAccountFormFail(submitErrors))
  }
}

const updateAccountBegin = createAction(accountFormTypes.UPDATE_ACCOUNT_BEGIN)
const updateAccountSuccess = createAction(accountFormTypes.UPDATE_ACCOUNT_SUCCESS)
const updateAccountFail = createAction(accountFormTypes.UPDATE_ACCOUNT_FAIL)

export const updateAccount = () => async (dispatch, getState) => {
  dispatch(updateAccountBegin())
  try {
    const { auth: { user }, accountForm: { data } } = getState()
    const updatedFields = Object.keys(data).reduce((payload, nextField) => {
      if (data[nextField] !== user[nextField]) payload[nextField] = data[nextField]
      return payload
    }, {})
    const res = await userService.updateUserAccount(user, updatedFields)
    dispatch(updateAccountSuccess(res))
    toast.success('The changes have been saved.')
  } catch (error) {
    dispatch(updateAccountFail())
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      const messages = responseMessage.split('\n').slice(1)
      const matchedMessages = messages.reduce((msgArr, nextMsg) => {
        const match = nextMsg.match(/(\w+).*(?:value)?:\s(.+)/)
        if (match) {
          return msgArr.concat(match[2])
        }
        return msgArr
      }, [])
      matchedMessages.forEach(msg => toast.error(i18n.t(msg)))
    }
  }
}

const updatePasswordBegin = createAction(accountFormTypes.UPDATE_PASSWORD_BEGIN)
const updatePasswordSuccess = createAction(accountFormTypes.UPDATE_PASSWORD_SUCCESS)
const updatePasswordFail = createAction(accountFormTypes.UPDATE_PASSWORD_FAIL)

export const updatePassword = () => async (dispatch, getState) => {
  dispatch(updatePasswordBegin())
  try {
    const { accountForm: { data } } = getState()
    const res = await userService.resetPassword(data)
    if (res) {
      dispatch(updatePasswordSuccess())
      toast.success(i18n.t('Password updated. Redirecting to the login page...'))
      return true
    }
  } catch (error) {
    dispatch(updatePasswordFail())
    if (error?.response?.data?.message) {
      toast.error(i18n.t(error.response.data.message))
    }
  }
}
