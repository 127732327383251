import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast, Zoom } from 'react-toastify'

const Message = ({ msg, transParams }) => {
  const { t } = useTranslation()
  return (
    <div className='c-toast__msg'>
      {t(msg, transParams)}
    </div>
  )
}

export default {
  success: (msg, options = {}) => {
    return (Array.isArray(msg) ? msg : [msg]).forEach(m => (
      toast.success(<Message msg={m} transParams={options.transParams} />, {
      ...options,
      className: 'c-toast__container c-toast__container--success'
    })))
  },
  error: (msg, options = {}) => {
    return (Array.isArray(msg) ? msg : [msg]).forEach(m => (
      toast.error(<Message msg={m} transParams={options.transParams} />, {
        ...options,
        className: 'c-toast__container c-toast__container--error'
    })))
  },
  info: (msg, options = {}) => {
    return (Array.isArray(msg) ? msg : [msg]).forEach(m => (
      toast.info(<Message msg={m} transParams={options.transParams} />, {
        ...options,
        className: 'c-toast__container c-toast__container--info'
    })))
  },
  options: {
    transition: Zoom,
    className: 'c-toast',
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  }
}
