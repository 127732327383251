import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
import * as PDFWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64 
export const createPDFImage = async (file) => {
  const blobData = URL.createObjectURL(file);
  const data = await fetch(blobData)
  PDFJS.GlobalWorkerOptions.workerSrc = PDFWorker;
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  if (pdf.numPages >= 1){
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    return new Promise(resolve => {
      canvas.toBlob(d => resolve(URL.createObjectURL(d)));
    });
  }
}