import React, { Component } from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import { DatePicker, MultiSelect, Checkbox } from 'shared/components'
import "react-datepicker/dist/react-datepicker.css"
import {
  searchItems,
  getPartners,
  setFilters,
  setSorting,
  togglePartnerBanner,
  toggleFiltersDropdown
} from '../../redux/searchActions'
import { connect } from 'react-redux'
import { fields, viewOptions } from '../../helpers/fields'
import { getSearchDefaultSort } from '../../helpers/sort'
import { getParameter, updateUrlParameters } from 'utils/functions'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { DEFAULT_START_DATE_TIMESTAMP, SEARCH_ITEMS, JA } from 'shared/constants'
import { setLoadingState } from 'shared/redux/sharedActions'
import { debounce } from 'utils/functions'

class Search extends Component  {

  constructor(props) {
    super(props)

    this.state = {
      viewDropdown: false,
      selectedView: 'list',
      sort: 'Relevance',
      keyword: '',
      showNotice: false,
    }
  }

  componentDidMount () {
    const defaultValues = {}
    const { hash } = window.location
    const { search, searchItems, setFilters, setSorting, getPartners, togglePartnerBanner, toggleFiltersDropdown } = this.props
    toggleFiltersDropdown(false)
    Object.keys(fields).forEach(field => {
      const { param, value} = fields[field]
      const parameter = getParameter(param)
      if(parameter) {
        if(Array.isArray(value)) {
          defaultValues[field] = parameter.split(',')
        } else if(param === 'rt' || param === 'headlines') {
          defaultValues[field] = parameter === 'true'
        } else {
          defaultValues[field] = decodeURIComponent(parameter)
        }
      } else {
        defaultValues[field] = value
      }
    })

    defaultValues.view = hash ? hash.substring(1) : 'list'

    const defaultSort = getSearchDefaultSort(search)

    setFilters(defaultValues).then(() => {
      if(search) {
        if(defaultSort) {
          setSorting(defaultSort, search)
        } else {
          searchItems(false, search)
        }
      }
    })
    this.setState({ keyword: defaultValues.keyword || '' })

    if(defaultValues.partners.length === 1){
      getPartners()
      togglePartnerBanner(true)
    }
  }

  handleSearchBtn = async (e = null) => {
    e && e.preventDefault()
    let {
      history,
      layout,
      setFilters,
      searchItems,
      filters,
      sort,
      search,
      toggleFiltersDropdown,
      langcode,
      setScreenLoading
    } = this.props
    const view = this.state.selectedView
    let base = layout !== 'full' ? `/${langcode}/activesearch` : null
    let newValues = {...filters}
    newValues.keyword = this.state.keyword

    if(document.body.clientWidth < 768 && search === SEARCH_ITEMS) {
      toggleFiltersDropdown(false)
    }

    await setFilters(newValues).then(() => {
      updateUrlParameters(history, base, newValues, sort, view)
      setScreenLoading(false)
      searchItems(false, search)
    })

  }

  handleDropdowns = (e, dropdown, option = null) => {
    const { selectedView } = this.state
    const { filtersDropdown, getPartners, toggleFiltersDropdown } = this.props
    e.preventDefault()

    if(dropdown === 'filtersDropdown') {
      getPartners()
      toggleFiltersDropdown(!filtersDropdown)
    } else {
      this.setState({
        [dropdown]: !this.state[dropdown],
        selectedView: option ? option : selectedView
      })
    }

  }

  handleCheckboxes = (e, field) => {
    const value = e.target.value
    const checked = e.target.checked
    const { filters, setFilters } = this.props
    const currentValues = filters[field]

    setFilters({
        ...filters,
        [field]: field !== 'language' && field !== 'media'
          ? !filters[field]
          : value === 'all'
            ? []
            : checked
              ? currentValues.concat(value)
              : currentValues.filter((v, i) => v !== value)
    }).then(() => this.updateSearch())
  }

  updateSearch = () => {
    const { layout, searchItems, search, setScreenLoading } = this.props

    if(layout === 'full') {
      setScreenLoading(false)
      searchItems(true, search)
    }
  }

  debouncedHandleSearch = debounce(this.handleSearchBtn, 300);

  handleFields = (value, field) => {
    if(field === 'partners') {
      this.props.togglePartnerBanner(value.length === 1)
    }

    if(field === 'keyword') {
      this.setState({ keyword: value })
      // this.debouncedHandleSearch()
    } else {
      this.props.setFilters({
        ...this.props.filters,
        [field]: value
      }).then(() => this.updateSearch())
    }
  }

  handleKeyDown = (event) => {
    if (event.which === 13) {
      this.handleSearchBtn(event)
    }
  }

  handleReset = (e) => {
    e.preventDefault()
    let values = {}
    Object.keys(fields).forEach(field => values[field] = fields[field].value)

    this.props.setFilters(values).then(() => this.updateSearch())
  }

  setDatetime = (value, input, type) => {
    const format = type === 'date' ? 'YYYY-MM-DD' : 'h:mm a'

    this.props.setFilters({
      ...this.props.filters,
      [input]: moment(value).format(format)
    }).then(() => {
      this.updateSearch()
      $('#search-btn').trigger('click')
    })
  }

  toggleNotice = (visible) => {
    this.setState({ showNotice: visible })
  }

  render () {
    if (!this.props.filters) return null

    let {
      viewDropdown,
      selectedView,
      keyword,
      showNotice
    } = this.state

    let {
      t,
      langcode,
      filtersDropdown,
      partners: partnerOptions,
      filters: {
        partners,
        media,
        language,
        startDate,
        endDate,
        startTime,
        endTime
      }
    } = this.props

    partnerOptions = partnerOptions.filter(el => el !== null)

    let startDateUTC = moment(startDate ? startDate : moment.unix(DEFAULT_START_DATE_TIMESTAMP)).toDate()
    let endDateUTC = moment(endDate ? endDate : moment.unix(DEFAULT_START_DATE_TIMESTAMP)).toDate()
    let startTimeDefault = startTime ? startTime : '12:00 PM'
    let endTimeDefault = endTime ? endTime : '12:00 PM'

    const { search, layout } = this.props

    const placeholder = t(layout === 'full' ? 'Search' : 'Search articles, videos, tweets, and more...')
    const jpSearchNotice = langcode === JA && t('Search Notice For Long Word')

    const renderViewDropdown = () => (
      <div className="c-searchform__view">
        <div className="c-searchform__view-selected"> {/* add ARIA */}
          <button
            onClick={(e) => this.handleDropdowns(e, 'viewDropdown')}
            className="c-link c-link--icon">
            <span className="c-link__icon c-link__icon--left">
              <i className="material-icons">{viewOptions[selectedView].icon}</i>
              <i className="material-icons">expand_more</i>
            </span>
          </button>
        </div>
        <ul className={`c-searchform__view-dropdown ${viewDropdown ? 'is-open' : ''}`}>
          {Object.keys(viewOptions).map(view => {
            const { name, icon, label, viewLabel } = viewOptions[view]
            if(view === 'collection') return
            return (
              <li key={name} className={`c-searchform__view-item ${selectedView === name ? 'c-searchform__view-item--selected' : ''}`}>
                <button
                  onClick={(e) => this.handleDropdowns(e, 'viewDropdown', name)}
                  className="c-link c-link--icon">
                  <span className="c-link__icon c-link__icon--left">
                    <i className="material-icons">{icon}</i>
                  </span>
                  {t(viewLabel)}
                </button>
              </li>
            )}
          )}
        </ul>
      </div>
    )

    const renderFilterbtn = () => {
      if(layout === 'full') {
        return <button
          onClick={(e) => this.handleDropdowns(e, 'filtersDropdown')}
          className="c-btn c-btn--lg c-btn--hover-fx u-color-white u-bg-teal">
          {t('More Filters')}
        </button>
      }

      return <div className='c-filters__toggle-wrapper'>
        <button
          onClick={(e) => this.handleDropdowns(e, 'filtersDropdown')}
          className="c-link c-link--icon">
          <span className="c-link__icon c-link__icon--left c-copy--bold">
            <i className="material-icons">tune</i>
            {t('Search Filters')}
          </span>
        </button>
        {jpSearchNotice
          ? <Tooltip 
            overlayClassName={`
              c-filters__toggle-tooltip 
              ${showNotice ? 'c-filters__toggle-tooltip--visible' : ''}
            `}
            trigger={['click']} 
            placement='bottomLeft' 
            title={jpSearchNotice}
            onVisibleChange={this.toggleNotice}
          >
            <span className='c-filters__toggle-info'>
              <i class="material-icons-outlined">
                info
              </i>
            </span>
          </Tooltip>
          : null}
      </div>
    }

    const renderFilters = () => (
      <div className="c-filters">
        <div className="c-filters__toggle">
          {layout !== 'full' ? renderFilterbtn() : null}
        </div>
        <div className={`c-filters__container ${filtersDropdown ? 'is-open':''}`}>
          <div className="o-grid o-grid--sm">
            <div className="o-grid__col u-9/12@md">
              <fieldset>
                <div className="c-filters__typeall">
                  <legend className="c-filters__heading">{t('Media Types')}</legend>
                  <Checkbox
                    id="type-all"
                    value="all"
                    label={t('All')}
                    name="media"
                    checked={!media || !media.length}
                    onChange={(e) => this.handleCheckboxes(e, 'media')}
                  />
                </div>
                <div className="c-filters__typerest">
                  {fields.media.options.map(({ name, label }) =>
                    <Checkbox
                      key={name}
                      id={name}
                      value={name}
                      label={t(label)}
                      name="media"
                      checked={media && media.includes(name)}
                      onChange={(e) => this.handleCheckboxes(e, 'media')}
                    />
                  )}
                </div>
              </fieldset>
              <div className="c-filters__bottom">
                <fieldset className="c-filters__partners">
                  <legend className="c-filters__heading">{t('Partners')}</legend>
                  <div className="c-filters__select">
                    <MultiSelect
                      multiple={true}
                      label={t('Select Partners')}
                      value={partners}
                      onChange={(value) => this.handleFields(value, fields.partners.param)}
                    >
                      {partnerOptions.map(partner => {
                        if (partner) {
                          const {
                            uid,
                            fieldDisplayName: displayName,
                            fieldPartnerDropdownName: dropdownName
                          } = partner
                          return (
                            <option key={`${uid}`} value={`${uid}`}>
                              {dropdownName || displayName}
                            </option>
                          )
                        }
                        return null
                      }
                      )}
                    </MultiSelect>
                  </div>
                </fieldset>
                <fieldset className="c-filters__dates">
                  <legend className="c-filters__heading">{t('Date Published (Start)')}</legend>
                  <div className="c-filters__dates-inputs">
                    <label for={`${fields.startDate.name}_start`} className="u-visually-hidden">{t('Select start Published date')}</label>
                    <DatePicker
                      id={`${fields.startDate.name}_start`}
                      selected={startDateUTC}
                      onChange={date => this.setDatetime(date, 'startDate', 'date')}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onMonthChange={date => this.setDatetime(date, 'startDate', 'date')}
                      onYearChange={date => this.setDatetime(date, 'startDate', 'date')}
                      maxDate={new Date()}
                    />
                    <input type="hidden" value={startDate || ''} id={`${fields.startDate.param}_value`} />
                  </div>
                  <div className="c-filters__dates-inputs">
                    <label for={fields.startTime.name} className="u-visually-hidden">{t('Select start Published time')}</label>
                    <DatePicker
                      id={fields.startTime.name}
                      selected={new Date(new Date().toDateString() + ' ' + startTimeDefault)}
                      onChange={time => this.setDatetime(time, fields.startTime.name, 'time')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption={t("Time")}
                      dateFormat="h:mm aa"
                    />
                    <input type="hidden" value={startTime || ''} id={`${fields.startTime.name}_value`} />
                  </div>
                </fieldset>
                <fieldset className="c-filters__dates">
                  <legend className="c-filters__heading">{t('Date Published (End)')}</legend>
                  <div className="c-filters__dates-inputs">
                    <label for={`${fields.endDate.name}_end`} className="u-visually-hidden">{t('Select end Published date')}</label>
                    <DatePicker
                      id={`${fields.endDate.name}_end`}
                      selected={endDateUTC}
                      onChange={date => this.setDatetime(date, 'endDate', 'date')}
                      showMonthDropdown
                      showYearDropdown
                      onMonthChange={date => this.setDatetime(date, 'endDate', 'date')}
                      onYearChange={date => this.setDatetime(date, 'endDate', 'date')}
                      dropdownMode="select"
                      maxDate={new Date()}
                    />
                    <input type="hidden" value={endDate || ''} id={`${fields.endDate.param}_value`} />
                  </div>
                  <div className="c-filters__dates-inputs">
                    <label for={fields.endTime.name} className="u-visually-hidden">{t('Select start Published time')}</label>
                    <DatePicker
                      id={fields.endTime.name}
                      selected={new Date(new Date().toDateString() + ' ' + endTimeDefault)}
                      onChange={time => this.setDatetime(time, fields.endTime.name, 'time')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption={t("Time")}
                      dateFormat="h:mm aa"
                    />
                    <input type="hidden" value={endTime || ''} id={`${fields.endTime.name}_value`} />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="o-grid__col u-3/12@md">
              <fieldset className="c-filters__right">
                <legend className="c-filters__heading">{t('Language')}</legend>
                {renderLanguageOptions()}
                <div className="c-filters__actions">
                  <button
                    role="reset"
                    className="c-btn c-btn--lg u-color-black u-bg-grey-100"
                    onClick={(e) => this.handleReset(e)}>
                    {t('Reset')}
                  </button>
                  <button
                    onClick={(e) => this.handleSearchBtn(e)}
                    role="submit"
                    className="c-btn c-btn--lg u-color-white u-bg-orange"
                  >
                    {t('Search')}
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    )

    const renderSearchOptions = () => {
      if(search === SEARCH_ITEMS) {
        const options = [fields.retweets, fields.headlines]

        return (
          options.map(({ name, label, icon}) =>
            <Checkbox
              key={name}
              id={name}
              value={name}
              label={t(label)}
              name={name}
              icon={icon}
              checked={this.props.filters[name]}
              onChange={(e) => this.handleCheckboxes(e, name)}
            />
          )
        )
      }

      return renderLanguageOptions()
    }

    const renderLanguageOptions = () => (
      fields.language.options.map(({ name, label, short }) =>
        <Checkbox
          key={name}
          id={name}
          value={short}
          label={t(label)}
          name="language"
          checked={language && language.includes(short)}
          onChange={(e) => this.handleCheckboxes(e, 'language')}
        />
      )
    )

    return (
      <form className={layout === 'full' ? 'c-searchformwrapper' : ''}>
        <div className={`
          ${layout === 'full' ? 'c-searchform-jpwrapper' : ''}
          ${layout === 'full' && jpSearchNotice ? 'c-searchform-jpwrapper--withnotice' : ''}
        `}>
          <div className={`c-searchform ${layout === 'full' ? 'c-searchform--full' : ''}`}>
            <fieldset className="c-searchform__bar">
              <legend style={{ height: 0, width: 0, overflow: 'hidden'}}>Search for articles, videos, tweets and more</legend>
              {layout === 'full' ? <i className="material-icons">search</i> : null}
              <label className="u-visually-hidden" htmlFor={fields.keyword.name}>Search</label>
              <input
                className="c-searchform__bar-search"
                id={fields.keyword.name}
                type="text"
                name={fields.keyword.name}
                placeholder={t(placeholder)}
                value={keyword}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.handleFields(e.target.value, fields.keyword.param)}
              />
            </fieldset>
            {layout !== 'full' ? renderViewDropdown() : null}
            <div className="c-searchform__submit">
              {layout === 'full'
                ? <>
                    {renderSearchOptions()}
                    <button
                      id="search-btn"
                      onClick={this.handleSearchBtn}
                      type="submit"
                      className="c-searchform__btn c-btn c-btn--lg c-btn--hover-fx u-color-white u-bg-orange"
                    >{t('Search')}</button>
                    {search === SEARCH_ITEMS ? renderFilterbtn() : null}
                  </>
                : <button
                    onClick={this.handleSearchBtn}
                    type="submit"
                    className="c-searchform__btn--large c-btn c-btn--lg c-btn--hover-fx u-color-white u-bg-orange"
                  >{t('Search')}</button>
              }
            </div>
          </div>
          {layout === 'full' && jpSearchNotice 
            ? <div className='c-searchform__notice'>
              <i class="material-icons-outlined">info</i>
              {jpSearchNotice}
            </div>
            : null}
        </div>
        {layout !== 'full' && jpSearchNotice 
          ? <div className='c-searchform__notice c-searchform__notice--main'>
              <i class="material-icons-outlined">info</i>
              {jpSearchNotice}
            </div> 
          : null}
        {search !== 'collections' ? renderFilters() : null}
      </form>
    )
  }
}

function mapStateToProps({ search, shared }) {
  return {
    langcode: shared.langcode,
    ...search
  }
}

export default compose(
  connect(mapStateToProps, {
    searchItems,
    getPartners,
    setFilters,
    setSorting,
    togglePartnerBanner,
    toggleFiltersDropdown,
    setScreenLoading: setLoadingState
  }),
  withTranslation()
)(Search)
