import { createAction } from '@reduxjs/toolkit'
import authTypes from './authenticatonTypes'
import * as userService from 'shared/utils/api/user'
import { updateLangCodeFlag } from 'shared/redux/sharedActions'
import { setCsrfToken } from 'utils/axios'
import toast from 'shared/utils/toast'
import i18n from 'i18n'

export const loginBegin = createAction(authTypes.LOGIN_BEGIN)
export const loginSuccess = createAction(authTypes.LOGIN_SUCCESS)
export const loginFail = createAction(authTypes.LOGIN_FAIL)
export const clearUser = createAction(authTypes.CLEAR_USER)
const getAlertBegin = createAction(authTypes.REQUEST_ALERT_CONTENT_BEGIN)
const getAlertSuccess = createAction(authTypes.REQUEST_ALERT_CONTENT_SUCCESS)
const getAlertFail = createAction(authTypes.REQUEST_ALERT_CONTENT_FAIL)
export const closeAlertBanner = createAction(authTypes.CLOSE_ALERT_BANNER)

export const loadUser = (loginId, logoutToken, csrfToken) => async (dispatch, getState) => {
  dispatch(loginBegin())
  try {
    setNewCsrfToken()
    const { auth: { user: { id } } } = getState()
    // user id is retrieved from storage or passed from login method
    const userId = loginId || id
    // verify if client is logged in before loading user
    const loginStatus = await userService.getLoginStatus()
    if (loginStatus && userId) {
      const user = await userService.getUserById(userId)
      // if user.id exists, the user was requested correctly
      if (user.id) { 
        if (loginId) {
          user.preferredLangcode && i18n.changeLanguage(user.preferredLangcode)
          dispatch(updateLangCodeFlag(user.preferredLangcode))
        }
        if (logoutToken) user.logoutToken = logoutToken
        if (csrfToken) user.csrfToken = csrfToken
        dispatch(loginSuccess(user))
        return true
      } else {
        throw new Error(`Unable to login user. No user ID found.`)
      }
    } else if (userId) {
      dispatch(clearUser())
    }
  } catch (e) {
    console.error('loadUser error: ', e)
    dispatch(loginFail(e))
    dispatch(logoutUser())
    return false
  }
}

export const logoutUser = () => async dispatch => {
  try {
    const token = await userService.getLogoutToken()
    if (token) {
      await userService.logoutUser(token)
      dispatch(clearUser())
    }
  } catch (e) { console.warn(e) }
}

const setNewCsrfToken = async () => {
  try {
    const token = await userService.getSessionToken()
    setCsrfToken(token)
  } catch (error) {

  }
}

const requestSignIn = createAction(authTypes.REQUEST_SIGN_IN)

export const authenticationWrapper = actionWithDispatch => (dispatch, getState) => {
  try {
    const { auth: { user: { roles, id} } } = getState()
    if (roles?.length > 0 && id) {
      dispatch(actionWithDispatch())
    } else {
      toast.error(`You need to login to perform this action`)
      dispatch(requestSignIn())
    }
  } catch { }
} 

export const getAlertBanner = () => async dispatch => {
  try {
    dispatch(getAlertBegin())
    const newAlert = await userService.getAlertBanner()
    dispatch(getAlertSuccess(newAlert))
  } catch {
    dispatch(getAlertFail())
  }
}
