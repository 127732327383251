import { PUBLIC_FOLDER, TESTIMONIAL } from "shared/constants"

export const processCollectionItem = item => {
  const title = item.label && item.label[0] && item.label[0].value
  return {
    ...item,
    id: item.id && item.id[0] && item.id[0].value,
    uuid: item.uuid && item.uuid[0] && item.uuid[0].value,
    langcode: item.langcode && item.langcode[0] && item.langcode[0].value,
    collectionId: item.gid && item.gid[0] && item.gid[0]['target_id'],
    title,
    sortOrder: item['field_sort_order'] && item['field_sort_order'][0] && item['field_sort_order'][0].value,
    notes: item['field_notes'] && item['field_notes'][0] && item['field_notes'][0].value,
    itemData: {
      title,
      id: item['entity_id'] && item['entity_id'][0] && item['entity_id'][0]['target_id']
    }
  }
}

export const processRestItem = item => {
  if (!item) return null
  let thumbnail = item['field_new_thumbnail_url'] && item['field_new_thumbnail_url'][0] && item['field_new_thumbnail_url'][0].value
  if (thumbnail) thumbnail = thumbnail.replace('public://', PUBLIC_FOLDER)
  const idField = item.nid ? 'nid' : 'id'
  let type = (item['field_layer_type'] && item['field_layer_type'][0] && item['field_layer_type'][0].value)
    || (item.type && item.type[0] && item.type[0]['target_id'])
  if (type && type.toLowerCase() === 'text') type = TESTIMONIAL
  return ({
    ...item,
    id: item[idField] && item[idField][0] && item[idField][0].value,
    langcode: item.langcode && item.langcode[0] && item.langcode[0].value,
    publishedDate: item['field_media_date_create'] && item['field_media_date_create'][0].value && item['field_media_date_create'][0].value,
    updatedDate: item.changed && item.changed[0] && item.changed[0].value,
    img: thumbnail || (item["field_file"] && item["field_file"][0] && item["field_file"][0].url),
    owner: (item['field_media_creator_realname'] && item['field_media_creator_realname'][0] && item['field_media_creator_realname'][0].value) 
      || (item['field_media_creator_username'] && item['field_media_creator_username'][0] && item['field_media_creator_username'][0].value),
    type,
    link: item['field_new_attribution_uri'] && item['field_new_attribution_uri'][0] && item['field_new_attribution_uri'][0].value,
    title: {
      en: (item['field_english_title'] && item['field_english_title'][0] && item['field_english_title'][0].value) || '',
      ja: (item['field_japanese_title'] && item['field_japanese_title'][0] && item['field_japanese_title'][0].value) || ''
    },
    description: {
      en: (item['field_english_description'] && item['field_english_description'][0] && item['field_english_description'][0].value) || '',
      ja: (item['field_japanese_description'] && item['field_japanese_description'][0] && item['field_japanese_description'][0].value) || ''
    },
    published: item.status && item.status[0] && item.status[0].value,
    isCollection: item.type && item.type[0] && item.type[0]['target_id'] === 'collection',
    uuid: item.uuid && item.uuid[0] && item.uuid[0].value,
    itemCount: 0,
    ownerId: item.uid && item.uid[0] && item.uid[0]['target_id'],
    notes: item['field_notes'] && item['field_notes'][0] && item['field_notes'][0].value,
    topicWeight: item['field_topic_weight'] && item['field_topic_weight'][0] && item['field_topic_weight'][0].value,
    collectionTopic: item['field_collection_topic'] && item['field_collection_topic'][0] && item['field_collection_topic'][0]['target_id']
  })
}
