import { execute } from '../utils'
import { processFeaturedResources } from './model'
import { HOMEPAGE_NODE_ID, EN, JA } from 'shared/constants'

export const getHomepageItems = async () => {
  const query = `
    query HomepageItems($id: String!, $language: LanguageId) {
      nodeById(id: $id,language: $language) {
        ... on NodeHomepage {
          title
          fieldSubtitle
          fieldLearnMore {
            url {
              path
              ... on EntityCanonicalUrl {
                pathAlias
              }
            }
          }
          fieldFeaturedResources {
            entity {
              ... on ParagraphHomepageCardsItem {
                fieldTitle
                fieldShortDescription
                fieldMediaImage {
                  entity {
                    ...on MediaImage {
                      fieldMediaImage {
                        url
                        height
                        width
                        alt
                      }
                    }
                  }
                }
                fieldLink {
                  title
                  url {
                    path
                    ... on EntityCanonicalUrl {
                      pathAlias
                    }
                  }
                  options
                }
              }
            }
          }
        }
      }
    }
  `
  const nodeId = typeof HOMEPAGE_NODE_ID === 'number' ? HOMEPAGE_NODE_ID.toString() : HOMEPAGE_NODE_ID
  const res = await execute(query, { id: nodeId, language: 'EN' })
  const jaRes = await execute(query, { id: nodeId, language: 'JA' })
  return res && !res.errors && ({
    featuredResources: processFeaturedResources(res.nodeById.fieldFeaturedResources),
    title: {
      en: res.nodeById.title,
      ja: jaRes.nodeById.title
    },
    subtitle: {
      en: res.nodeById.fieldSubtitle,
      ja: jaRes.nodeById.fieldSubtitle
    },
    learnMore: res.nodeById?.fieldLearnMore?.url && (res.nodeById.fieldLearnMore.url.pathAlias || res.nodeById.fieldLearnMore.url.path)
  })
}