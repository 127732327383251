import React from 'react'

const DotsLoader = ({ loading = true, cover = false, bgColor = false }) => {
  if (!loading) return null
  return (
    <div className={`${bgColor ? `c-dots-loader__bg u-bg-${bgColor}` : ''}`}>
      <div className={`${cover ? 'c-dots-loader__wrapper' : ''}`}>
        <div className='c-dots-loader'></div>
      </div>
    </div>
  )
}

export default DotsLoader
