import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'features/Modal'

const NotesModal = ({ isOpen, onRequestClose, submitFn, submitting, notes, canDelete }) => {
  const [text, setText] = useState('')
  const [warning, setWarning] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    if (notes && notes.length) {
      setText(notes)
    }
  }, [notes])
  const onSubmit = useCallback(() => {
    submitFn(text)
    setText('')
  }, [text, submitFn])
  const onDelete = useCallback(() => {
    submitFn('')
    setText('')
  }, [submitFn])
  const onClose = () => {
    if (notes && text != notes || !notes && text) {
      setWarning(true)
      return;
    }
    setText('')
    onRequestClose()
  }

  const renderNotesModal = () => {
    if(warning) {
      return (
        <div className='c-account-form'>
          <div className='c-account-form__header'>
            <span className='c-copy c-copy--bold u-color-white'>{t('Are you sure you want to close the window? Any unsaved notes will be lost')}</span>
          </div>
          <div className='c-account-form__content c-account-form__content--confirm'>
            <button
              className={`c-btn c-btn--sm-p c-btn--full u-color-white u-bg-orange u-mbxs `}
              onClick={() => {setWarning(false); setText(''); onRequestClose()}}
            >
              {t('Confirm')}
            </button>
            <button className='c-btn c-btn--sm-p c-btn--full u-color-white u-bg-teal' onClick={() => setWarning(false)}>
              {t('Cancel')}
            </button>
          </div>
        </div>)
    } else {
      return (
        <div className='c-account-form'>
          <div className='c-account-form__header'>
            <span className='c-copy c-copy--bold u-color-white'>{t('Notes')}</span>
          </div>
          <div className='u-p c-account-form__content--confirm'>
            <label className='c-copy c-copy--bold c-copy--xs c-copy--uppercase u-color-grey-300'>{t('Note Text')}</label>
            <textarea
              autoFocus
              value={text}
              onChange={e => setText(e.currentTarget.value)}
              rows={4}
              className='u-bg-grey-100 u-block u-mbxs u-p c-copy c-modal__notes-textarea'
            />
            {notes && notes.length && canDelete ? (
              <button
                className={`c-btn c-btn--sm-p c-btn--full c-btn--hover-fx u-color-white u-bg-red c-copy--uppercase u-mbxs ${submitting ? ' is-disabled' : ''}`}
                onClick={onDelete}
              >
                {t('Delete Note')}
              </button>
            ) : null}
            <button
              className={`c-btn c-btn--sm-p c-btn--full c-btn--hover-fx u-color-white u-bg-orange c-copy--uppercase ${submitting ? 'is-disabled' : ''}`}
              onClick={onSubmit}
              disabled={submitting || !text}
            >
              {t('Submit')}
            </button>
          </div>
        </div>
      )
    }
  }
  return (
    <Modal
      isOpen={!!isOpen}
      className='c-modal--centered c-modal--authentication c-modal--confirmation'
      contentLabel='Add Notes'
      onRequestClose={onClose}
      closeTimeoutMS={200}
      shouldReturnFocusAfterClose={false}
    >
      {renderNotesModal()}
    </Modal>
  )
}

export default NotesModal
