import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { selectUser } from 'features/authentication/redux/authenticationSelectors'
import { setAccountFormData } from 'features/AccountForm/redux/accountFormActions'
import { loadUserItems } from 'features/Collections/redux/collectionsActions'
import {
  UpdateAccountForm,
  ProfileSidebar,
  MyContributions,
  RequestNewPass
} from 'features/UserProfile/components'
import { USER_COLLECTION_COUNT, USER_ITEMS } from 'shared/constants'
import Metatags from 'shared/components/Metatags'
import { pageMeta } from 'shared/data/meta'

const UserProfile = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const { mail, preferredLangcode, roles } = user
  const isEditScreen = props.match.path === '/:langcode/user/:uid/edit'
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  useEffect(() => {
    if (mail && preferredLangcode && roles) {
      dispatch(setAccountFormData({
        mail,
        preferredLangcode,
        roles,
        accountName: mail
      }))
      dispatch(loadUserItems([USER_COLLECTION_COUNT, USER_ITEMS]))
    }
    // roles in the dependency array is causing the effect to unnecessarily fire twice so it has been removed
    /* eslint-disable-next-line */
  }, [dispatch, mail, preferredLangcode])
  if (isEditScreen) return <UpdateAccountForm editScreen />
  return (
    <div className='c-basic-page c-user-profile'>
      <Metatags tags={pageMeta['profile']} />
      <ProfileSidebar />
      <div className='c-user-profile__content'>
        <div className='c-basic-page__content'>
          <h1 className='c-heading c-heading--l1'>{t('Update Account Information')}</h1>
          <RequestNewPass />
          <UpdateAccountForm />
        </div>
        <MyContributions />
      </div>
    </div>
  )
}

export default UserProfile

