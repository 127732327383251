import { createReducer } from '@reduxjs/toolkit'
import accountFormTypes from 'features/AccountForm/redux/accountFormTypes'
import { EN, JA } from 'shared/constants'
import authTypes from './authenticatonTypes'

const defaultState = {
  user: {
    id: null,
    name: '',
    displayName: '',
    mail: '',
    language: '',
    avatar: null,
    joinDate: '',
    roles: []
  },
  alertBanner: {
    id: null,
    changedDate: null,
    showIcon: false,
    en: {
      viewed: false,
      content: ''
    },
    ja: {
      viewed: false,
      content: ''
    }
  }
}

export default createReducer(defaultState, {
  [authTypes.LOGIN_SUCCESS]: (state, action) => {
    state.user = {
      ...defaultState.user,
      logoutToken: state.user.logoutToken,
      csrfToken: state.user.csrfToken,
      ...action.payload
    }
  },
  [authTypes.LOGIN_FAIL]: () => {
    return defaultState
  },
  [authTypes.CLEAR_USER]: () => {
    return defaultState
  },
  [accountFormTypes.UPDATE_ACCOUNT_SUCCESS]: (state, action) => {
    state.user = { ...state.user, ...action.payload }
  },
  [authTypes.REQUEST_ALERT_CONTENT_BEGIN]: (state) => {
    const alertBanner = state.alertBanner
    if (!alertBanner) {
      state.alertBanner = defaultState.alertBanner
    }
  },
  [authTypes.REQUEST_ALERT_CONTENT_SUCCESS]: (state, action) => {
    const newAlert = action.payload
    if (!newAlert || !newAlert.id) {
      state.alertBanner = defaultState.alertBanner
      return
    }
    const { id, changedDate, showIcon, enContent, jaContent } = newAlert
    const alertBanner = state.alertBanner
    if (id !== alertBanner.id || changedDate !== alertBanner.changedDate) {
      state.alertBanner = {
        id,
        changedDate,
        showIcon,
        en: { viewed: false, content: enContent },
        ja: { viewed: false, content: jaContent }
      }
    }
  },
  [authTypes.CLOSE_ALERT_BANNER]: (state, action) => {
    const lang = [EN, JA].includes(action.payload) ? action.payload : EN
    state.alertBanner[lang].viewed = true
  }
})
