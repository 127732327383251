import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserItems } from 'features/Collections/redux/collectionsSelectors'
import MyContributionItem from './components/MyContributionItem'
import { useTranslation } from 'react-i18next'

const MyContributions = () => {
  const {t} = useTranslation()
  const contributions = useSelector(selectUserItems)
  if (!contributions.length) return null

  return (
    <div className='c-user-profile__contributions'>
      <div className='c-heading c-heading--l3'>{t("My Contributions")}</div>
      <ul className='c-user-profile__list'>
        {contributions.map(({ ...rest }, index) => (
          <li key={index}>
            <MyContributionItem
              {...rest}
            />
          </li>
        ))}
      </ul>
      <a className='c-link c-link--default' href='mailto:info@jdarchive.org'>
        {t("To update or remove a previous submission, please contact the site administrator.")}
      </a>
    </div>
  )
}

export default MyContributions
