import React, { memo, useEffect, useState, useRef, useCallback } from 'react'
import { setFilters } from 'features/Search/redux/searchActions'
import { selectSearch } from 'features/Search/redux/searchSelectors'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { useSelector, useDispatch, connect } from 'react-redux'
import { debounce, select } from 'utils/functions'
import { loadItemModal } from 'features/Collections/redux/collectionsActions'
import $ from 'jquery'
import moment from 'moment'
import mapboxgl from 'mapbox-gl'
import { map_config, mapOptions } from '../../utils/settings'
import {
  callHeatmapApi,
  setHeatmapCount,
  performProximitySearch,
  mapLog,
  aggrInput,
  solrApi,
  getResults,
  setupHoverCounts,
  layersControl_UI,
  addLegend,
  removeLegend,
  resizeMapLegendContainer,
  getCollectionItems,
  toggleLabelsDisplay
} from '../../utils/functions'
import { ITEM } from 'shared/constants'
import DotsLoader from 'shared/components/DotsLoader'
import { compose } from 'redux'

let filters

const contributePageTester = (src) => /contributeMap/.test(src);

const MapContainer = ({ page, location = [], setLocation = null, items = [], onDrop, partners, keyword, ...props }) => {
  const [mapState, setMapState] = useState(null)
  const [markerState, setMarkerState] = useState(null)
  const [sourceDataLoading, setSourceDataLoading] = useState(false)
  const [layerInit, setLayerInit] = useState(false)
  const mapContainer = useRef(null)
  const dispatch = useDispatch()
  const searchState = useSelector(selectSearch)
  const langCode = useSelector(selectLangcodeFlag)
  
  const debouncedSubmit = useCallback(
    debounce(() => {
      submit();
    }, 300), []);

  useEffect(() => {
    page == 'searchMap' && debouncedSubmit();
  }, [partners, keyword])

  const locationMarker = () => {
    let el = document.createElement('div')
    el.classList.add('point-marker')
    return el
  }

  filters = searchState.filters
  let map

  const openModal = (id) => {
    dispatch(loadItemModal({ id, type: ITEM }))
  }

  const updateTimelineSliderResults = () => {
    let start_date = select('#map-start-date').value
    let end_date = select('#map-end-date').value

    dispatch(setFilters({
      ...filters,
      startDate: start_date && moment(start_date).format('YYYY-MM-DD'),
      endDate: end_date && moment(end_date).format('YYYY-MM-DD')
    }))
    submit()
  }

  const submit = (e = null, reset = false) => {
    let query
    if (!query) {
      query = aggrInput(reset)
    } else {
      if ((query.match(/\?/g) || []).length > 0) {
        query = query.replace("?", "")
      }
    }

    let search = query
    let page = window.location.pathname

    if (page.indexOf('activesearch') > 0) {
      getResults(solrApi("items", search))
      initializeMapHeatmapLayer(reset)
    }
  }

  const setCollectionMarkers = () => {

    const sourceData = {
      "type": "FeatureCollection",
      "features": getCollectionItems(items)
    }

    if (map.getSource('jda-collection')) {
      map.getSource('jda-collection').setData(sourceData)
      return
    }

    map.addSource('jda-collection', {
      "type": "geojson",
      "data": sourceData
    })
    map.addLayer({
      "id": "jda-collection",
      "type": "circle",
      "source": "jda-collection",
      "minzoom": 1,
      "paint": {
        "circle-radius": 8,
        "circle-color": "rgb(255,0,2)"
      }
    }, 'waterway-label')

    map.on('click', 'jda-collection', (event) => {
      const feature = event.features[0]
      if (event.features.length > 100) {
        const lnglat = {
          "lng": parseFloat(feature.geometry.coordinates[0]),
          "lat": parseFloat(feature.geometry.coordinates[1])
        }
        // this pin shares coordinates with other pins. Show proximity search.
        performProximitySearch(openModal, map, lnglat, 0.0001, onDrop)
      } else if (event.features.length > 0) {
        const { item_id } = feature.properties

        if (item_id) {
          openModal(item_id)
        }
      }
    })

    map.on('mousemove', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['jda-collection'] })
      map.getCanvas().style.cursor = features.length ? 'pointer' : ''
    })

  }

  const initializeMapHeatmapLayer = (reset = false) => {
    let query = aggrInput(reset)
    query = query.replace('partners=', 'uid=')
    query = query === '' ? '' : `${query}&`
    let request = `${map_config.search_api_url}/heatmap?${query}`

    return callHeatmapApi(map, request, {
      field: map_config.solr_location_field
    })
      .then(setHeatmapCount)
      .catch((err) => console.error("request error:", err))
  }

  const initializeMapUserInteraction = () => {
    let zoom = Number(map.getZoom().toFixed(0))

    // Handle user zooming and panning
    map.on('moveend', debounce(() => initializeMapHeatmapLayer(), 500)) // prevent moveend from firing heatmap search api requests repeatedly during browser resize

    map.on('zoom', () => {
      // let newZoom = map.getZoom()
    })

    // Actions specific to user changing zoom level
    map.on('zoomend', () => mapLog("Zoom level changed to:" + map.getZoom()))

    //Perform proximity search when user clicks on map
    map.on('click', (event) => {
      let lngLat = event.lngLat
      // Check for click on Sea of Japan label
      if (lngLat.lat < 40.930 && lngLat.lat > 39.420 && lngLat.lng > 133.394 && lngLat.lng < 135.986) {
        //TODO: open about the map resource page
        window.open(`/${langCode}/resources/guides-resources/using-map`, '_blank')
      } else {
        if (map.getLayer('jda-heat').visibility === 'visible') {
          performProximitySearch(openModal, map, event.lngLat, null, onDrop)
        }
      }
    })

    map.on('mousemove', (event) => {
      let lngLat = event.lngLat

      if (lngLat.lat < 40.930 && lngLat.lat > 39.420 && lngLat.lng > 133.394 && lngLat.lng < 135.986) {
        map.getCanvas().style.cursor = 'pointer'
      } else {
        map.getCanvas().style.cursor = ''
      }
    })

    map.on('click', 'jda-point', (event) => {
      let lngLat = event.features[0].properties
      performProximitySearch(openModal, map, lngLat, null, onDrop)
    })

    map.on('mouseenter', 'jda-point', (event) => {
      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', 'jda-point', (event) => {
      map.getCanvas().style.cursor = ''
    })


    // Attached events to search and filter inputs to fire map search
    const $submitBtns = $('button[role="submit"]')
    const $resetBtn = $('button[role="reset"]')
    const $inputs = $("input[name='media'], input[name='language'], input[name='dates'], #retweets, #headlines")
    $inputs.off()
    $submitBtns.off()
    $resetBtn.off()
    $inputs.on('change', submit)
    $submitBtns.on('click', submit)
    $resetBtn.on('click', (e) => submit(e, true))
  

    setupHoverCounts(map)
  }

  const initializeMapLegends = () => {
    function legend_container() { }

    legend_container.prototype.onAdd = () => {
      let $container = document.createElement('div')
      $container.setAttribute('id', 'legend-container')
      $container.style.display = 'none'
      return $container
    }
    map.addControl(new legend_container(), 'bottom-left')

    map.on('overlayadd', (overlay) => {
      addLegend(map, overlay.id)
    })

    map.on('overlayremove', (overlay) => {
      removeLegend(map, overlay.id)
    })
    resizeMapLegendContainer()
  }

  const initializeTimelineSlider = () => {
    function timeSliderControl() { }

    timeSliderControl.prototype.onAdd = () => {
      let $container = document.createElement('div')
      $container.setAttribute('id', 'map-timeline-slider')
      let subdiv = document.createElement('div')
      subdiv.classList.add('leaflet-touch')
      subdiv.innerHTML =
        '<div id="slider-ui">' +
        '<div id="slider-range"></div>' +
        '<div id="slider-time-container">' +
        '<input type="text" name="map-start-date" id="map-start-date" value="" class="map-datetimepicker">' +
        '<div id="map-start-date-label"></div><div id="map-end-date-label"></div>' +
        '<input type="text" name="map-end-date" id="map-end-date" value="" class="map-datetimepicker">' +
        '</div>' +
        '</div>'
      $container.append(subdiv)
      return $container
    }

    map.addControl(new timeSliderControl())

    let $pickerContainer = $('.map-datetimepicker') // Needs to be a jQuery Obj
    let $mapStartDate = select('#map-start-date')
    let $mapEndDate = select('#map-end-date')

    if ($pickerContainer.length > 0) {
      $pickerContainer.datetimepicker({
        dateFormat: 'mm/dd/yy',
        timeFormat: 'hh:mm tt',
        autoUpdateInput: true,
        showSecond: false,
        showMillisec: false,
        showMicrosec: false,
        showTimezone: false,
        onSelect: () => {
          $('#slider-range').slider('values', 0, new Date($mapStartDate.value / 1000))
          $('#slider-range').slider('values', 1, new Date($mapEndDate.value / 1000))
          updateTimelineSliderResults()
        }
      })
    }

    /*
     * Prevent clicks and drags on the timeline slider from affecting the map
     */
    $('#map-timeline-slider').on('click', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
    $('#map-timeline-slider').on('mousedown dragenter dragstart dragend dragleave dragover drag drop', (e) => {
      if (!$(e.target).hasClass('map-datetimepicker')) {
        e.stopPropagation()
        e.preventDefault()
      }
    })
    $('#slider-range').on('dragenter dragstart dragend dragleave dragover drag drop', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })

    // handle mousewheel properly when hovering over layer UI. prevent from zooming the map
    $(".control-layers-overlays").on("mousewheel", (e) => {
      e.stopPropagation()
    })
    // handle mousewheel properly when hovering over layer UI. prevent from zooming the map
    $(".legend-container").on("mousewheel", (e) => {
      e.stopPropagation()
    })

    // set up the timer
    let dt_from = '2011/3/11 00:00:00' /* Matches previous JDA system */

    let min_val = Date.parse(dt_from) / 1000
    let max_val = Math.ceil(Date.now() / 1000)

    $('#slider-range').slider({
      range: true,
      min: min_val,
      max: max_val,
      values: [min_val, max_val],
      slide: (e, ui) => {
        const dt_cur_from = new Date(ui.values[0] * 1000) //.format('yyyy-mm-dd hh:ii:ss')
        $mapStartDate.value = moment(dt_cur_from).format('MM/DD/yyyy hh:mm a')
        const mapStartDateEvent = new Event('change')
        $mapStartDate.dispatchEvent(mapStartDateEvent)

        const dt_cur_to = new Date(ui.values[1] * 1000) //.format('yyyy-mm-dd hh:ii:ss')
        $mapEndDate.value = moment(dt_cur_to).format('MM/DD/yyyy hh:mm a')
        const mapEndDateEvent = new Event('change')
        $mapEndDate.dispatchEvent(mapEndDateEvent)

        e.stopPropagation()
      },
      start: (e) => e.stopPropagation(),
      stop: () => updateTimelineSliderResults()
    })

    // Set up initial values
    if (select('#map-start-date')) select('#map-start-date').value = moment(min_val * 1000).format('MM/DD/yyyy hh:mm a')
    if (select('#map-end-date')) select('#map-end-date').value = moment(max_val * 1000).format('MM/DD/yyyy hh:mm a')
    select('.mapboxgl-ctrl-top-right').style.pointerEvents = 'all'
  }

  const updateContributeMapMarker = (map = mapState) => {
    if (location && location.length > 0) {
      if (markerState) { // If there is a marker in the map, move it
        markerState.setLngLat(location)
        map.setCenter(location)
      } else { // If ther isnt on, creat it and add it to the map
        let markerEl = locationMarker()
        markerEl.addEventListener('click', (e) => {
          setLocation({})
          e.stopPropagation()
        })

        let marker = new mapboxgl.Marker(markerEl).setLngLat(location).addTo(map)

        map.setCenter(location)
        setMarkerState(marker)
      }
    } else {
      if (markerState) {
        markerState.remove()
      }
      setMarkerState(null)
    }
  }

  const initializeMap = () => {
    const options = mapOptions[page] || mapOptions['defaultMap']
    const { style, zoom, center: defaultCenter, minZoom, maxZoom, scrollZoom } = options
    const center = location.length ? location : defaultCenter
    const container = page
    mapboxgl.accessToken = process.env.NODE_ENV === 'development'
      ? map_config.devAccessToken
      : map_config.accessToken

    map = new mapboxgl.Map({ container, style, center, zoom, minZoom, maxZoom, scrollZoom })

    map.on('load', () => {
      setMapState(map)

      // Add zoom and rotation controls to the map.
      if (page !== 'previewMap') {
        map.addControl(new mapboxgl.NavigationControl(), 'top-left')
      }
    })

    switch (page) {
      case 'searchMap':
        map.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        }), 'bottom-right')

        map.on('load', () => {
          map.resize()
          initializeMapHeatmapLayer()
            .then(() => {
              initializeMapUserInteraction()
              layersControl_UI(map, setSourceDataLoading) // initialize map layers
              initializeMapLegends()
              setLayerInit(true)
            })
          initializeTimelineSlider()
        })
      case 'itemMap':
        new mapboxgl.Marker(locationMarker()).setLngLat(center).addTo(map)
        break
      case 'previewMap':
        if (location.length) {
          let previewMarkerEl = locationMarker()
          let previewMarker = new mapboxgl.Marker(previewMarkerEl).setLngLat(center).addTo(map)
          map.setCenter(center)
          setMarkerState(previewMarker)
        }

        break
      case contributePageTester(page):
        let latitude
        let longitude

        map.on("click", (e) => {
          latitude = e.lngLat.lat
          longitude = e.lngLat.lng

          setLocation({ latitude, longitude, address: '' })
        })

        updateContributeMapMarker(map)

        break
      case 'collectionMap':
        // Need to map through items and add markers to map
        map.on('load', () => {
          setCollectionMarkers()
        })
      // What should be the center?
      default:
        break
    }
  }

  const updateMap = () => {
    if (contributePageTester(page)) {
      updateContributeMapMarker()
    }

    if (page === 'previewMap') {
      if (markerState) {
        markerState.setLngLat(location)
        mapState.setCenter(location)
      }
    }
  }

  useEffect(() => {
    if (!mapState) {
      initializeMap()
    } else {
      updateMap()
    }
  }, [location])

  useEffect(() => {
    if (layerInit) {
      toggleLabelsDisplay(langCode)
    }
  }, [langCode, layerInit])

  const pageClass = contributePageTester(page) ? 'contributeMap' : page;

  return (
    <>
      <div id={page} className={`c-map c-map--${pageClass}`} ref={el => (mapContainer.current = el)} />
      {sourceDataLoading && (
        <div className='c-map__loading-indicator'>
          <DotsLoader cover bgColor='loading' />
          <div className='c-map__loading-text'>Loading layer source ...</div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(states) {
  const { search } = states
  const { filters } = search
  let view = filters?.view;
  let partners = filters?.partners;
  let keyword = filters?.keyword;

  return {
    view, partners, keyword
  }
}

export default connect(mapStateToProps)(memo(MapContainer))
