import React, { useCallback, useRef } from 'react'
import { Select } from 'antd'

const NewTagBox = ({ newTag, tabIndex, onChange, onTagSubmit, handleSearch, searchResults }) => {
  const ref = useRef()
  const renderSelectOptions = useCallback(() => {
    const taxExists = !!searchResults.find(t => t.name === newTag)
    const concatted = [{ tid: 0, name: newTag }, ...searchResults]
    return (taxExists ? searchResults : concatted).map(option => {
      return (
        <Select.Option key={option.tid} value={option.name.toString()}>
          {option.name}
        </Select.Option>
      );
    });
  }, [newTag, searchResults])
  const width = ((ref && ref.current && ref.current.clientWidth) || 0) + 30
  return (
    <>
      <span ref={ref} className='c-tag-manager__field-size-calculator'>{newTag}</span>
      <Select
        autoFocus
        defaultActiveFirstOption
        open={!!(newTag.length && tabIndex !== '-1')}
        mode='combobox'
        style={{ width: !newTag.length ? 66 : width > 430 ? 430 : width }}
        showSearch
        dropdownMatchSelectWidth={false}
        showArrow={false}
        value={newTag}
        onChange={onChange}
        onSelect={onTagSubmit}
        onSearch={handleSearch}
        placeholder='NEW'
        tabIndex={tabIndex}
        notFoundContent={() => <></>}
      >
        {renderSelectOptions()}
      </Select>
    </>
  )
}

export default NewTagBox
