import React from 'react';

class CollectionItemGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spans: 0
    }
    this.imageRef = React.createRef()
  }

  componentDidMount() {
    this.imageRef.current.addEventListener('load', this.setSpans)
  }

  setSpans = () => {
    const height = this.imageRef.current.clientHeight
    const spans = Math.ceil(height / 10 )
    this.setState({ spans: spans })
  }

  render() {
    const { image, drag, opacity, onClick } = this.props
    const { description, src } = image

    return (
      <button
        className="c-results__photo-grid_item"
        type="button"
        ref={drag} style={{ gridRowEnd: `span ${this.state.spans}`, opacity }}
        onClick={onClick}
      >
        <img ref={this.imageRef} src={src} alt={description} />
      </button>
    )
  }
}

export default CollectionItemGrid
