import React from 'react'
import { useTranslation } from 'react-i18next'

import { Facebook, Twitter }from 'features/Global/components'
import { useSelector } from 'react-redux'
import { selectUserIsAdmin } from 'features/authentication/redux/authenticationSelectors'
import { JdaLink } from 'shared/components'
import { ADMIN_SITE_PATH } from 'shared/constants'

const Footer = () => {
  const isAdmin = useSelector(selectUserIsAdmin)
  const { t } = useTranslation()
  return (
    <footer className='c-footer'>
      <div className='c-footer__links'>
        <div className='c-footer__col'>
          <div className='c-footer__harvard' />
          <a
            className='u-color-white'
            href='https://projects.iq.harvard.edu/jdrc'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('Japan Digital Research Center')}
          </a>
          <span className='u-color-teal u-ph'>|</span>
          <a
            className='u-color-white'
            href='https://rijs.fas.harvard.edu/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('Edwin O. Reischauer Institute of Japanese Studies')}
          </a>
          {isAdmin &&
            <>
              <span className='u-color-teal u-ph'>|</span>
              <a className='u-color-white' href={ADMIN_SITE_PATH}>{t('Admin')}</a>
            </>
          }
        </div>
        <div className='c-footer__col'>
          <span className='c-footer__col c-footer__col--social u-color-white'>
            <Facebook width={9} />
            <a
              className='c-footer__url'
              href='https://www.facebook.com/Digital-Archive-of-Japans-2011-Disasters-143040332434759'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='u-sr-only'>{t('Visit the JDA Facebook page')}</span>
            </a>
          </span>
          <span className='c-footer__col c-footer__col--social u-ml'>
            <Twitter width={20} />    
            <a
              className='c-footer__url'
              href='https://twitter.com/jdarchive'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='u-sr-only'>{t('Visit the JDA Twitter')}</span>
            </a>
          </span>
          <JdaLink className='u-color-white u-mh c-footer__contact' to='/resources/guides-resources/provide-feedback'>
           {t('Contact Us')}
          </JdaLink>
        </div>
      </div>
    </footer>
  )
}

export default Footer
