import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const PrivacyToggle = ({ onChange, defaultChecked = false }) => {
  const {t} = useTranslation()
  const [checked, setChecked] = useState(defaultChecked)
  const onCheck = () => {
    setChecked(!checked)
    onChange && onChange(!checked)
  }
  return (
    <div className='c-privacy-toggle'>
      <input
        className='c-privacy-toggle__input'
        type='checkbox'
        onChange={onCheck}
        checked={checked}
      />
      <div className='c-privacy-toggle__container u-bg-teal' onClick={onCheck}>
        <div className='c-privacy-toggle__tab' onClick={onCheck} />
        <div className='c-privacy-toggle__text'>
          <span className='c-copy c-copy--bold u-color-white'>{checked ? '' : t('Private')}</span>
          <span className='c-copy c-copy--bold u-color-white'>{checked ? t('Public') : ''}</span>
        </div>
      </div>
    </div>
  )
}

export default PrivacyToggle
