import React from 'react'
import Modal from 'features/Modal'
import AccountForm from 'features/AccountForm'
import { useSelector, useDispatch } from 'react-redux'
import { selectAccountModalIsOpen, selectAccountModalType } from 'features/AccountForm/redux/accountFormSelectors'
import { closeAccountModal, setAccountModalType } from 'features/AccountForm/redux/accountFormActions'

const AccountModal = () => {
  const dispatch = useDispatch()
  const modalType = useSelector(selectAccountModalType)
  const modalOpen = useSelector(selectAccountModalIsOpen)
  const onRequestClose = () => dispatch(closeAccountModal())
  const setOpenModal = type => dispatch(setAccountModalType(type))
  
  return (
    <Modal
      isOpen={modalOpen}
      className='c-modal--centered c-modal--authentication'
      contentLabel='Sign up modal'
      onRequestClose={onRequestClose}
    >
      <AccountForm formType={modalType} setOpenModal={setOpenModal} /> 
    </Modal>
  )
}

export default AccountModal
