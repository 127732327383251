import React, { useCallback, useState } from 'react'
import { LanguageTabber } from 'shared/components'
import MediaIcon from '../MediaIcon'
import { MapContainer } from 'features/Map'
import {
  AUDIO,
  DOCUMENT,
  IMAGE,
  TESTIMONIAL,
  VIDEO,
  WEBSITE,
  ARTICLE,
  HEADLINE,
  TWEET
} from 'shared/constants'
import { Video } from 'features/BasicPage'
import { parseHtmlToReact } from 'shared/utils/tools/parse'
import { formatItemDate } from 'shared/utils/tools/datetime'
import { TagManager } from 'features/Form/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTagToSelectedItem,
  updateItem,
  removeTagFromSelectedItem
} from 'features/Contribute/redux/contributeActions'
import { authenticationWrapper } from 'features/authentication/redux/authenticationAction'
import { selectContributeSubmitting } from 'features/Contribute/redux/contributeSelectors'
import ContributeFieldModal from './subcomponents/ContributeFieldModal'
import RelatedCollections from './subcomponents/RelatedCollections'
import { debounce } from 'utils/functions'
import { useTranslation } from 'react-i18next'
import { closeItemModal } from 'features/Collections/redux/collectionsActions'
import verifyWaybackLink from './helpers/verifyWaybackLink'

const renderPreview = (mediaType, mediaSrcObj, fileBlob, shouldRenderImage, validImageExt, detail) => {
  const { img, link, url, pdfThumbnail } = mediaSrcObj
  switch (mediaType) {
    case ARTICLE:
    case HEADLINE:
    case DOCUMENT:
    case WEBSITE:
    case IMAGE:
    case TESTIMONIAL:
      if (validImageExt && mediaType === WEBSITE || shouldRenderImage ) {
        return (
            <img
              className={`c-contribution-item__media ${detail ? 'c-contribution-item__media--detail' : ''}`}
              src={pdfThumbnail || fileBlob || img} alt=''
            />
          )
      } else {        
        return  <></>
      }
    case AUDIO:
    case VIDEO:
      return (
        <div className={`c-contribution-item__media c-contribution-item__media--fill-width ${detail ? 'c-contribution-item__media--detail' : ''}`}>
          <Video
            className='c-video--contribution'
            fallbackComponent={shouldRenderImage && validImageExt ? <img className='c-contribution-item__media' src={img} alt='' /> : <></>}
            height='auto'
            width='auto'
            src={link || url}
          />
        </div>
      )
    default:
      break;
  }
}

const ContributionItem = ({ activeTab, preview = false, data = {}, onTabChange, detail = false, editable = false, onEditClicked = () => {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [updateField, setUpdateField] = useState(null)
  const closeModal = useCallback(() => dispatch(closeItemModal()), [dispatch])
  const addNewTag = useCallback(tag => { 
    dispatch(authenticationWrapper(() => addTagToSelectedItem(tag)))
  }, [dispatch])
  const deleteTag = useCallback(debounce(tag => {
    dispatch(removeTagFromSelectedItem(tag))
  }, 500, true), [dispatch])
  const setField = useCallback((value, label) => {
    setUpdateField({ value, language: activeTab, label })
    setUpdateModalOpen(true)
  }, [setUpdateField, setUpdateModalOpen, activeTab])
  const onSubmit = useCallback(async text => {
    await dispatch(updateItem({ 
      id: data.id,
      [updateField.value]: { [updateField.language]: text },
      langcode: data.langcode
    }))
    setUpdateModalOpen(false)
  }, [dispatch, updateField, data, setUpdateModalOpen])
  const submitting = useSelector(selectContributeSubmitting)
  const {
    pdfThumbnail,
    mediaType,
    type,
    title,
    description,
    date,
    publishedDate,
    lon,
    lat,
    location,
    tags,
    owner,
    partnerName,
    user,
    url,
    file,
    img,
    id,
    link,
    relatedCollections,
    showViewSourceButton,
    avoidUsingImage,
    allowThumbnailsAllMedia,
    removeViewSourceWebsiteArticles,
    fieldNewUri
  } = data
  const latitude = lat || (location && location.latitude) || false
  const longitude = lon || (location && location.longitude) || false
  const displayName = (user && (user.displayName || user.username)) || owner
  const activeTitle = title[activeTab]
  const activeDescription = description[activeTab]
  const useType = mediaType || type
  const shouldRenderImage = useCallback(() => {
    const typesControlledByFlag = [TWEET, VIDEO, DOCUMENT, AUDIO, HEADLINE]
    const isControlledType = typesControlledByFlag.includes(useType)
    if (!preview) {
      if (avoidUsingImage) return false
      if (isControlledType && !allowThumbnailsAllMedia) return false
    }
    return true
  }, [useType, avoidUsingImage, allowThumbnailsAllMedia, preview])
  const validateImageExt = useCallback(() => {
    const imgExtensions = ['jpg', 'jpeg', 'png', 'gif']
    const ext = img && img.split('.').pop()
    // allowing images to be shown even if broken only on preview
    if (pdfThumbnail) return true
    if (preview) return true
    if (!ext) return false
    if (!imgExtensions.includes(ext)) return false
    return true
  }, [img, preview])
  if (!data) return null

  const mediaPreview = renderPreview(useType, { img, link, url, pdfThumbnail }, (file && file.preview), shouldRenderImage(), validateImageExt(), detail)
  const content = (
    <>
      <div className='u-pb u-pt32 u-pl32 u-pr32'>
        <div className='c-heading c-heading--l4 u-flex u-jc-between'>
          {activeTitle ? activeTitle
          : <div></div>}
          {editable && <button className='c-btn c-btn--bare ' onClick={onEditClicked}>
                <div className='c-copy c-copy--bold c-copy--icon'>
                  <span className='c-copy__icon c-copy__icon--md'>
                    <i className='material-icons u-color-teal'>edit</i>
                    {t('Edit')}
                  </span>
                </div>
              </button>}
        </div>
        <div className="c-collection-item__subtitle c-copy c-copy--sm c-copy--italic c-copy--icon">
          {(date || publishedDate) && <span className='c-collection-item__subitem'>{t('Published')} {formatItemDate(date || publishedDate)}</span>}
          <span className='c-collection-item__subitem'>
            {t('via')} <span className='c-copy c-copy--underline'>{partnerName || displayName}</span>
          </span>
          {(date || publishedDate) && (
            <span className="c-collection-item__subitem c-copy__icon">
              <i className='material-icons'>person</i>
              {displayName}
            </span>)
          }
          <span className='c-collection-item__subitem c-copy__icon'>
            <MediaIcon type={useType} />
            {t(useType)}
          </span>
          {url && (
            <span className='c-collection-item__subitem'>
              {preview ? (
                <span className='c-link c-link--default c-contribution-item__view-source'>
                  {t('View Source')}
                </span>
              ) : (
                  <a href={url} className='c-link c-link--default' target='_blank' rel='noopener noreferrer'>
                    {url}
                  </a>
                )}
            </span>
          )}
          {!detail && (id || url) && (
            <span className='c-collection-item__subitem'>
              <a href={id ? `/${activeTab}/item/${id}` : undefined} className='c-link c-link--default' target='_blank' rel='noopener noreferrer'>
                {preview ? t('Item URL') : t('Citation URL')}
              </a>
            </span>
          )}
        </div>
      </div>
      <div className='u-pb32 u-pt u-ph32 c-contribution-item__scroll'>
        <div className='c-contribution-item__intro'>
          {mediaPreview}
          <div>
            {activeDescription ? <div className='c-wysiwyg'>{parseHtmlToReact(activeDescription)}</div>
              : null}
            {verifyWaybackLink(fieldNewUri) && (
              <a
                className='c-btn c-btn--sm u-bg-orange u-color-white c-contribution-item__view-source c-copy--uppercase u-mr'
                href={fieldNewUri}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('View Archived Source')}
              </a>
            )}
            {!preview && showViewSourceButton && !(removeViewSourceWebsiteArticles && [WEBSITE, ARTICLE].includes(useType)) && (
              <a
                className='c-btn c-btn--sm u-bg-orange u-color-white c-contribution-item__view-source c-copy--uppercase'
                href={link ? link : undefined}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('View Source')}
              </a>
            )}
          </div>
        </div>
        {detail && <h3 className="u-mv c-copy--uppercase">{t('Source Text')}</h3>}
        <div>
          {!preview || tags.length ? <h3 className="u-mv c-copy--uppercase">{t('Tags')}</h3> : null}
          <TagManager hideAdd={preview} tags={tags} addNewFn={addNewTag} deleteFn={deleteTag} onTagLinkClick={closeModal} disableDelete={true}/>
        </div>
        {longitude && latitude &&
          (
            <>
            <h3 className="u-mv c-copy--uppercase">{t('Location')}</h3>
              <div className="c-contribution-item__location">
                {typeof location === 'string' && <span><i className="material-icons">place</i>{location}</span>}
                {location && location.address && <span><i className="material-icons">place</i>{location.address}</span>}
                <div className="c-contribution-item__location-coords">
                  <span>Lat: {latitude}</span>
                  <span>Lng: {longitude}</span>
                </div>
              </div>
              <MapContainer page={`${preview ? 'previewMap' : 'itemMap'}`} location={[longitude, latitude]} />
            </>
          )
        }
        {!detail && relatedCollections && relatedCollections.length ?
          <RelatedCollections data={relatedCollections} /> : null}
      </div>
    </>
  )
  return (
    <div className='c-contribution-item'>
      {!detail ? (
      <LanguageTabber
        setActiveTab={onTabChange && (value => onTabChange(value))}
        activeTab={activeTab}
        className={`c-contribution-item__tabber ${preview ? 'c-contribution-item__tabber--preview' : ''}`}
        contentClassName='c-contribution-item__content'
      >
        {content}
      </LanguageTabber>
      ) : content}
      <ContributeFieldModal
        isOpen={updateModalOpen}
        onRequestClose={() => setUpdateModalOpen(false)}
        data={updateField}
        submitFn={onSubmit}
        submitting={submitting}
      />
    </div>
  )
}

export default ContributionItem