import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setCollectionForm, setItemModalType } from 'features/Collections/redux/collectionsActions'
import { EDIT_COLLECTION } from 'shared/constants'
import { useTranslation } from 'react-i18next'
const EditCollectionBtn = ({ data }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch()
  const {
    description,
    items,
    title,
    img,
  } = data || {}
  const collectionImages = items && items.reduce((imgs, nextItem) => {
    if (nextItem.itemData?.img) imgs.push(nextItem.itemData.img)
    return imgs
  }, [])

  if (img) collectionImages.unshift(img);
  
  const uniqueCollectionImages = [...new Set(collectionImages)];

  const openEditCollectionModal = useCallback(() => {
    dispatch(setItemModalType(EDIT_COLLECTION))
    dispatch(setCollectionForm({ ...data, description, title, itemImages: uniqueCollectionImages }))
  }, [dispatch, data, description, title, uniqueCollectionImages])

  return (
    <button type='button' onClick={openEditCollectionModal} className='c-btn c-btn--md u-color-white u-bg-orange c-btn--hover-fx c-copy c-copy--sm'>
      {t('EDIT COLLECTION')}
    </button>
  )

}

export default EditCollectionBtn
