import React, { useState } from 'react'
import Translate from 'shared/components/Translate'
import { useTranslation } from 'react-i18next'
import { parseHtmlToReact } from 'shared/utils/tools/parse';
import { useSelector } from 'react-redux';
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors';
import { EN, JA } from 'shared/constants';

const CollectionTopic = ({ count, topic, children, editFn = null, reorderFn, position, isLast, editing, editable = false }) => {
  const {t} = useTranslation();
  const siteLangcode = useSelector(selectLangcodeFlag)

  const { englishTitle, japaneseTitle, englishDescription, japaneseDescription } = topic
  const [open, setOpen] = useState(true)
  const empty = count === 0

  let description = '';
  if(!siteLangcode) description = englishDescription || japaneseDescription || ''
  if(siteLangcode === EN) description = englishDescription || japaneseDescription || ''
  if(siteLangcode === JA) description = japaneseDescription || englishDescription || ''

  return (
    <div className="c-collection-topic">
      <div className="c-collection-topic__header">
        <div className="c-collection-topic__info">
          <div className="c-heading c-heading--l6 u-mbxm">
            {editing && `${t('Editing')}: `}
            <Translate en={englishTitle} ja={japaneseTitle} />
            {editFn && editable && <button className="c-btn c-btn__icon c-btn--bare c-btn__icon--sm" onClick={() => editFn(topic.id)}>
              <i className="material-icons">edit</i>
            </button>}
          </div>
          <p className="c-collection-topic__blurb">
            {open ? parseHtmlToReact(description) : count > 1 ? t('Number of Items', {'0': count}) : t('Number of Item', {'0': count})}
          </p>
        </div>
        <div className="c-collection-topic__actions">
          {!empty > 0 && (<button className="c-btn c-btn__icon c-btn--bare" onClick={() => setOpen(!open)}>
            <i className="material-icons-outlined">{open ? 'indeterminate_check_box' : 'add_box'}</i>
          </button>
          )}
          {englishTitle !== 'Uncategorized' && editable && (
            <>
              <button
                className="c-btn c-btn__icon c-btn--bare"
                onClick={() => reorderFn(topic.id, position - 1)}
                disabled={position === 0}
              >
                <i className="material-icons">arrow_upward</i>
              </button>
              <button
                className="c-btn c-btn__icon c-btn--bare"
                onClick={() => reorderFn(topic.id, position + 1)}
                disabled={isLast}
              >
                <i className="material-icons">arrow_downward</i>
              </button>
            </>
          )}
        </div>
      </div>
      <div className={`c-collection-topic__items ${empty || (!empty && open) ? 'is-open' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default CollectionTopic
