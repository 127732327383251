import React from 'react'
import ReactModal from 'react-modal'

// this hides the app from screen readers and other assistive technologies
// while the modal is active
ReactModal.setAppElement('#root')

export const ModalHeader = ({ title }) => {
  return (
    <div className='c-modal__header'>
      <span className='u-color-white c-heading c-heading--l6'>{title}</span>
    </div>
  )
}

const Modal = ({ children, className = '', overlayClassName = '', contentStyle = {}, overlayStyle = {}, style, ...props }) => {

  return (
    <ReactModal
      className={{
        base: `c-modal ${className}`,
        beforeClose: 'is-closed',
        afterOpen: 'is-open'
      }}
      overlayClassName={{
        base: `c-modal__overlay ${overlayClassName}`,
        afterOpen: 'c-modal__overlay--after-open',
        beforeClose: 'c-modal__overlay--before-close'
      }}
      bodyOpenClassName='c-modal__body'
      closeTimeoutMS={150}
      style={style ? style : {
        overlay: overlayStyle,
        content: contentStyle
      }}
      {...props}
    >
      {children}
    </ReactModal>
  )
}

export default Modal

