import { get, post, del, patch } from "utils/axios"
import {
  buildCollectionPayload,
  buildCollectionItemPayload,
  buildEditCollectionPayload,
  buildTextSlidePayload,
  buildTopicPayload,
  buildTopicResponse,
  buildTopicItemPayload
} from "./helpers"
import { processRestItem, processCollectionItem } from './model'
import { createFile } from "../item"
import { getUserById } from "../user"
import { TEXT_SLIDE } from "shared/constants"

// CRUD Collection
export const createCollection = async (formData, user) => {
  let fid
  if (formData.image && formData.image.file) {
    fid = await createFile(formData.image.file)
  }
  const url = '/entity/group/?_format=json'
  const payload = buildCollectionPayload(formData, user, fid)
  const res = await post(url, payload, { withCredentials: true }, { csrfToken: true })
  const processed = processRestItem(res)
  processed.items = []
  return processed
}

// export const addFileToCollection = async (id, imageFile) => {
//   const url = `/jsonapi/group/collection/${id}/field_file`
//   const config = {
//     headers: {
//       'Content-Disposition': `filename="${imageFile.name}"`,
//       'Content-Type': 'application/octet-stream'
//     }
//   }

//   const imageBinary = await readFileAsArrayBufferAsync(imageFile)
//   const res = await post(url, imageBinary, config)
//   const imgUrl = res && res.data && res.data.attributes && res.data.attributes.uri.url
//   return imgUrl && imgUrl.replace('public://', PUBLIC_FOLDER)
// }

export const deleteCollection = async id => {
  const url = `/group/${id}?_format=json`
  return del(url)
}

export const editCollection = async (formData, currentLang = '') => {
  const { id } = formData
  let fid
  if (formData.image && formData.image.file) {
    fid = await createFile(formData.image.file)
  }
  const url = `/group/${id}?_format=json`
  const payload = buildEditCollectionPayload(formData, fid, currentLang)
  const res = await patch(url, payload)
  const processed = res && processRestItem(res)
  processed.isCollection = true
  return processed
}

// CRUD Collection Items
export const createCollectionItem = async (user, collection, item) => {
  const url = '/entity/group_content?_format=json'
  const payload = buildCollectionItemPayload(user.id, collection.id, item)
  const res = await post(url, payload)
  return res && processCollectionItem(res)
}

export const bulkCreateCollectionItem = async (user, requestConfigs) => {
  const url = '/subrequests?_format=json'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': user.csrfToken
  }
  const payload = requestConfigs.map(({ requestItem, col }, index) => (
    {
      requestId: `req-${index + 1}`,
      uri: '/entity/group_content?_format=json',
      action: 'create',
      body: JSON.stringify(buildCollectionItemPayload(user.id, col.id, requestItem)),
      headers
    }
  ))
  const res = await post(url, payload)
  const requests = (res && res['req-1'] && Object.values(res)) || []
  return requests && requests.map(request => processCollectionItem(JSON.parse(request.body)))
}

export const editCollectionItem = (collectionItem, collectionId) => {
  const url = `/group/${collectionId}/content/${collectionItem.id}?_format=json`
  const payload = buildCollectionItemPayload(null, null, collectionItem, true) 
  const res = patch(url, payload)
  return res && processCollectionItem(res)
}

export const deleteCollectionItem = (id, collectionId) => {
  const url = `/group/${collectionId}/content/${id}?_format=json`
  return del(url)
}

export const bulkEditCollectionItem = async (items, collectionId, user, inTopic) => {
  const url = '/subrequests'
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      'X-CSRF-Token': user.csrfToken
    }
  }
  const payload = items.map((colItem, index)=> {
    return ({
      requestId: `req-${index + 1}`,
      uri: `/group/${collectionId}/content/${colItem.id}?_format=json`,
      action: 'update',
      body: JSON.stringify(buildCollectionItemPayload(user.id, collectionId, colItem, true, inTopic)),
      headers: config.headers
    })
  })
  await post(url, payload, config)
}

export const getCollection = async id => {
  const url = `/collection/${id}?_format=json`
  const res = await get(url)
  return processRestItem(res)
}

// CRUD Text Slide
export const createAddAssignTextSlide = async (formData, collectionId, csrfToken) => {
  const url = '/subrequests?_format=json'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  let payload = [
    {
      requestId: "req-1",
      uri: "/node?_format=json",
      action: "create",
      body: JSON.stringify(buildTextSlidePayload(formData)),
      headers
    },
    {
      requestId: "req-2",
      waitFor: ["req-1"],
      uri: "/entity/group_content?_format=json",
      action: "create",
      body: `{
        "type":[{ "target_id":"collection-group_node-text_slide",
        "target_type":"group_content_type" } ],
        "gid":[ { "target_id":${collectionId} } ],
        "entity_id":[ { "target_id":"{{req-1.body@$.nid.0.value}}"}]
      `,
      headers
    }
  ]
  // only add topic if topic exists
  // if (formData.topic) {
    let moveTo = formData.topic === '' ? null : formData.topic
    payload[1].body += `,"field_collection_topic":[{ "target_id": ${moveTo} } ]`
    payload[1].body += `,"field_topic_weight":[{ "value": 1 }]`
  // }
  // closes the object of req-2
  payload[1].body += `\n}`
  const res = await post(url, payload)
  const itemData = res && res['req-1'] && processRestItem(JSON.parse(res['req-1'].body))
  const textSlide = res && res['req-2#body{0}'] && processRestItem(JSON.parse(res['req-2#body{0}'].body))
  if (itemData && textSlide) {
    textSlide.itemData = itemData
    textSlide.fieldCollectionTopic = { entity: { id: textSlide.collectionTopic } }
    textSlide.type = TEXT_SLIDE
  }
  return textSlide
}

export const getTextSlide = async id => {
  const url = `/node/${id}?_format=json`
  const res = await get(url)
  const processed = processRestItem(res)
  if (res && res.uid && res.uid[0] && res.uid[0]['target_id']) {
    const user = await getUserById(res.uid[0]['target_id'])
    processed.owner = user?.displayName
  }
  return processed
}

export const editTextSlide = async formData => {
  const url = `/node/${formData.id}?_format=json`
  const payload = buildTextSlidePayload(formData)
  const res = await patch(url, payload)
  return processRestItem(res)
}

export const addExistingTextSlideToCollections = async (textSlideId, collectionIds, csrfToken) => {
  const url = '/subrequests?_format=json'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const payload = collectionIds.map((id, index) => (
    {
      requestId: `req-${index + 1}`,
      uri: "/entity/group_content?_format=json",
      action: "create",
      body: `{
        "type":[{ "target_id":"collection-group_node-text_slide",
        "target_type":"group_content_type" } ],
        "gid":[ { "target_id": ${id} } ],
        "entity_id":[ { "target_id": "${textSlideId}" }]
      }`,
      headers
    }
  ))
  return await post(url, payload)
}

// CRUD Topic 
export const createAddAssignTopic = async (formData, topicsOrder, collectionId, csrfToken) => {
  const url = '/subrequests?_format=json'
  let topicIds = ''
  topicIds += topicsOrder.map(id => id === 0 ? `{"target_id": "{{req-1.body@$.id.0.value}}"}`:`{"target_id": ${id}}`)

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  }
  const payload = [
    {
      requestId: "req-1",
      uri: "/entity/collection_topic?_format=json",
      action: "create",
      body: JSON.stringify(buildTopicPayload(formData)),
      headers
    },
    {
      requestId: "req-2",
      waitFor: ["req-1"],
      uri: `/group/${collectionId}?_format=json`,
      action: "update",
      body: `{"type":[{"target_id":"collection","target_type":"group_type"}],"field_collection_topic":[${topicIds}]}`,
      headers
    }
  ]

  const res = await post(url, payload)
  const newTopic = res && res['req-1'] && processRestItem(JSON.parse(res['req-1'].body))
  // const textSlide = res && res['req-2#body{0}'] && processRestItem(JSON.parse(res['req-2#body{0}'].body))

  if (newTopic) {
    return {
      update: false,
      position: topicsOrder.findIndex(id => id === 0),
      topic: {
        entity: buildTopicResponse(newTopic)
      }
    }
  }

  return false
}

export const updateTopic = async (formData, topicId) => {
  const url = `/entity/collection_topic/${topicId}?_format=json`
  const payload = buildTopicPayload(formData)
  const res = await patch(url, payload)
  return {
    update: true,
    topic: {
      entity: buildTopicResponse(processRestItem(res))
    }
  }
}

export const deleteTopic = async (id) => {
  const url = `/entity/collection_topic/${id}?_format=json`
  return del(url)
}

export const moveItemToTopic = async (item, topic, group, weight = null) => {
  const url = `/group/${group}/content/${item}?_format=json`

  const payload = buildTopicItemPayload(topic, weight)
  const res = await patch(url, payload)

  return res && processCollectionItem(res)
}

// CRUD Membership - This section deals with adding other users (contributors) to collections
/** @returns uid | string */
export const validateUserAccount = async (email) => {
  try {
    const url = `/api/get/userId?email=${email}`
    const res = await get(url)
    if (!res?.account[0]?.uid || res.account[0].uid === null) throw new Error('No user exists with this email.')
    return res.account[0].uid
  } catch (e) {
    if (e instanceof Error) {
      console.log('validateUserAccount', e)
    }
    return false
  }
}

export const inviteUser = async (groupId, email, userId) => {
  try {
    const url = `/entity/group_content?_format=json`
    const payload = {
      "type": [{ "target_id": "collection-group_membership", "target_type": "group_content_type" }],
      "gid": [{ "target_id": groupId }],
      "entity_id": [{ "target_id": userId }]
    }
    const res = await post(url, payload)
    const member = {
      mid: res?.id[0]?.value,
      email: email,
      name: res?.label[0]?.value,
      uid: res?.entity_id[0]?.target_id
    }
    return member
  } catch (e) {
    if (e instanceof Error) {
      console.log('inviteUserToCollection', e)
    }
  }
}

export const deleteUser = async (groupId, entityId) => {
  try {
    const url = `/group/${groupId}/content/${entityId}?_format=json`
    const res = await del(url)
    return res
  } catch (e) {
    if (e instanceof Error) {
      console.log('deleteUserFromCollection',e)
    }
  }
}

/** @returns {[number]} */
export const getContributorCollectionsForUser = async (uid) => {
  try {
    const url = `/api/get/usersCollection?uid=${uid}`
    const res = await get(url)
    if (!res?.collections || !res?.collections?.collections_groups) throw new Error('No response with this user.')
    const { collections_groups } = res.collections
    if (collections_groups.length < 1) return []
    return collections_groups.map(coll => coll.gid)
  } catch (e) {
    if (e instanceof Error) {
      console.log('find collections as contributor', e)
    }
    return
  }
}