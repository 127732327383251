import { createAction } from '@reduxjs/toolkit'
import contributeTypes from './contributeTypes'
import * as itemService from 'shared/utils/graphql'
import * as restItemService from 'shared/utils/api/item'
import toast from 'shared/utils/toast'
import { setLoadingState } from 'shared/redux/sharedActions'
import { EN, JA } from 'shared/constants'
import { getItemModalData } from 'features/Collections/redux/collectionsActions'

export const setContributeFormData = createAction(contributeTypes.SET_DATA)
export const setContributeMultiFormData = createAction(contributeTypes.SET_MULTIFORM_DATA)
export const removeContributeMultiFormItem = createAction(contributeTypes.REMOVE_MULTIFORM_ITEM)
export const clearContributeFormData = createAction(contributeTypes.CLEAR_DATA)
export const setContributeFormLanguage = createAction(contributeTypes.SET_LANGUAGE)
export const setContributeFormStep = createAction(contributeTypes.SET_STEP)
export const removeContributeFormTag = createAction(contributeTypes.REMOVE_TAG)
export const removeContributeFormMultipleTag = createAction(contributeTypes.REMOVE_MULTIPLE_TAG)
export const toggleContributeFormLanguage = createAction(contributeTypes.TOGGLE_LANGUAGE)
export const toggleContributeFormMultipleLanguage = createAction(contributeTypes.TOGGLE_MULTIPLE_LANGUAGE)
export const resetContributeForm = createAction(contributeTypes.RESET_FORM)
export const setContributeFormMediaType = createAction(contributeTypes.SET_MEDIA_TYPE)
export const setContributeLocation = createAction(contributeTypes.SET_LOCATION)
export const setContributeMultipleLocation = createAction(contributeTypes.SET_LOCATION_FOR_MULTIPLE)
export const setContributeUser = createAction(contributeTypes.SET_USER)

export const contributeFormSubmitBegin = createAction(contributeTypes.FORM_SUBMIT_BEGIN)
export const contributeFormSubmitSuccess = createAction(contributeTypes.FORM_SUBMIT_SUCCESS)
export const contributeFormSubmitFail = createAction(contributeTypes.FORM_SUBMIT_FAIL)

export const setIsMultipleUpload = createAction(contributeTypes.SET_MULTIPLE_UPLOAD)
export const setMultipleType = createAction(contributeTypes.SET_MULTIPLE_TYPE)
export const addMoreWebsite = createAction(contributeTypes.ADD_MORE_WEBSITES)
export const updateWebsiteField = createAction(contributeTypes.UPDATE_WEBSITE_FIELD)

export const contributeFormSubmit = () => async (dispatch, getState) => {
  dispatch(contributeFormSubmitBegin())
  dispatch(setLoadingState(true))
  try {
    const { contribute: { data, isMultipleUpload, multiUploadType }, auth: { user: { csrfToken }} } = getState()
    let res;
    if (!isMultipleUpload){
      res = await restItemService.createItem(data)
    } else {
      if (multiUploadType == 2) {
        const filteredData = data.multiForm.filter(d=>d?.uri);
        res = await restItemService.createItems(filteredData, csrfToken);
      } else {
        res = await restItemService.createItems(data.multiForm, csrfToken);
      }
    }
    dispatch(contributeFormSubmitSuccess(res))
    dispatch(setLoadingState(false))
  } catch (error) {
    console.log(error)
    if (error && error.response && error.response.data && error.response.data.message) {
      const responseMessage = error.response.data.message
      const messages = responseMessage.split('\n').slice(1)
      const matchedMessages = messages.reduce((msgArr, nextMsg) => {
        const match = nextMsg.match(/(\w+).*(?:value)?:\s(.+)/)
        if (match) {
          return msgArr.concat(match[2])
        }
        return msgArr
      }, [])
      matchedMessages.forEach(msg => toast.error(msg))
    }
    dispatch(setLoadingState(false))
    dispatch(contributeFormSubmitFail())
  }
}

export const searchTagsBegin = createAction(contributeTypes.SEARCH_TAGS_BEGIN)
export const searchTagsSuccess = createAction(contributeTypes.SEARCH_TAGS_SUCCESS)
export const searchTagsFail = createAction(contributeTypes.SEARCH_TAGS_FAIL)


export const searchTags = value => async dispatch => {
  dispatch(searchTagsBegin())
  try {
    if (value.length) {
      const tags = await itemService.searchTags(value)
      if (tags && tags[0]) {
        dispatch(searchTagsSuccess(tags[0]))
      }
    } else {
      dispatch(searchTagsSuccess([]))
    }
  } catch {
    dispatch(searchTagsFail())
  }
}

export const createTagBegin = createAction(contributeTypes.CREATE_TAG_BEGIN)
export const createTagSuccess = createAction(contributeTypes.CREATE_TAG_SUCCESS)
export const createTagFail = createAction(contributeTypes.CREATE_TAG_FAIL)


export const createTag = tag => async dispatch => {
  dispatch(createTagBegin())
  try {
    const regex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g
    const tagLang = regex.test(tag) ? JA : EN
    if (tagLang === JA || tag.length) {
      const res = await restItemService.createTag(tag)
      if (res) {
        dispatch(createTagSuccess(res))
      }
    } else {
      toast.error(`English tags require at least one character`)
    }
  } catch {
    dispatch(createTagFail())
  }
}

export const addContributeFormTagBegin = createAction(contributeTypes.ADD_TAG_BEGIN)
export const addContributeFormTagSuccess = createAction(contributeTypes.ADD_TAG_SUCCESS)
export const addContributeFormTagSuccessMultiple = createAction(contributeTypes.ADD_MULTIPLE_TAG_SUCCESS)
export const addContributeFormTagFail = createAction(contributeTypes.ADD_TAG_FAIL)
export const setApplyTagsToAll = createAction(contributeTypes.SET_APPLY_TO_ALL)

export const addContributeFormTag = (tag, type = 'single', uuid, applyToAll = false) => async (dispatch, getState) => {
  dispatch(addContributeFormTagBegin())
  try {
    const { contribute: { data: { tags }, tagResults } } = getState()
    const tagExists = !!tags.find(t => t.name === tag)
    const selectedTagResult = tagResults.find(t => t.name === tag)
    if (tagExists) {
      toast.error(`Tag already exists`)
    } else {
      if (!selectedTagResult) {
        await dispatch(createTag(tag))
      }
      if (type == 'single') {
        dispatch(addContributeFormTagSuccess(tag))
      } else {
        dispatch(addContributeFormTagSuccessMultiple({ uuid: uuid, tag, applyToAll }))
      }

    }
  } catch {
    toast.error(`There was an error adding the tag`)
    dispatch(addContributeFormTagFail())
  }
}

export const addTagToItemBegin = createAction(contributeTypes.ADD_TAG_TO_SELECTED_ITEM_BEGIN)
export const addTagToItemSuccess = createAction(contributeTypes.ADD_TAG_TO_SELECTED_ITEM_SUCCESS)
export const addTagToItemFail = createAction(contributeTypes.ADD_TAG_TO_SELECTED_ITEM_FAIL)


export const addTagToSelectedItem = tag => async (dispatch, getState) => {
  dispatch(addTagToItemBegin())
  try {
    const { contribute: { tagResults }, collections: { selectedItemData } } = getState()
    const tagExists = !!selectedItemData.tags.find(t => t.name === tag)
    const selectedTagResult = tagResults.find(t => t.name === tag)
    if (tagExists) {
      toast.error(`Tag already exists`)
    } else {
      let newTag = selectedTagResult
      if (!selectedTagResult) newTag = await restItemService.createTag(tag)
      await restItemService.addTagToItem(newTag.tid, selectedItemData.id)
      dispatch(addTagToItemSuccess({ ...newTag, allowDelete: true }))
    }
  } catch {
    dispatch(addTagToItemFail())
  }
}

export const updateItemBegin = createAction(contributeTypes.UPDATE_SELECTED_ITEM_BEGIN)
export const updateItemSuccess = createAction(contributeTypes.UPDATE_SELECTED_ITEM_SUCCESS)
export const updateItemFail = createAction(contributeTypes.UPDATE_SELECTED_ITEM_FAIL)

export const updateItem = (item, callback) => async (dispatch) => {
  dispatch(setLoadingState(true))
  try {
    await restItemService.updateItem(item)
    await dispatch(getItemModalData(item.id, 'item', true))
    dispatch(setLoadingState(false))
    callback && callback();
  } catch {
    dispatch(setLoadingState(false))
  }
}

export const removeTagFromItemBegin = createAction(contributeTypes.REMOVE_TAG_FROM_SELECTED_ITEM_BEGIN)
export const removeTagFromItemSuccess = createAction(contributeTypes.REMOVE_TAG_FROM_SELECTED_ITEM_SUCCESS)
export const removeTagFromItemFail = createAction(contributeTypes.REMOVE_TAG_FROM_SELECTED_ITEM_FAIL)

export const removeTagFromSelectedItem = tag => async (dispatch, getState) => {
  dispatch(removeTagFromItemBegin())
  try {
    const { collections: { selectedItemData } } = getState()
    const res = await restItemService.removeTagFromItem(tag, selectedItemData.id)
    if (res) {
      dispatch(removeTagFromItemSuccess(tag))
    } else {
      toast.error(`There was an error removing ${tag.name}`)
    }
  } catch {
    dispatch(removeTagFromItemFail())
  }
}
