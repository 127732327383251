import React from 'react'

export default (props) => (
  <svg width="17px" height="16px" viewBox="0 0 17 16" version="1.1" {...props}>
      <title>9568536C-3C20-4112-8C86-18592548874E</title>
      <g id="Alerts-Notifications-close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Home-Notification-close-1" transform="translate(-1387.000000, -106.000000)" fill="#FFFFFF">
              <g id="Icons/icon-close" transform="translate(1387.799721, 106.000000)">
                  <path d="M9.38363608,7.99937854 L15.713088,14.328284 C16.0956373,14.7111008 16.0956373,15.3300707 15.713088,15.7104016 C15.3305387,16.0932184 14.7120012,16.0932184 14.331936,15.7104016 L8,9.38398198 L1.66806397,15.7128874 C1.28551467,16.0957042 0.666977177,16.0957042 0.28691197,15.7128874 C-0.0956373234,15.3300707 -0.0956373234,14.7111008 0.28691197,14.3307698 L6.61636392,8.00186437 L0.28691197,1.67047308 C-0.0956373234,1.28765633 -0.0956373234,0.668686398 0.28691197,0.288355473 C0.478186617,0.0969470986 0.729079336,0.00248582304 0.977487968,0.00248582304 C1.22838069,0.00248582304 1.47678932,0.0969470986 1.66806397,0.288355473 L8,6.61726093 L14.331936,0.28586965 C14.5232107,0.0944612755 14.7716193,0 15.022512,0 C15.2734048,0 15.5218134,0.0944612755 15.713088,0.28586965 C16.0956373,0.668686398 16.0956373,1.28765633 15.713088,1.66798726 L9.38363608,7.99937854 L9.38363608,7.99937854 Z" id="icon-close" transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) "></path>
              </g>
          </g>
      </g>
  </svg>
)
