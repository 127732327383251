import { execute } from '../utils'
import { ALL_RESOURCES_QUERY, getSinglePageNodeQuery } from './query'

export const getResources = async () => {
  const query = `
    query {
      nodeQuery(filter: {conditions: {field: "type", value: "page"}}, limit: 50) {
        count
        entities {
          ... on NodePage {
            langcode {
              value
            }
            path {
              alias
              pid
              langcode
              pathauto
            }
            title
            fieldComponents {
              entity {
                ... on ParagraphWysiwyg {
                  fieldTitle
                  fieldBody {
                    processed
                  }
                }
                ... on ParagraphSplitContentSection {
                  fieldTitle
                  fieldSplitContent {
                    entity {
                      ... on ParagraphSplitContent {
                        fieldTitle
                        fieldBody {
                          processed
                        }
                        fieldImage {
                          entity {
                            ... on MediaImage {
                              fieldMediaImage {
                                url
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on ParagraphLinkCardSection {
                  fieldTitle
                  fieldFeaturedLinks {
                    entity {
                      ... on ParagraphLinkCard {
                        fieldTitle
                        fieldShortDescription
                        fieldLink {
                          uri
                          url {
                            routed
                            path
                          }
                          options
                        }
                      }
                    }
                  }
                }
                ... on ParagraphVideo {
                  fieldVideo {
                    entity {
                      ... on MediaVideo {
                        fieldMediaVideoFile {
                          entity {
                            url
                          }
                        }
                      }
                      ... on MediaRemoteVideo {
                        fieldMediaOembedVideo
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  return await execute(query)
}

export const getMultiLangResources = async () => {
  return await execute(ALL_RESOURCES_QUERY)
}

export const getSinglePageResource = async id => {
  const query = getSinglePageNodeQuery(id)
  return await execute(query)
}
