import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextInput } from 'features/Form/components'
import PasswordStrength from '../PasswordStrength'

const calcScore = pass => {
  const conditions = pass ? {
    containsLowercase: pass.match(/(?=.*[a-z])/),
    containsUppercase: pass.match(/(?=.*[A-Z])/),
    containsNumber: pass.match(/(?=.*[0-9])/),
    containsPunctuation: pass.match(/(?=.*\W)/)
  } : {}
  const lengthScore = !pass ? 0 : pass.length < 6 ? pass.length * 5 : 60
  const conditionCount = Object.values(conditions).filter(v => v).length
  const score = lengthScore + (conditionCount === 2 ? 15 : conditionCount === 3 ? 27.5 : conditionCount > 3 ? 40 : 0)
  return [score, conditions, conditionCount]
}

const PasswordHandler = ({ data, onChange }) => {
  const { t } = useTranslation()
  const [score, conditions, conditionCount] = calcScore(data.newPass)
  return (
    <>
      <div className='c-form__item'>
        <TextInput
          required
          name='newPass'
          label={t('New Password')}
          type='password'
          labelClassname='c-text-input__label--user-profile'
          value={data.newPass}
          onChange={onChange}
          className='c-account-form__input'
        />
      </div>
      <PasswordStrength score={score} />
      <div className='c-form__item'>
        <TextInput
          required
          name='newPassConfirm'
          label={t('Confirm New Password')}
          type='password'
          labelClassname='c-text-input__label--user-profile'
          value={data.newPassConfirm}
          onChange={onChange}
          className='c-account-form__input'
        />
        {data.newPass && data.newPassConfirm && (
          <div className='c-copy'>
            <span className='u-mr'>
              {t('Password Match:')}
            </span>
            <span className={data.newPass === data.newPassConfirm ? 'u-color-black' : 'u-color-red'}>
              {t(data.newPass === data.newPassConfirm ? 'YES' : 'NO')}
            </span>
          </div>
        )}
      </div>
      {conditionCount < 4 || !data.newPass || data.newPass.length < 6 ? (
        <div className='c-copy c-copy--sm u-p' style={{ maxWidth: '30rem', border: '1px solid #B4B4B4' }}>
          {t('To make your password stronger:')}
          <ul className='u-pl'>
            {!data.newPass || data.newPass.length < 6 ? <li>{t('Make it at least 6 characters')}</li> : null}
            {!conditions.containsLowercase && <li>{t('Add lowercase letters')}</li>}
            {!conditions.containsUppercase && <li>{t('Add uppercase letters')}</li>}
            {!conditions.containsNumber && <li>{t('Add numbers')}</li>}
            {!conditions.containsPunctuation && <li>{t('Add punctuation')}</li>}
          </ul>
        </div>
      ) : null}
    </>
  )
}

export default PasswordHandler
