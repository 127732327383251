import React, { useState } from 'react'
import moment from 'moment'
import { EN } from 'shared/constants'

const PartnerBanner = ({ clearPartner, partner, langCode }) => {
  const [visible, setVisibility] = useState(true)

  if(!visible) return null

  const handleClose = () => {
    setVisibility(false)
    clearPartner()
  }

  const lang = langCode === EN ? '' : 'Japanese'
  const langAlt = langCode === EN ? 'Japanese' : ''

  const { userPicture, created } = partner

  const title = partner[`field${lang}DisplayName`] || partner[`field${langAlt}DisplayName`] || ''
  const description = partner[`field${lang}Description`] || partner[`field${langAlt}Description`] || ''

  return (
    <div className="c-partner">
      <div onClick={() => handleClose()} className="c-partner__close">
        <i className="material-icons">close</i>
      </div>
      {userPicture && userPicture.url && (
        <img src={userPicture.url} alt={title} />
      )}
      <div className="c-partner__text">
        <div className="c-partner__text_name">
          {title}
        </div>
        {created && (
          <div className="c-partner__text_date">
            Joined on {moment.unix(created).format('MM/DD/YY')}
          </div>
        )}
        <div className="c-partner__text_description">
          {description}
        </div>
      </div>
    </div>
  )
}

export default PartnerBanner