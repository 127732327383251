export const WEBSITE = 'Website'
export const DOCUMENT = 'Document'
export const AUDIO = 'Audio'
export const IMAGE = 'Image'
export const TESTIMONIAL = 'Testimonial'
export const TWEET = 'Tweet'
export const VIDEO = 'Video'
export const BOOKMARK = 'Bookmark'
export const ARTICLE = 'Article'
export const HEADLINE = 'Headline'
export const BROADCAST = 'Broadcast'

// https://adkgroup.atlassian.net/browse/JD8M-276?focusedCommentId=126533
export const WEBSITE_MEDIA_ID = 37
export const WEBSITE_LAYER_ID = 24

export const AUDIO_MEDIA_ID = 27
export const AUDIO_LAYER_ID = 4

export const DOCUMENT_MEDIA_ID = 30

export const TESTIMONIAL_MEDIA_ID = 34
export const TESTIMONIAL_LAYER_ID = 20

export const TWEET_MEDIA_ID = 35
export const TWEET_LAYER_ID = 22

export const IMAGE_MEDIA_ID = 32
export const IMAGE_LAYER_ID = 9

export const VIDEO_MEDIA_ID = 36
export const VIDEO_LAYER_ID = 23
