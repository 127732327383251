import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ManageContributorButton({
  onClick = () => {},
  hasContributor = false
}) {
  const { t } = useTranslation()

  return (
    <button
      className='c-contributor-manage-button'
      onClick={onClick}
      data-hascontributor={hasContributor}
    >
      {hasContributor ? t('Manage') : t('Invite Contributors')}
    </button>
  )
}