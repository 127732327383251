import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'SEARCH'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
  'SET_SORTING',
  'SET_FILTERS',
  'SET_PAGINATION',
  'SET_SEARCH_TYPE',
  'TOGGLE_PARTNER_BANNER',
  'TOGGLE_FILTERS_DROPDOWN'
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'GET_ITEMS',
  'GET_ITEM',
  'GET_PARTNERS'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
