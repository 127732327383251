import React from 'react';
import ReactDOM from 'react-dom';
import { DragAndDrop } from 'shared/components';
import $ from 'jquery'
import axios from 'axios'
import { select } from 'utils/functions'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/widgets/slider'
import 'jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon'
import mapboxgl from 'mapbox-gl'
import { map_config, map_vars } from './settings'
import toast from 'shared/utils/toast'
import jdaMapLayers from './mapLayers'
import { EN, JA } from 'shared/constants'
import { ItemBox } from 'features/Collections'
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const closeTimelineSliderDatePicker = () => {
  if (isTimelineSliderDatePickerOpen()) {
    $('.map-datetimepicker').datetimepicker('hide')
  }
}

const isTimelineSliderDatePickerOpen = () => {
  const $datePickerDiv = select('#ui-datepicker-div')

  if ($datePickerDiv) {
    if ($datePickerDiv.style.display === 'block') {
      return true
    }
  }
  return false
}

/*
 * Configure km proximity search radius based on a given zoom level of the map (map.getZoom())
 * Tighten the search radius as user zooms in.
 */
const getProximitySearchRadiusByZoomLevel = (zoom_level) => {
  // setup zoom_level : km radius search proximities
  const config = {
    0: 2500,
    1: 2250,
    2: 2000,
    3: 1000,
    4: 500,
    5: 100,
    6: 50,
    7: 30,
    8: 10,
    9: 5,
    10: 2,
    11: 1,
    12: 0.5,
    13: 0.2,
    14: .02,
    15: .01,
    16: .01,
    17: .01,
    18: .01,
    19: .01,
    20: .01
  }

  return config[zoom_level]
}

const aggrInput = (reset = false) => {
  let params = ''

  let keyword = select('input#title').value

  params += keyword && keyword !== '' ? `&keyword=${keyword}` : ''

  if (reset) {
    return params
  }

  let mediaTypes = $("input[name='media']:checked").map(function () {
    return $(this).val()
  }).get().join()

  params += mediaTypes === 'all' ? '' : `&media_types=${mediaTypes}`

  const date_start_value = select("#date_start_value").value
  const startTime_value = select("#startTime_value").value

  if (!date_start_value == "") {
    let startDateTime = date_start_value
    if (!startTime_value == "") {
      startDateTime += ' ' + startTime_value
    }
    params += `&content_date_start=${startDateTime}`
  }

  const date_end_value = select("#date_end_value").value
  const endTime_value = select("#endTime_value").value

  if (!date_end_value == "") {
    let endDateTime = date_end_value
    if (!endTime_value == "") {
      endDateTime += ' ' + endTime_value
    }
    params += `&content_date_end=${endDateTime}`
  }

  let language = $("input[name='language']:checked").map(function () {
    return $(this).val()
  }).get().join()

  params += language === '' ? '' : `&language=${language}`

  let partners = $(".c-filters__partners select").val()
  partners = partners.length ? partners.join() : false
  params += partners ? `&partners=${partners}` : ''

  // Check if retweets is on
  if (select('#retweets')) {
    if (select('#retweets').checked) {
      params += '&rt=true'
    }
  }

  // Check if headlines is on
  if (select('#headlines')) {
    if (select('#headlines').checked) {
      params += '&headlines=true'
    }
  }

  params = params.substring(1)
  return params
}

/*
 * Logging for devel/debug
 */
const mapLog = (message) => {
  if (map_config.debug_log_enabled) {
  }
}

const setHeatmapCount = (data) => {
  let $counter = select("#results-count")
  let $map = select('#searchMap')
  if (data.response && $map) {
    $counter.innerHTML = data.response.numFound.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + " Items"
    $counter.style.display = 'block'
  }
}

const getPinsCollection = (docs) => {
  return docs.map((d) => {
    const coor = d.locs_field_location.split(',')
    const lng = Number(coor[1])
    const lat = Number(coor[0])
    return {
      "type": "Feature",
      "properties": { "item_id": d.item_id, "lng": lng, "lat": lat },
      "geometry": {
        "type": "Point",
        "coordinates": [lng, lat]
      }
    }
  })
}

const getFeatureCollection = (heatmaps) => {
  if (!heatmaps.rpts_field_location || !heatmaps.rpts_field_location.counts_ints2D) {
    return [];
  }

  const { minX, maxX, minY, maxY, columns, rows, counts_ints2D: countsInts2 } = heatmaps.rpts_field_location

  const deltaX = (maxX - minX) / columns;
  const deltaY = (maxY - minY) / rows;
  const featureCollection = [];

  const counts_ints2D = countsInts2.map(function (row) {
    return row === null ? [] : row;
  });

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {

      const hmVal = counts_ints2D[counts_ints2D.length - i - 1][j];

      if (hmVal && hmVal !== null) {
        const lat = minY + i * deltaY + (0.5 * deltaY);
        const lon = minX + j * deltaX + (0.5 * deltaX);

        featureCollection.push({
          "type": "Feature",
          "properties": { "mag": hmVal },
          "geometry": {
            "type": "Point",
            "coordinates": [lon, lat]
          }
        });
      }
    }
  }
  return featureCollection;
}

//Add a geojson point source.
//Heatmap layers also work with a vector tile source.
// https://www.mapbox.com/mapbox-gl-js/style-spec/#sources-geojson
const maxValueFeatureCollection = (featureCollection) => {
  return Math.max.apply(null, featureCollection.map(function (feat) {
    return feat.properties.mag
  }))
}

const setSourceData = (featureCollection) => {
  const sourceData = {
    "type": "FeatureCollection",
    "features": featureCollection
  };
  const source = {
    "type": "geojson",
    "data": sourceData
  };
  return { sourceData: sourceData, source: source }
}

const addHeatmapLayers = (map, featureCollection, pinsCollection, numFound) => {

  const dataHm = setSourceData(featureCollection)
  const dataPins = setSourceData(pinsCollection)

  if (map.getSource('jda')) {
    map.getSource('jda').setData(dataHm.sourceData)
    map.getSource('jda2').setData(dataPins.sourceData)

  } else {
    map.addSource('jda', dataHm.source)
    map.addSource('jda2', dataPins.source)

    const maxValue = maxValueFeatureCollection(featureCollection)
    const weightStops = [[1, 1], [10, 1.5], [50, 2], [100, 4]]

    if (maxValue / 2 > 100) {
      weightStops.push([maxValue / 2, 8])
      weightStops.push([maxValue, 10])
    }

    map.addLayer({
      "id": "jda-heat",
      "type": "heatmap",
      "source": "jda",
      "maxzoom": 24,
      "paint": {
        //Increase the heatmap weight based on frequency and property magnitude
        "heatmap-weight": {
          "property": "mag",
          "type": "exponential",
          "stops": weightStops
        },
        //Increase the heatmap color weight weight by zoom level
        //heatmap-ntensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": {
          "stops": [
            [0, 0.2],
            [4, 0.2],
            [5, 0.3],
            [7, 0.2],
            [8, 0.3],
            [10, 0.1],
            [11, 0.2],
            [15, 0.3]
          ]
        },
        //Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        //Begin color ramp at 0-stop with a 0-transparancy color
        //to create a blur-like effect.
        //.RdYlGn .q0-6{fill:rgb(215,48,39)} .RdYlGn .q1-6{fill:rgb(252,141,89)} .RdYlGn .q2-6{fill:rgb(254,224,139)} .RdYlGn .q3-6{fill:rgb(217,239,139)} .RdYlGn .q4-6{fill:rgb(145,207,96)} .RdYlGn .q5-6{fill:rgb(26,152,80)}
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0, "rgba(255, 0, 137, 0)",
          0.083, "hsl(230, 100%, 50%)",
          0.166, "hsl(200, 100%, 50%)",
          0.25, "hsl(170, 100%, 50%)",
          0.333, "hsl(140, 100%, 50%)",
          0.416, "hsl(120, 100%, 50%)",
          0.5, "hsl(100, 100%, 50%)",
          0.583, "hsl(80, 100%, 50%)",
          0.666, "hsl(60, 100%, 50%)",
          0.75, "hsl(60, 100%, 50%)",
          0.833, "hsl(50, 100%, 50%)",
          0.916, "hsl(40, 100%, 50%)",
          0.95, "hsl(30, 100%, 50%)",
          0.98, "hsl(20, 100%, 50%)",
          1, "hsl(0, 100%, 40%)"
        ],
        // },
        //Adjust the heatmap radius by zoom level
        "heatmap-radius": {
          "stops": [
            [1, 10],
            [4, 10],
            [5, 24],
            [15, 30],
            [18, 70]
          ]
        },
        //Transition from heatmap to circle layer by zoom level
        "heatmap-opacity": {
          "default": 1,
          "stops": [
            [1, 0.4],
            [13, 0.4],
            [15, 0.2]
          ]
        },
      }
    }, 'waterway-label');
    map.addLayer({
      "id": "jda-point",
      "type": "circle",
      "source": "jda2",
      "paint": {
        //Size circle raidus by earthquake magnitude and zoom level
        "circle-radius": 8,
        //Color circle by earthquake magnitude
        "circle-color": {
          "type": "exponential",
          "stops": [
            [2, "rgb(255,0,2)"]
          ]
        },
        "circle-stroke-color": "transparent",
        "circle-stroke-width": 4,
        //Transition from heatmap to circle layer by zoom level
        "circle-opacity": {
          "stops": [
            [1, 1],
            [13, 1],
            [13.5, 1]
          ]
        }
      }
    }, 'waterway-label');
  }

  if (map_config.max_pins > numFound) {
    map.setLayoutProperty("jda-heat", 'visibility', 'none');
    map.setLayoutProperty("jda-point", 'visibility', 'visible');
  } else {
    map.setLayoutProperty("jda-heat", 'visibility', 'visible');
    map.setLayoutProperty("jda-point", 'visibility', 'none');
  }
}

const setupHoverCounts = (map) => {
  // var map = map
  const el = document.createElement('div')
  $(el).addClass('count-popup')
  const marker = new mapboxgl.Marker(el)

  const isiPad = navigator.userAgent.match(/iPad/i) != null
  if (!isiPad) {
    map.on('mousemove', function (e) {
      let counts = getCountFromCoordinate(map_vars.facet_heatmap, e.lngLat)
      if (counts !== null) {
        $(el).text(counts)
        marker.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map)
      } else {
        marker.remove()
      }
    })
  }

  map.on('click', function () {
    marker.remove()
  })


  function getCountFromCoordinate(facet_hm, lngLat) {
    if (facet_hm === undefined) {
      return
    }

    const heatmapLatitudeStepSize = (facet_hm.maxY - facet_hm.minY) / facet_hm.rows
    const heatmapLongitudeStepSize = (facet_hm.maxX - facet_hm.minX) / facet_hm.columns
    const heatmapIndexLatitude = Math.floor((lngLat.lat - facet_hm.minY) / heatmapLatitudeStepSize)
    const heatmapIndexLongitude = Math.floor((lngLat.lng - facet_hm.minX) / heatmapLongitudeStepSize)
    let counts = 0
    try {
      counts = facet_hm.counts_ints2D[facet_hm.rows - heatmapIndexLatitude - 1][heatmapIndexLongitude]
      if (!counts || Number.isNaN(counts)) counts = 0
    }
    catch (e) {
      counts = 0
    } // errors due to nulls in solr array instead of zeros

    return counts === 0 ? null : counts.toLocaleString()
  }
}
// Hide timeline slider when user is interacting with the layer control
const hideTimeSliderControls = () => {
  $('#map-layers-control').on('mouseenter', function () {
    $(this).addClass('control-layers-expanded')
    $('#map-timeline-slider').hide()
    // close date time picker if open
    closeTimelineSliderDatePicker()
  }).on('mouseleave', function () {
    $(this).removeClass('control-layers-expanded')
    $('#map-timeline-slider').show()
  })
}

const setHeatmapOpacity = (map) => {
  let slider = document.getElementById('heatmap-slider')
  let sliderValue = document.getElementById('heatmap-slider-value')

  if (slider && sliderValue) {
    slider.addEventListener('input', function (e) {
      map.setPaintProperty('jda-heat', 'heatmap-opacity', parseInt(e.target.value, 10) / 100)
      sliderValue.textContent = e.target.value + '%'
    })
  }
}

const arrayToRgba = (color = [247, 147, 78, 1]) => {
  return `rgba(${color.join(',')})`
}

const processUniqueValues = (uniqueValueInfos) => {
  return uniqueValueInfos.map(({
    value,
    label,
    labelJa,
    symbol: {
      color,
      width,
      outline
    } 
  }) => {
    const { color: outlineColor, width: outlineWidth } = outline || {}
    let numVal = value.toString()
    if (!isNaN(numVal)) {
      const fixedVal = (Number(value).toFixed(6)).toString()
      if (fixedVal.length < numVal.length) {
        numVal = fixedVal
      }
    }
    return {
      label,
      labelJa,
      value: numVal,
      color: arrayToRgba(color),
      outlineColor: arrayToRgba(outlineColor),
      width,
      outlineWidth
    }
  })
}

const processClassBreaks = (classBreakInfos, outFields) => {
  const maxs = []
  const breakInfos = classBreakInfos.map(({
    label,
    labelJa,
    symbol: {
      size,
      width,
      color,
      outline
    }
  }) => {
    const { color: outlineColor, width: outlineWidth } = outline || {}
    const [minVal, maxVal] = label.split(' - ').map(Number)
    maxs.push(maxVal)
    return {
      label,
      labelJa,
      size,
      color: arrayToRgba(color),
      outlineColor: arrayToRgba(outlineColor),
      width,
      outlineWidth,
      minVal,
      maxVal
    }
  })

  return breakInfos.map(({ minVal, maxVal, ...rest }) => {
    const getField = ['get', outFields]
    const noLessThan = maxs.includes(minVal) ? minVal + 1e-6 : minVal
    const range = minVal === maxVal
      ? ['<=', getField, maxVal]
      : ['all', ['>=', getField, noLessThan], ['<=', getField, maxVal]]
    return { ...rest, range }
  })
}

const getDataDrivenPaint = (drawingInfo, outFields) => {
  const defaultPaint = {
    'fill-color': jdaMapLayers.defaultFill.color,
    'fill-outline-color': jdaMapLayers.defaultFill.outlineColor
  }

  if (!drawingInfo) {
    return defaultPaint
  }

  const fillColor = []
  const fillOutlineColor = []
  const {
    renderer: {
      type, symbol, defaultSymbol, uniqueValueInfos, classBreakInfos
    }
  } = drawingInfo

  switch (type) {
    case 'uniqueValue': {
      const fieldExp = ['to-string', ['get', outFields]]
      fillColor.push('match', fieldExp)
      fillOutlineColor.push('match', fieldExp)
      const uniqueValues = processUniqueValues(uniqueValueInfos)
      uniqueValues.forEach(({ value, color, outlineColor }) => {
        fillColor.push(value)
        fillColor.push(color)
        fillOutlineColor.push(value)
        fillOutlineColor.push(outlineColor)
      })
      break
    }
    case 'classBreaks': {
      fillColor.push('case')
      fillOutlineColor.push('case')
      const breakValues = processClassBreaks(classBreakInfos, outFields)
      breakValues.forEach(({ color, outlineColor, range }) => {
        fillColor.push(range)
        fillColor.push(color)
        fillOutlineColor.push(range)
        fillOutlineColor.push(outlineColor)
      })
      break
    }
    case 'simple': {
      const { color, outline: { color: outlineColor } } = symbol
      return {
        'fill-color': arrayToRgba(color),
        'fill-outline-color': arrayToRgba(outlineColor)
      }
    }
    default: {
      return defaultPaint
    }
  }
  const { color, outline: { color: outlineColor } } = defaultSymbol
  fillColor.push(arrayToRgba(color))
  fillOutlineColor.push(arrayToRgba(outlineColor))
  return { 'fill-color': fillColor, 'fill-outline-color': fillOutlineColor }
}

const getCircleDataDrivenPaint = (drawingInfo, outFields) => {
  const defaultPaint = {
    'circle-color': jdaMapLayers.defaultFill.color,
    'circle-stroke-color': jdaMapLayers.defaultFill.outlineColor,
    'circle-stroke-width': 1,
    'circle-radius': 3
  }

  const circleRadius = {
    base: 5,
    stops: [
      [6, 5],
      [9, 6],
      [12, 7]
    ]
  }
  const circleColor = []
  const circleOutlineColor = []
  const {
    renderer: {
      type, symbol, defaultSymbol, uniqueValueInfos, classBreakInfos
    }
  } = drawingInfo

  switch (type) {
    case 'uniqueValue': {
      const fieldExp = ['to-string', ['get', outFields]]
      circleColor.push('match', fieldExp)
      circleOutlineColor.push('match', fieldExp)
      const uniqueValues = processUniqueValues(uniqueValueInfos)
      uniqueValues.forEach(({ value, color, outlineColor }) => {
        circleColor.push(value)
        circleColor.push(color)
        circleOutlineColor.push(value)
        circleOutlineColor.push(outlineColor)
      })
      break
    }
    case 'classBreaks': {
      circleColor.push('case')
      circleOutlineColor.push('case')
      const breakValues = processClassBreaks(classBreakInfos, outFields)
      breakValues.forEach(({ color, outlineColor, range }) => {
        circleColor.push(range)
        circleColor.push(color)
        circleOutlineColor.push(range)
        circleOutlineColor.push(outlineColor)
      })
      break
    }
    case 'simple': {
      const { color, outline: { color: outlineColor, width } } = symbol
      return {
        'circle-color': arrayToRgba(color),
        'circle-stroke-color': arrayToRgba(outlineColor),
        'circle-stroke-width': width,
        'circle-radius': circleRadius
      }
    }
    default: {
      return defaultPaint
    }
  }
  const { color, outline: { color: outlineColor } } = defaultSymbol
  circleColor.push(arrayToRgba(color))
  circleOutlineColor.push(arrayToRgba(outlineColor))
  return {
    'circle-color': circleColor,
    'circle-stroke-color': circleOutlineColor,
    'circle-stroke-width': 1,
    'circle-radius': circleRadius
  }
}

const getLineDataDrivenPaint = (drawingInfo, outFields) => {
  const defaultPaint = {
    'line-color': jdaMapLayers.defaultFill.color,
    'line-width': 1
  }

  const lineColor = []
  const lineWidth = []
  const {
    renderer: {
      type, symbol, defaultSymbol, classBreakInfos, uniqueValueInfos
    }
  } = drawingInfo

  switch (type) {
    case 'uniqueValue': {
      const fieldExp = ['to-string', ['get', outFields]]
      lineColor.push('match', fieldExp)
      lineWidth.push('match', fieldExp)
      const uniqueValues = processUniqueValues(uniqueValueInfos)
      uniqueValues.forEach(({ value, color, width }) => {
        lineColor.push(value)
        lineColor.push(color)
        lineWidth.push(value)
        lineWidth.push(width)
      })
      break
    }
    case 'classBreaks': {
      lineColor.push('case')
      lineWidth.push('case')
      const breakValues = processClassBreaks(classBreakInfos, outFields)
      breakValues.forEach(({ color, range, width }) => {
        lineColor.push(range)
        lineColor.push(color)
        lineWidth.push(range)
        lineWidth.push(width)
      })
      break
    }
    case 'simple': {
      const { color, width } = symbol
      return {
        'line-color': arrayToRgba(color),
        'line-width': width
      }
    }
    default: {
      return defaultPaint
    }
  }
  const { color, width } = defaultSymbol
  lineColor.push(arrayToRgba(color))
  lineWidth.push(width)
  return {
    'line-color': lineColor,
    'line-width': lineWidth
  }
}

const getIconImageId = (id) => `${id}_icon_image`

const addMapIconImage = (map, id) => {
  const imgId = getIconImageId(id)
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const img = document.getElementById(imgId)
  canvas.width = img.width;
  canvas.height = img.height;
  context.drawImage(img, 0, 0);
  const imgData = context.getImageData(0, 0, img.width, img.height);
  map.addImage(imgId, imgData)
}

const getPointDataDrivenPaint = (drawingInfo, map, layerId) => {
  const {
    renderer: { type }
  } = drawingInfo

  switch (type) {
    case 'simple': {
      addMapIconImage(map, layerId)
      const { color, outlineColor } = jdaMapLayers.defaultFill
      return {
        layout: {
          'icon-image': getIconImageId(layerId),
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-color': color,
          'icon-halo-color': outlineColor
        }
      }
    }
  }
}

const parseLabel = (l, ja) => {
  const { allValues, otherValues } = jdaMapLayers.translation
  const label = l && l.replace('\u003c', '&lt;').replace('\u003e', '&gt;')
  if (label && ja) {
    return label.replace('All Values', allValues).replace('Other Values', otherValues)
  }
  return label
}

const getDataDrivenLegend = (drawingInfo) => {
  if (!drawingInfo) {
    return [jdaMapLayers.defaultFill]
  }
  const {
    renderer: {
      type, symbol, label, defaultLabel, defaultSymbol, uniqueValueInfos, classBreakInfos
    }
  } = drawingInfo
  const legendList = []

  switch (type) {
    case 'uniqueValue': {
      const uniqueValues = processUniqueValues(uniqueValueInfos)
      legendList.push(...uniqueValues)
      break
    }
    case 'classBreaks': {
      const breakValues = processClassBreaks(classBreakInfos)
      legendList.push(...breakValues)
      break
    }
    case 'simple': {
      const { color, width, outline } = symbol
      const { color: outlineColor, width: outlineWidth } = outline || {}
      return [{
        label: parseLabel(label),
        labelJa: parseLabel(label, true),
        color: arrayToRgba(color),
        outlineColor: arrayToRgba(outlineColor),
        width,
        outlineWidth
      }]
    }
    default: {
      return [jdaMapLayers.defaultFill]
    }
  }
  const { color, width, outline } = defaultSymbol
  const { color: outlineColor, width: outlineWidth } = outline || {}
  legendList.push({
    label: parseLabel(defaultLabel),
    labelJa: parseLabel(defaultLabel, true),
    color: arrayToRgba(color),
    outlineColor: arrayToRgba(outlineColor),
    width,
    outlineWidth
  })
  return legendList
}

const MAX_RECORD_COUNT = 2000

const getMapLayerUrl = (layerId, query) => {
  const {
    where = '',
    offsetPage = 0,
    outFields = '',
    orderBy = '',
    pageSize = MAX_RECORD_COUNT,
    returnCountOnly = 'false',
    returnExtentOnly = 'false',
    outSpatialReference = ''
  } = query
  return `https://services7.arcgis.com/iEMmryaM5E3wkdnU/arcgis/rest/services/${layerId}/FeatureServer/0/query?resultRecordCount=${pageSize}&resultOffset=${offsetPage * pageSize}&where=ObjectId>=0${where}&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=${outFields}&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=${outSpatialReference}&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=${returnCountOnly}&returnExtentOnly=${returnExtentOnly}&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=${orderBy}&groupByFieldsForStatistics=&outStatistics=&having=&returnZ=false&returnM=false&returnExceededLimitFeatures=false&quantizationParameters=&sqlFormat=none&f=pgeojson&token=`
}

const getMapLayerId = (layerId, offset) => {
  if (!offset) {
    return layerId
  }
  return `${layerId}*${offset}`
}

const getMapLayer = (layer, offsetPage) => {
  const { id, paintData, pageSize, type, where, orderBy, layout = {}, outFields = '' } = layer
  const query = { offsetPage, where, outFields, orderBy, pageSize }
  return {
    'id': getMapLayerId(id, offsetPage),
    'type': type || 'fill',
    'source': {
      'type': 'geojson',
      'data': getMapLayerUrl(id, query)
    },
    'metadata': layer,
    'paint': paintData,
    'layout': {
      ...layout,
      'visibility': 'visible'
    }
  }
}

const layersControl_UI = (map, setLoading) => {
  const addedLayers = {} // key value pair of layerId and numPage Of recordCount >= 1
  const layers = jdaMapLayers.layers.sort((a, b) => a.category == b.category ? a.sort - b.sort : a.category < b.category ? -1 : 1)
  const heatlayer = map.getLayer('jda-heat')
  const defaultBounds = map.getBounds()
  const intervals = {}

  async function getMapLayerDetail(layer) {
    if (addedLayers[layer.id]) {
      return layer
    }

    const { where, largeData, zoomIn, bounds, source } = layer

    if (source && source === 'tiledimage') {
      return layer;
    }


    // Need to zoom in and fit layer bounds
    if (zoomIn && !bounds) {
      const layerExtentUrl = getMapLayerUrl(layer.id, {
        where,
        returnExtentOnly: true,
        // Convert EPSG 3857/projected coordinate system to EPSG 4326/globe curved coordinate system
        outSpatialReference: 4326
      })
      const extentRes = await axios.get(layerExtentUrl)
      const extentBox = extentRes.data.bbox
      if (extentBox) {
        const [xMin, yMin, xMax, yMax] = extentBox
        layer.bounds = [[xMin, yMin], [xMax, yMax]]
      }
    }
    return layer;
  }

  async function addMapLayer(layerId) {
    if (addedLayers[layerId]) {
      return
    }

    const layerRaw = layers.find(({ id }) => id === layerId)
    if (!layerRaw) {
      return
    }

    const { where, largeData, zoomIn, bounds, source } = layerRaw

    if (source && source === 'tiledimage') {
      return
    }

    if (largeData) {
      setLoading(true)
    }

    // Need to zoom in and fit layer bounds
    if (zoomIn && !bounds) {
      const layerExtentUrl = getMapLayerUrl(layerId, {
        where,
        returnExtentOnly: true,
        // Convert EPSG 3857/projected coordinate system to EPSG 4326/globe curved coordinate system
        outSpatialReference: 4326
      })
      const extentRes = await axios.get(layerExtentUrl)
      const extentBox = extentRes.data.bbox
      if (extentBox) {
        const [xMin, yMin, xMax, yMax] = extentBox
        layerRaw.bounds = [[xMin, yMin], [xMax, yMax]]
      }
    }


    const recordCountUrl = getMapLayerUrl(layerId, { where, returnCountOnly: true })
    const countRes = await axios.get(recordCountUrl)
    const totalRecordCount = countRes.data.properties.count
    const pageSize = layerRaw.pageSize || MAX_RECORD_COUNT
    const numPages = Math.ceil(totalRecordCount / pageSize) || 1
    if (!layerRaw.paintData) {
      const { drawingInfo, outFields, type, dash } = layerRaw
      switch (type) {
        case 'fill':
          layerRaw.paintData = getDataDrivenPaint(drawingInfo, outFields)
          break
        case 'circle':
          layerRaw.paintData = getCircleDataDrivenPaint(drawingInfo, outFields)
          break
        case 'line':
          const linePaintData = getLineDataDrivenPaint(drawingInfo, outFields)
          if (dash) {
            linePaintData['line-dasharray'] = [3, 3]
          }
          layerRaw.paintData = linePaintData
          break
        case 'symbol': {
          const { layout, paint } = getPointDataDrivenPaint(drawingInfo, map, layerId)
          layerRaw.paintData = paint
          layerRaw.layout = layout
          break
        }
        default:
          layerRaw.paintData = getDataDrivenPaint(drawingInfo, outFields)
      }
    }
    
    for (let i = 0; i < numPages; i++) {
      const layer = getMapLayer(layerRaw, i)
      const sourceId = layer.id

      if (largeData) {
        intervals[sourceId] = setInterval(() => {
          if (map.isSourceLoaded(sourceId)) {
            clearInterval(intervals[sourceId])
            intervals[sourceId] = null
          }
          const loadSources = Object.values(intervals)
          if (loadSources.every(val => !val)) {
            setLoading(false)
          }
        }, 1000)
      }
      if (layer.type == 'fill') layer.paint['fill-opacity'] = 0.85;
      if (layer.type == 'circle') layer.paint['circle-opacity'] = 0.85;
      if (layer.type == 'line') layer.paint['line-opacity'] = 0.85;
      if (heatlayer) {
        map.addLayer(layer, 'jda-heat')
      } else {
        map.addLayer(layer)
      }
    }

    addedLayers[layerId] = numPages
  }

  const groupedLayers = layers.reduce((result, layer) => {
    result[layer.category] = result[layer.category] || [];
    result[layer.category].push(layer);
    return result;
  }, {})

  const groupedKeys = Object.keys(groupedLayers)
  const sortedKeys = groupedKeys.sort()
  let layerList = '';
  for (let key of sortedKeys) {
    let list = groupedLayers[key];
    let sortedList = list.sort((a, b) => a.sort - b.sort)
    let items = '';
    let disableAll = false;
    sortedList.forEach(layer => {
      let iconImg = ''
      if (layer.icon) {
        const iconData = require(`./icons/${layer.icon}.png`)
        iconImg = `<img
          style="display:none;"
          src="${iconData}"
          id="${getIconImageId(layer.id)}"
        />`
      }
      const disabled = layer.source && layer.source === 'tiledimage' ? ' disabled' : ''
      if(disabled) disableAll = true;
      if (!disabled) {
        const item =
          '<label class="collapsible">' +
            '<input type="checkbox"' + disabled + ' value="' +layer.id + '" class="control-layers-selector">' +
            '<span class="layername-langcode-en"> ' + layer.title + '</span>' +
            '<span class="layername-langcode-ja"> ' + layer.titleJa + '</span>' + iconImg +
          '</label>'
        items += item;
      }
    })

    const disabled = disableAll ? 'disabled': '';

    if (!disableAll) {

      const categoryStart = 
      '<div class="group-checkbox">' +
      '<label>' +
        '<input type="checkbox"' + disabled + ' value="category-' + key + '" class="control-layers-group-selector">' +
        '<span class="layer-category-name-en"> ' + i18n.t(key) + '</span>' +
        // '<span class="layer-category-name-ja"> ' + key + '</span>' + 
      '</label>'

      const categoryEnd = '</div>'
      layerList += categoryStart;
      layerList += items;
      layerList += categoryEnd;
    }
  }

  let heatmapOpacity = 40
  let heatmapOpacitySlider =
    '<div class="heatmap-sl-overlay">' +
      '<div class="heatmap-sl-overlay-inner">' +
        '<label>Heatmap opacity: <span id="heatmap-slider-value">' + heatmapOpacity + '%</span>' +
        '<input id="heatmap-slider" type="range" min="0" max="100" step="0" value="' + heatmapOpacity + '" />' +
        '</label>' +
      '</div>' +
      '<hr>' +
    '</div>'

  function LayersControl() { }
  LayersControl.prototype.onAdd = function (map) {
    this._map = map
    this._container = document.createElement('div')
    $(this._container).attr('id', 'map-layers-control')
    $(this._container).addClass('control-layers')
    $(this._container).html('<a class="control-layers-toggle" href="#"></a>')
    $(this._container).append(
      '<form class="control-layers-list">' +
        '<div class="control-layers-overlays">' +
          heatmapOpacitySlider + layerList +
        '</div>' +
      '</form>'
    )
    return this._container
  }

  map.addControl(new LayersControl())

  const calculateBoundsArea = (bounds) => {
    const w1 = ((bounds||[])[0]||[])[0] ?? 0;
    const w2 = ((bounds||[])[1]||[])[0] ?? 0;
    const l1 = ((bounds||[])[0]||[])[1] ?? 0;
    const l2 = ((bounds||[])[1]||[])[1] ?? 0;
    return Math.abs((l2 - l1 ) * (w2 - w1));
  }

  $('.control-layers-overlays').on('change', 'input[type=checkbox].control-layers-group-selector', async function (event) {
    event.preventDefault()
    if ($(this).val().startsWith('category-')) {
      if ($(this).is(':checked')) {
        $(this).parent().siblings('label').find('input:checkbox').prop("checked",true)
      } else {
        $(this).parent().siblings('label').find('input:checkbox').prop("checked",false)
      }
      const key = $(this).val().split('-')[1];
      const listLayers = groupedLayers[key] || [];
      const layerDetails = [];
      try {
        setLoading(true)
        let a00 = defaultBounds?._sw?.lng;
        let a01 = defaultBounds?._sw?.lat;
        let a10 = defaultBounds?._ne?.lng;
        let a11 = defaultBounds?._ne?.lat;
        const defaultBound = a00 && a01 && a10 && a11 ? [[a00, a01], [a10, a11]] : null;
        let maxBounds = defaultBound;
        let maxArea = calculateBoundsArea(maxBounds);
        for (let layer of listLayers) {
          const data = await getMapLayerDetail(layer)
          if (!data?.bounds) data.bounds = defaultBound
          layerDetails.push(data);
        }
        const sortedLayerDetails = layerDetails.sort((a, b) => {
          let areaA = calculateBoundsArea(a?.bounds);
          let areaB = calculateBoundsArea(b?.bounds);
          if (areaA > maxArea) {
            maxArea = areaA;
            maxBounds = a?.bounds;
          }
          if (areaB > maxArea) {
            maxArea = areaB;
            maxBounds = b?.bounds;
          }
          return areaA - areaB;
        });
        for (let layer of sortedLayerDetails) {
          await addMapLayer(layer.id)
          const count = addedLayers[layer.id]
          if (!count) continue

          let visibilityStatus = 'none'
          let layerEvent = 'overlayremove'
          if ($(this).is(':checked')) {
            visibilityStatus = 'visible'
            layerEvent = 'overlayadd'
            if (layer && layer.bounds) {
              map.fitBounds(maxBounds, { linear: true })
            }
          }
          for (let i = 0; i < count; i++) {
            map.setLayoutProperty(getMapLayerId(layer.id, i), 'visibility', visibilityStatus)
          }
          map.fire(layerEvent, {id: layer.id})
        }
      } catch (error) {
        setLoading(false)
      }
      if (!$(this).is(':checked')) {
        setLoading(false);
      } else {
        const loadSources = Object.values(intervals)
          if (loadSources.every(val => !val)) {
            setLoading(false)
          }
      }
    }
  });
  $('.control-layers-overlays').on('change', 'input[type=checkbox]', async function (event) {
    event.preventDefault()
    if ($(this).val().startsWith('category-')) return;
    let value = { id: $(this).val() }
    await addMapLayer(value.id)
    const count = addedLayers[value.id]
    if (!count) return

    let visibilityStatus = 'none'
    let layerEvent = 'overlayremove'
    if ($(this).is(':checked')) {
      visibilityStatus = 'visible'
      layerEvent = 'overlayadd'
      // fit to small area layer bounds
      const layer = layers.find(({ id }) => id === value.id)
      if (layer && layer.bounds) {
        map.fitBounds(layer.bounds, { linear: true, padding: 20 })
      } else {
        map.fitBounds(defaultBounds, { linear: true })
      }
    }
    for (let i = 0; i < count; i++) {
      map.setLayoutProperty(getMapLayerId(value.id, i), 'visibility', visibilityStatus)
    }
    map.fire(layerEvent, value)
  });

  hideTimeSliderControls()
  setHeatmapOpacity(map)
}

const renderItem = (item, openItem, onDrop) => {
  const itemId = item.id;
  const newTitle = item.title.length > 75
  ? item.title.substring(0, 75) + '...'
  : item.title;
  let icon = ''

  if (item.media_type) {
    let value = item.media_type.toLowerCase()
    if (value == "website") icon = "public";
    else if (value == "broadcast") icon = "radio";
    else if (value == "document") icon = "attach_file";
    else if (value == "article") icon = "book";
    else if (value == "image") icon = "image";
    else if (value == "headline") icon = "header";
    else if (value == "video") icon = "film";
    else if (value == "testimonial") icon = "person";
    else if (value == "audio") icon = "music_note";
    else if (value == "tweet") icon = "twitter";
  }
  return (
    <ItemBox onDrop={onDrop} type='list' data={item} key={itemId}>
      <div className='item item--map' data-id={itemId} onClick={() => openItem(itemId)} >
        {item.image ? <div className="item--map_img"><img src={item.image}/></div> : <div className='item--map_icon'><i className='material-icons'>{icon}</i></div>}
        <p>{newTitle}</p>
        <div className="item__drag">
          <i className='material-icons'>drag_handle</i>
        </div>
      </div>
    </ItemBox>)
}

const performProximitySearch = (openItem, map, latlng, radius, onDrop) => {
  if (isTimelineSliderDatePickerOpen()) {
    return // ignore request if timeline date picker is open
  }

  if ($('.mapboxgl-popup-content').length > 0) {
    return // ignore request if popup already exists
  }

  map.flyTo({ center: latlng })

  // variable radius depending on zoom level
  const zoom = Number(map.getZoom().toFixed(0))

  const searchRadius = radius || getProximitySearchRadiusByZoomLevel(zoom)

  const lat_lng = latlng.lat + ',' + latlng.lng
  const InitialContent = () => {
    const {t} = useTranslation()
    return (
      <>
        <h3>{t('Nearby Items')}</h3>
        <div className='map__items' id="mapitems">
          <span className='map__items_loading'>{t('Loading')}...</span>
        </div>
      </>
    )
  }
  const popupNodeOne = document.createElement('div');
  popupNodeOne.classList.add('proximity-popup')
  popupNodeOne.setAttribute('id', 'proximity-popup')
  ReactDOM.render(<InitialContent />, popupNodeOne);

  if (map.popup) {
    map.popup.remove()
  }

  const mapPopup = new mapboxgl.Popup({ anchor: 'bottom' });
  mapPopup.setLngLat([latlng.lng, latlng.lat])
    .addTo(map)
    .setDOMContent(popupNodeOne)

  // const query = aggrInput()
  const query = aggrInput().replace('partners=', 'uid=')

  const url = map_config.search_api_url +
    "/spatial?max_results=" +
    map_config.max_proximity_results +
    "&radius=" + searchRadius + "&point=" + lat_lng + "&" + query

  $.get(url, null, function (result) {
    let Content = null;
    if (result.total > 0 && result.items.length > 0) {
      const PopupList = () => {
        const {t} = useTranslation()
        return (
          <DragAndDrop>
            <h3>{t('Nearby Items')}</h3>
            <div className="map__items" id="mapitems">
              {result.items.map(item => renderItem(item, openItem, onDrop))}
            </div>
          </DragAndDrop>
        )
      }
      Content = PopupList
    } else {
      const EmptyPopupList = () => {
        const { t } = useTranslation();
        return (
          <DragAndDrop>
            <h3>{t('Nearby Items')}</h3>
            <div className="map__items" id="mapitems">
              <span class="map__items_loading">{t('No nearby results found.')}</span>
            </div>
          </DragAndDrop>
        )
      }
      Content = EmptyPopupList
    }
    if (Content) {
      ReactDOM.render(<Content />, popupNodeOne);
      mapPopup.setLngLat([latlng.lng, latlng.lat]).addTo(map).setDOMContent(popupNodeOne);
    }

    // mapHack() //TODO: Remove completly if we move away from DnD for map items

    const half = $("#searchMap canvas").height() / 2
    const offset = half - $('.mapboxgl-popup').height() - 60
    map.panBy([0, offset])
  })
}

const addLegend = (map, layerId) => {
  const { id, metadata: { title, titleJa, drawingInfo, icon, dash, type = 'fill' } } = map.getLayer(layerId)
  const legendList = getDataDrivenLegend(drawingInfo)
  const langCode = $('#map-layers-control').attr('class').includes(JA) ? JA : EN;
  const legendListHtml = legendList.map(({ label = '', labelJa = '', color, outlineColor, outlineWidth }) => {
    let legendStyle = ''
    switch (type) {
      case 'symbol':
        const iconData = require(`./icons/${icon}.png`)
        legendStyle = icon
          ? (`
            background-image: url(${iconData});
            background-size: 100%;
          `)
          : (`
            border-left: .8rem solid transparent;
            border-right: .8rem solid transparent;
            border-bottom: 1.4rem solid #735139;
          `)
        break
      case 'fill':
        legendStyle = `
          border: 1px solid ${outlineColor};
          border-radius: 0.2rem;
          background-color: ${color};
        `
        break
      case 'circle':
        legendStyle = `
          border: ${outlineWidth || 2}px solid ${outlineColor};
          border-radius: 50%;
          background-color: ${color};
        `
        break
      case 'line':
        legendStyle = `
          height: 1.4rem;
          width: 1.4rem;
          border-right: .3rem ${dash ? 'dashed' : 'solid'} ${color};
          -moz-transform: skew(-45deg) translateX(-.7rem);
          -webkit-transform: skew(-45deg) translateX(-.7rem);
          transform: skew(-45deg) translateX(-.7rem);
        `
        break
    }
    const labelHtml = labelJa 
      ? `<div class="layername-langcode-en ${langCode === JA ? 'c-map__label-hide' : ''}">${label}</div>
        <div class="layername-langcode-ja ${langCode === EN ? 'c-map__label-hide' : ''}">${labelJa}</div>`
      : `<div>${label}</div>`
    return (
      `<div class="item__legend-fill">
        <div style="${legendStyle}"></div>
        ${labelHtml}
      </div>`
    )
  })

  const div = document.createElement('div')
  $(div).attr('id', 'legend-' + id)
  $(div).addClass('item__legend')
  $(div).html(`
    <span class="item__legend-title layername-langcode-en ${langCode === JA ? 'c-map__label-hide' : ''}">
      ${title}
    </span>
    <span class="item__legend-title layername-langcode-ja ${langCode === EN ? 'c-map__label-hide' : ''}">
      ${titleJa}
    </span>`
  )
  $(div).append('<hr/>')
  $(div).append('<div class="item__legend-list">' + legendListHtml.join('') + '</div>')
  $('#legend-container').prepend(div)
  map_vars.legends.push(id)
  resizeMapLegendContainer()
}

const removeLegend = (map, layerId) => {
  const layer = map.getLayer(layerId)
  $("#legend-" + layer.id).remove()
  resizeMapLegendContainer()
}

const toggleLabelsDisplay = (langcode) => {
  $('#map-layers-control').toggleClass('langcode-ja', langcode === JA)
  $('.layername-langcode-en').toggleClass('c-map__label-hide', langcode === JA)
  $('.layername-langcode-ja').toggleClass('c-map__label-hide', langcode === EN)
}

const callHeatmapApi = (map, request, options) => {
  // add all of the query params from options
  for (let key in options.query) {
    request += key + '=' + options.query[key] + '&'
  }

  const bounds = map.getBounds()
  const minX = Math.max(-180, bounds.getWest())
  const maxX = Math.min(180, bounds.getEast())
  const minY = Math.max(-90, bounds.getSouth())
  const maxY = Math.min(90, bounds.getNorth())

  const levels = {
    0:2,
    1:2,
    2:3,
    3:4,
    4:4,
    5:4,
    6:5,
    7:5,
    8:5,
    9:6,
    10:6,
    11:7,
    12:7,
    13:7,
    14:8,
    15:8,
    16:8,
    17:8,
    18:9,
    19:10,
    20:10
  };
  /*
  * END Custom Addition
  */
  
  const zoom = Math.round(map.getZoom())
  const level = levels[zoom];
  const distErrPct = zoom <= 3 ? 0.14 : 0.10

  const url = request + 'rows=0&facet=true&facet.heatmap=' + options.field + '&' +
    'intersects=locs_field_location:"Intersects(ENVELOPE('+minX+', '+maxX+', '+maxY+', '+minY+'))"&' +
    'facet.heatmap.geom=['+minX+' '+minY+' TO '+maxX+' '+maxY+']&' +
    'facet.heatmap.format=ints2D&facet.heatmap.distErrPct=' + distErrPct + '&wt=json&' + 
    'gridLevel=' + level

  return new Promise(function (resolve, reject) {
    $.get(url, function (data) {
      if (data.facet_counts) {
        const featureCollection = getFeatureCollection(data.facet_counts.facet_heatmaps)
        const pinsCollection = getPinsCollection(data.response.docs)

        addHeatmapLayers(map, featureCollection, pinsCollection, data.response.numFound)
        // for the hover count
        map_vars.facet_heatmap = data.facet_counts.facet_heatmaps.rpts_field_location
      }
      resolve(data)
    }, 'json')
      .fail(reject)
  })
}

const resizeMapLegendContainer = () => {
  $("#legend-container").css("max-height", $("#map").height() - 100);

  if ($("#legend-container").html() == "") {
    $("#legend-container").hide();
  } else {
    $("#legend-container").show();
  }
}

const getResults = (request) => {
  $(".item, .collection--list").each(() => {
    $(this).addClass("item--loading")
  })

  fetch(request, { timeout: 0 }).then(function (data) {
    $("#list .item, .collection--list").each(() => {
      $(this).removeClass("item--loading")
    })
  })
}

const solrApi = (type, query) => {
  let request

  if (typeof query === 'undefined') {
    request = `${map_config.search_api_url}/${type}`
  } else {
    request = `${map_config.search_api_url}/${type}?${query}`
  }
  return request
}

const geolocate = async (input) => {
  return new Promise((resolve, reject) => {
    /* eslint-disable */
    const geocoder = new google.maps.Geocoder()
    /* eslint-disable */
    geocoder.geocode({ address: input }, (results, status) => {
      if (status === 'OK') {
        const latlng = results[0].geometry.location
        const retObject = {
          latitude: latlng.lat(),
          longitude: latlng.lng(),
          address: input,
          formattedAddress: results[0].formatted_address,
        }

        // return retObject
        resolve(retObject)
      } else {
        toast.error('Google could not locate this address.')
        reject(status)
      }
    })
  })
}

const getCollectionItems = (items) => {
  let features = []

  items.forEach(({ itemData: item }) => {

    if (item.lon && item.lat) {
      features.push({
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            item.lon,
            item.lat
          ]
        },
        "properties": {
          "item_id": item.id
        }
      })
    }
  })

  return features
}

export {
  callHeatmapApi,
  setHeatmapCount,
  performProximitySearch,
  mapLog,
  solrApi,
  geolocate,
  getResults,
  aggrInput,
  setupHoverCounts,
  layersControl_UI,
  addLegend,
  removeLegend,
  resizeMapLegendContainer,
  getCollectionItems,
  toggleLabelsDisplay
}
