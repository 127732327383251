import React, { useState, useEffect, useCallback } from 'react'
import ReactGA from 'react-ga'

import { McSubNav } from 'features/MyCollections'
import { SearchResults } from 'features/Search'
import { useDispatch, useSelector } from 'react-redux'
import { selectCreateCollectionSubmitting, selectSortedUserCollections, selectUserCollectionCount } from 'features/Collections/redux/collectionsSelectors'
import { deleteCollection, loadUserItems, setMcSort } from 'features/Collections/redux/collectionsActions'
import { USER_COLLECTIONS } from 'shared/constants'
import { selectLoadingState } from 'shared/redux/sharedSelectors'
import { ConfirmationModal } from 'shared/components'
import Metatags from 'shared/components/Metatags'
import { pageMeta } from 'shared/data/meta'
import { useTranslation } from 'react-i18next'

const MyCollections = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [settingsMode, setSettingsMode] = useState(false)
  const [page, setPage] = useState(0)
  const [selectedDeleteCollection, setSelectedDeleteCollection] = useState(null)
  const loading = useSelector(selectLoadingState)
  const collectionCount = useSelector(selectUserCollectionCount)
  const toggleSettingsMode = useCallback(() => setSettingsMode(v => !v), [])
  const submitting = useSelector(selectCreateCollectionSubmitting)
  const deleteFn = useCallback(async () => {
    await dispatch(deleteCollection(selectedDeleteCollection.id))
    setSelectedDeleteCollection(null)
  }, [selectedDeleteCollection, dispatch])
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    dispatch(setMcSort('newest'))
    dispatch(loadUserItems([USER_COLLECTIONS], 0))
    setPage(page + 1)
  }, [dispatch])
  const items = useSelector(selectSortedUserCollections)

  const handleLoadMore = () => {
    if(!loading && collectionCount / 20 > page) {
      dispatch(loadUserItems([USER_COLLECTIONS], page))
      setPage(page + 1)
    }
  }

  return (
    <div className='c-my-collections c-layout__fill'>
      <McSubNav toggleSettingsMode={toggleSettingsMode} />
      <Metatags tags={pageMeta['mycollections']} />
      <SearchResults
        loadMore={handleLoadMore}
        results={items}
        draggableItems={false}
        deleteItemFxn={setSelectedDeleteCollection}
        deleteItemVisible={settingsMode}
      />
      <ConfirmationModal
        isOpen={selectedDeleteCollection && settingsMode}
        onRequestClose={() => setSelectedDeleteCollection(null)}
        confirmDisabled={submitting}
        onConfirm={deleteFn}
        confirmText={t('Delete Collection')}
      />
    </div>
  )
}

export default MyCollections
