import React, { useCallback, useState } from 'react'
import Modal from 'features/Modal'
import { EN } from 'shared/constants'

const ContributeFieldModal = ({ isOpen, onRequestClose, submitFn, submitting, data }) => {
  const [text, setText] = useState('')
  const { label, language, value } = data || {}
  const onSubmit = useCallback(() => {
    submitFn(text)
  }, [text, submitFn])
  const onClose = () => {
    setText('')
    onRequestClose()
  }
  const fieldName =  label && `${language === EN ? 'English' : 'Japanese'} ${label}`
  return (
    <Modal
      isOpen={!!isOpen}
      className='c-modal--centered c-modal--authentication c-modal--confirmation'
      contentLabel='Contribute'
      onRequestClose={onClose}
      closeTimeoutMS={200}
      shouldReturnFocusAfterClose={false}
    >
      <div className='c-account-form'>
        <div className='c-account-form__header'>
        <span className='c-copy c-copy--bold u-color-white'>{`Contribute ${fieldName}`}</span>
        </div>
        <div className='u-p c-account-form__content--confirm'>
          <label className='c-copy c-copy--uppercase c-copy--bold c-copy--xs u-color-grey-300'>{fieldName}</label>
          {value === 'description' ? (
            <textarea
              value={text}
              onChange={e => setText(e.currentTarget.value)}
              rows={4}
              className='u-bg-grey-100 u-block u-mbxs u-p c-copy c-modal__notes-textarea'
            />
          ) : (
            <input
              value={text}
              onChange={e => setText(e.currentTarget.value)}
              className='u-bg-grey-100 u-block u-mbxs u-p c-copy c-modal__notes-textarea'
            />
          )}
          <button
            className={`c-btn c-btn--sm-p c-btn--full u-color-white u-bg-orange ${submitting ? 'is-disabled' : ''}`}
            onClick={onSubmit}
            disabled={submitting}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ContributeFieldModal
