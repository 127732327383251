export const buildAccountPatchPayload = ({
  mail,
  password,
  preferredLangcode
  }) => {
  const payload = {}
  if (mail) {
    payload.mail = [{ value: mail }]
    payload.pass = [{ existing: password }]
  }
  if (preferredLangcode) payload['preferred_langcode'] = [{ value: preferredLangcode }]
  return payload
}
