import React, { useRef, useCallback, useState } from 'react'
import Masonry from 'react-masonry-css'

import { CollectionItem, CollectionItemV, ItemBox, PartnerBanner } from 'features/Collections'
import { useDispatch, useSelector } from 'react-redux'
import {
  addItemToUserCollection,
  addCollectionToUserCollection,
  loadItemModal,
  loadUserItems
} from 'features/Collections/redux/collectionsActions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { ConfirmationModal } from 'shared/components'
import { MapContainer } from 'features/Map'
import { debounce } from 'utils/functions'
import { ITEM, LOGIN, MY_COLLECTIONS_ITEM, EN, USER_COLLECTIONS_WITH_ITEMS } from 'shared/constants'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import { openAccountModal } from 'features/AccountForm/redux/accountFormActions'
import MasonryGridItem from '../MasonryGridItem'
import DotsLoader from 'shared/components/DotsLoader'
import { useTranslation } from 'react-i18next'

const SearchResults = ({
  type,
  results,
  draggableItems = true,
  deleteItemFxn,
  deleteItemVisible,
  full = false,
  view,
  loadMore,
  clearPartner,
  partner,
  filtersDropdown,
  loading
}) => {
  const {t} = useTranslation()
  const resultsDiv = useRef(null)
  const dispatch = useDispatch()
  const [confirmProps, setConfirmProps] = useState(null)
  const siteLangcode = useSelector(selectLangcodeFlag)
  const userLoaded = useSelector(selectUserLoadStatus)
  const siteLang = siteLangcode === EN ? 'english' : 'japanese'
  const siteLangAlt = siteLangcode === EN ? 'japanese' : 'english'
  const openModal = useCallback(debounce((id, type) => dispatch(loadItemModal({ id, type: type || ITEM })), 500, true), [dispatch])
  const openLoginModal = useCallback(() => dispatch(openAccountModal(LOGIN)), [dispatch])
  const onItemDrop = useCallback((item, collectionId) => {
    if (item.isCollection) {
      setConfirmProps({ item, collectionId })
    } else {
      dispatch(addItemToUserCollection(item, collectionId))
    }
    dispatch(loadUserItems([USER_COLLECTIONS_WITH_ITEMS], 0));
  }, [dispatch, setConfirmProps])
  const onConfirm = useCallback(() => {
    setConfirmProps(null)
    const { item, collectionId } = confirmProps
    dispatch(addCollectionToUserCollection(item.id, collectionId))
  }, [dispatch, confirmProps, setConfirmProps])

  const onScroll = debounce(() => {
    const { scrollTop, scrollHeight, clientHeight } = resultsDiv.current
    if(Math.abs(scrollHeight - clientHeight - scrollTop) < 100) {
      loadMore()
    }
  }, 100)

  const renderItemV = useCallback(item => (
    <CollectionItemV
      {...item}
      hideHandle={!draggableItems}
      deleteItemFxn={() => deleteItemFxn(item)}
      deleteItemVisible={deleteItemVisible}
      className='c-collection-item--collectionsearch'
    />
  ), [draggableItems, deleteItemVisible, deleteItemFxn])

  const openAddToCollectionsModal = useCallback(id => {
    if (userLoaded) {
      openModal(id, MY_COLLECTIONS_ITEM)
    } else {
      openLoginModal()
    }
  }, [openModal, openLoginModal, userLoaded])

  const renderResults = () => {
    if(type === 'items') {
      switch (view) {
        case 'photo':
          if (!results.length) {
            return <DotsLoader cover loading={loading} />
          }
          let photos = []
          results.forEach((image, index) => {
            if(image.field_thumbnail_url || image.img) {
              photos.push({
                src: image.field_thumbnail_url || image.img,
                id: image.id,
                description: image[`field_${siteLang}_title`] || image[`field_${siteLangAlt}_title`] || '',
                link: image.url,
                title: image.title,
                langcode: image.langcode
              })
            }
          })

          return (
            <Masonry
              breakpointCols={{ default: 4, 992: 3, 768: 2, 480: 1 }}
              className='c-masonry-grid'
              columnClassName='c-masonry-grid_column'>
              {draggableItems ? (
                photos.map(photo => (
                  <ItemBox
                    type="image-grid"
                    data={photo}
                    key={`${photo.id}-${photo.langcode}`}
                    onDrop={onItemDrop}
                  >
                    <MasonryGridItem
                      key={photo.id}
                      image={photo}
                      onClick={() => openModal(photo.id)}
                      mobileAddBtnFn={() => openAddToCollectionsModal(photo.id)}
                    />
                  </ItemBox>
                ))) : (
                  photos.map(photo => (
                    <MasonryGridItem
                      key={`${photo.id}-${photo.langcode}`}
                      image={photo}
                      onClick={() => openModal(photo.id)}
                    />
                  )))}
            </Masonry>
          )
        case 'map':
          return (
            <MapContainer location={false} page="searchMap" onDrop={onItemDrop}></MapContainer>
          )
        default:
          if (!results.length) {
            return <DotsLoader cover loading={loading} />
          }
          return (
            results.map((result) => (
              <ItemBox
                type="list"
                key={`${result.nid}-${result.langcode}`}
                data={result}
                onDrop={onItemDrop}
              >
                <CollectionItem
                  {...result}
                  handleRight
                  hideHandle={!draggableItems}
                  mobileAddBtnFn={() => openAddToCollectionsModal(result.nid)}
                />
              </ItemBox>
            ))
          )
      }
    } else {
      if (!results.length) {
        return <DotsLoader cover loading={loading} />
      }
      return (
        <div className='o-grid o-grid--xs o-grid--equal-height c-results__collection-container'>
          {results.map((result) => result ? (
            <div className='o-grid__col u-6/12 u-4/12@md u-3/12@xl u-mbxs' key={`${result.id}-${result.langcode}`}>
              {draggableItems ? (
                <ItemBox type="grid" data={result} onDrop={onItemDrop}>
                  {renderItemV(result)}
                </ItemBox>
              ) : renderItemV(result)}
            </div>
          ) : null)}
        </div>
      )
    }
  }

  return (
    <>
      <div
        className={`c-results ${type !== 'items' && full ? 'c-results--full'  : ''} ${filtersDropdown ? 'c-results--no-scroll':''}`}
        ref={resultsDiv}
        onScroll={view !== 'map' && loadMore ? onScroll : null}
      >
        {partner && (
          <PartnerBanner
            clearPartner={clearPartner}
            partner={partner}
            langCode={siteLangcode}
          />
        )}
        {renderResults()}
      </div>
      <ConfirmationModal
        isOpen={!!confirmProps}
        onRequestClose={() => setConfirmProps(null)}
        onConfirm={onConfirm}
        confirmText={t('Add Entire Collection')}
      />
    </>
  )
}

export default SearchResults
