import { createReducer } from '@reduxjs/toolkit'
import sharedTypes from './sharedTypes'
import { EN } from 'shared/constants'

const defaultState = {
  loading: false,
  langcode: EN,
  homepage: {
    title: {},
    subtitle: {},
    learnMore: null,
    featuredResources: []
  }
}

export default createReducer(defaultState, {
  [sharedTypes.SET_LOADING]: (state, action) => {
    state.loading = action.payload
  },
  [sharedTypes.SET_LANGCODE_FLAG]: (state, action) => {
    state.langcode = action.payload
  },
  [sharedTypes.GET_HOMEPAGE_ITEMS_SUCCESS]: (state, action) => {
    state.homepage = action.payload
  },
})
