import React from 'react'
import { Twitter } from 'features/Global/components'
import {
  WEBSITE,
  DOCUMENT,
  AUDIO,
  IMAGE,
  TESTIMONIAL,
  BROADCAST,
  TWEET,
  VIDEO,
  BOOKMARK,
  ARTICLE,
  HEADLINE
} from 'shared/constants'

const MediaIcon = ({ type, color, size }) => {
  if (!type) return null
  switch (type) {
    case WEBSITE:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          public
        </i>
      )
    case BOOKMARK:
    case DOCUMENT:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          attach_file
        </i>
      )
    case BROADCAST:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          radio
        </i>
      )
    case HEADLINE:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          view_headline
        </i>
      )
    case ARTICLE:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          menu_book
        </i>
      )
    case AUDIO:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          music_note
        </i>
      )
    case IMAGE:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          image
        </i>
      )
    case TESTIMONIAL:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          person
        </i>
      )
    case TWEET:
      return <Twitter width={size || 13} color={color || 'black'} />
    case VIDEO:
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          theaters
        </i>
      )
    case 'Collection':
      return (
        <i
          className='material-icons'
          style={{ fontSize: `${size}px`, color }}
        >
          layers
        </i>
      )
    default:
      return null
  }
}

export default MediaIcon
