import React from 'react'
import { render } from 'react-snapshot'
import './shared/styles/main.scss'
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import store, { history, persistor } from 'bootstrap/redux'
import { PersistGate } from 'redux-persist/integration/react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-22729281-2')

render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App history={history} />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
