import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FileInput, TextInput } from 'features/Form/components'
import { AUTHENTICATED_USER, ADMINISTRATOR, CONTENT_EDITOR } from 'shared/constants'
import { selectAccountFormData, selectAccountFormSubmitting } from 'features/AccountForm/redux/accountFormSelectors'
import { setAccountFormData, toggleRole, updateAccount, updatePassword } from 'features/AccountForm/redux/accountFormActions'
import { selectUserIsAdmin } from 'features/authentication/redux/authenticationSelectors'
import PasswordHandler from './components/PasswordHandler'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import toast from 'shared/utils/toast'
import { EN, JA } from 'shared/constants'

const UpdateAccountForm = ({ resetScreen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isAdmin = useSelector(selectUserIsAdmin)
  const formData = useSelector(selectAccountFormData)
  const submitting = useSelector(selectAccountFormSubmitting)
  const siteLang = useSelector(selectLangcodeFlag)
  const [resetSucces, setResetSucces] = useState(false)
  const onChange = event => {
    dispatch(setAccountFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value
    }))
  }
  const onPictureUpload = file => {
    dispatch(setAccountFormData({
      ...formData,
      picture: file
    }))
  }
  const validateResetForm = useCallback(() => {
    if (!resetScreen) return false
    const errors = []
    if (!formData.mail) errors.push('Email field is required.')
    if (!formData.password) errors.push('Temporary password field is required.')
    if (!formData.newPass) {
      errors.push('New Password is required.')
    } else if (formData.newPass !== formData.newPassConfirm) {
      errors.push('Password confirmation does not match new password.')
    }
    if (errors.length) toast.error(errors)
    return !errors.length
  }, [formData, resetScreen])
  const onRoleToggle = role => dispatch(toggleRole(role))
  const onSubmit = useCallback(async event => {
    event.preventDefault()
    if (resetScreen && validateResetForm()) {
      const succesful = await dispatch(updatePassword())
      setResetSucces(succesful)
    } else {
      dispatch(updateAccount())
    }
  }, [dispatch, resetScreen, setResetSucces, validateResetForm])
  if (resetSucces) return <Redirect to={`/${siteLang}/user/login`} />
  return (
    <form className={`c-form ${resetScreen ? 'u-bg-white u-p' : ''}`} onSubmit={onSubmit}>
      <div className='c-form__item'>
        <TextInput
          required
          name='mail'
          label={t(resetScreen ? 'Email Address' : `Update Email Address`)}
          labelClassname='c-text-input__label--user-profile'
          value={formData.mail}
          onChange={onChange}
          className='c-account-form__input'
        />
      </div>
      <div className='c-form__item'>
        <TextInput
          required={resetScreen}
          name='password'
          type='password'
          label={t(`Enter ${resetScreen ? 'Temporary' : 'Current'} Password`)}
          labelClassname='c-text-input__label--user-profile'
          value={formData.password}
          onChange={onChange}
          className='c-account-form__input'
        />
      </div>
      {!resetScreen && (
        <div className='c-form__caption'>
          {t('A valid e-mail address. All e-mails from the system will be sent to this address. The e-mail address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by e-mail.')}
        </div>
      )}
      {resetScreen && <PasswordHandler data={formData} onChange={onChange} />}
      {isAdmin && !resetScreen && <div className='c-form__item'>
        <fieldset>
          <legend className='c-heading c-heading--l6'>{t('Roles')}</legend>
          <label htmlFor='authenticated' className='c-form__option'>
            <input
              type='checkbox'
              name='authenticated'
              checked={formData.roles.includes(AUTHENTICATED_USER)}
              onChange={() => onRoleToggle(AUTHENTICATED_USER)}
              disabled={true}
            />
              {t('Authenticated User')}
            </label>
          <label htmlFor='admin' className='c-form__option'>
            <input
              type='checkbox'
              name='admin'
              onChange={() => onRoleToggle(ADMINISTRATOR)}
              checked={formData.roles.includes(ADMINISTRATOR)}
            />
              {t('Administrator')}
          </label>
          <label htmlFor='editor' className='c-form__option'>
            <input
              type='checkbox'
              name='editor'
              onChange={() => onRoleToggle(CONTENT_EDITOR)}
              checked={formData.roles.includes(CONTENT_EDITOR)}
            />
              {t('Content Editor')}
          </label>
        </fieldset>
      </div>}
      {!resetScreen && (
        <div className='c-form__item'>
          <fieldset>
            <legend className='c-heading c-heading--l6'>{t('Language')}</legend>
            <label className='c-form__option'>
              <input
                type='radio'
                name='preferredLangcode'
                value={EN}
                onChange={onChange}
                checked={formData.preferredLangcode === EN}
              />
                English
            </label>
            <label htmlFor={JA} className='c-form__option'>
              <input
                type='radio'
                name='preferredLangcode'
                value={JA}
                onChange={onChange}
                checked={formData.preferredLangcode === JA}
              />
                Japanese (日本語)
            </label>
          </fieldset>
          <div className='c-form__caption'>
            {t('This account\'s default language for e-mails, and preferred language for site presentation.')}
          </div>
        </div>
      )}
      {!resetScreen && (
        <div className='c-form__item'>
          <label className='c-heading c-heading--l6' htmlFor='picture'>
            {t('Upload picture')}
          </label>
          <FileInput type='file' accept='image/jpeg, image/png' onChange={onPictureUpload} value={formData.picture} />
          <div className='c-form__caption'>
            {t('Your virtual face or picture. Pictures larger than 1024x1024 pixels will be scaled down.')}
          </div>
        </div>
      )}
      <div className='c-form__item'>
        <button
          className={`c-btn c-btn--sm u-color-white u-bg-orange c-copy--uppercase ${submitting ? 'is-disabled' : ''}`}
          disabled={submitting}
        >
          {t('Update')}
        </button>
        {isAdmin && !resetScreen && (
          <button
            className={`c-btn c-btn--sm u-color-white u-bg-orange u-ml ${submitting ? 'is-disabled' : ''}`}
            disabled={submitting}
          >
            {t('Cancel Account')}
          </button>
        )}
      </div>
    </form>
  )
}

export default UpdateAccountForm
