import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { LanguageTabber } from 'shared/components'
import { selectCollectionData } from 'features/Collections/redux/collectionsSelectors'
import { parseHtmlToReact } from 'shared/utils/tools/parse'
import { formatItemDate } from 'shared/utils/tools/datetime'
import { EN } from 'shared/constants'
import { useTranslation } from 'react-i18next'

const CollectionDetailSidebar = () => {
  const {t} = useTranslation()
  const data = useSelector(selectCollectionData)
  const [activeTab, setActiveTab] = useState(EN)
  const {
    publishedDate,
    img,
    published,
    description,
    updatedDate,
    title,
    owner,
  } = data || {}

  return (
    <div className='c-collection-detail__sidebar'>
      <div className={`c-collection-detail__sidebar-img-container ${!img ? 'c-collection-detail__sidebar-img-container--loading' : ''}`}>
        <img src={img} alt='' className='c-collection-detail__sidebar-img' />
      </div>
      <LanguageTabber
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className='c-language-tabber--fill'
        contentClassName='u-p'
      >
        <div className='c-heading c-heading-14'>{title[activeTab]}</div>
        {publishedDate && (
          <div className='c-copy c-copy--sm c-copy--italic c-copy--icon u-color-grey-300'>
            <span className='c-copy__icon c-copy__icon--left c-copy__icon--sm'>
              <i className='material-icons'>layers</i>
            </span>
            {`${t('Created')} ${formatItemDate(publishedDate)} ${t('via')} ${owner}${updatedDate ? ` | ${t('Updated')} ${formatItemDate(updatedDate)}` : ''} | ${published ? t('Published') : t('Private')}`}
          </div>
        )}
        <div className='c-collection-detail__description'>
          {description && description[activeTab] && parseHtmlToReact(description[activeTab])}
        </div>
      </LanguageTabber>
    </div>
  )
}

export default CollectionDetailSidebar
