import { AUTHENTICATED_USER, JA, JAPANESE, EN } from "shared/constants"

export const processLangcode = (data) => {
  if (!data || !Array.isArray(data) || !data.length) {
    return EN
  }
  const { value } = data[0]
  const langcode = value && value.toLowerCase()
  if (langcode === JA || langcode === JAPANESE) {
    return JA
  }
  return EN
}

export const processLogin = response => {
  if (response['current_user']) {
    const user = response['current_user']
    return ({
      id: Number(user.uid),
      username: user.name,
      roles: user.roles,
      logoutToken: response['logout_token'],
      csrfToken: response['csrf_token']
    })
  } else {
    return {
      error: response.message
    }
  }
}

export const processUser = response => {
  if (response) {
    const roles = response.roles ? [...response.roles.map(role => role['target_uuid']), AUTHENTICATED_USER]
      : [AUTHENTICATED_USER]
    return {
      id: response.uid[0] && response.uid[0].value,
      uuid: response.uuid[0] && response.uuid[0].value,
      username: response.name[0] && response.name[0].value,
      displayName: response['field_display_name'][0] && response['field_display_name'][0].value,
      mail: response.mail && response.mail[0] && response.mail[0].value,
      preferredLangcode: processLangcode(response['preferred_langcode']),
      avatar: response['user_picture'][0] && response['user_picture'][0].url,
      joinDate: response.created[0] && response.created[0].value,
      roles
    }
  } else {
    return {
      error: 'User data not found.'
    }
  }
}

export const extractProfilePictureUrl = response => {
  if (response.data && response.data.attributes) {
    return response.data.attributes.uri.url
  } else {

  }
}

export const processAlert = data => {
  if (!data || !Array.isArray(data) || !data.length) {
    return null;
  }
  const alert = data[0];
  return {
    id: alert.nid,
    changedDate: alert.changed,
    showIcon: alert.field_show_icon,
    enContent: alert.field_english_content,
    jaContent: alert.field_japanese_content,
  }
}
