import { DndProvider, createDndContext } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import React, { useRef } from 'react'

const RNDContext = createDndContext(HTML5Backend)

const useDNDProviderElement = props => {
  const manager = useRef(RNDContext)

  if (!props.children) return null

  return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>
}

const DragAndDrop = props => {
  const DNDElement = useDNDProviderElement(props)
  return <>{DNDElement}</>
}

export default DragAndDrop
