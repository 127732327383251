import { rectangle } from 'leaflet'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const locationOffset = 0

export default function ContributorDeleteIcon({
  onDelete = () => {},
}) {
  const [showModal, setShowModal] = useState(false)
  const [location, setLocation] = useState({
    top: null,
    left: null
  })
  const ref = useRef(null)
  const { t } = useTranslation()

  function setShow(bool) {
    // side effect
    const rect = ref.current.getBoundingClientRect()
    const windowWidth = window.innerWidth < 992
    
    setLocation({
      top: rect.top + (rect.height / 2) - 135,
      left: rect.left - (rect.width / 2) + 50 - 215
    })

    // actually set modals
    if (bool) return setShowModal(bool)
    return setShowModal(prev => !prev)
  }

  return <div className='c-delete-contributor' ref={ref}>
    <button 
      className='c-delete-contributor__icon'
      onClick={() => setShow(true)}
    >
      <i className='small material-icons'>clear</i>
    </button>
    <DeleteConfirmationModal 
      isOpen={showModal}
      onClose={() => setShow(false)}
      onDelete={onDelete}
      style={{
        position: 'fixed',
        ...location
      }}
    />
  </div>
}