import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { updateLangCodeFlag } from 'shared/redux/sharedActions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { JdaLink } from 'shared/components'
import { EN, JA } from 'shared/constants'

export default ({ mobile = false, mobileClick = () => {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const lang = useSelector(selectLangcodeFlag)
  const setLang = useCallback(langcode => dispatch(updateLangCodeFlag(langcode)), [dispatch])

  const handleLangToggle = (code) => {
    setLang(code)
    mobileClick()
  }

  const renderSwitch = () => (
    <div className={`c-tophat__switch ${mobile ? 'c-tophat__switch--mobile' : ''}`}>
      <button style={{ cursor: 'pointer' }} onClick={() => handleLangToggle(EN)} className="c-tophat__lang">English</button>
      <button style={{ cursor: 'pointer' }} onClick={() => handleLangToggle(lang === EN ? JA : EN)} className={`c-tophat__toggle c-tophat__toggle--${lang}`} aria-label={lang === EN ? 'English' : '日本語'}>
        <div className="c-tophat__toggle-inner"></div>
      </button>
      <button style={{ cursor: 'pointer' }} onClick={() => handleLangToggle(JA)} className="c-tophat__lang">日本語</button>
    </div>
  )

  if(mobile) return renderSwitch()

  return (
    <div className="c-tophat">
      <nav className="c-tophat__nav">
        <ul className="c-tophat__items">
          <li className="c-tophat__item">
            <JdaLink to="/resources/about/about-jda" className="c-tophat__item-link">{t('About')}</JdaLink>
          </li>
        </ul>
      </nav>
      {renderSwitch()}
    </div>
  )
}
