import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const SortDropdown = ({ options = [], borders = false, onChange }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(options.length ? options[0].label : '')
  const [visible, setVisible] = useState(false)
  const [listHeight, setListHeight] = useState(0)
  const listRef = useRef()
  const buttonRef = useRef()
  const toggleDropdown = event => {
    event.preventDefault()
    setVisible(!visible)
  }
  const setOption = (event, option) => {
    if (event.keyCode === 13 || event.type === 'click') {
      const optionItem = options.find(o => o.value === option)
      setSelected(optionItem?.label || option)
      onChange(option)
      setVisible(false)
    }
  }
  useEffect(() => {
    const onClickOutside = event => {
      const clickedButton = buttonRef.current && buttonRef.current.contains(event.target)
      if (listRef.current && !listRef.current.contains(event.target) && !clickedButton) {
        setVisible(false)
      }
    }
    const onEscClose = event => {
      if (event.key === 'Escape') setVisible(false)
    }
    window.addEventListener('mousedown', onClickOutside)
    window.addEventListener('keydown', onEscClose)
    return () => {
      window.removeEventListener('mousedown', onClickOutside)
      window.removeEventListener('keydown', onEscClose)
    }
  }, [setVisible])
  useEffect(() => {
    if (listRef.current) setListHeight(listRef.current.clientHeight)
  }, [listRef, setListHeight])
  const listStyle = listHeight ? { maxHeight: `${listHeight}px` } : {}
  return (
    <div className={`c-sort-dropdown ${borders ? 'c-sort-dropdown--w-borders' : ''}`}>
      <button ref={buttonRef} className='c-sort-dropdown__selector u-ph' onClick={toggleDropdown}>
        <div>
          <div className='c-sort-dropdown__label'>
            {t('Sort By')}
          </div>
          <div className='c-sort-dropdown__selected'>
            {t(selected)}
          </div>
        </div>
        <div className='u-ml'>
          <i className='material-icons'>
            expand_more
          </i>
        </div>
      </button>
      <ul
        ref={listRef}
        className={`c-sort-dropdown__list ${!visible && listHeight !== 0 ? 'hidden' : ''} ${listHeight === 0 ? 'loading' : ''}`}
        style={listStyle}
      >
        {options.filter(option => selected !== option.value).map(({ label, value }, index) => {
          return (
            <li
              tabIndex={visible ? '0' : '-1'}
              key={index} 
              className={`c-sort-dropdown__option`}
              onClick={e => setOption(e, value)}
              onKeyDown={e => setOption(e, value)}
            >
              {t(label || value)}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SortDropdown
