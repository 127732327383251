import { createReducer } from '@reduxjs/toolkit'
import basicPageTypes from './basicPageTypes'

const defaultState = {
  data: [],
  menus: [],
  currentPage: null,
  meta: {
    resourcesLoaded: false,
    singlePageLoaded: false
  },
  defaultResourceLink: "/resources/about/about-jda",
}

export default createReducer(defaultState, {
  [basicPageTypes.GET_RESOURCES_SUCCESS]: (state, action) => {
    const { data, menus, defaultResourceLink } = action.payload
    state.data = data
    state.menus = menus
    state.meta.resourcesLoaded = true
    if (defaultResourceLink ) state.defaultResourceLink = defaultResourceLink
  },
  [basicPageTypes.GET_RESOURCES_FAIL]: state => {
    state.meta.resourcesLoaded = true
  },
  [basicPageTypes.GET_SINGLE_PAGE_BEGIN]: state => {
    state.meta.singlePageLoaded = false
  },
  [basicPageTypes.GET_SINGLE_PAGE_SUCCESS]: (state, action) => {
    state.currentPage = action.payload
    state.meta.singlePageLoaded = true
  },
  [basicPageTypes.GET_SINGLE_PAGE_FAIL]: state => {
    state.meta.singlePageLoaded = true
  }
})
