import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputWrapper from '../InputWrapper';

const ReactCKEditor = ({
  name,
  caption,
  required,
  label,
  labelClassname = '',
  labelEx,
  onChange,
  extensions = [],
  showToolBar = true,
  value,
  id,
}) => {

  return (
      <InputWrapper
        name={name}
        caption={caption}
        required={required}
        label={label}
        labelEx={labelEx}
        labelClassname={labelClassname}
      >
          <CKEditor
              id={id}
              config={{
                toolbar: showToolBar ?['bold', 'italic', '|', 'link', '|', 'numberedList', 'bulletedList', '|' , 'blockquote', '|', 'heading',] : []
              }}
              editor={ ClassicEditor }
              data={value}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  const payload = {currentTarget: {
                    name: name, value: data
                  }}
                  onChange && onChange(payload)
              } }
          />
      </InputWrapper>
  );
}

export default ReactCKEditor;