const FEATURED_LINK_FIELDS = `
  ... on ParagraphLinkCard {
    fieldTitle
    fieldShortDescription
    fieldLink {
      uri
      url {
        routed
        path
        ... on EntityCanonicalUrl {
          pathAlias
          nodeContext {
            entityBundle
          }
        }
      }
      options
    }
  }
`

const SHARED_FRAGMENTS = `
  fragment ParagraphWysiwyg on ParagraphWysiwyg {
    fieldTitle
    fieldBody {
      processed
    }
  }
  fragment JaParagraphSplitContentSection on ParagraphSplitContentSection {
    fieldTitle
    fieldSplitContent {
      entity {
        ja: entityTranslation(language: JA) {
          ... on ParagraphSplitContent {
            fieldTitle
            fieldBody {
              processed
            }
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
        en: entityTranslation(language: EN) {
          ... on ParagraphSplitContent {
            fieldTitle
            fieldBody {
              processed
            }
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment EnParagraphSplitContentSection on ParagraphSplitContentSection {
    fieldTitle
    fieldSplitContent {
      entity {
        en: entityTranslation(language: EN) {
          ... on ParagraphSplitContent {
            fieldTitle
            fieldBody {
              processed
            }
            fieldImage {
              entity {
                ... on MediaImage {
                  fieldMediaImage {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment JaParagraphLinkCardSection on ParagraphLinkCardSection {
    fieldTitle
    fieldFeaturedLinks {
      entity {
        ja: entityTranslation(language: JA) {
          ${FEATURED_LINK_FIELDS}
        }
        en: entityTranslation(language: EN) {
          ${FEATURED_LINK_FIELDS}
        }
      }
    }
  }
  fragment EnParagraphLinkCardSection on ParagraphLinkCardSection {
    fieldTitle
    fieldFeaturedLinks {
      entity {
        en: entityTranslation(language: EN) {
          ${FEATURED_LINK_FIELDS}
        }
      }
    }
  }
`

const JA_QUERY_FIELDS = `
  ... on NodePage {
    metatag: entityMetatags {
      type: __typename
      property: key
      content: value
    }
    langcode {
      value
    }
    path {
      alias
      langcode
    }
    nid
    title
    fieldComponents {
      entity {
        ja: entityTranslation(language: JA) {
          ...ParagraphWysiwyg
          ...JaParagraphSplitContentSection
          ...JaParagraphLinkCardSection
        }
          ... on ParagraphVideo {
            fieldVideo {
              entity {
                ... on MediaVideo {
                  fieldMediaVideoFile {
                    entity {
                      url
                    }
                  }
                }
                ... on MediaRemoteVideo {
                  fieldMediaOembedVideo
                }
              }
            }
          }
      }
    }
  }
`

const EN_QUERY_FIELDS = `
  ... on NodePage {
    metatag: entityMetatags {
      type: __typename
      property: key
      content: value
    }
    langcode {
      value
    }
    path {
      alias
      langcode
    }
    nid
    title
    fieldComponents {
      entity {
        en: entityTranslation(language: EN) {
          ...ParagraphWysiwyg
          ...EnParagraphSplitContentSection
          ...EnParagraphLinkCardSection
        }
        ... on ParagraphVideo {
          fieldVideo {
            entity {
              ... on MediaVideo {
                fieldMediaVideoFile {
                  entity {
                    url
                  }
                }
              }
              ... on MediaRemoteVideo {
                fieldMediaOembedVideo
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_RESOURCES_QUERY = `
  {
    nodeQuery(limit: 1000, filter: {conditions: {field: "type", value: "page"}}) {
      count
      entities {
        ja: entityTranslation(language: JA) {
          ${JA_QUERY_FIELDS}
        }
        en: entityTranslation(language: EN) {
          ${EN_QUERY_FIELDS}
        }
      }
    }
  }
  ${SHARED_FRAGMENTS}
`

export const getSinglePageNodeQuery = id => `
  {
    nodeById(id: "${id}") {
      ja: entityTranslation(language: JA) {
        ${JA_QUERY_FIELDS}
      }
      en: entityTranslation(language: EN) {
        ${EN_QUERY_FIELDS}
      }
    }
  }
  ${SHARED_FRAGMENTS}
`