// meta
export const selectContributeFormLanguage = state => state.contribute.meta.language
export const selectContributeFormPage = state => state.contribute.meta.page
export const selectContributePreviewVisible = state => state.contribute.meta.previewVisible
export const selectContributeSuccesful = state => state.contribute.meta.succesful
export const selectTagSearching = state => state.contribute.meta.searching
export const selectContributeSubmitting = state => state.contribute.meta.submitting

export const selectContributeFormData = state => state.contribute.data
export const selectContributeFormUserData = state => state.contribute.data.user
export const selectContributeMultiFormData = state => state.contribute.data.multiForm
export const selectTagSearchResults = state => state.contribute.tagResults
export const selectContributeFormRes = state => state.contribute.res

export const selectIsMultipleUploadMode = state => state.contribute.isMultipleUpload
export const selectMultipleType = state => state.contribute.multiUploadType
export const selectApplyTagsToAllValue = state => state.contribute.applyToAll