import React from 'react'
import { CollectionItem } from 'features/Collections'
import { JdaLink, LanguageTabber } from 'shared/components'
import defaultLogo from 'shared/assets/images/jda_default-large-logo.jpg'
import { useSelector } from 'react-redux'
import { selectItemData } from 'features/Collections/redux/collectionsSelectors'
import { parseCollectionPreview } from 'shared/utils/tools/parse'
import { formatDate } from 'shared/utils/tools/datetime'
import { useTranslation } from 'react-i18next'

const CollectionPreview = ({ activeTab, onTabChange, closeModal }) => {
  const collectionData = useSelector(selectItemData)
  const {
    id,
    title,
    description,
    publishedDate,
    owner,
    img,
    items
  } = collectionData
  const {t} = useTranslation()
  return (
    <div className='c-collection-preview'>
      <LanguageTabber
        setActiveTab={onTabChange && (value => onTabChange(value))}
        activeTab={activeTab}
        className='c-collection-preview__tabber'
        contentClassName='c-collection-preview__content-wrapper'
      >
        <div className='c-collection-preview__info'>
          <div className='c-collection-preview__info-col'>
            <img className='c-collection-preview__img u-pr' src={img || defaultLogo} alt='' />
          </div>
          <div className='c-collection-preview__info-col'>
            <div className='c-heading c-heading--l4'>
              {title && title[activeTab]}
            </div>
            <div className='c-copy c-copy--italic c-copy--sm c-copy--icon'>
              <span className='c-link__icon c-copy__icon c-copy__icon--sm'>
                <i className="material-icons">layers</i>
              </span>
              <span>{`${t('Created')} ${formatDate(publishedDate)} ${t('via')} ${owner}`}</span>
            </div>
            <div className='c-collection-preview__description'>
              {description && description[activeTab] && parseCollectionPreview(description[activeTab])}
            </div>
          </div>
        </div>
        <div className='c-collection-preview__items'>
          {items.filter(({ type }) => type === 'collectionItem').map(({ id, itemData }) => {
            return (
              <CollectionItem
                key={id}
                hideHandle
                hideActionButtons
                hideImg
                {...itemData}
              />
            )
          })}
        </div>
        <JdaLink
          to={`/collection/${id}`}
          className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn'
          onClick={closeModal}
        >
          {t('View Collection')}
        </JdaLink>
      </LanguageTabber>
    </div>
  )
}

export default CollectionPreview
