import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectUser } from 'features/authentication/redux/authenticationSelectors'
import defaultAvatar from 'shared/assets/images/default.jpg'
import moment from 'moment'
import { GEN_DATE_FORMAT } from 'shared/utils/tools/datetime'
import { selectUserCollectionCount } from 'features/Collections/redux/collectionsSelectors'
import { getBackgroundImageStyle } from 'shared/utils/tools/parse'

const ProfileSidebar = () => {
  const user = useSelector(selectUser)
  const { t } = useTranslation()
  const collectionCount = useSelector(selectUserCollectionCount)
  const { displayName, joinDate, username, avatar } = user
  return (
    <aside className='c-basic-page__sidebar c-basic-page__sidebar--user-profile'>
      <div
        aria-label='User avatar'
        style={getBackgroundImageStyle(avatar || defaultAvatar)}
        className='c-user-profile__avatar'
      />
      <div className='c-user-profile__s-title'>{displayName && displayName.length ? displayName : username}</div>
      <div className='u-p'>
        <div className='c-user-profile__s-heading'>{t('User statistics')}</div>
        <ul className='c-user-profile__list'>
          <li>{t('Joined')} {joinDate && moment(joinDate).format(GEN_DATE_FORMAT)}</li>
          <li>{collectionCount} {t(`Collection${collectionCount === 1 ? '' : 's'}`)}</li>
        </ul>
      </div>
    </aside>
  )
}

export default ProfileSidebar
