import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import Translate from 'shared/components/Translate'
import defaultLogo from 'shared/assets/images/jda_default-large-logo.jpg'
import { loadItemModal } from 'features/Collections/redux/collectionsActions'
import { debounce } from 'utils/functions'
import { COLLECTION } from 'shared/constants'
import { useTranslation } from 'react-i18next'

const RelatedCollections = ({ data }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const openModal = useCallback(debounce(id => dispatch(loadItemModal({ id, type: COLLECTION })), 500, true), [dispatch])
  return (
    <div>
      <h3 className="u-mv">{t('RELATED COLLECTIONS')}</h3>
      <ul className='o-grid o-grid--equal-height'>
        {data.map(col => (
          <li
            key={col.id}
            className={`o-grid__col u-mbxs c-contribution-item__related-collection`}
            onClick={() => openModal(col.id)}
            style={{ width: `${1 / data.length * 100}%` }}
          >
            <div className='c-contribution-item__related-collections-img' style={{ background: `url(${col.img || defaultLogo})`}} />
            <div className='u-p u-bg-grey-100 u-color-black c-copy c-copy--bold'>
              <Translate {...col.title} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RelatedCollections
