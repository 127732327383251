import React, { useState, useEffect, useCallback } from 'react'
import { CollectionItem, TextSlide } from 'features/Collections'
import { DragAndDrop } from 'shared/components'
import update from 'immutability-helper'
import SortItem from './SortItem'
import { TEXT_SLIDE } from 'shared/constants'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/authentication/redux/authenticationSelectors'
import { useTranslation } from 'react-i18next'

const ColItemSortList = ({
  items = [],
  topics,
  setSelectedDeleteItem,
  setSelectedNoteItem,
  saveNewSort,
  isOwner: isCollectionOwner,
  canEdit,
  handleRight,
  moveItemToTopic,
  allItems,
  setItemTopicList,
  topicId,
  view
}) => {
  const activeUser = useSelector(selectUser)
  const { t } = useTranslation();

  // let items = view === 'list' ? items : items[topicId].map(item => item)
  const [itemList, setList] = useState([])
  useEffect(() => {
    const sortedList = items.map(x => x)
    let sortField = view === 'list' ? 'fieldSortOrder':'fieldTopicWeight'
    sortedList.sort((a, b) => parseInt(a[sortField], 10) > parseInt(b[sortField], 10))

    setList(sortedList)
  }, [setList, items, view])
  const moveItem = useCallback((dragIndex, hoverIndex, item, dropped, topicId) => {
    const itemExists = itemList.find(x => x.id === item.id)
    const dragItem = itemList[dragIndex]
    let newItemList

    if(itemExists) {
      newItemList = update(itemList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      })
      if (dropped && saveNewSort) {
        const inTopic = view === 'collection'
        saveNewSort(newItemList, inTopic)
      } else {
        setList(newItemList)
      }
    } else {
      if(dropped) {
        moveItemToTopic(item.id, topicId)

      } else {

        // setItemTopicList({ ...items, [topicId]: [...newItemList], [oldTopic]: removeFrom})
        // setList(newItemList)
      }
    }
  }, [items, itemList, setList, setItemTopicList, saveNewSort, allItems])
  return (
    <>
    {canEdit ? (
      <DragAndDrop>
        {itemList.length > 0 ? (
          itemList.map(({ id, itemData, notes, type, fieldCollectionTopic: topicField }, index) => {
          const itemTopic = topicField && topicField.entity && topicField.entity.id || 0
          const isItemOwner = itemData.entityOwner?.entityId === activeUser.id
          const canDelete = (isItemOwner || isCollectionOwner)
          const removeFn = canDelete
            ? () => setSelectedDeleteItem(id)
            : false

          return (
            <SortItem
              hoverTopic={topicId}
              itemTopic={itemTopic}
              key={id}
              id={id}
              index={index}
              moveItem={moveItem}
            >
                {type === TEXT_SLIDE ? (
                  <TextSlide
                    topics={topics}
                    notes={notes}
                    topicItemId={id}
                    notesFn={() => setSelectedNoteItem({ id, notes, canDelete })}
                    removeFn={removeFn}
                    moveFn={moveItemToTopic}
                    view={view}
                    {...itemData}
                  />
                ) : (
                <CollectionItem
                  key={id}
                  topicItemId={id}
                  topics={topics}
                  handleRight={handleRight}
                  notesFn={() => setSelectedNoteItem({ id, notes, canDelete })}
                  removeFn={removeFn}
                  moveFn={moveItemToTopic}
                  notes={notes}
                  view={view}
                  {...itemData}
                />
              )}
            </SortItem>
          )
        })
      ) : (
        <SortItem
          hoverTopic={topicId}
          itemTopic={0}
          id={0}
          index={0}
          moveItem={moveItem}
          isTopic={true}
        >
          <div className="c-collection-topic__empty">{t('Drag or move items into this topic')}</div>
        </SortItem>
        )}
      </DragAndDrop>
    ) : (
      itemList.map(({ id, type, itemData, notes }) => type === TEXT_SLIDE ? (
        <TextSlide
          notes={notes}
          {...itemData}
        />
      ) : (
        <CollectionItem
          hideHandle
          key={id}
          handleRight={handleRight}
          notes={notes}
          {...itemData}
        />
      ))
    )}
    </>
  )
}

export default ColItemSortList
