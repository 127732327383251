import React, { useState } from 'react'
import { ContributionItem } from 'features/Collections'
import { useSelector } from 'react-redux'
import { isItemEditable, selectItemData } from 'features/Collections/redux/collectionsSelectors'
import ContributionItemEditView from 'features/Contribute/components/ContributionItemEditView'

const ItemPreview = ({ activeTab, onTabChange }) => {
  const item = useSelector(selectItemData)
  const editable = useSelector(isItemEditable);
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit(value => !value);
  }
  return (
    edit ?
    <ContributionItemEditView onEditClicked={setEdit}/>
    :
    <ContributionItem
      onEditClicked={toggleEdit}
      editable={editable}
      activeTab={activeTab}
      onTabChange={onTabChange}
      data={{ ...item, description: item.descriptionProcessed }}
    />
  )
}

export default ItemPreview
