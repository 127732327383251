import React from 'react'
import { CollectionItem } from 'features/Collections'
import { TEXT_SLIDE } from 'shared/constants'

const TextSlide = (props) => {
  return (
    <CollectionItem
      hideImg={true}
      fadeDescription
      handleRight={false}
      itemModalType={TEXT_SLIDE}
      {...props}
    />
  )
}

export default TextSlide
