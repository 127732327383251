import React, { useEffect } from 'react'
import { FeaturedCollections } from 'features/Collections'
import { Search } from 'features/Search'
import { ArrowLink } from 'shared/components'
import { useDispatch, useSelector } from 'react-redux'
import { loadHomepageItems } from 'shared/redux/sharedActions'
import { selectHomepageContent, selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

const Homepage = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const content = useSelector(selectHomepageContent)
  const langcode = useSelector(selectLangcodeFlag)
  const { title, subtitle, learnMore } = content
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    dispatch(loadHomepageItems())
  }, [dispatch])
  return (
    <div className='c-layout__fill'>
      <section className="u-mb u-pt">
        <div className="o-container">
          <h1 className='c-search-container__title'>{title[langcode]}</h1>
          <div className='c-search-container__caption'>
            {subtitle[langcode]} {learnMore && <ArrowLink url={learnMore} text={t('Learn More')} />}
          </div>
        </div>
      </section>
      <section className="u-mv">
        <div className="o-container">
          <Search search="items" layout="compact" history={props.history}/>
        </div>
      </section>
      <FeaturedCollections />
    </div>
  )
}

export default Homepage