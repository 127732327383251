import { getCurrentCreateDate } from 'shared/utils/tools/datetime'
import { getLangValue } from 'utils/functions'
import { EN, JA } from 'shared/constants'

export const buildCollectionPayload = (formData = { title: {}, description: {} }, user = {}, fid)=> {
  const { title, description, published, langcode } = formData
  const { username, displayName } = user
  const date = getCurrentCreateDate()
  return ({
      type: [
        {
          'target_id': 'collection',
          'target_type': 'group_type'
        }
      ],
      status: [{ value: published }],
      label: [{ value: title[langcode] }],
      'field_description': [{ value: description[langcode] }],
      'field_media_creator_realname': [{ value: (displayName || username) }],
      'field_media_creator_username': [{ value: (displayName || username) }],
      'field_media_date_create': [{ value: date }],
      'field_japanese_title': [{ value: title[JA] || '' }],
      'field_japanese_description': [{ value: description[JA] || '' }],
      'field_english_title': [{ value: title[EN] || '' }],
      'field_english_description': [{ value: description[EN] || '' }],
      'field_file': [{ "target_id": fid }]
    })
}

export const buildCollectionItemPayload = (userId, collectionId, item, patch = false, inTopic) => {
  const { title, id, langcode, sortValue, notes } = item
  const sortField = inTopic ? 'field_topic_weight':'field_sort_order'
  let payload = {
    'type': [
      {
        'target_id': 'collection-group_node-item',
        'target_type': 'group_content_type'
      }
    ]
  }
  if (!patch) {
    payload = {
      ...payload,
      'gid': [
        {
          "target_id": collectionId,
          "target_type": "group",
          "url": `/group/${collectionId}`
        }
      ],
      'entity_id': [
        {
          "target_id": id,
          "target_type": "node",
          "url": `/node/${id}`
        }
      ],
      'field_include_waku_presentation': [
        {
          "value": true
        }
      ],
      'field_notes': [
        {
          "value": ''
        }
      ],
      'field_sort_order': [
        {
          "value": sortValue
        }
      ],
      'uid': [
        {
          'target_id': userId,
          'target_type': 'user'
        }
      ]
    }
  }
  if (title) payload.label = [{ "value": getLangValue(langcode, title) }]
  if (patch && (notes || notes === '')) payload['field_notes'] = [{ "value": notes }]
  if (sortValue) payload[sortField] = [{ "value": sortValue }]
  return payload
}

export const buildEditCollectionPayload = (formData = {}, fid, currentLang) => {
  const { title, description, image, published, imageThumbnail = null, topicsWeight = null } = formData
  let payload = {
    type: [
      {
        'target_id': 'collection',
        'target_type': 'group_type'
      }
    ],
    status: [{ value: published }]
  }
  if (title) {
    payload = {
      ...payload,
      'field_japanese_title': [{ value: title[JA] }],
      'field_english_title': [{ value: title[EN] }]
    }
    if (currentLang) {
      const collectionTitle = title[currentLang] ? title[currentLang] : title[JA] ? title[JA] : title[EN];
      if (currentLang && collectionTitle) {
        payload = {
          ...payload,
          'label': [{value: collectionTitle}],
        }
      }
    }
  }
  if (description) {
    payload = {
      ...payload,
      'field_japanese_description': [{ value: description[JA] }],
      'field_english_description': [{ value: description[EN] }]
    }
  }
  // image thumbnail takes priority over the file in collection and item models so we set it to null if a file is included
  if (imageThumbnail || (image && image.file)) {
    payload['field_new_thumbnail_url'] = [{ value: imageThumbnail }]
    payload['field_thumbnail_url'] = [{ uri: imageThumbnail }]
    payload['field_file'] = null
  }
  if (fid) payload['field_file'] = [{ "target_id": fid}]

  if (topicsWeight) {
    payload['field_collection_topic'] = topicsWeight.map(topic => ({ "target_id": topic }))
  }
  return payload
}

export const buildTextSlidePayload = (formData) => {
  const { title, description } = formData
  let payload = {
    type: [{ "target_id": "text_slide", "target_type": "content_type" }]
  }
  if (title) {
    payload = {
      ...payload,
      'field_japanese_title': [{ value: title[JA] }],
      'field_english_title': [{ value: title[EN] }]
    }
  }
  if (description) {
    payload = {
      ...payload,
      'field_japanese_description': [{ value: description[JA], format: "basic_html" }],
      'field_english_description': [{ value: description[EN], format: "basic_html" }]
    }
  }
  return payload
}

export const buildTopicPayload = (formData) => {
  const { topic_title, description } = formData
  let payload

  if (topic_title) {
    payload = {
      'english_title': [{ value: topic_title[EN] }],
      'japanese_title': [{ value: topic_title[JA] }]
    }
  }
  if (description) {
    payload = {
      ...payload,
      'english_description': [{ value: description[EN] }],
      'japanese_description': [{ value: description[JA] }]
    }
  }
  return payload
}

export const buildTopicResponse = (data) => {
  const {
    id,
    english_title,
    english_description,
    japanese_description,
    japanese_title
  } = data

  return {
    id,
    englishTitle: (english_title && english_title[0] && english_title[0].value) || null,
    englishDescription: (english_description && english_description[0] && english_description[0].value) || null,
    japaneseTitle: (japanese_title && japanese_title[0] && japanese_title[0].value) || null,
    japaneseDescription: (japanese_description && japanese_description[0] && japanese_description[0].value) || null
  }
}

export const buildTopicItemPayload = (topic, weight) => {
  let payload = {
    'type': [
      {
        'target_id': 'collection-group_node-item',
        'target_type': 'group_content_type'
      }
    ],
    'field_collection_topic': [{ target_id: topic }]
  }

  if (weight) {
    payload = {
      ...payload,
      'field_topic_weight': [{ value: weight }]
    }
  }

  return payload
}