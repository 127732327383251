import { LOGIN, SIGNUP, REQUEST_PASS } from 'shared/constants'
import i18n from 'i18n'
export const modalTypes = [LOGIN, SIGNUP, REQUEST_PASS]

export const loginFields = [
  {
    name: 'name',
    label: 'Username',
    caption: i18n.t('Enter your Japan Disasters Archive username'),
    required: true
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    caption: i18n.t('Enter the password that accompanies your username.'),
    required: true
  }
]

export const signupFields = [
  {
    name: 'name',
    caption: i18n.t('Spaces are allowed; punctuation is not allowed except for periods, hyphens, apostrophes, and underscores.'),
    label: 'Username',
    required: true,
    signup: true,
    errorMessage: i18n.t('Username already exists, please choose a different one'),
  },
  {
    name: 'mail',
    label: 'Email',
    caption: i18n.t('A valid e-mail address. All e-mails from the system will be sent to this address. The e-mail address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by e-mail.'),
    required: true,
    signup: true,
    errorMessage: i18n.t('Email already exists, please choose a different one'),
  },
  {
    name: 'displayName',
    label: 'Display Name'
  }
]

export const requestPassFields = [
  {
    name: 'accountName',
    label: 'E-mail address',
    required: true
  }
]

export const config = {
  [LOGIN]: {
    header: 'Sign In',
    buttonText: 'Log In'
  },
  [SIGNUP]: {
    header: 'Create new account',
    buttonText: 'Create new account'
  },
  [REQUEST_PASS]: {
    header: 'Request new password',
    buttonText: 'E-mail new password'
  }
}