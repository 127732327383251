import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import toast from 'shared/utils/toast'
import { openAccountModal } from 'features/AccountForm/redux/accountFormActions'
import { CREATE_COLLECTION, LOGIN } from 'shared/constants'
import { setItemModalType } from 'features/Collections/redux/collectionsActions'

const CreateCollectionCtaBox = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userLoaded = useSelector(selectUserLoadStatus)
  const onDrop = useCallback(() => {
    if (!userLoaded) {
      toast.error(`You must log in to create a new collection`)
      dispatch(openAccountModal(LOGIN))
    } else {
      toast.info(`Create your first collection`)
      dispatch(setItemModalType(CREATE_COLLECTION))
    }
  }, [dispatch, userLoaded])
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'box',
    drop: () => {
      onDrop()
      return { type: 'ctaBox' }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  })
  const isActive = canDrop && isOver

  return (
    <div ref={drop}>
      <div className={`c-collections__sidebar-ctabox ${isActive ? 'is-active' : ''}`}>
        <p className='c-copy--bold'>{t('Create your first collection')}!</p>
        <p>{t('Drag items into the sidebar to create your first collection')}</p>
      </div>
    </div>
  )
}

export default CreateCollectionCtaBox