import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CollectionItemMini } from 'features/Collections'
import {
  selectCreateCollectionSubmitting,
  selectUserCollections
} from 'features/Collections/redux/collectionsSelectors'
import { selectContributeFormRes } from 'features/Contribute/redux/contributeSelectors'
import { addCollectionItem, loadUserItems } from 'features/Collections/redux/collectionsActions'
import toast from 'shared/utils/toast'
import { USER_COLLECTIONS } from 'shared/constants'
import { getLangValue } from 'utils/functions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { useTranslation } from 'react-i18next'

const ContributeSuccess = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [checkedCollections, setCheckedCollections] = useState([])
  const submitting = useSelector(selectCreateCollectionSubmitting)
  const collections = useSelector(selectUserCollections)
  const item = useSelector(selectContributeFormRes)
  const siteLang = useSelector(selectLangcodeFlag)
  useEffect(() => {
    dispatch(loadUserItems([USER_COLLECTIONS]))
  }, [dispatch])
  const validateSelection = useCallback(checkedCollection => {
    if (!checkedCollection) return false
    const foundItem = checkedCollection.items.find(it => it.itemData.id === item.id)
    if (foundItem) return false
    return true
  }, [item])
  const toggleItem = useCallback(checkedCollection => {
    const valid = validateSelection(checkedCollection)
    if (valid) {
      setCheckedCollections(cols => {
        if (cols.find(col => checkedCollection.id === col.id)) {
          return cols.filter(col => col.id !== checkedCollection.id)
        } else {
          return [...cols, checkedCollection]
        }
      })
    } else {
      toast.error('already contains', {transParams: {'0': getLangValue(siteLang, checkedCollection.title), '1': getLangValue(siteLang, item.title)}})
    }
  }, [setCheckedCollections, validateSelection, item, siteLang])
  const addToCollections = useCallback(async () => {
    await dispatch(addCollectionItem(checkedCollections, item))
    setCheckedCollections([])
  }, [dispatch, checkedCollections, item])
  const addToCollectionsDisabled = submitting || !checkedCollections.length
  return (
    <div className='c-contribute-form'>
      <div className='c-contribute-form__content c-contribute-form__content--success'>
        <div className='c-heading c-heading--l1 u-pt24 u-ph24'>
          {t('Thanks for Submitting to the Archive!')}
        </div>
        <div className='c-heading c-heading--l3 u-ph24'>
          {t('Would you like to add this item to any of your collections?')}
        </div>
        <div className='c-contribute-form__col-list u-ph24'>
          {collections.map(col => {
            const checked = !!checkedCollections.find(v => v.id === col.id)
            return (
              <CollectionItemMini
                key={`${col.id}-${col.langcode}`}
                checkboxLeft
                privateItem={col.status}
                className='c-item-modal__collection-item u-mbxs'
                onCheck={toggleItem}
                checked={checked}
                {...col}
              />
            )
          })}
        </div>
        <button
          onClick={addToCollections}
          disabled={addToCollectionsDisabled}
          className={`
            c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn 
            ${addToCollectionsDisabled ? 'is-disabled' : ''}`}
        >
          {t('ADD TO COLLECTIONS')}
        </button>
      </div>
    </div>
  )
}

export default ContributeSuccess
