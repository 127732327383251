import toast from "shared/utils/toast"
import { AUDIO, DOCUMENT, IMAGE, TESTIMONIAL, TWEET, VIDEO, WEBSITE } from "shared/constants"

export default (form, activePage) => {
  const { title, mediaType, uri, author, twitterUsername, date, description, file } = form
  let errorMsg = []
  if (activePage === 1) {
    if (!title.en.length && !title.ja.length) errorMsg.push('Please choose a title and media type.')
  } else if (activePage === 2) {
    if ([WEBSITE, AUDIO, VIDEO, TWEET].includes(mediaType) && !uri) errorMsg.push('Item requires a URL')
    if (mediaType === TWEET && !twitterUsername) errorMsg.push('Item requires a username')
    if (!date) errorMsg.push('Item requires an original publish date')
    if (!uri && !file.file) {
      if (mediaType === DOCUMENT) errorMsg.push('Item requires a URL or attached file')
      if (mediaType === IMAGE) errorMsg.push('Item requires a URL or attached image')
    }
    if (mediaType === TESTIMONIAL) {
      if (!author) errorMsg.push('Item requires an author')
      if (!description.en && !description.ja) errorMsg.push('Item requires a testimonial')
    }
  }
  if (errorMsg.length) {
    toast.error(errorMsg)
    return false
  }
  return true
}
