import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

const Captcha = ({ captchaRef, ...props }) => {
  const { t } = useTranslation()
  return (
    <fieldset className='c-captcha'>
      <legend className='c-captcha__legend'>
        <span className='c-captcha__fieldset-legend'>CAPTCHA</span>
      </legend>
      <div className='c-captcha__description'>
        {t('This question is for testing whether or not you are a human visitor and to prevent automated spam submissions.')}
      </div>
      <div className='c-captcha__frame'>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey='6LdKciwUAAAAAOyamuk9pfGFAf922GVmdi-3yvMi'
          {...props}
        />
      </div>
    </fieldset>
  )
}

export default Captcha
