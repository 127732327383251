// const baseUrl = 'https://staging.jdarchive.org' // TODO: Make relative once the endpoints are migrated
const baseUrl = '/api' // TODO: Make relative once the endpoints are migrated

let map_config = {
  lng: 11,
  lat: 49,
  zoom: 4,
  search_api_url: `${baseUrl}/api/search`,
  solr_location_field: 'locs_field_location',
  max_proximity_results: 50, // max results when user clicks on map and popup is delivered
  max_pins: 250, // we will show map pins if the total number does not exceed this amount.
  tiles: 'http://a.tiles.mapbox.com/v3/jdarchive.he805gp4/{z}/{x}/{y}.png',
  accessToken: 'pk.eyJ1IjoiamRhcmNoaXZlLWhhcnZhcmQiLCJhIjoiY2todzlkZGF6MGpoMDJ6bW8ydGk4ZnYydSJ9.q5DtnV-X89sD3nVnuJWlYw',
  devAccessToken: 'pk.eyJ1IjoiaW5nZW5pZXJvYXJpZWwiLCJhIjoibXhDZ3pIMCJ9.qTmPYCbnUKtaNFkvKKysAQ',
  debug_log_enabled: false
}

let mapOptions = {
  searchMap: {
    style: 'mapbox://styles/jdarchive-harvard/ck2wemflp0x8m1cnr3pj4fmt8',
    center: [137.944336, 36.305165],
    zoom: 5,
    minZoom: 2,
    maxZoom: 18,
    scrollZoom: true
  },
  itemMap: {
    style: 'mapbox://styles/jdarchive-harvard/ck2wemflp0x8m1cnr3pj4fmt8',
    center: [51.505, -0.09],
    zoom: 13,
    minZoom: 2,
    maxZoom: 18,
    scrollZoom: false
  },
  defaultMap: {
    style: 'mapbox://styles/jdarchive-harvard/ck2wemflp0x8m1cnr3pj4fmt8',
    center: [137.944336, 36.305165],
    zoom: 5,
    scrollZoom:false,
    dragging: false,
  }
}

let map_vars = {
  pin_layer:undefined,
  heatmap_layer:undefined,
  heatmap_object:undefined,
  facet_heatmap:undefined,
  legend_container:undefined,
  legends:[]
}

export {
  map_config,
  map_vars,
  mapOptions
}
