import { createAction } from '@reduxjs/toolkit'
import searchTypes from './searchTypes'
import * as itemService from 'shared/utils/graphql'
import { updateUrlParameters } from 'utils/functions'
import { setLoadingState } from 'shared/redux/sharedActions'
import { SEARCH_ITEMS } from 'shared/constants'

const searchBegin = createAction(searchTypes.GET_ITEMS_BEGIN)
export const searchSuccess = createAction(searchTypes.GET_ITEMS_SUCCESS)
const searchFail = createAction(searchTypes.GET_ITEMS_FAIL)
const partnersSuccess = createAction(searchTypes.GET_PARTNERS_SUCCESS)
const saveSorting = createAction(searchTypes.SET_SORTING)
const saveFilters = createAction(searchTypes.SET_FILTERS)
const setPagination = createAction(searchTypes.SET_PAGINATION)
const togglePartnerBanner = createAction(searchTypes.TOGGLE_PARTNER_BANNER)
const toggleFiltersDropdown = createAction(searchTypes.TOGGLE_FILTERS_DROPDOWN)
const setSearchType = createAction(searchTypes.SET_SEARCH_TYPE)

// search service method helper
const getSearchResults = async (type, filters, sort, page, userId) => {
  const res = type === SEARCH_ITEMS 
    ? await itemService.searchItems(filters, sort, page) 
    : await itemService.searchCollections(filters, sort, '', page, false, userId)
  const items = res && res[0]
  if (!items) throw new Error(`Failed to get items`)
  return { items, resultCount: res && res[1] }
}

const getSearchStamp = (state) => {
  const { search: { filters, sort, searchType } } = state
  return `${searchType}-${filters.view}-${JSON.stringify(sort)}-${JSON.stringify(filters)}`
}

const searchItems = (updateUrl, type, loadmore = false) => async (dispatch, getState) => {
  dispatch(setSearchType(type))
  const state = getState()
  const {
    search: { filters, sort, page, meta: {loading}}, 
    auth: { user },
  } = state
  const pagination = loadmore ? page + 1 : 1
  const searchStamp = getSearchStamp(state)

  dispatch(searchBegin(loadmore))
  if (loading) return

  dispatch(setPagination(pagination))
  dispatch(setLoadingState(true))

  try {
    const { items, resultCount } = await getSearchResults(type, filters, sort, pagination, user.id)
    const newSearchStamp = getSearchStamp(getState())
    if (searchStamp !== newSearchStamp) return
    dispatch(searchSuccess({ items, resultCount, preload: loadmore }))

    // if initial load then preload second set items
    if (!loadmore) {
      dispatch(setPagination(pagination + 1))
      const res = await getSearchResults(type, filters, sort, pagination + 1, user.id)
      const moreSearchStamp = getSearchStamp(getState())
      if (searchStamp !== moreSearchStamp) return
      dispatch(searchSuccess({
        items: res.items,
        resultCount,
        preload: true
      }))
    }
  } catch (e) {
    dispatch(searchFail())
  }

  dispatch(setLoadingState(false))
  if (updateUrl) updateUrlParameters(null, null, filters, sort)
}

const getPartners = () => async (dispatch, getState) => {
  const { search: { partners } } = getState()

  if(!partners.length) {
    try {
      const res = await itemService.getPartners()
      const partners = res.userQuery.entities

      if (partners) {
        dispatch(partnersSuccess(partners))
      } else {
        throw new Error(`Failed to get partners`)
      }
    } catch (e) {
      // dispatch()
    }
  }
}

const setFilters = (values) => (dispatch) => {
  dispatch(saveFilters(values))
  return Promise.resolve()
}

const setSorting = (values, type) => (dispatch) => {
  dispatch(saveSorting(values))
  dispatch(searchItems(true, type))
}

export {
  searchItems,
  getPartners,
  setSorting,
  setFilters,
  setPagination,
  togglePartnerBanner,
  toggleFiltersDropdown
}