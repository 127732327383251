import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MediaIcon from '../MediaIcon'
import { getBackgroundImageStyle, parseCollectionPreview } from 'shared/utils/tools/parse'
import { formatItemDate } from 'shared/utils/tools/datetime'
import { debounce, getLangValue } from 'utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { loadItemModal } from 'features/Collections/redux/collectionsActions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { COLLECTION, ITEM, TEXT_SLIDE } from 'shared/constants'

const CollectionItem = ({ 
  title, 
  topics = [],
  publishedDate,
  img,
  owner,
  type,
  link,
  description,
  className = '',
  hideHandle = false,
  hideActionButtons = false,
  hideImg = false,
  moveFn,
  notesFn,
  removeFn,
  handleRight = true,
  id,
  topicItemId,
  isCollection,
  notes,
  fadeDescription = false,
  itemModalType,
  mobileAddBtnFn,
  view
}) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [topicsDropdown, setTopicsDropdown] = useState(false)
  const [moveToTopic, setMoveToTopic] = useState('')
  const siteLangcode = useSelector(selectLangcodeFlag)
  const openModal = useCallback(debounce(() => dispatch(loadItemModal({
    id,
    type: itemModalType || (isCollection ? COLLECTION : ITEM) 
  })), 500, true), [dispatch, id, isCollection])
  const activeTitle = getLangValue(siteLangcode, title)
  const activeDescription = getLangValue(siteLangcode, description)
  const activeDescriptionWithoutAnchor = (activeDescription || '').replace(/(<[a|A][^>]*>|)/g, '').replace('</a>', '');
  const renderMoveAction = (
    <>
      <div className="c-collection-item__select">
        <label className="c-collection-item__select-label c-copy c-copy--sm c-copy--bold" htmlFor="moveItem">{t('Move to')}:</label>
        <select
          onChange={(e) => setMoveToTopic(e.currentTarget.value)}
          className="c-collection-item__select-input"
          name="moveItem"
          id="moveItem"
          defaultValue=""
        >
          <option value="">-- {t('None')} --</option>
          {topics.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
        </select>
      </div>
      <button onClick={() => moveFn(topicItemId, moveToTopic)} className="c-collection-item__btn u-bg-green" type="button" aria-label="Complete">
        <i className="material-icons">done</i>
      </button>
      <button onClick={() => setTopicsDropdown(false)} className="c-collection-item__btn u-bg-red" type="button" aria-label="Cancel">
        <i className="material-icons">block</i>
      </button>
    </>
  )

  return (
    <div className={`c-collection-item ${handleRight ? 'c-collection-item--handle-right' : ''} ${className}`}>
      {!hideHandle && !handleRight && <div className="c-collection-item__drag c-collection-item__drag--default">
        <i className="material-icons">drag_handle</i>
        </div>}
      <div className="c-collection-item__wrapper c-collection-item__wrapper--interactable" onClick={openModal}>
        {hideImg ? null : img ? (
          <div className="c-collection-item__img" style={getBackgroundImageStyle(img)} />
        ) : (
          <div className="c-collection-item__img c-collection-item__img--placeholder">
            <MediaIcon type={type} size={48} color='#576167' />
          </div>
        )
        }

        <div className="c-collection-item__content c-collection-item__content--default">
          <h3 className="c-heading c-heading--l6">{activeTitle}</h3>
          <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
            {publishedDate && (
              <span className="c-collection-item__subitem">{t('Published')} {formatItemDate(publishedDate)}</span>
            )}
            {owner && (
              <span className="c-collection-item__subitem c-copy__icon">
                <i className="material-icons">person</i>
                  {owner}
              </span>
            )}
            {type && type !== TEXT_SLIDE && <span className="c-collection-item__subitem">{t(type)}</span>}
            <span className="c-collection-item__subitem">{link}</span>
          </div>
          {description && <div className={`c-collection-item__blurb ${fadeDescription ? 'c-collection-item__blurb--fade' : ''}`}>
            {parseCollectionPreview(activeDescriptionWithoutAnchor)}
          </div>}
          {notes && notes.length ? (
            <div className='u-color-white u-bg-orange c-copy c-copy--sm c-collection-item__notes'>
              <i className="material-icons">insert_drive_file</i>
              {notes}
            </div>
          ) : null}
        </div>
      </div>
      {!hideActionButtons && <div className={`c-collection-item__actions ${topicsDropdown && view === 'collection' ? 'c-collection-item__actions--move':''}`}>
        {topicsDropdown && view === 'collection' && renderMoveAction}
        {moveFn && view === 'collection' && !topicsDropdown && (
          <button onClick={() => setTopicsDropdown(true)} className="c-collection-item__btn u-bg-grey-300" type="button" aria-label="Move">
            <i className="material-icons">redo</i>
          </button>
        )}
        {notesFn && !topicsDropdown && (
          <button onClick={notesFn} className="c-collection-item__btn u-bg-orange" type="button" aria-label="Add Notes">
            <i className="material-icons">library_add</i>
          </button>
        )}
        {removeFn && !topicsDropdown && (
          <button onClick={removeFn} className="c-collection-item__btn u-bg-red" type="button" aria-label="Remove">
            <i className="material-icons">close</i>
          </button>
        )}
      </div>}

      {!hideHandle && handleRight && <div className="c-collection-item__drag c-collection-item__drag--default">
        <i className="material-icons">drag_handle</i>
      </div>}

      {mobileAddBtnFn && (
        <button className='c-btn c-btn--bare u-hidden@md c-collection-item__mobile-add' onClick={mobileAddBtnFn}>
          <span className='c-btn__icon c-btn__icon--lg'>
            <i className='material-icons u-color-orange'>add_circle</i>
          </span>
        </button>
      )}
    </div>
  )
}

export default CollectionItem
