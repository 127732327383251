import React, { useState, useCallback } from 'react'
import toast from 'shared/utils/toast'
import InputWrapper from '../InputWrapper'
import { useTranslation } from 'react-i18next'

const FileInput = ({
  name,
  caption,
  required,
  label,
  labelClassname = '',
  labelEx,
  onChange,
  extensions = [],
  value,
  accept,
  ...rest
}) => {
  const [nextKey, setNextKey] = useState(Date.now())
  const [file, setFile] = useState(null);
  const {t} = useTranslation()
  const returnValidExtensionsArr = useCallback(acceptString => {
    return acceptString.split(',').reduce((list, nextExt) => {
      if (list.includes(nextExt) || !nextExt) return list
      if (nextExt.trim() === 'image/jpeg') {
        if (!list.includes('.jpg')) list.push('.jpg')
        if (!list.includes('.jpeg')) list.push('.jpeg')
        return list
      }
      if (nextExt.trim() === 'image/*') {
        const imgExtensions = ['.jpg', '.jpeg', '.png', '.gif']
        imgExtensions.forEach(extension => {
          if (!list.includes(extension)) list.push(extension)
        })
      }
      if (nextExt.trim() === 'image/png' && !list.includes('.png')) {
        list.push('.png')
        return list
      }
      return [...list, nextExt.trim()]
    }, [])
  }, [])
  const onUpload = e => {
    const file = e.target.files[0]
    setFile(file);
    const reset = () => {
      onChange({})
      setNextKey(Date.now())
    }
    if (file) {
      if (file.size > 8000000) {
        toast.error(t('Max File Size', {'0': file.name, '1': '8mb'}))
        reset()
        return
      }
      if (accept) {
        const extension = file.name.split('.').pop()
        const validExtensions = returnValidExtensionsArr(accept)
        if (!validExtensions.includes(`.${extension && extension.toLowerCase()}`)) {
          toast.error(`Invalid extension type. This field only accepts ${validExtensions.join(', ')}.`)
          reset()
          return
        }
      }
      onChange({
        preview: URL.createObjectURL(file),
        name: file.name,
        file,
        key: (value && value.resetKey) || nextKey
      })
      if (value && value.resetKey) setNextKey(value.resetKey)
    } else {
      reset()
    }
  }

  const key = value && (value.resetKey || value.key) ? (value.resetKey || value.key) : nextKey ?? 'file_upload_input_key';
  return (
    <InputWrapper
      name={name}
      caption={caption}
      required={required}
      label={label}
      labelEx={labelEx}
      labelClassname={labelClassname}
    >
      <label className='file__upload_label' for={key} >
        <input
          id={key}
          key={key}
          type='file'
          name={name}
          onChange={onUpload}
          accept={accept}
          {...rest}
          style={{display: 'none'}}
        />
        <span className='title'>{t('Choose File')}</span>
        <span className='detail'>{file ? file?.name : t('No file chosen')}</span>
      </label>
    </InputWrapper>
  )
}

export default FileInput
