import { deleteUserFromCollection, inviteUserToCollection } from 'features/Collections/redux/collectionsActions'
import { selectCollectionData, selectCollectionMembers } from 'features/Collections/redux/collectionsSelectors'
import Modal from 'features/Modal'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { validateUserAccount } from 'shared/utils/api/collection'
import toast from 'shared/utils/toast'
import { validateEmail } from 'utils/functions'
import ContributorDeleteIcon from './ContributorDeleteIcon'
import ContributorItem from './ContributorItem'

export default function ManageContributorModal({
  isOpen = false,
  onClose = () => {},
  addContributor = () => {},
}) {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const [showErrorFeedback, setShowErrorFeedback] = useState(false)
  const { t } = useTranslation()
  const collectionData = useSelector(selectCollectionData)
  const members = useSelector(selectCollectionMembers)

  const onSubmit = useCallback(async (event) => {
    event.preventDefault()
    try {
      setShowErrorFeedback(false)
      const validEmail = validateEmail(email)
      if (!validEmail) throw new Error('invalid-email')

      // get uid based on email
      const userId = await validateUserAccount(email)
      if (!userId) throw new Error(t('Could not find an account associated with this email.'))
      
      await dispatch(inviteUserToCollection(collectionData.id, email, userId))
      setEmail('')
    } catch (e) {
      if (e instanceof Error) {
        switch(e.message) {
          case 'invalid-email': return setShowErrorFeedback(true)
          default: return toast.error(e.message)
        }
      }
    }
  }, [addContributor, email])

  return (
    <Modal
      isOpen={!!isOpen}
      className='c-modal--centered c-modal--authentication c-modal--confirmation c-modal-view-contributor'
      onRequestClose={onClose}
      shouldReturnFocusAfterClose={true}
    >
      <div className='c-account-form'>
        <div className='c-account-form__header'>
          <span className='c-copy c-copy--bold u-color-white'>{t('Manage Contributors')}</span>
        </div>
        <div className='u-p c-account-form__content--confirm'>
          <form className='c-add-contributor__email'>
            <p className='c-add-contributor__email--description'>
              {t(`Enter the email address of anyone you would like to invite to contribute to this collection.  If this user has an account, they will be added to this collection`)}
            </p>
            <label
              htmlFor='c-add-contributor-email-input'
              className='c-add-contributor__email--label'
            >
              {t('EMAIL ADDRESS')}
            </label>
            <div className='c-add-contributor__email--input-container'>
              <input
                id='c-add-contributor-email-input'
                className='c-add-contributor__email--input c-text-input'
                data-error={showErrorFeedback}
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
              {showErrorFeedback && (
                <div className='c-add-contributor__email--feedback-container' role='alert'>
                  <div className='c-add-contributor__email--feedback-icon'>
                    <i className='material-icons red'>cancel</i>
                  </div>
                  <p className='c-add-contributor__email--feedback-text c-copy--xs c-copy--bold'>{t('Please enter a valid email address.')}</p>
                </div>
              )}
            </div>
            <button
              className='c-add-contributor__email--submit-button c-btn c-btn--sm-p c-btn--full c-btn--hover-fx u-color-white u-bg-orange c-copy--uppercase '
              onClick={onSubmit}
              type='submit'
            >
              {t('ADD CONTRIBUTOR')}
            </button>
          </form>
          <div className='c-add-contributor__current'>
            <h3 className='c-add-contributor__current--title'>{t('Current Contributors')}</h3>
            {members 
              ? <ul className='c-view-contributor__ul c-add-contributor__current--ul'>
                  {members.length > 0 && members.map((user, index) => <li className='c-add-contributor__current--li' key={index}>
                    <ContributorItem
                      user={user}
                    />
                    {!user?.isOwner && (
                      <ContributorDeleteIcon
                        // delete should be with the entity id (entity is connection of user and group), not userId (aka uid)
                        onDelete={async () => await dispatch(deleteUserFromCollection(collectionData.id, user.mid, user.name))}
                      />
                    )}
                  </li>)}
                </ul>
              : <p className='c-add-contributor__current--description'>
                  {t('There are contributors for this collection.', {'0': 0})} 
                </p>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}