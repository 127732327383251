import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'

export default ({ setValue = null, options, label, selected = "Pick One" }) => {
  const { t } = useTranslation()
  const langCode = useSelector(selectLangcodeFlag)
  const [open, setOpen] = useState(false)
  const [value, setSelection] = useState(selected)

  const handleChange = (value) => {
    setSelection(value)
    if(setValue) {
      setValue(value)
    }
  }
  return (
    <div className="c-dropdown">
      <label className="c-dropdown__label" for="sort_by">{label}</label>
      <div className="c-dropdown__input" onClick={() => setOpen(!open)} id="sort_by">
        <div className={`c-dropdown__current c-dropdown__current--${langCode}`}>
          <span>{t(options[value])}</span>
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <ul className={`c-dropdown__options ${open ? 'is-open' : ''}`}>
          {Object.keys(options).map(option => {
            return option !== value
              ? <li key={option} className="c-dropdown__option" onClick={() => handleChange(option)}>{t(options[option])}</li>
              : null;
            }
          )}
        </ul>
      </div>
    </div>
  )
}