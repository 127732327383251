import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const CollectionGallery = ({ images = [], onChange, clearSelection, currentCollectionImage, uploadedImage}) => {
  const {t} = useTranslation()
  const currentCollectionImageIndex = images.findIndex((a) => a == currentCollectionImage) ?? null;
  const [selected, setSelected] = useState(null)
  const onSelect = useCallback((key, value) => {
    onChange && onChange(selected !== key ? value : null)
    setSelected(selected !== key ? key : null)
  }, [selected, setSelected, onChange])
  useEffect(() => {
    if (clearSelection) setSelected(null)
  }, [clearSelection, setSelected])
  return (
    <div className='c-collection-gallery'>
      <div className='c-copy c-copy--sm c-copy--italic u-color-grey-300 u-mbxs'>
        {t('Choose from collection photos or upload a new photo')}
      </div>
      <ul className='c-collection-gallery__container u-mbxs'>
        {images.map((img, index) =>{ 
        return (
          <li
            key={index}
            onClick={() => {
              onSelect(index, img)
            }}
            className={`c-collection-gallery__tile
            ${selected === index ? 'selected' : ''}
            ${currentCollectionImageIndex === index && selected == null && !uploadedImage ? 'current-image' : ''}
            `}
          >
            <img src={img} className='c-collection-gallery__img' alt='' />
          </li>
        )})}
      </ul>
    </div>
  )
}

export default CollectionGallery
