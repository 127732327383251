import React from 'react'
import { DatePicker } from 'shared/components'
import InputWrapper from '../InputWrapper'

const DateInput = ({
  name,
  caption,
  required,
  label,
  labelEx,
  labelClassname = '',
  value,
  onChange,
  tabIndex
}) => {
  return (
    <InputWrapper
      name={name}
      caption={caption}
      required={required}
      label={label}
      labelEx={labelEx}
      labelClassname={labelClassname}
      className='c-date-input'
    >
      <DatePicker
        selected={value}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperClassName='c-date-input__popper'
        closeOnScroll={true}
        tabIndex={tabIndex}
      />
    </InputWrapper>
  )
}

export default DateInput
