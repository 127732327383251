import React from 'react'
import Modal from 'features/Modal'
import { useTranslation } from 'react-i18next'
const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm, confirmText, confirmDisabled, headerText }) => {
  const {t} = useTranslation();
  return (
    <Modal
      isOpen={!!isOpen}
      className='c-modal--centered c-modal--authentication c-modal--confirmation'
      contentLabel='Delete Confirmation'
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      shouldReturnFocusAfterClose={false}
    >
      <div className='c-account-form'>
        <div className='c-account-form__header'>
          <span className='c-copy c-copy--bold u-color-white'>{headerText || t('Are you sure?')}</span>
        </div>
        <div className='c-account-form__content c-account-form__content--confirm'>
          <button
            className={`c-btn c-btn--sm-p c-btn--full u-color-white u-bg-orange u-mbxs ${confirmDisabled ? 'is-disabled' : ''}`}
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            {confirmText || t('Delete Collection')}
          </button>
          <button className='c-btn c-btn--sm-p c-btn--full u-color-white u-bg-teal' onClick={onRequestClose}>
            {t('Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
