const BEGIN = 'BEGIN'
const SUCCESS = 'SUCCESS'
const FAIL = 'FAIL'
const separator = '/'
const suffixes = [BEGIN, SUCCESS, FAIL]

export const getActionTypes = (actions = [], pre = '', isMulti = false) => {
  const prefix = pre && `${pre}${separator}`
  return actions.reduce(
    (actionObj, nextAction) => {
      const actionVal = `${prefix}${nextAction}`
      const newActions = { [nextAction]: actionVal }
      if (isMulti) {
        suffixes.forEach(suffix => {
          const ACTION_KEY = `${nextAction}_${suffix}`
          const ACTION_TYPE = `${actionVal}_${suffix}`
          newActions[ACTION_KEY] = ACTION_TYPE
        })
      }
      Object.assign(actionObj, newActions)
      return actionObj
    }, {}
  )
}

export const getMultiActionTypes = (actions = [], pre = '') => {
  return getActionTypes(actions, pre, true)
}
