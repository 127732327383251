import React, { useCallback, useEffect, useState } from 'react'
import './multiform.scss'
import { DateInput, LanguagesManager, ReactCKEditor, TagManager, TextInput } from 'features/Form/components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectApplyTagsToAllValue, selectContributeFormUserData, selectContributeMultiFormData, selectMultipleType } from 'features/Contribute/redux/contributeSelectors'
import { addContributeFormTag, removeContributeFormMultipleTag, setContributeMultipleLocation, toggleContributeFormMultipleLanguage, setContributeMultiFormData, removeContributeMultiFormItem, setApplyTagsToAll, updateWebsiteField } from 'features/Contribute/redux/contributeActions'
import { MapContainer } from 'features/Map'
import { debounce } from 'utils/functions'
import { geolocate } from 'features/Map/utils/functions'
import MediaIcon from 'features/Collections/components/MediaIcon'
import { authenticationWrapper } from 'features/authentication/redux/authenticationAction'

const ContributeMultiform = ({ acceptedFiles, activeTab, setAcceptedFiles, goBack }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const multiTypes = useSelector(selectMultipleType)
    const form = useSelector(selectContributeMultiFormData)
    const applyToAll = useSelector(selectApplyTagsToAllValue);
    const addTag = useCallback((tag, uuid, applyToAll = false) => dispatch(authenticationWrapper(() => addContributeFormTag(tag, 'multiple', uuid, applyToAll))), [dispatch])
    const removeTag = useCallback((tag, uuid, applyToAll = false) => dispatch(removeContributeFormMultipleTag({ tag: tag, uuid, applyToAll })), [dispatch])
    const toggleLanguage = useCallback((language, uuid) => dispatch(toggleContributeFormMultipleLanguage({ language: language, uuid })), [dispatch])
    const setForm = useCallback(data => dispatch(setContributeMultiFormData(data)), [dispatch])
    const userData = useSelector(selectContributeFormUserData);
    const displayName = userData.displayName || userData.username || userData.name;

    const setApplyToAll = (value) => {
      dispatch(setApplyTagsToAll(value))
    }

    const defaultFormData = {
        title: { en: '', ja: '' },
        description: { en: '', ja: '' },
        date: '',
        tags: [],
        languages: {
            english: false,
            japanese: false,
            chinese: false,
            korean: false,
            french: false,
            others: false
        },
        location: {
            longitude: "",
            latitude: "",
        },
        mediaType: "Document"
    }

    const defaultWebsiteData = {
      title: { en: '', ja: '' },
        description: { en: '', ja: '' },
        date: '',
        tags: [],
        languages: {
            english: false,
            japanese: false,
            chinese: false,
            korean: false,
            french: false,
            others: false
        },
        location: {
            longitude: "",
            latitude: "",
        },
        mediaType: "Website"
    }

    const isImagesDocumentsType = multiTypes == 1;
    const isWebsiteType = multiTypes == 2;

    const genereateDefaultFormDatas = () => {
        let data = []
        if (isImagesDocumentsType) {
          acceptedFiles.forEach(form => {
            data.push({...defaultFormData, ...form, user: userData})
          });
        } else if (isWebsiteType) {
          form.forEach(form => {
            data.push({...defaultWebsiteData, ...form, user: userData})
          })
        }
        return data
    }

    useEffect(() => {
        dispatch(setContributeMultiFormData(genereateDefaultFormDatas()))
    }, [])

    const validFormLength = isWebsiteType ? form.filter(f=>f.uri).length : form.length;

    useEffect(() => {
      if (validFormLength == 0) {
        goBack && goBack()
      }
    }, [validFormLength])

    const setLocation = useCallback((location, uuid) => {
        dispatch(setContributeMultipleLocation({location, uuid}))
    }, [dispatch])
    // const [uploadedFiles, setUploadedFiles] = useState([])

    const getLocation = async (value, uuid) => {
        if (value.length > 0) {
            const location = await geolocate(value)
            setLocation(location, uuid)
        }
    }



    const debouncedGetLocation = debounce((value, uuid) => getLocation(value, uuid), 1000)

    const handleAddressChange = useCallback((e, uuid) => debouncedGetLocation(e.currentTarget.value, uuid), [debouncedGetLocation])

    const handleKeyDown = async (e, uuid) => {
        if (e.which === 13) {
            getLocation(e.currentTarget.value, uuid)
        }
    }

    const handleRemove = (item) => {
        setAcceptedFiles((prev) => [...prev.filter((a, index) => a.uuid !== item.uuid)])
        if (isWebsiteType) {
          dispatch(updateWebsiteField({ uuid: item.uuid, uri: '', error: ''}))
        } else {
          dispatch(removeContributeMultiFormItem(item.uuid))
        }
    }

    const onChangeWithLang = useCallback((event, i) => {
        let clonedData = JSON.parse(JSON.stringify(form))
        clonedData.forEach((item) => {
            if (item.uuid == i.uuid) {
                item[event.currentTarget.name][activeTab] = event.currentTarget.value
            }
        });
        setForm(clonedData)
    }, [form, setForm, activeTab])

    const onChangeUri = useCallback((event, i) => {
      let clonedData = JSON.parse(JSON.stringify(form))
      clonedData.forEach((item) => {
          if (item.uuid == i.uuid) {
              item[event.currentTarget.name] = event.currentTarget.value
          }
      });
      setForm(clonedData)
  }, [form, setForm, activeTab])

    const onChangeDateWithLang = useCallback((event, i) => {
        let clonedData = JSON.parse(JSON.stringify(form))
        clonedData.forEach((item) => {
            if (item.uuid == i.uuid) {
                item["date"] = event
            }
        });

        setForm(clonedData)
    }, [form, setForm])

    return (
        <div>
            <div>
                <p className='head-text'>{t('Please provide some additional information for your contributions. You can add them a new or existing collection on the following page if you wish to.')}</p>
            </div>

            {
                form.map((item, index) =>{
                  if(isWebsiteType && !item.uri) return null;
                  return (
                    <div className='multiform-container' key={item.uuid}>
                        <div className='multiform-head'>{isWebsiteType ?t('Website Index', {'0': (index + 1)}) : t('Upload Index', {'0': (index + 1)})}</div>
                        <div className='an-form-container'>
                            <div className='multiform-fileinfo-container'>
                                <div className='multiform-fileinfo-right'>
                                    {item.mediaType == 'Image' ? 
                                      <img src={item.data} style={{height: '100%', width: '100%'}} /> 
                                      : 
                                      item.pdfThumbnail ? <img src={item.pdfThumbnail} style={{height: '100%', width: '100%'}} />
                                      :
                                      item.mediaType == 'Website' ? 
                                      <i style={{ fontSize: '50px', color: '#4c5961' }} className="material-icons">public</i> 
                                      :
                                      <i style={{ fontSize: '50px', color: '#4c5961' }} className="material-icons">attach_file</i>}
                                </div>
                                <div className='multiform-fileinfo-left'>
                                    <div className='multiform-fileinfo'>
                                        <div className='file-name'>{item?.title && item?.title[activeTab] ? item?.title[activeTab] : item?.path}</div>
                                        <div className='file-type'>
                                          <span className="c-collection-item__subitem c-copy__icon">
                                            <i className='material-icons' style={{fontSize: '15px'}} >person</i>
                                            {displayName}
                                          </span>
                                          <span className='c-collection-item__subitem c-copy__icon'>
                                            <MediaIcon type={item?.mediaType || 'Document'} size={15}/>
                                            {t(item?.mediaType || 'Document')}
                                          </span>
                                        </div>
                                    </div>
                                    <div className='file-remove' onClick={() => handleRemove(item)}>
                                        <div className=''><i style={{ fontSize: '20px', color: '#416579', marginTop: '5px' }} className="material-icons">close</i></div>
                                        <div className='remove-text'>{t('Remove')}</div>
                                    </div>
                                </div>
                            </div>


                            <div className='multiform-inputs'>
                                {isWebsiteType ? <div className='c-form__item'>
                                    <TextInput
                                        required
                                        onChange={(e) => onChangeUri(e, item)}
                                        value={item?.uri}
                                        name='uri'
                                        label={t('URL')}
                                        placeholder={'http://www.website.com'}
                                    // tabIndex={pageOneIndex}
                                    />
                                </div> : null}
                                <div className='c-form__item'>
                                    <TextInput
                                        required
                                        onChange={(e) => onChangeWithLang(e, item)}
                                        value={item?.title ? item?.title[activeTab] : ''}
                                        name='title'
                                        label={t('Title')}
                                        placeholder={t('Title')}
                                    // tabIndex={pageOneIndex}
                                    />
                                </div>
                                <div className='c-form__item'>
                                    <ReactCKEditor
                                        id={`contribute-multi-item-${index}-${activeTab}`}
                                        onChange={(e) => onChangeWithLang(e, item)}
                                        value={item?.description ? item?.description[activeTab] : ''}
                                        name='description'
                                        label={t('Description')}
                                        placeholder={t('Description')}
                                    // tabIndex={pageOneIndex}
                                    />
                                </div>
                                <div className='c-form__item'>
                                    {/* {form[index]?.date["en"] !== undefined ? console.log("1111", form[index]?.date["en"]) : console.log("2222", new Date(form[index]?.date["en"]))} */}
                                    <DateInput
                                        onChange={(e) => onChangeDateWithLang(e, item)}
                                        required
                                        name={'date'}
                                        label={t("Original Publish Date")}
                                        value={item?.date ? new Date(item?.date) : ''}
                                    />
                                </div>

                                <div className='c-form__item'>
                                    <LanguagesManager
                                        label={t('Languages')}
                                        name='languages'
                                        languages={item?.languages ?? []}
                                        // handleToggle
                                        toggleFn={(language) => { toggleLanguage(language, item.uuid) }}
                                        index={index}
                                    // tabIndex={pageTwoIndex}
                                    />
                                </div>
                                <div className='c-form__item'>
                                    <TagManager
                                        label={t('Tags')}
                                        name='tags'
                                        tags={item?.tags}
                                        addNewFn={(tag) => addTag(tag, item.uuid, (index == 0 && applyToAll))}
                                        removeFn={(tag) => removeTag(tag, item.uuid, (index == 0 && applyToAll))}
                                        applyToAll={index==0}
                                        applyToAllValue={applyToAll}
                                        onApplyToAllValueChanged={setApplyToAll}
                                    // tabIndex={pageTwoIndex}
                                    />
                                </div>

                                <div className='c-form__item'>
                                    <TextInput
                                        name='location'
                                        label={t('Location')}
                                        placeholder={t('Enter a location or click the map to place a marker...')}
                                        // tabIndex={activePage !== 2 ? '-1' : undefined}
                                        onChange={(e) => handleAddressChange(e, item.uuid)}
                                        onKeyDown={(e) => handleKeyDown(e, item.uuid)}
                                    />
                                    <MapContainer page={`contributeMap-${item.uuid}`} setLocation={(e) => setLocation(e, item.uuid)} location={item?.location?.longitude && item?.location?.latitude ? [item.location.longitude, item.location.latitude] : false}></MapContainer>
                                </div>
                            </div>
                        </div>

                    </div>)})
            }
        </div>
    )
}

export default ContributeMultiform