import { PUBLIC_FOLDER, TESTIMONIAL, TEXT_SLIDE } from "shared/constants"

const getImageUrl = item => {
  let img = item.fieldNewThumbnailUrl
    || (item.fieldFile && item.fieldFile && item.fieldFile.entity && item.fieldFile.entity.url)
  img = img && img.match(/\.(jpeg|jpg|gif|png)/) != null ? img : '';
  if (img) img = img.replace('public://', PUBLIC_FOLDER)
  return img
}

export const processGroupByIdItem = res => {
  if (!res || (!res.groupById && !res.entity && !res.id && !res.nodeById)) return null
  const item = res.groupById || res.nodeById || res.entity || res
  const langcode = item?.langcode?.value
  let type = item?.fieldMediaType?.entity?.name
  if (type && type.toLowerCase() === 'text') type = TESTIMONIAL
  const title = {
    en: item.fieldEnglishTitle || '',
    ja: item.fieldJapaneseTitle || ''
  }
  const description = {
    en: (item.fieldEnglishDescription && (item.fieldEnglishDescription.value || item.fieldEnglishDescription.processed)) || '',
    ja: (item.fieldJapaneseDescription && (item.fieldJapaneseDescription.value || item.fieldJapaneseDescription.processed)) || ''
  }
  const descriptionProcessed = {
    en: item.fieldEnglishDescription?.processed || '',
    ja: item.fieldJapaneseDescription?.processed || ''
  }

  const processed = {
    ...item,
    id: item.id || item.nid || item.gid,
    title,
    description,
    descriptionProcessed,
    langcode,
    location: item.fieldTextLocation,
    lon: item.fieldLongitude,
    lat: item.fieldLatitude,
    publishedDate: item.fieldMediaDateCreate && item.fieldMediaDateCreate.value,
    updatedDate: item.changed,
    img: getImageUrl(item),
    owner: item.fieldMediaCreatorRealname || item.fieldMediaCreatorUsername || item.entityOwner && (item.entityOwner.name || item.entityOwner.fieldDisplayName || item.entityOwner.fieldPartnerDropdownName),
    ownerId: item.entityOwner && item.entityOwner.entityId,
    partnerName: item.entityOwner && (item.entityOwner.name || item.entityOwner.fieldDisplayName || item.entityOwner.fieldPartnerDropdownName),
    type,
    link: item.fieldNewAttributionUri || (type && type.toLowerCase() === 'collection' && `/collection/${item.id}`),
    published: item.status,
    isCollection: (type && type.toLowerCase() === 'collection') || (item.members && item.members.memberCount),
    thumbnailUrl: item.fieldNewThumbnailUrl,
  }

  if (item.reverseGidGroupContent || item.items) {
    let preProcessedItems = (item.items && item.items.items) || (item.reverseGidGroupContent && item.reverseGidGroupContent.entities) || []
    if(preProcessedItems.length > 0) {
      let items = processCollectionItems(preProcessedItems, processed)
      const itemCount = items.length
      if (item.textSlides) {
        items = items.concat(item.textSlides?.entities.map(textSlide => {
          return {
            id: textSlide.id,
            itemData: {
              id: textSlide.entityIdOfGroupContentCollectionGroupNodeTextSlide?.targetId
            }
          }
        }))
      }
      processed.items = items
      processed.itemCount = itemCount
    } else {
      processed.items = []
      processed.itemCount = item.items.collectionItemCount || 0
    }
  }
  if (item.fieldTags) {
    processed.tags = item.fieldTags.map(v => ({ tid: v.targetId, name: v.entity && v.entity.entityLabel }))
  }
  return processed
}

const processCollectionItems = data => {
  const collectionItems = data && data.length ? data.filter(v => !!(v && v.id)).map((item, index) => ({
    ...item,
    itemData: processGroupByIdItem(item.entityIdOfGroupContent || item['text_slides']),
    title: item.label,
    notes: item.fieldNotes,
    sortOrder: item.fieldTopicWeight || item.fieldSortOrder || index,
    type: item['text_slides'] ? TEXT_SLIDE : 'collectionItem'
  })) : []
  return collectionItems && collectionItems.sort((a, b) => {
    const aSort = Number(a.sortOrder)
    const bSort = Number(b.sortOrder)
    if (aSort > bSort) {
      return 1;
    } else if (aSort < bSort) {
      return -1;
    }
    return 0;
  })
}

export const processCollections = data => {
  if (!data) return null
  return data.map(collection => processGroupByIdItem(collection))
}

export const processMemberOfCollection = (entity, ownerId) => {
  const member = {
    // mid or member ID is the path entity id for user -> collection
    mid: entity.id,
    email: entity.entityIdOfGroupContentCollectionGroupMembership.entity.mail,
    name: entity.entityIdOfGroupContentCollectionGroupMembership.entity.name,
    uid: entity.entityIdOfGroupContentCollectionGroupMembership.entity.uid
  }
  if (parseInt(member.uid) === parseInt(ownerId)) member.isOwner = true
  return member
}

export const processAllMembersOfCollection = (data) => {
  const group = data?.groupById
  if (!group) return
  const ownerId = group.entityOwner?.entityId
  // filter out null, empty objects, etc.
  const rawMembers = group.reverseGidGroupContent?.entities?.filter(entity => entity && entity.id && entity.entityIdOfGroupContentCollectionGroupMembership)
  const members = rawMembers?.map(member => processMemberOfCollection(member, ownerId)) || []
  return members
}