import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'COLLECTIONS'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
  'SET_ITEM_MODAL_TYPE',
  'CLOSE_ITEM_MODAL',
  'SET_FORM',
  'ITEM_MODAL_CLOSED',
  'SET_MC_SORT',
  'LOAD_ITEM_MODAL_BEGIN',
  'SET_ITEM_MODAL_TAB'
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'CREATE_COLLECTION',
  'GET_USER_ITEMS',
  'DELETE_COLLECTION',
  'GET_ITEM_DATA',
  'GET_COLLECTION_DATA',
  'EDIT_COLLECTION',
  'ADD_COLLECTION_ITEM',
  'REMOVE_COLLECTION_ITEM',
  'UPDATE_COLLECTION_ITEM',
  'UPDATE_COLLECTION_ITEM_SORT',
  'ADD_TEXT_SLIDE_TO_TOPIC',
  'EDIT_TEXT_SLIDE',
  'ADD_UPDATE_TOPIC',
  'DELETE_TOPIC',
  'ADD_TEXT_SLIDE_TO_COLLECTIONS',
  'MOVE_COLLECTION_ITEM',
  'INVITE_USER',
  'DELETE_USER',
  'GET_ALL_USERS'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
