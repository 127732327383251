import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'

import { Search, SearchToolbar, SearchResults } from 'features/Search'
import {
  searchItems,
  setFilters,
  togglePartnerBanner,
  toggleFiltersDropdown
} from 'features/Search/redux/searchActions'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CollectionsSidebar } from 'features/Collections'
import {
  selectSearch,
  selectSearchLoading,
  selectSearchPreloadClear,
  selectItemsPreloaded
} from 'features/Search/redux/searchSelectors'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { setLoadingState } from 'shared/redux/sharedActions'
import { DragAndDrop } from 'shared/components'
import Metatags from 'shared/components/Metatags'
import { pageMeta } from 'shared/data/meta'
import { SEARCH_ITEMS, JA } from 'shared/constants'

const Collections = ({ search, history }) => {
  const { t } = useTranslation();
  const searchState = useSelector(selectSearch)
  const searchLoading = useSelector(selectSearchLoading)
  const searchPreloadClear = useSelector(selectSearchPreloadClear)
  const isItemsPreloaded = useSelector(selectItemsPreloaded)
  const langcode = useSelector(selectLangcodeFlag)
  const { items, resultCount, page, filters, partnerBanner, partners, filtersDropdown } = searchState
  const dispatch = useDispatch()
  const defaultView = window.location.hash !== '' ? window.location.hash.substring(1) : false
  const [view, setView] = useState(defaultView || 'list')
  const jpSearchNotice = langcode === JA && t('Search Notice For Long Word')
  let selectedPartner = null

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    dispatch(setLoadingState(false))
    return () => {
      dispatch(setLoadingState(false))
    }
  }, [])

  useEffect(() => {
    if (searchPreloadClear && isItemsPreloaded) {
      // if preloaded items missed pass to items, then manually clear preloaded items
      dispatch(searchItems(false, search, true))
    }
  }, [search, searchPreloadClear, isItemsPreloaded])

  const handleViewChange = (view) => {
    window.history.pushState(null, null, `#${view}`)
    setView(view)
    dispatch(setLoadingState(false))
  }

  const handleLoadMore = () => {
    if (resultCount / 10 > page) {
      dispatch(searchItems(false, search, true))
    }
  }

  const handleClearPartner = () => {
    dispatch(setFilters({
      ...filters,
      partners: []
    }))
    dispatch(searchItems(true, SEARCH_ITEMS))
    dispatch(togglePartnerBanner(false))
  }

  if(partnerBanner && search === SEARCH_ITEMS && (view === 'photo' || view === 'list')) {
    selectedPartner = partners.find(partner => partner.uid === parseInt(filters.partners[0], 10))
  }

  return (
    <div className="c-search">
      <Metatags tags={search === 'collections' ? pageMeta['collections']:pageMeta['search']} />
      <Search
        layout="full"
        search={search}
        history={history}
        view={view}
      />
      <div className={`c-collections ${jpSearchNotice ? 'c-collections--jpnotice' : ''}`}>
        <DragAndDrop>
          <div className="c-collections__main">
            <SearchToolbar
              type={search}
              count={resultCount}
              view={view}
              onTabChange={handleViewChange}
            />
            <SearchResults
              loading={searchLoading}
              type={search}
              results={items}
              view={view}
              loadMore={handleLoadMore}
              clearPartner={handleClearPartner}
              partner={selectedPartner}
            />
          </div>
          <CollectionsSidebar />
          <div
            className={`c-collections__overlay ${filtersDropdown ? 'is-visible':''}`}
            onClick={() => dispatch(toggleFiltersDropdown(false))}
          >
          </div>
        </DragAndDrop>
      </div>
    </div>
  )
}

export default Collections