// creates a single menu with a label object with all language titles
// parentKeys array is used in basic page accordion to assign default keys on page load
const formatMenuItems = (menus, parentKeys = [], otherMenus) => {
  return menus.map(menu => {
    menu.parentKeys = parentKeys
    menu.label = { en: menu.title }
    otherMenus.forEach(({ lang, flatMenu }) => {
      menu.label[lang] = flatMenu.find(m => m.key === menu.key).title
    })
    if (menu.below) menu.below = formatMenuItems(menu.below, [...parentKeys, menu.key], otherMenus)
    return menu
  })
}

// flattens menus to make them easier to search through
const flattenMenu = menu => {
  const flat = []
  const flatten = items => items.forEach(({ key, alias, relative, title, below }) => {
    flat.push({
      key,
      alias,
      relative,
      title
    })
    if (below) flatten(below)
  })
  flatten(menu)
  return flat
}

export const processMenus = (mainMenu, otherMenus = []) => {
  const flatMenus = otherMenus.map(({ lang, menu }) => ({ lang, flatMenu: flattenMenu(menu) }))
  return formatMenuItems(mainMenu, [], flatMenus)
}
