import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, Dropdown } from 'shared/components'
import { setLoadingState } from 'shared/redux/sharedActions'
import { setSorting, setFilters, searchItems } from '../../redux/searchActions'
import { selectSearch } from '../../redux/searchSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { SEARCH_ITEMS, SEARCH_DETAIL } from 'shared/constants'
import { sortOptions, viewOptions } from '../../helpers/fields'
import { getToolbarDefaultSort } from '../../helpers/sort'

const SearchToolbar = ({ type, hideDropdown = false, onTabChange, count, view, countOverride }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchState = useSelector(selectSearch)
  const { filters, sort } = searchState
  const defaultSort = getToolbarDefaultSort(type, sort)
  const [localSort, setLocalSort] = useState(defaultSort)

  const handleViewChange = (e) => {
    const selection = e.target.value
    onTabChange && onTabChange(selection)

    if (type === SEARCH_ITEMS) {
      dispatch(setFilters({ ...filters, view: e.target.value }))
      if (selection === 'photo' || selection === 'list') {
        dispatch(setLoadingState(false))
        dispatch(searchItems(false, SEARCH_ITEMS))
      }
    }
  }

  const handleSortChange = (option) => {
    const pair = option.split('_')
    const sortObj = {
      sort: pair[0],
      order: pair[1] ? pair[1] : ''
    }

    dispatch(setLoadingState(false))
    dispatch(setSorting(sortObj, type))
    setLocalSort(pair[1] ? `${pair[0]}_${pair[1]}` : pair[0])
  }

  const collectionBar = () => (
    <>
      <div className='c-toolbar__heading c-heading--l6'>
        {t('Collection Results')}
      </div>
      {!hideDropdown && (
        <Dropdown
          options={sortOptions.collections}
          selected={localSort}
          label={t('Sort By')}
          setValue={(option) => handleSortChange(option)}
        />)}
    </>
  )
  const itemsBar = () => (
    <>
      <div className={`c-toolbar__views ${hideDropdown ? 'c-toolbar__views--no-border' : ''}`}>
        {Object.keys(viewOptions).map(viewOption => {
          const { icon, label, name, pages } = viewOptions[viewOption]

          if (pages.includes(type)) {
            return (
              <Checkbox
                radio={true}
                key={label}
                id={label}
                value={name}
                label={t(label)}
                icon={icon}
                name="view"
                size="lg"
                checked={view === name}
                onChange={(e) => handleViewChange(e)}
              />
            )
          }

          return null
        })}
      </div>
      {!hideDropdown && view !== 'map' && (
        <Dropdown
          options={sortOptions.items}
          selected={localSort}
          label={t('Sort By')}
          setValue={(option) => handleSortChange(option)}
        />
      )}
    </>
  )

  return (
    <div className="c-toolbar">
      {type === 'collections' ? collectionBar() : itemsBar()}
      <div id="results-count" style={{ display: view !== 'map' ? 'none' : 'block' }} className="c-toolbar__count"></div>
      {view !== 'map' ?
        <div className="c-toolbar__count">
          {countOverride ? countOverride
            : `${count && Number(count).toLocaleString()} ${t(type === SEARCH_ITEMS || type === SEARCH_DETAIL ? 'Items' : 'Collections')}`
          }
        </div>
        : null}
    </div>
  )
}

export default SearchToolbar
