import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'

const PrivateRoute = React.memo(({
  component: Component,
  authRoles,
  role,
  ...rest
}) => {
  const { pathname } = useLocation()
  const userLoaded = useSelector(selectUserLoadStatus)
  const langcode = useSelector(selectLangcodeFlag)
  const authorized = authRoles ? role && authRoles.includes(role) : true
  return (
    <Route {...rest} render={(props) => (
      !userLoaded
        ? <Redirect to={{ pathname: `/${langcode}/user/login`, state: { redirectPath: pathname } }} />
        : authorized
          ? <Component {...props} />
          : <Redirect to='/' />
    )} />
  )
})

export default PrivateRoute
