import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import { useSelector } from 'react-redux'
import { UpdateAccountForm } from 'features/UserProfile/components'
import { Redirect } from 'react-router-dom'

const Reset = () => {
  const userLoaded = useSelector(selectUserLoadStatus)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  if (userLoaded) return <Redirect to='/' />
  return (
    <div className='u-p c-layout__centered c-layout__fill'>
      <UpdateAccountForm resetScreen />
    </div>
  )
}

export default Reset
