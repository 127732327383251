import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IndividualContributor } from '../asset/icons8-writer_male.svg'

export default function ContributorItem({
  user = { email: '' },
}) {
  const { t } = useTranslation()

  return (
    <span className='c-view-contributor__list-item'>
      <span className='c-view-contributor__list-item--icon'><IndividualContributor /></span>
      <p className='c-view-contributor__list-item--email'>{user.name}</p>
      {user.isOwner && <span className='c-view-contributor__list-item--owner-tag'>{t('OWNER')}</span>}
    </span>
  )
}