import React from 'react'
import FeaturedCard from './components/FeaturedCard'
import { useSelector } from 'react-redux'
import { selectFeaturedResources } from 'shared/redux/sharedSelectors'
import { useTranslation } from 'react-i18next'

const FeaturedCollections = () => {
  const {t} = useTranslation()
  const featured = useSelector(selectFeaturedResources)
  const list = featured.map((collection, index) => {
    return <FeaturedCard key={index} {...collection} />
  })
  return (
    <div className='c-featured-collections'>
      <h2 className='c-featured-collections__title'>{t('Get the most from our archive')}</h2>
      <ul className='c-featured-collections__list o-grid'>
        {list}
      </ul>
    </div>
  )
}

export default FeaturedCollections
