import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import translationJA from 'shared/assets/locales/ja/translation.json'
import translationEN from 'shared/assets/locales/en/translation.json'

const detetectorOptions = {
  order: ['path'],
  lookupFromPathIndex: 0
}

const resources = {
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJA
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    nsSeparator: ':::',
    keySeparator: '::',
    resources,
    detection: detetectorOptions,
    fallbackLng: false,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })


export default i18n