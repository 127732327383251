import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { formatItemDate } from 'shared/utils/tools/datetime'
import { getBackgroundImageStyle } from 'shared/utils/tools/parse'
import { getLangValue } from 'utils/functions'
import ColItemMiniCheckbox from './components/CoItemMiniCheckbox'

const CollectionItemMini = ({
  id,
  title,
  publishedDate,
  img,
  className = '',
  hideHandle = true,
  hideActionButtons = false,
  itemCount,
  items,
  checkboxLeft = false,
  privateItem = false,
  hidePublishDate = false,
  onCheck,
  checked
}) => {
  const [innerChecked, setChecked] = useState(false)
  const { t } = useTranslation()
  const onCheckFn = useCallback(() => {
    if (checked !== undefined) setChecked(val => !val)
    onCheck && onCheck({ id, title, itemCount, items })
  }, [setChecked, checked, onCheck, id, title, itemCount, items])
  const siteLangcode = useSelector(selectLangcodeFlag)
  const activeTitle = getLangValue(siteLangcode, title)
  return (
    <div className={`c-collection-item c-collection-item--mini ${className}`}>
      <div className="c-collection-item__wrapper">
        {!hideHandle && <div className="c-collection-item__drag c-collection-item__drag--default">
          <i className="material-icons">drag_handle</i>
        </div>}

        {checkboxLeft ? (
          null
        ) : img ? (
          <div className="c-collection-item__img c-collection-item__img--mini" style={getBackgroundImageStyle(img)} />
        ) : (
            <div className='c-collection-item__img c-collection-item__img--mini c-collection-item__img--placeholder'>
              <i className='material-icons'>layers</i>
            </div>
        )}

        <div className={`c-collection-item__content c-collection-item__content--default c-collection-item__content--mini ${checkboxLeft ? 'c-collection-item__content--actions-left' : ''}`}>
          <h3 className="c-heading c-heading--l6">{activeTitle}</h3>
          <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
            {!hidePublishDate && <span className="c-collection-item__subitem">{t('Created')} {formatItemDate(publishedDate)}</span>}
            <span className="c-collection-item__subitem">{itemCount || 0} {t('items')}</span>
            {privateItem && <span className="c-collection-item__subitem">{t('Private')}</span>}
          </div>
        </div>
      </div>
      <div className={`c-collection-item__actions c-collection-item__actions--mini is-shown ${checkboxLeft ? 'c-collection-item__actions--left' : ''}`}>
        <ColItemMiniCheckbox checked={checked !== undefined ? checked : innerChecked} onChange={onCheckFn} />
      </div>
    </div>
  )
}

export default CollectionItemMini
