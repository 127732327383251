const LINE = 'line'
const SYMBOL = 'symbol'
const CIRCLE = 'circle'
const TILEDIMAGE = 'tiledimage'

const jdaMapLayers = {
	"layers": [
    {
        "title": "Muncipal Districts",
        "titleJa": "市区町村",
        "sort": 67,
        "src": "geonode:Admin_Dissolve_Test2_JOB",
        "category": "Urban Planning",
        "description": "",
        "id": "Admin_bound_Test2",
        "largeData": true,
        "pageSize": 200,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        76,
                        129,
                        205,
                        191
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 0.75
                    }
                }
            },
            "transparency": 0,
            "labelingInfo": null
        }
    },
    {
        "title": "Confirmed Tsunami Inundation",
        "titleJa": "津波到達域",
        "sort": 5,
        "src": "geonode:tsunamiinundationmerge_jpf",
        "format": "image/png",
        "category": "Earthquake and Tsunami Data",
        "description": "",
        "id": "jpn_TsunamiInundationExtent",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        42,
                        7,
                        181,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            13,
                            136,
                            185,
                            255
                        ],
                        "width": 0.7
                    }
                }
            }
        }
    },
    {
        "title": "Cumulative Radiation",
        "titleJa": "累積放射線量",
        "sort": 35,
        "src": "geonode:rad_may11_contours_final_cgl",
        "format": "image/png",
        "category": "Nuclear Meltdown",
        "description": "",
        "id": "MEXT_Radiation_Contour_0511",
        "type": "line",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        165,
                        83,
                        183,
                        255
                    ],
                    "width": 1
                }
            },
            "transparency": 0,
            "labelingInfo": null
        }
    },
    {
        "title": "Shakemap Intensity",
        "titleJa": "震度分布",
        "sort": 24,
        "src": "geonode:InstruIntensity_Clip_dOd",
        "format": "image/png",
        "category": "Earthquake and Tsunami Data",
        "description": "",
        "id": "USGS_ShakeMap",
        "largeData": true,
        "outFields": "VALUE",
        "pageSize": 500,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "VALUE",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 1,
                        "label": "0.0 - 1.0",
                        "description": "0.0 - 1.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 4,
                        "label": "2.0 - 4.0",
                        "description": "2.0 - 4.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                63,
                                191,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 5,
                        "label": "4.0 - 5.0",
                        "description": "4.0 - 5.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                212,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 6,
                        "label": "5.0 - 6.0",
                        "description": "5.0 - 6.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                191,
                                233,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 7,
                        "label": "6.0 - 7.0",
                        "description": "6.0 - 7.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 8,
                        "label": "7.0 - 8.0",
                        "description": "7.0 - 8.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                247,
                                191,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 9,
                        "label": "8.0 - 9.0",
                        "description": "8.0 - 9.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                240,
                                127,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 10,
                        "label": "9.0 - 10.0",
                        "description": "9.0 - 10.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                232,
                                63,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 11,
                        "label": "10.0 - 11.0",
                        "description": "10.0 - 11.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                225,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "2010 Population Density (person/km^2)",
        "titleJa": "人口密度(人/平方km) 2010年",
        "sort": 46,
        "src": "geonode:_popcensus2_2id",
        "format": "image/png",
        "category": "Demographic Data",
        "description": "",
        "id": "2010Census_Test2",
        "outFields": "PopDensity",
        "largeData": true,
        "pageSize": 200,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "PopDensity",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 100,
                        "label": "1.6 - 100",
                        "description": "1.6 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100.000001 - 250",
                        "description": "100.000001 - 250",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                111,
                                196,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250.000001 - 500",
                        "description": "250.000001 - 500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                176,
                                224,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500.000001 - 1000",
                        "description": "500.000001 - 1000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 2500,
                        "label": "1000.000001 - 2500",
                        "description": "1000.000001 - 2500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 5000,
                        "label": "2500.000001 - 5000",
                        "description": "2500.000001 - 5000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                85,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 21881.5,
                        "label": "5000.000001 - 21881.5",
                        "description": "5000.000001 - 21881.5",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    102,
                                    102,
                                    102,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "3/10-3/20 Earthquakes",
        "titleJa": "地震（3月10日〜20日）",
        "sort": 14,
        "src": "geonode:__0320_UTM54N_TVM",
        "format": "image/png",
        "category": "Earthquake and Tsunami Data",
        "description": "",
        "id": "Earthquakes_0310_0320_test2",
        "type": "circle",
        "outFields": "MAGNITUDE",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "MAGNITUDE",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 6,
                        "label": "5 - 6",
                        "description": "5 - 6",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 7,
                        "label": "6.00001 - 7",
                        "description": "6.00001 - 7",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                153,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 8,
                        "label": "7.00001 - 8",
                        "description": "7.00001 - 8",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                51,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 9,
                        "label": "8.00001 - 9",
                        "description": "8.00001 - 9",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "Fukushima Evacuation Zones",
        "titleJa": "福島避難指示区域",
        "sort": 36,
        "src": "geonode:updatedevaczonefinal_xcg",
        "format": "image/png",
        "category": "Nuclear Meltdown",
        "description": "",
        "id": "Fukushima_Evacuation_Zones_new",
        "outFields": "Name",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Name",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "避難指示区域",
                        "label": "Evacuation Order Zone (~20km)",
                        "labelJa": "避難指示区域",
                        "description": "避難指示区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                18,
                                1,
                                114
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    194,
                                    10,
                                    1,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    },
                    {
                        "value": "屋内退避区域",
                        "label": "Indoor Evacuation Zone(~30km)",
                        "labelJa": "屋内退避区域",
                        "description": "屋内退避区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                0
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    212,
                                    184,
                                    2,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    },
                    {
                        "value": "緊急時避難準備区域",
                        "label": "Emergency Evacuation-Ready Zone",
                        "labelJa": "緊急時避難準備区域",
                        "description": "緊急時避難準備区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                189,
                                4,
                                240,
                                102
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    234,
                                    4,
                                    255,
                                    255
                                ],
                                "width": 2
                            }
                        }
                    },
                    {
                        "value": "計画的避難区域",
                        "label": "Planned Evacuation Zone",
                        "labelJa": "計画的避難区域",
                        "description": "計画的避難区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                92,
                                227,
                                2,
                                89
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    25,
                                    111,
                                    1,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "10/11 Reported Casualties",
        "titleJa": "死者（10月11日）",
        "sort": 4,
        "src": "geonode:casualties_rpt140_final_xsy",
        "format": "image/png",
        "category": "Casualties",
        "description": "",
        "id": "FDMA_Casualties_Report140",
        "largeData": true,
        "outFields": "Casualties",
        "where": "and Casualties>0",
        "pageSize": 30,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "DEAD",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -1,
                "classBreakInfos": [
                    {
                        "classMaxValue": 100,
                        "label": "1.0 - 100.0",
                        "description": "1.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100.0 - 250.0",
                        "description": "100.0 - 250.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                248,
                                212,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250.0 - 500.0",
                        "description": "250.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                242,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500.0 - 1000.0",
                        "description": "500.0 - 1000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                235,
                                127,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1500,
                        "label": "1000.0 - 1500.0",
                        "description": "1000.0 - 1500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                229,
                                85,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "1500.0 - 2000.0",
                        "description": "1500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                222,
                                42,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 3175,
                        "label": "2000.0 - 3175.0",
                        "description": "2000.0 - 3175.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                216,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "65+ Population (2005 Pop census)",
        "titleJa": "65歳以上人口（2005年国勢調査）",
        "sort": 47,
        "src": "geonode:PopCensus2005_vpg",
        "format": "image/png",
        "category": "Demographic Data",
        "description": "",
        "id": "jpn_PopOver65",
        "outFields": "F64_yr",
        "pageSize": 500,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "64_yr",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0,
                        "label": "0 - 0",
                        "description": "0 - 0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 20,
                        "label": "0 - 20",
                        "description": "0 - 20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                235,
                                226,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 25,
                        "label": "20 - 25",
                        "description": "20 - 25",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                251,
                                180,
                                185,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    102,
                                    102,
                                    102,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 30,
                        "label": "25 - 30",
                        "description": "25 - 30",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                247,
                                104,
                                161,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    102,
                                    102,
                                    102,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 35,
                        "label": "30 - 35",
                        "description": "30 - 35",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                197,
                                27,
                                138,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    102,
                                    102,
                                    102,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 57.8,
                        "label": "35 - 57.8",
                        "description": "35 - 57.8",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                122,
                                1,
                                119,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    102,
                                    102,
                                    102,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "title": "08/15 Shelters",
        "titleJa": "避難所（8月15日）",
        "sort": 13,
        "src": "geonode:CoordShelters_SJ_0tf",
        "format": "image/png",
        "category": "Shelter",
        "description": "",
        "id": "Shelters_RampTest",
        "outFields": "SHEL_POP",
        "type": "circle",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "SHEL_POP",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 50,
                        "label": "0 - 50",
                        "description": "0 - 50",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                40,
                                146,
                                199,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "50.00001 - 100",
                        "description": "50.00001 - 100",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                160,
                                194,
                                155,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "100.00001 - 200",
                        "description": "100.00001 - 200",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                250,
                                250,
                                100,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 400,
                        "label": "200.00001 - 400",
                        "description": "200.00001 - 400",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                250,
                                141,
                                52,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 811,
                        "label": "400.00001 - 811",
                        "description": "400.00001 - 811",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                232,
                                16,
                                20,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:Airport_Pt_Join_ZF7",
        "titleJa": "空港",
        "sort": 56,
        "description": "",
        "title": "Airports",
        "id": "jpn_Airport_test2",
        "type": "symbol",
        "icon": "triangle-3",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSTriangle",
                    "color": [
                        127,
                        127,
                        127,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            53,
                            44,
                            44,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:jpn_PrefBound_UTM54N_WNx",
        "titleJa": "都道府県域界",
        "sort": 72,
        "description": "",
        "title": "Prefecture Boundaries",
        "id": "jpn_Prefectures_test",
        "pageSize": 10,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        76,
                        129,
                        205,
                        191
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 0.75
                    }
                }
            },
            "transparency": 0,
            "labelingInfo": null
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:ferryl_jpn_hrc",
        "titleJa": "フェリー航路",
        "sort": 63,
        "description": "",
        "title": "Japan Ferry Lines",
        "id": "ferry_lines",
        "type": "line",
        "dash": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSDash",
                    "color": [
                        0,
                        102,
                        153,
                        255
                    ],
                    "width": 1
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:jpn_stations_UTF_kb2",
        "titleJa": "駅",
        "sort": 80,
        "description": "",
        "title": "Train Stations",
        "id": "jpn_AllStations_UTF_test",
        "type": "line",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        165,
                        83,
                        183,
                        255
                    ],
                    "width": 3
                }
            },
            "transparency": 0,
            "labelingInfo": null
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:roadl_jpn_Ke9",
        "titleJa": "道路",
        "sort": 75,
        "description": "",
        "title": "Roads",
        "id": "Jpn_roads_GlobalMap",
        "type": "line",
        "outFields": "f_code",
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "f_code",
                "defaultSymbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "width": 1
                },
                "uniqueValueInfos": [
                    {
                        "value": "AP030",
                        "label": "AP030",
                        "description": "AP030",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                95,
                                95,
                                93,
                                186
                            ],
                            "width": 1
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Natural Resources",
        "src": "geonode:jpn_AgriArea_UTM54N_rzE",
        "titleJa": "農業地域",
        "sort": 62,
        "description": "",
        "title": "Japan Agricultural Areas",
        "id": "jpn_AgriculturalAreasSHP",
        "outFields": "DGC",
        "largeData": true,
        "orderBy": "DGC",
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "DGC",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "1",
                        "label": "Agricultural Region",
                        "labelJa": "農業地域",
                        "description": "1",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                136,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "2",
                        "label": "Agricultural Zone",
                        "labelJa": "農業地帯",
                        "description": "2",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                153,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:Railway_Dissolve_UTF_P9c",
        "titleJa": "鉄道",
        "sort": 73,
        "description": "",
        "title": "Railways",
        "id": "jpn_railways_UTF_test",
        "type": "line",
        "pageSize": 200,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        153,
                        51,
                        51,
                        255
                    ],
                    "width": 1
                }
            }
        }
    },
    {
        "category": "Employment Data",
        "src": "geonode:slct_2005Indus_UTF_DpY",
        "titleJa": "就業者の第３次産業",
        "sort": 55,
        "description": "",
        "title": "Tertiary Sector Employment",
        "id": "jpn_TertiarySector",
        "outFields": "TER_IND",
        "pageSize": 400,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "TER_IND",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 20,
                        "label": "0.0 - 20.0",
                        "description": "0.0 - 20.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 40,
                        "label": "20.0 - 40.0",
                        "description": "20.0 - 40.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                212,
                                191,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 60,
                        "label": "40.0 - 60.0",
                        "description": "40.0 - 60.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                170,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 80,
                        "label": "60.0 - 80.0",
                        "description": "60.0 - 80.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                127,
                                63,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "80.0 - 100.0",
                        "description": "80.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                85,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Employment Data",
        "src": "geonode:slct_2005Indus_UTF_AdU",
        "titleJa": "就業者の第１次産業",
        "sort": 53,
        "description": "",
        "title": "Primary Sector Employment",
        "id": "jpn_PrimarySectorBlue",
        "outFields": "PRIME_IND",
        "pageSize": 400,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "PRIME_IND",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 20,
                        "label": "0 - 20",
                        "description": "0 - 20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 40,
                        "label": "20 - 40",
                        "description": "20 - 40",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                191,
                                191,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 60,
                        "label": "40 - 60",
                        "description": "40 - 60",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 80,
                        "label": "60 - 80",
                        "description": "60 - 80",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                63,
                                63,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "80 - 100",
                        "description": "80 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                0,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Metal and Machinery",
        "src": "geonode:Miyagi_Goods_NxA",
        "titleJa": "宮城県・金属機械工業品の運送トン数",
        "sort": 66,
        "description": "",
        "title": "Miyagi Goods Transport: Metal and Machinery (tons)",
        "id": "MiyagiMetalMachineryGoodsTransport_tons_",
        "type": "line",
        "outFields": "AMMn",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "AMMn",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "width": 1
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 10000,
                        "label": "9 - 10000",
                        "description": "9 - 10000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 100000,
                        "label": "10001 - 100000",
                        "description": "10001 - 100000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 200000,
                        "label": "100001 - 200000",
                        "description": "100001 - 200000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 500000,
                        "label": "200001 - 500000",
                        "description": "200001 - 500000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 17072983,
                        "label": "500001 - 17072983",
                        "description": "500001 - 17072983",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Metal and Machinery",
        "src": "geonode:Iwate_Goods_9Wu",
        "titleJa": "岩手県・金属機械工業品の運送トン数",
        "sort": 61,
        "description": "",
        "title": "Iwate Goods Transport: Metal and Machinery (tons)",
        "id": "IwateMetalMachineryTransport_tons_",
        "type": "line",
        "outFields": "AMMn",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "AMMn",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "width": 1
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 10000,
                        "label": "15 - 10000",
                        "description": "15 - 10000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 100000,
                        "label": "10001 - 100000",
                        "description": "10001 - 100000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 200000,
                        "label": "100001 - 200000",
                        "description": "100001 - 200000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 500000,
                        "label": "200001 - 500000",
                        "description": "200001 - 500000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 6538047,
                        "label": "500001 - 6538047",
                        "description": "500001 - 6538047",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:world_urban_areas_110_million_fSE",
        "titleJa": "都市地区",
        "sort": 60,
        "description": "",
        "title": "Global Urban Areas",
        "id": "Urban_areas",
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        136,
                        136,
                        0,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            255,
                            255,
                            187,
                            255
                        ],
                        "width": 0.7
                    }
                }
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:PopCensus2005_kGk",
        "titleJa": "人口密度(人/平方km) 2005年",
        "sort": 44,
        "description": "",
        "title": "2005 Population Density (person/km^2)",
        "id": "jpn_PopDens_Cens2005",
        "outFields": "Field15",
        "pageSize": 500,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "Pop_Dens4",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0,
                        "label": "0 - 0",
                        "description": "0 - 0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "0 - 100",
                        "description": "0 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                91,
                                182,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100 - 250",
                        "description": "100 - 250",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                144,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250 - 500",
                        "description": "250 - 500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                199,
                                229,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500 - 1000",
                        "description": "500 - 1000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 2500,
                        "label": "1000 - 2500",
                        "description": "1000 - 2500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                235,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 5000,
                        "label": "2500 - 5000",
                        "description": "2500 - 5000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                215,
                                84,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 19924.8,
                        "label": "5000 - 19924.8",
                        "description": "5000 - 19924.8",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                196,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    104,
                                    104,
                                    104,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Employment Data",
        "src": "geonode:slct_2005Indus_UTF_tHl",
        "titleJa": "就業者の第２次産業",
        "sort": 54,
        "description": "",
        "title": "Secondary Sector Employment",
        "id": "jpn_SecondarySector",
        "outFields": "SEC_IND",
        "pageSize": 500,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "SEC_IND",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 20,
                        "label": "0.0 - 20.0",
                        "description": "0.0 - 20.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 40,
                        "label": "20.0 - 40.0",
                        "description": "20.0 - 40.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                233,
                                191,
                                223,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 60,
                        "label": "40.0 - 60.0",
                        "description": "40.0 - 60.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                212,
                                127,
                                191,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 80,
                        "label": "60.0 - 80.0",
                        "description": "60.0 - 80.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                191,
                                63,
                                159,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "80.0 - 100.0",
                        "description": "80.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                170,
                                0,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:_popcensus2_s07",
        "titleJa": "人口増率 2005年-2010年",
        "sort": 52,
        "description": "",
        "title": "Population Growth 2005-2010",
        "id": "PopulationGrowth2010",
        "outFields": "PopGrowthR",
        "pageSize": 200,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "PopGrowthR",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -29.47,
                "classBreakInfos": [
                    {
                        "classMaxValue": -20,
                        "label": "-29.47 - -20",
                        "description": "-29.47 - -20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                69,
                                117,
                                180,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": -10,
                        "label": "-19.999999 - -10",
                        "description": "-19.999999 - -10",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                145,
                                191,
                                219,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": -2,
                        "label": "-9.999999 - -2",
                        "description": "-9.999999 - -2",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                224,
                                243,
                                248,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 2,
                        "label": "-1.999999 - 2",
                        "description": "-1.999999 - 2",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                191,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 10,
                        "label": "2.000001 - 10",
                        "description": "2.000001 - 10",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                224,
                                144,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 20,
                        "label": "10.000001 - 20",
                        "description": "10.000001 - 20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                252,
                                141,
                                89,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 35.31,
                        "label": "20.000001 - 35.31",
                        "description": "20.000001 - 35.31",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                215,
                                48,
                                39,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:PopCensus2005_YYw",
        "titleJa": "人口増率 2000年-2005年",
        "sort": 51,
        "description": "",
        "title": "Population Growth 2000-2005",
        "id": "jpn_PopGrowth_Cens2005",
        "outFields": "PopInc_rat",
        "pageSize": 500,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "PopInc_rat",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -100,
                "classBreakInfos": [
                    {
                        "classMaxValue": -20,
                        "label": "-100 - -20",
                        "description": "-100 - -20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                69,
                                117,
                                180,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": -10,
                        "label": "-20 - -10",
                        "description": "-20 - -10",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                145,
                                191,
                                219,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": -2,
                        "label": "-10 - -2",
                        "description": "-10 - -2",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                224,
                                243,
                                248,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 2,
                        "label": "-2 - 2",
                        "description": "-2 - 2",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                191,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 10,
                        "label": "2 - 10",
                        "description": "2 - 10",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                224,
                                144,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 20,
                        "label": "10 - 20",
                        "description": "10 - 20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                252,
                                141,
                                89,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "20 - 100",
                        "description": "20 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                215,
                                48,
                                39,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    52,
                                    52,
                                    52,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:populationnum2005_utm54n_5jn",
        "titleJa": "東北地方の人口密度(人/平方km) 2005年",
        "sort": 45,
        "description": "",
        "title": "2005 Tohoku Population Density Grid (person/km^2)",
        "id": "Tohoku2005PopulationDensityGrid",
        "outFields": "Pop",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "Pop",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 50,
                        "label": "1 - 50",
                        "description": "1 - 50",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "51 - 100",
                        "description": "51 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                102,
                                191,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "101 - 200",
                        "description": "101 - 200",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                155,
                                217,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 300,
                        "label": "201 - 300",
                        "description": "201 - 300",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                222,
                                242,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "301 - 500",
                        "description": "301 - 500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                221,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "501 - 1000",
                        "description": "501 - 1000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                145,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "1001 - 2000",
                        "description": "1001 - 2000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                72,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 9881,
                        "label": "2001 - 9881",
                        "description": "2001 - 9881",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:jpn_DenseInhab_UTM54N_wNg",
        "titleJa": "人口集中地区",
        "sort": 49,
        "description": "",
        "title": "Densely Inhabited Areas",
        "id": "DenselyInhabitedAreas_test",
        "largeData": true,
        "pageSize": 500,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        255,
                        137,
                        0,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 0.1
                    }
                }
            }
        }
    },
    {
        "category": "Risk Assessment",
        "src": "geonode:_yr_65shinshp2_UTF_scD",
        "titleJa": "30年以内に震度６弱以上の揺れに見舞われる確立の分布図 (%)",
        "sort": 15,
        "description": "",
        "title": "Earthquake Risk in 30 years (%)",
        "id": "jpn_EarthquakeRisk30yrs",
        "outFields": "GRIDCODE",
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "GRIDCODE",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 200,
                        "label": "0.0 - 200.0",
                        "description": "0.0 - 200.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                251,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 300,
                        "label": "200.0 - 300.0",
                        "description": "200.0 - 300.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                243,
                                191,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "300.0 - 500.0",
                        "description": "300.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                236,
                                127,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 750,
                        "label": "500.0 - 750.0",
                        "description": "500.0 - 750.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                228,
                                63,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 959,
                        "label": "750.0 - 959.0",
                        "description": "750.0 - 959.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                221,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Employment Data",
        "src": "geonode:fishingemployment2008_cropped_b6n",
        "titleJa": "漁業就業者数",
        "sort": 52,
        "description": "",
        "title": "Fishing Industry Employment Count",
        "id": "Fishing_Industry_Employment_count_漁業者数",
        "largeData": true,
        "pageSize": 100,
        "outFields": "Count_",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "Count_",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 100,
                        "label": "0 - 100",
                        "description": "0 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                182,
                                237,
                                240,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 300,
                        "label": "100 - 300",
                        "description": "100 - 300",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                116,
                                180,
                                232,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 600,
                        "label": "300 - 600",
                        "description": "300 - 600",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                32,
                                130,
                                224,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 1500,
                        "label": "600 - 1500",
                        "description": "600 - 1500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                28,
                                68,
                                184,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 3657,
                        "label": "1500 - 3657",
                        "description": "1500 - 3657",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                12,
                                9,
                                145,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Metal and Machinery",
        "src": "geonode:Fukushima_Goods_dBY",
        "titleJa": "福島県・金属機械工業品の運送トン数",
        "sort": 59,
        "description": "",
        "title": "Fukushima Goods Transport: Metal and Machinery (tons)",
        "id": "FukushimaMetalMachineryTransport",
        "outFields": "AMMn",
        "type": "line",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "AMMn",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "width": 1
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 10000,
                        "label": "3 - 10000",
                        "description": "3 - 10000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 100000,
                        "label": "10001 - 100000",
                        "description": "10001 - 100000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 200000,
                        "label": "100001 - 200000",
                        "description": "100001 - 200000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 500000,
                        "label": "200001 - 500000",
                        "description": "200001 - 500000",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    },
                    {
                        "classMaxValue": 8572496,
                        "label": "500001 - 8572496",
                        "description": "500001 - 8572496",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "width": 3
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Natural Resources",
        "src": "geonode:onsen_bx1",
        "titleJa": "温泉区分",
        "sort": 69,
        "description": "",
        "title": "Onsens (Hot Springs)",
        "id": "jpn_Onsen",
        "type": "circle",
        "outFields": "Name",
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Name",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "熱水型",
                        "label": "Thermal Water",
                        "labelJa": "熱水型",
                        "description": "熱水型",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                248,
                                95,
                                35,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    187,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "value": "その他型",
                        "label": "Other",
                        "labelJa": "その他型",
                        "description": "その他型",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                209,
                                61,
                                152,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    187,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "value": "伝道加熱型",
                        "label": "Heat Conduction",
                        "labelJa": "伝道加熱型",
                        "description": "伝道加熱型",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                40,
                                190,
                                143,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    187,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "value": "蒸気加熱型",
                        "label": "Steam Heated",
                        "labelJa": "蒸気加熱型",
                        "description": "蒸気加熱型",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                54,
                                135,
                                3,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    187,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:fukushimaradiation_final2_69g",
        "titleJa": "福島県における1時間あたりの放射線量 (マイクロシーベルト/時間)",
        "sort": 38,
        "description": "",
        "title": "Fukushima radiation dose (microSv/hr)",
        "id": "Fukushima_radiation_nnistar2",
        "type": "circle",
        "outFields": "microSV_hr",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "AMMn",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0.2,
                        "label": "0 - 0.2",
                        "description": "0 - 0.2",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 1,
                        "label": "0.2 - 1",
                        "description": "0.2 - 1",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 5,
                        "label": "1.0 - 5.0",
                        "description": "1.0 - 5.0",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 10,
                        "label": "5.0 - 10.0",
                        "description": "5.0 - 10.0",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 20,
                        "label": "10.0 - 20.0",
                        "description": "10.0 - 20.0",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 40,
                        "label": "20.0 - 75.0",
                        "description": "20.0 - 75.0",
                        "symbol": {
                            "type": "esriSLS",
                            "style": "esriSLSSolid",
                            "color": [
                                51,
                                1,
                                1,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Demographic Data",
        "src": "geonode:_popcensuscomplete_4eo",
        "titleJa": "65歳以上人口（2010年国勢調査）",
        "sort": 48,
        "description": "",
        "title": "65+ Population (2010 Pop census)",
        "id": "65_Population_",
        "outFields": "Over64Rati",
        "pageSize": 200,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "Over64Rati",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 20,
                        "label": "0 - 20",
                        "description": "0 - 20",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                235,
                                226,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 25,
                        "label": "20.000001 - 25",
                        "description": "20.000001 - 25",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                251,
                                180,
                                185,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 30,
                        "label": "25.000001 - 30",
                        "description": "25.000001 - 30",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                247,
                                104,
                                161,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 35,
                        "label": "30.000001 - 35",
                        "description": "30.000001 - 35",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                197,
                                27,
                                138,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 57.243087,
                        "label": "35.000001 - 57.243087",
                        "description": "35.000001 - 57.243087",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                122,
                                1,
                                119,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:Safecast_08_P1_WGS1984_Vhp",
        "titleJa": "サーフキャストが収集した1時間あたりの放射線量 (マイクロシーベルト/時間) 8月11日1回目",
        "sort": 27,
        "description": "",
        "title": "08/11 Safecast Radiation Part 1 (uSv/hr)",
        "id": "Safecast_0811_P1",
        "type": "circle",
        "outFields": "reading_va",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "reading_va",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            130,
                            130,
                            130,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0.2,
                        "label": "0 - 0.2",
                        "description": "0 - 0.2",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    56,
                                    168,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 0.5,
                        "label": "0.2 - 0.5",
                        "description": "0.2 - 0.5",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    139,
                                    209,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 1,
                        "label": "0.5 - 1",
                        "description": "0.5 - 1",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 5,
                        "label": "1 - 5",
                        "description": "1 - 5",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    128,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 8,
                        "label": "5 - 8",
                        "description": "5 - 8",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    0,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:mrdata_food_finaldraft_bkr",
        "titleJa": "食品1kgあたりに含まれるセシウム137 (ベクレル/kg)",
        "sort": 34,
        "description": "",
        "title": "Cesium-137 Radiation in Food (Bq/kg)",
        "id": "crms_food_radiation_lvls",
        "type": "circle",
        "outFields": "CS137",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "CS137",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 50,
                        "label": "0.01 - 50",
                        "description": "0.01 - 50",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                0,
                                136,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "50 - 100",
                        "description": "50 - 100",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                153,
                                204,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "100 - 200",
                        "description": "100 - 200",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    11,
                                    11,
                                    2,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "classMaxValue": 400,
                        "label": "200 - 400",
                        "description": "200 - 400",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                153,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "400 - 1000",
                        "description": "400 - 1000",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "classMaxValue": 10000,
                        "label": "1000 - 10000",
                        "description": "1000 - 10000",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                77,
                                2,
                                152,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:speediradiationcontour_zgi",
        "titleJa": "1歳児のヨウ素による内部被曝の推定値 (ミリシーベルト) 3月12日～24日",
        "sort": 26,
        "description": "",
        "title": "03/12-24 1yr old Iodine Internal Exposure Estimate (mSv)",
        "id": "SPEEDI_Iodine_Internal_Radiation_Exposure_mSv_",
        "outFields": "Rad",
        "orderBy": "Rad",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Rad",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "100",
                        "label": "100",
                        "description": "100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                235,
                                214,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "value": "500",
                        "label": "500",
                        "description": "500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                240,
                                188,
                                156,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "value": "1000",
                        "label": "1000",
                        "description": "1000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                224,
                                132,
                                101,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "value": "5000",
                        "label": "5000",
                        "description": "5000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                209,
                                71,
                                52,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "value": "10000",
                        "label": "10000",
                        "description": "10000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                196,
                                10,
                                10,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:landslidedamage_umo",
        "titleJa": "土砂災害",
        "sort": 19,
        "description": "",
        "title": "Landslide Damage",
        "id": "LandslideDamage",
        "type": "circle",
        "outFields": "Type",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Type",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "地すべり",
                        "label": "Landslide",
                        "labelJa": "地すべり",
                        "description": "地すべり",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                160,
                                86,
                                1,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    250,
                                    234,
                                    9,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "人工斜面",
                        "label": "Artificial Slope",
                        "labelJa": "人工斜面",
                        "description": "人工斜面",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSTriangle",
                            "color": [
                                9,
                                1,
                                161,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    251,
                                    13,
                                    9,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "value": "崩壊",
                        "label": "Collapse/Falling Debris",
                        "labelJa": "崩壊",
                        "description": "崩壊",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                247,
                                224,
                                49,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    },
                    {
                        "value": "変状",
                        "label": "Deformation/Water Overflow/other",
                        "labelJa": "変状",
                        "description": "変状",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSX",
                            "color": [
                                67,
                                6,
                                102,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    206,
                                    2,
                                    179,
                                    255
                                ],
                                "width": 0.5
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Blackout",
        "src": "geonode:F18201103100955.composite_Jyo",
        "titleJa": "停電地帯（3月10日）",
        "sort": 29,
        "description": "",
        "title": "3/10 Blackout Satellite Imagery",
        "id": "Night_lights_03_10_tiffTest",
        "source": "tiledimage"
    },
    {
        "category": "Blackout",
        "src": "geonode:F18201103110943.composite_VLS",
        "titleJa": "停電地帯（3月11日）",
        "sort": 30,
        "description": "",
        "title": "3/11 Blackout Satellite Imagery",
        "id": "jpn_NightLights_03_11",
        "source": "tiledimage"
    },
    {
        "category": "Shelter",
        "src": "geonode:temphousing_final_l0l",
        "titleJa": "避難者の仮設住宅",
        "sort": 22,
        "description": "",
        "title": "Refugee Temporary Housing",
        "id": "RefugeeTempHousing",
        "type": "symbol",
        "icon": "default-circle",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {}
            }
        }
    },
    {
        "category": "Blackout",
        "src": "geonode:F18201103120930.composite_w3e",
        "titleJa": "停電地帯（3月12日）",
        "sort": 31,
        "description": "",
        "title": "3/12 Blackout Satellite Imagery",
        "id": "jpn_NightLights_03_12",
        "source": "tiledimage"
    },
    {
        "category": "Other",
        "src": "geonode:volunteermap_final2_br5",
        "titleJa": "ボランティアマップ",
        "sort": 82,
        "description": "",
        "title": "Volunteer Centers",
        "id": "Tohoku_Volunteer_Map",
        "type": "symbol",
        "icon": "triangle-5",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSTriangle",
                    "color": [
                        255,
                        204,
                        51,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            124,
                            37,
                            191,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Blackout",
        "src": "geonode:F18201103130918.composite_fQS",
        "titleJa": "停電地帯（3月13日）",
        "sort": 32,
        "description": "",
        "title": "3/13 Blackout Satellite Imagery",
        "id": "jpn_NightLights_03_13",
        "source": "tiledimage"
    },
    {
        "category": "Blackout",
        "src": "geonode:F18201103161023.composite_HAV",
        "titleJa": "停電地帯（3月16日）",
        "sort": 33,
        "description": "",
        "title": "3/16 Blackout Satellite Imagery",
        "id": "jpn_NightLights_03-16",
        "source": "tiledimage"
    },
    {
        "category": "Shelter",
        "src": "geonode:CasualtyRpt3Join_3Y4",
        "titleJa": "避難者（3月18日）",
        "sort": 10,
        "description": "",
        "title": "03/18 Shelter Population",
        "id": "03_18_Shelter_Population_CATDAT_Rpt_3",
        "outFields": "SHEL_POP",
        "largeData": true,
        "pageSize": 100,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "SHEL_POP",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -1,
                "classBreakInfos": [
                    {
                        "classMaxValue": -1,
                        "label": "-1.0 - -1.0",
                        "description": "-1.0 - -1.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                0,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 0,
                        "label": "0.0 - 0.0",
                        "description": "0.0 - 0.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "0.0 - 200.0",
                        "description": "0.0 - 200.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                196,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "200.0 - 500.0",
                        "description": "200.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                212,
                                226,
                                184,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "500.0 - 2000.0",
                        "description": "500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                170,
                                198,
                                173,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 5000,
                        "label": "2000.0 - 5000.0",
                        "description": "2000.0 - 5000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                170,
                                161,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 10000,
                        "label": "5000.0 - 10000.0",
                        "description": "5000.0 - 10000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                85,
                                141,
                                150,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 20000,
                        "label": "10000.0 - 20000.0",
                        "description": "10000.0 - 20000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                42,
                                113,
                                138,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 70467,
                        "label": "20000.0 - 70467.0",
                        "description": "20000.0 - 70467.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                85,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Shelter",
        "src": "geonode:CasualtyRpt19EditFinal_G3d",
        "titleJa": "避難者（4月9日）",
        "sort": 11,
        "description": "",
        "title": "04/09 Shelter Population",
        "id": "04_09_Shelter_Population_CATDAT_Rpt_19",
        "outFields": "SHEL_POP",
        "largeData": true,
        "pageSize": 100,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "SHEL_POP",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -1,
                "classBreakInfos": [
                    {
                        "classMaxValue": -1,
                        "label": "-1.0 - -1.0",
                        "description": "-1.0 - -1.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                0,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 0,
                        "label": "0.0 - 0.0",
                        "description": "0.0 - 0.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "0.0 - 200.0",
                        "description": "0.0 - 200.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                196,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "200.0 - 500.0",
                        "description": "200.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                212,
                                226,
                                184,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "500.0 - 2000.0",
                        "description": "500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                170,
                                198,
                                173,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 5000,
                        "label": "2000.0 - 5000.0",
                        "description": "2000.0 - 5000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                170,
                                161,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 10000,
                        "label": "5000.0 - 10000.0",
                        "description": "5000.0 - 10000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                85,
                                141,
                                150,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 20000,
                        "label": "10000.0 - 20000.0",
                        "description": "10000.0 - 20000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                42,
                                113,
                                138,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 70467,
                        "label": "20000.0 - 70467.0",
                        "description": "20000.0 - 70467.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                85,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Energy Infrastructure",
        "src": "geonode:tsunagari_project_03_03_12final_67h",
        "titleJa": "つながり・ぬくもりプロジェクト",
        "sort": 43,
        "description": "",
        "title": "Tsunagari Nukumori Renewable Energy Projects",
        "id": "Tsunagari_030312_2",
        "type": "circle",
        "outFields": "Type_En",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Type_En",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "P",
                        "label": "P",
                        "description": "P",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "B",
                        "label": "B",
                        "description": "B",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                102,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "S",
                        "label": "S",
                        "description": "S",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                51,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Shelter",
        "src": "geonode:Slct_Casualty2010Join1_Final_O4M",
        "titleJa": "避難者（7月25日）",
        "sort": 12,
        "description": "",
        "title": "07/25 Shelter Population",
        "id": "jpn_ShelterPop0725",
        "outFields": "SHEL_POP",
        "pageSize": 200,
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "SHEL_POP",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0,
                        "label": "0 - 0",
                        "description": "0 - 0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 200,
                        "label": "0 - 200",
                        "description": "0 - 200",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                196,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "200 - 500",
                        "description": "200 - 500",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                212,
                                226,
                                184,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "500 - 2000",
                        "description": "500 - 2000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                170,
                                198,
                                173,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 5000,
                        "label": "2000 - 5000",
                        "description": "2000 - 5000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                170,
                                161,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 10000,
                        "label": "5000 - 10000",
                        "description": "5000 - 10000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                85,
                                141,
                                150,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 20000,
                        "label": "10000 - 20000",
                        "description": "10000 - 20000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                42,
                                113,
                                138,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 70000,
                        "label": "20000 - 70000",
                        "description": "20000 - 70000",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                85,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Casualties",
        "src": "geonode:CasualtyRpt3Join_g37",
        "titleJa": "死者（3月18日）",
        "sort": 1,
        "description": "",
        "title": "03/18 Reported Casualties",
        "id": "03_18_Casualties_CATDAT_Rpt_3",
        "outFields": "DEAD",
        "largeData": true,
        "pageSize": 100,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "DEAD",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -1,
                "classBreakInfos": [
                    {
                        "classMaxValue": -1,
                        "label": "-1.0 - -1.0",
                        "description": "-1.0 - -1.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                0,
                                255,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 0,
                        "label": "0.0 - 0.0",
                        "description": "0.0 - 0.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "0.0 - 100.0",
                        "description": "0.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100.0 - 250.0",
                        "description": "100.0 - 250.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                248,
                                212,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250.0 - 500.0",
                        "description": "250.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                242,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500.0 - 1000.0",
                        "description": "500.0 - 1000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                235,
                                127,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1500,
                        "label": "1000.0 - 1500.0",
                        "description": "1000.0 - 1500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                229,
                                85,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "1500.0 - 2000.0",
                        "description": "1500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                222,
                                42,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 3000,
                        "label": "2000.0 - 3000.0",
                        "description": "2000.0 - 3000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                216,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Casualties",
        "src": "geonode:Slct_Casualty2010Join1_Final_zDe",
        "titleJa": "死者（7月25日）",
        "sort": 3,
        "description": "",
        "title": "07/25 Reported Casualties",
        "id": "jpn_CasualtiesRpt37",
        "outFields": "DEAD",
        "pageSize": 200,
        "largeData": true,
        "where": "and DEAD>0",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "DEAD",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 100,
                        "label": "1.0 - 100.0",
                        "description": "1.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100.0 - 250.0",
                        "description": "100.0 - 250.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                248,
                                212,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250.0 - 500.0",
                        "description": "250.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                242,
                                170,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500.0 - 1000.0",
                        "description": "500.0 - 1000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                235,
                                127,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1500,
                        "label": "1000.0 - 1500.0",
                        "description": "1000.0 - 1500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                229,
                                85,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "1500.0 - 2000.0",
                        "description": "1500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                222,
                                42,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 3097,
                        "label": "2000.0 - 3097.0",
                        "description": "2000.0 - 3097.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                216,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Casualties",
        "src": "geonode:CasualtyRpt19JoinFinal_TK0",
        "titleJa": "死者（4月09日）",
        "sort": 2,
        "description": "",
        "title": "04/09 Reported Casualties",
        "id": "jpn_Casualties_Rpt19",
        "outFields": "DEAD",
        "pageSize": 200,
        "largeData": true,
        "where": "and DEAD>0",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "DEAD",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 100,
                        "label": "1.0 - 100.0",
                        "description": "1.0 - 100.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 250,
                        "label": "100.0 - 250.0",
                        "description": "100.0 - 250.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                246,
                                215,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 500,
                        "label": "250.0 - 500.0",
                        "description": "250.0 - 500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                237,
                                175,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1000,
                        "label": "500.0 - 1000.0",
                        "description": "500.0 - 1000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                229,
                                135,
                                1,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 1500,
                        "label": "1000.0 - 1500.0",
                        "description": "1000.0 - 1500.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                220,
                                95,
                                1,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2000,
                        "label": "1500.0 - 2000.0",
                        "description": "1500.0 - 2000.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                211,
                                55,
                                1,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    },
                    {
                        "classMaxValue": 2482,
                        "label": "2000.0 - 2482.0",
                        "description": "2000.0 - 2482.0",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                203,
                                15,
                                2,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.26
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:Safecast_08_P2_WGS1984_XaS",
        "titleJa": "サーフキャストが収集した1時間あたりの放射線量 (マイクロシーベルト/時間) 8月11日2回目",
        "sort": 28,
        "description": "",
        "title": "08/11 Safecast Radiation Part 2 (uSy/hr)",
        "id": "Safecast_0811_P2",
        "type": "circle",
        "outFields": "reading_va",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "reading_va",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 0.2,
                        "label": "0.029 - 0.2",
                        "description": "0.029 - 0.2",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                56,
                                168,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    56,
                                    168,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 0.5,
                        "label": "0.200001 - 0.5",
                        "description": "0.200001 - 0.5",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                139,
                                209,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    139,
                                    209,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 1,
                        "label": "0.500001 - 1",
                        "description": "0.500001 - 1",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                255,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    255,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 5,
                        "label": "1.000001 - 5",
                        "description": "1.000001 - 5",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                128,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    128,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 11.54,
                        "label": "5.000001 - 11.54",
                        "description": "5.000001 - 11.54",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                255,
                                0,
                                0,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    255,
                                    0,
                                    0,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Nuclear Meltdown",
        "src": "geonode:evacuationzones_qni",
        "titleJa": "福島避難指示区域(グーグル)",
        "sort": 37,
        "description": "",
        "title": "Fukushima Evacuation Zones - Google",
        "id": "Fukushima_Evacuation_Zones_new",
        "outFields": "Name",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Name",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "避難指示区域",
                        "label": "Evacuation Order Zone (~20km)",
                        "labelJa": "避難指示区域",
                        "description": "避難指示区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                18,
                                1,
                                114
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    194,
                                    10,
                                    1,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    },
                    {
                        "value": "屋内退避区域",
                        "label": "Indoor Evacuation Zone(~30km)",
                        "labelJa": "屋内退避区域",
                        "description": "屋内退避区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                0
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    212,
                                    184,
                                    2,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    },
                    {
                        "value": "緊急時避難準備区域",
                        "label": "Emergency Evacuation-Ready Zone",
                        "labelJa": "緊急時避難準備区域",
                        "description": "緊急時避難準備区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                189,
                                4,
                                240,
                                102
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    234,
                                    4,
                                    255,
                                    255
                                ],
                                "width": 2
                            }
                        }
                    },
                    {
                        "value": "計画的避難区域",
                        "label": "Planned Evacuation Zone",
                        "labelJa": "計画的避難区域",
                        "description": "計画的避難区域",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                92,
                                227,
                                2,
                                89
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    25,
                                    111,
                                    1,
                                    255
                                ],
                                "width": 3
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:tohokurubble_20120521_fr7",
        "titleJa": "災害廃棄物処理トン数",
        "sort": 81,
        "description": "",
        "title": "Treated Debris (tons)",
        "id": "TohokuRubble_0521",
        "outFields": "TreatedTot",
        "largeData": true,
        "zoomIn": true,
        "type": "fill",
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "TreatedTot",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": 0,
                "classBreakInfos": [
                    {
                        "classMaxValue": 10,
                        "label": "0 - 10",
                        "description": "0 - 10",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                255,
                                212,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 50,
                        "label": "10.000001 - 50",
                        "description": "10.000001 - 50",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                217,
                                142,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 100,
                        "label": "50.000001 - 100",
                        "description": "50.000001 - 100",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                254,
                                153,
                                41,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 300,
                        "label": "100.000001 - 300",
                        "description": "100.000001 - 300",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                217,
                                95,
                                14,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    },
                    {
                        "classMaxValue": 1212,
                        "label": "300.000001 - 1212",
                        "description": "300.000001 - 1212",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                153,
                                52,
                                4,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    110,
                                    110,
                                    110,
                                    255
                                ],
                                "width": 0.4
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Earthquake and Tsunami Data",
        "src": "geonode:mergedtsunamilines_2lh",
        "titleJa": "津波到達",
        "sort": 6,
        "description": "",
        "title": "Interpreted Tsunami Inundation",
        "id": "Interpreted_Tsunami_Inundation_line",
        "type": "line",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSSolid",
                    "color": [
                        94,
                        40,
                        1,
                        255
                    ],
                    "width": 1
                }
            }
        }
    },
    {
        "category": "Earthquake and Tsunami Data",
        "src": "geonode:japan8m_ezt",
        "titleJa": "津波浸水モデル",
        "sort": 7,
        "description": "",
        "title": "Tsunami Flood Model",
        "id": "tsunami_flood_model",
        "outFields": "WDepth",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "WDepth",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "0.888888889",
                        "label": "0.888888889",
                        "description": "0.888888889",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                232,
                                16,
                                20,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "1.333333333",
                        "label": "1.333333333",
                        "description": "1.333333333",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                237,
                                58,
                                26,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "1.777777778",
                        "label": "1.777777778",
                        "description": "1.777777778",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                242,
                                89,
                                34,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "2.222222222",
                        "label": "2.222222222",
                        "description": "2.222222222",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                247,
                                119,
                                45,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "2.666666667",
                        "label": "2.666666667",
                        "description": "2.666666667",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                250,
                                141,
                                52,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "3.111111111",
                        "label": "3.111111111",
                        "description": "3.111111111",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                252,
                                171,
                                66,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "3.555555556",
                        "label": "3.555555556",
                        "description": "3.555555556",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                252,
                                196,
                                76,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "4",
                        "label": "4",
                        "description": "4",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                252,
                                225,
                                88,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "4.444444444",
                        "label": "4.444444444",
                        "description": "4.444444444",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                250,
                                250,
                                100,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "4.888888889",
                        "label": "4.888888889",
                        "description": "4.888888889",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                227,
                                235,
                                115,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "5.333333333",
                        "label": "5.333333333",
                        "description": "5.333333333",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                206,
                                222,
                                129,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "5.777777778",
                        "label": "5.777777778",
                        "description": "5.777777778",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                182,
                                207,
                                140,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "6.222222222",
                        "label": "6.222222222",
                        "description": "6.222222222",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                160,
                                194,
                                155,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "6.666666667",
                        "label": "6.666666667",
                        "description": "6.666666667",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                136,
                                181,
                                166,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "7.111111111",
                        "label": "7.111111111",
                        "description": "7.111111111",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                109,
                                169,
                                179,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "7.555555556",
                        "label": "7.555555556",
                        "description": "7.555555556",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                79,
                                158,
                                189,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "8",
                        "label": "8",
                        "description": "8",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                40,
                                146,
                                199,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Earthquake and Tsunami Data",
        "src": "geonode:tsunami_ttjt_final2_86i",
        "titleJa": "津波痕跡高(m)",
        "sort": 8,
        "description": "",
        "title": "Tsunami Inundation Height (meters)",
        "id": "tsunami_height_ttjt",
        "type": "circle",
        "outFields": "height_ttj",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "classBreaks",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field": "height_ttj",
                "classificationMethod": "esriClassifyManual",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "minValue": -0.499,
                "classBreakInfos": [
                    {
                        "classMaxValue": 5,
                        "label": "-0.499 - 5",
                        "description": "-0.499 - 5",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                40,
                                146,
                                199,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 10.384,
                        "label": "5.000001 - 10.384",
                        "description": "5.000001 - 10.384",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                160,
                                194,
                                155,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 20,
                        "label": "10.384001 - 20",
                        "description": "10.384001 - 20",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                250,
                                250,
                                100,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 30,
                        "label": "20.000001 - 30",
                        "description": "20.000001 - 30",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                250,
                                141,
                                52,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "classMaxValue": 55.505,
                        "label": "30.000001 - 55.505",
                        "description": "30.000001 - 55.505",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCircle",
                            "color": [
                                232,
                                16,
                                20,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:Onagawa_Build_UTM54N_2Mh",
        "titleJa": "女川町建物跡地",
        "sort": 21,
        "description": "",
        "title": "Onagawa Building Footprint",
        "id": "Onagawa_building_outlines",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        153,
                        153,
                        153,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:Minamisanriku_Build_UTM54N_GFB",
        "titleJa": "南三陸町建物跡地",
        "sort": 20,
        "description": "",
        "title": "Minamisanriku Builiding Footprint",
        "id": "Minamisanriku_Building_Outlines",
        "largeData": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        102,
                        102,
                        102,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:shiomergefinal_h8k",
        "titleJa": "塩竈津波被害",
        "sort": 25,
        "description": "",
        "title": "Shiogama Building Damage",
        "id": "ShiogamaBuildingInundation",
        "largeData": true,
        "outFields": "DamageLvl",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "DamageLvl",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "High",
                        "label": "High",
                        "labelJa": "高",
                        "description": "High",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Low",
                        "label": "Low",
                        "labelJa": "低",
                        "description": "Low",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Water",
                        "label": "Water",
                        "labelJa": "水",
                        "description": "Water",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                20,
                                3,
                                169,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "None",
                        "label": "None",
                        "labelJa": "無し",
                        "description": "None",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                0
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    51,
                                    51,
                                    51,
                                    255
                                ],
                                "width": 0.1
                            }
                        }
                    },
                    {
                        "value": "Unknown",
                        "label": "Unknown",
                        "labelJa": "不明",
                        "description": "Unknown",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                153,
                                0,
                                153,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:ishinomakimerge_063",
        "titleJa": "石巻市津波被害",
        "sort": 17,
        "description": "",
        "title": "Ishinomaki Building Damage",
        "id": "Ishinomaki_Building_Inundation",
        "largeData": true,
        "zoomIn": true,
        "outFields": "DamageLvl",
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Damagelvl",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "High",
                        "label": "High",
                        "labelJa": "高",
                        "description": "High",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Low",
                        "label": "Low",
                        "labelJa": "低",
                        "description": "Low",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Water",
                        "label": "Water",
                        "labelJa": "水",
                        "description": "Water",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                51,
                                204,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "None",
                        "label": "None",
                        "labelJa": "無し",
                        "description": "None",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.1
                            }
                        }
                    },
                    {
                        "value": "Unknown",
                        "label": "Unknown",
                        "labelJa": "不明",
                        "description": "Unknown",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                153,
                                0,
                                153,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Natural Resources",
        "src": "geonode:jpn_Lakes_UTM54N_F6L",
        "titleJa": "湖沼",
        "sort": 64,
        "description": "",
        "title": "Lakes",
        "id": "jpn_lakes",
        "pageSize": 200,
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        49,
                        155,
                        247,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:Sendai_Build_UTM54N_ZsB",
        "titleJa": "仙台市建物マップ",
        "sort": 78,
        "description": "",
        "title": "Sendai Building Outline",
        "id": "Sendai_building_outline",
        "largeData": true,
        "where": "and Shape__Area<2.42381491164086E%2B10",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        102,
                        102,
                        102,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Religious",
        "src": "geonode:RegReli_UTM54N_eNl",
        "titleJa": "国に登録されている宗教施設",
        "sort": 74,
        "description": "",
        "title": "Registered Religious Buildings",
        "id": "jpn_Reg_Religious",
        "type": "symbol",
        "icon": "cross-1",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSX",
                    "color": [
                        0,
                        2,
                        137,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            187,
                            255,
                            255,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Religious",
        "src": "geonode:tsunami_memorial_finalhp_fg5",
        "titleJa": "津波碑",
        "sort": 9,
        "description": "",
        "title": "Tsunami Memorial Stones",
        "id": "Tsunami_Memorial_Stones",
        "type": "symbol",
        "icon": "triangle-1",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSTriangle",
                    "color": [
                        58,
                        56,
                        56,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            247,
                            171,
                            7,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Religious",
        "src": "geonode:miyagijinja_2x2",
        "titleJa": "神社と仏閣 (宮城県、福島県、岩手県）",
        "sort": 79,
        "description": "",
        "title": "Shrines and Temples (Miyagi, Fukushima, Iwate)",
        "id": "MiyagiFukushimaShrineTemple",
        "type": "symbol",
        "icon": "plus-1",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCross",
                    "color": [
                        136,
                        0,
                        136,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            255,
                            187,
                            255,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:jpn_FishPort_UTM54N_Mna",
        "titleJa": "漁港",
        "sort": 58,
        "description": "",
        "title": "Fish Ports",
        "id": "jpn_fishPorts",
        "type": "symbol",
        "icon": "default-circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {}
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:rikudamagemerge_0di",
        "titleJa": "陸前高田津波被害",
        "sort": 23,
        "description": "",
        "title": "Rikuzentakata Building Damage",
        "id": "RikuzentakataBuildingInundation",
        "largeData": true,
        "outFields": "DamageLvl",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Damagelvl",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "High",
                        "label": "High",
                        "labelJa": "高",
                        "description": "High",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Low",
                        "label": "Low",
                        "labelJa": "低",
                        "description": "Low",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Water",
                        "label": "Water",
                        "labelJa": "水",
                        "description": "Water",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                51,
                                204,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "None",
                        "label": "None",
                        "labelJa": "無し",
                        "description": "None",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.1
                            }
                        }
                    },
                    {
                        "value": "Unknown",
                        "label": "Unknown",
                        "labelJa": "不明",
                        "description": "Unknown",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                102,
                                0,
                                204,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:Police_Box_UTF_nzZ",
        "titleJa": "交番",
        "sort": 70,
        "description": "",
        "title": "Police Boxes",
        "id": "jpn_Police_Boxes",
        "type": "circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        255,
                        255,
                        255,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            51,
                            204,
                            255,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:jpn_Ports_UTM54N_ZAc",
        "titleJa": "港湾",
        "sort": 77,
        "description": "",
        "title": "Sea Ports",
        "id": "jpn_Ports_test",
        "type": "circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        8,
                        39,
                        242,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            51,
                            223,
                            246,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:jpn_Schools_efB",
        "titleJa": "学校",
        "sort": 76,
        "description": "",
        "title": "Schools",
        "id": "jpn_Schools",
        "type": "circle",
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        255,
                        153,
                        51,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            153,
                            102,
                            51,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:higamatsushimamerge_xz8",
        "titleJa": "東松島津波被害",
        "sort": 16,
        "description": "",
        "title": "Higashimatsushima Building Damage",
        "id": "higashimatsushimaBuildingInundation",
        "largeData": true,
        "outFields": "Damagelvl",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Damagelvl",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "High",
                        "label": "High",
                        "labelJa": "高",
                        "description": "High",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Low",
                        "label": "Low",
                        "labelJa": "低",
                        "description": "Low",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Water",
                        "label": "Water",
                        "labelJa": "水",
                        "description": "Water",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                51,
                                204,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "None",
                        "label": "None",
                        "labelJa": "無し",
                        "description": "None",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 0.1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:medicalfacilities3_utm54n_nvw",
        "titleJa": "医療機関",
        "sort": 65,
        "description": "",
        "title": "Medical-Facilities",
        "id": "jpn_MedicalFacilities",
        "type": "circle",
        "outFields": "MCC",
        "largeData": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "MCC",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "2",
                        "label": "Medical Clinics",
                        "labelJa": "診療所",
                        "description": "2",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSX",
                            "color": [
                                11,
                                3,
                                254,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    243,
                                    243,
                                    251,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "1",
                        "label": "Hospital",
                        "labelJa": "病院",
                        "description": "1",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSCross",
                            "color": [
                                255,
                                7,
                                3,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    254,
                                    254,
                                    254,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Urban Planning",
        "src": "geonode:Municipal_Offices_OT8",
        "titleJa": "公共団体",
        "sort": 68,
        "description": "",
        "title": "Municipal-Offices",
        "id": "jpn_MunicipalOffices",
        "type": "symbol",
        "icon": "triangle-2",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSTriangle",
                    "color": [
                        255,
                        255,
                        51,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            36,
                            35,
                            35,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:Police_station_UTF_SXV",
        "titleJa": "警察",
        "sort": 71,
        "description": "",
        "title": "Police Stations",
        "id": "4319f6c8_b871_11e0_a262_12313d194953",
        "type": "circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        255,
                        255,
                        255,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            51,
                            204,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Infrastructure",
        "src": "geonode:Fire_Stations_UTF_aQC",
        "titleJa": "消防署",
        "sort": 57,
        "description": "",
        "title": "Fire Stations",
        "id": "Fire_Stations_MLIT",
        "type": "circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        204,
                        0,
                        0,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            127,
                            127,
                            127,
                            255
                        ],
                        "width": 1
                    }
                }
            }
        }
    },
    {
        "category": "Energy Infrastructure",
        "src": "geonode:jpn_ThermalPP_UTF_n4w",
        "titleJa": "火力発電所",
        "sort": 42,
        "description": "",
        "title": "Thermal Power Plants",
        "id": "jpn_thermalPP",
        "type": "circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        126,
                        13,
                        0,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            2,
                            2,
                            2,
                            255
                        ],
                        "width": 2
                    }
                }
            }
        }
    },
    {
        "category": "Building Damage",
        "src": "geonode:kesenumamerge_xbo",
        "titleJa": "気仙沼市津波被害",
        "sort": 18,
        "description": "",
        "title": "Kesennuma Building Damage",
        "id": "KesenumaBuildingInundation",
        "largeData": true,
        "outFields": "Damagelvl",
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "Damagelvl",
                "defaultSymbol": {
                    "type": "esriSFS",
                    "style": "esriSFSSolid",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "High",
                        "label": "High",
                        "labelJa": "高",
                        "description": "High",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                204,
                                0,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Low",
                        "label": "Low",
                        "labelJa": "低",
                        "description": "Low",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                255,
                                204,
                                0,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "Water",
                        "label": "Water",
                        "labelJa": "水",
                        "description": "Water",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                0,
                                51,
                                204,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    },
                    {
                        "value": "None",
                        "label": "None",
                        "labelJa": "無し",
                        "description": "None",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                127,
                                127,
                                127,
                                0
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    5,
                                    5,
                                    5,
                                    255
                                ],
                                "width": 0.1
                            }
                        }
                    },
                    {
                        "value": "Unknown",
                        "label": "Unknown",
                        "labelJa": "不明",
                        "description": "Unknown",
                        "symbol": {
                            "type": "esriSFS",
                            "style": "esriSFSSolid",
                            "color": [
                                153,
                                0,
                                153,
                                255
                            ],
                            "outline": {
                                "type": "esriSLS",
                                "style": "esriSLSSolid",
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Energy Infrastructure",
        "src": "geonode:jpn_RenewablePP_UTF_ULJ",
        "titleJa": "自然エネ",
        "sort": 40,
        "description": "",
        "title": "Renewable Energy Power Plants",
        "id": "jpn_RenewablesPowerPlant_test",
        "type": "circle",
        "outFields": "TYPE",
        "drawingInfo": {
            "renderer": {
                "type": "uniqueValue",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "field1": "TYPE",
                "defaultSymbol": {
                    "type": "esriSMS",
                    "style": "esriSMSCircle",
                    "color": [
                        130,
                        130,
                        130,
                        255
                    ],
                    "size": 8,
                    "angle": 0,
                    "xoffset": 0,
                    "yoffset": 0,
                    "outline": {
                        "color": [
                            0,
                            0,
                            0,
                            255
                        ],
                        "width": 1
                    }
                },
                "uniqueValueInfos": [
                    {
                        "value": "Hydro",
                        "label": "Hydro",
                        "labelJa": "水力発電",
                        "description": "Hydro",
                        "icon": "triangle-4",
                        "symbol": {
                            "type": "esriSMS",
                            "style": "esriSMSTriangle",
                            "color": [
                                0,
                                102,
                                204,
                                255
                            ],
                            "size": 8,
                            "angle": 0,
                            "xoffset": 0,
                            "yoffset": 0,
                            "outline": {
                                "color": [
                                    127,
                                    127,
                                    127,
                                    255
                                ],
                                "width": 1
                            }
                        }
                    }
                ]
            }
        }
    },
    {
        "category": "Energy Infrastructure",
        "src": "geonode:tepcogrid_clm",
        "titleJa": "東京電力送電網",
        "sort": 41,
        "description": "",
        "title": "TEPCO Electricity Grid",
        "id": "tepcoGrid",
        "type": "line",
        "dash": true,
        "zoomIn": true,
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "defaultLabel": "<Other Values>",
                "rotationType": "geographic",
                "rotationExpression": "0",
                "label": "<All Values>",
                "description": "<All Values>",
                "symbol": {
                    "type": "esriSLS",
                    "style": "esriSLSDash",
                    "color": [
                        51,
                        51,
                        51,
                        255
                    ],
                    "width": 2
                }
            }
        }
    },
    {
        "category": "Energy Infrastructure",
        "src": "geonode:jpn_NuclearPP_UTM54N_M1z",
        "titleJa": "原子力発電所",
        "sort": 39,
        "description": "",
        "title": "Nuclear Power Plants",
        "id": "Jpn_Nuclear_test",
        "type": "symbol",
        "icon": "default-circle",
        "drawingInfo": {
            "renderer": {
                "type": "simple",
                "symbol": {}
            }
        }
    }
  ],
	"defaultFill": {
		color: 'rgba(247,147,78,1)',
		outlineColor: 'rgba(224,64,22,1)'
	},
	"translation": {
		allValues: "すべての値",
		otherValues: "その他の値"
	}
}

export default jdaMapLayers
