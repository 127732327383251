
import React, { Component } from 'react'
import i18n from 'i18n'
let idInc = 0

const keyHandlers = {
  38: 'handleUpKey',
  40: 'handleDownKey',
  32: 'handleSpaceKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
  74: 'handleDownKey',
  75: 'handleUpKey'
}

let clickingOption = false

const interceptEvent = (event) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }
}

export default class CustomSelect extends Component {

  static defaultProps = {
    closeText: i18n.t('Close'),
    clearText: i18n.t('Remove selected')
  }

  state = {
    focusedIndex: -1,
    id: 'react-select-box-' + (++idInc),
    open: false,
    pendingValue: [],
    clickingOption: false,
    blurTimeout: null
  }

  button = React.createRef()
  menu = React.createRef()

  changeOnClose = () => this.props.multiple && String(this.props.changeOnClose) === 'true'

  updatePendingValue = (value, cb) => {
    if (this.changeOnClose()) {
      this.setState({ pendingValue: value }, cb)
      return true
    }
    return false
  }

  componentWillMount () {
    this.updatePendingValue(this.props.value)
  }

  componentWillReceiveProps (next) {
    this.updatePendingValue(next.value)
  }
  handleFocus = () => {
    clearTimeout(this.blurTimeout)
  }

  handleBlur = () => {
    clearTimeout(this.blurTimeout)
    this.blurTimeout = setTimeout(this.handleClose, 0)
  }

  handleMouseDown = () => {
    clickingOption = true
  }

  handleChange = (val, cb) => (event) => {
    clickingOption = false
    interceptEvent(event)
    if (this.props.multiple) {
      let selected = []
      if (val != null) {
        selected = this.value().slice(0)
        let index = selected.indexOf(val)
        if (index !== -1) {
          selected.splice(index, 1)
        } else {
          selected.push(val)
        }
      }
      this.updatePendingValue(selected, cb) || this.props.onChange(selected)
    } else {
      this.updatePendingValue(val, cb) || this.props.onChange(val)
      this.handleClose()
      this.button.current.focus()
    }
  }

  handleNativeChange = event => {
    let val = event.target.value
    if (this.props.multiple) {
      let children = [].slice.call(event.target.childNodes, 0)
      val = children.reduce(function (memo, child) {
        if (child.selected) {
          memo.push(child.value)
        }
        return memo
      }, [])
    }
    this.props.onChange(val)
  }

  handleClear = (event) => {
    interceptEvent(event)
    this.handleChange(null, () => {
      // only called when change="true"
      this.props.onChange(this.state.pendingValue)
    })(event)
  }

  toggleOpenClose = (event) => {
    interceptEvent(event)
    this.setState({ open: !this.state.open })
  }

  handleOpen = (event) => {
    const that = this
    interceptEvent(event)
    this.setState({ open: true }, () => {
      that.menu.current.focus()
    })
  }

  handleClose = event => {
    interceptEvent(event)
    if (!clickingOption) {
      this.setState({ open: false, focusedIndex: -1 })
    }
    if (this.changeOnClose()) {
      this.props.onChange(this.state.pendingValue)
    }
  }

  moveFocus = move => {
    let len = React.Children.count(this.props.children)
    let idx = (this.state.focusedIndex + move + len) % len
    this.setState({ focusedIndex: idx })
  }

  handleKeyDown = event => {
    if (keyHandlers[event.which]) {
      this[keyHandlers[event.which]](event)
    }
  }

  handleUpKey = event => {
    interceptEvent(event)
    this.moveFocus(-1)
  }

  handleDownKey = event => {
    interceptEvent(event)
    if (!this.state.open) {
      this.handleOpen(event)
    }
    this.moveFocus(1)
  }

  handleSpaceKey = event => {
    interceptEvent(event)
    if (!this.state.open) {
      this.handleOpen(event)
    } else if (this.state.focusedIndex !== -1) {
      this.handleEnterKey()
    }
  }

  handleEnterKey = event => {
    if (this.state.focusedIndex !== -1) {
      this.handleChange(this.options()[this.state.focusedIndex].value)(event)
    }
  }

  handleEscKey = event => {
    if (this.state.open) {
      this.handleClose(event)
    } else {
      this.handleClear(event)
    }
  }

  label = () => {
    let selected = this.options()
    .filter(function (option) {
      return this.isSelected(option.value)
    }.bind(this))
    .map(function (option) {
        return option.label
      })

    return selected.length > 0 ? `${selected.length} ${i18n.t('Selected')}` : this.props.label
  }

  options = () => {
    let options = []
    React.Children.forEach(this.props.children, function (option) {
      options.push({
        value: option.props.value,
        label: option.props.children
      })
    })
    return options
  }

  value = () => {
    let value = this.changeOnClose() ?
      this.state.pendingValue :
      this.props.value

    if (!this.props.multiple || Array.isArray(value)) {
      return value
    } if (value != null) {
      return [value]
    }
    return []
  }

  hasValue = () => {
    if (this.props.multiple) {
      return this.value().length > 0
    }
    return this.value() != null
  }

  isSelected (value) {
    if (this.props.multiple) {
      return this.value().indexOf(value) !== -1
    }
    return this.value() === value
  }

  renderNativeSelect = () => {
    const id = this.state.id + '-native-select'
    const multiple = this.props.multiple
    const empty = multiple ? null : <option key="" value="">{i18n.t('No Selection')}</option>
    const options = [empty].concat(this.props.children)

    return <div className="react-select-box-native">
      <label htmlFor={id}>{this.props.label}</label>
      <select
        id={id}
        multiple={multiple}
        onKeyDown={(e) => e.stopPropagation()}
        value={this.props.value || (multiple ? [] : '')}
        onChange={() =>this.handleNativeChange}
      >
      {options}
      </select>
    </div>

  }

  renderOptionMenu = () => {
    let className = 'react-select-box-options'
    if (!this.state.open) {
      className += ' react-select-box-hidden'
    }

    const options = this.options()

    return (
      <div
        className={className}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        ref={this.menu}
        tabIndex={0}>
        <div className="react-select-box-off-screen">
          {options.map((option, key) => this.renderOption(option, key))}
        </div>
        {this.renderCloseButton()}
      </div>
    )
  }

  renderOption = (option, i) => {
    let className = 'react-select-box-option'
    if (i === this.state.focusedIndex) {
      className += ' react-select-box-option-focused'
    }
    if (this.isSelected(option.value)) {
      className += ' react-select-box-option-selected'
    }

    return (
      <a id={this.state.id + '-' + i} href="#"
        onClick={this.handleChange(option.value)}
        onMouseDown={this.handleMouseDown}
        className={className}
        tabIndex={-1}
        key={option.value}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}>
        <i className="material-icons unchecked">check_box_outline_blank</i>
        <i className="material-icons checked">check_box</i>
        {option.label}
      </a>
    )
  }

  renderClearButton = () => {
    if (this.hasValue()) {
      return <button
        aria-label={this.props.clearText}
        className="react-select-box-clear"
        onClick={this.handleClear} />
    }
  }

  renderCloseButton = () => {
    if (this.props.multiple && this.props.closeText) {
      return <button
          onClick={this.handleClose}
          className="react-select-box-close"
          onBlur={this.handleBlur}
          onFocus= {this.handleFocus}>
        {this.props.closeText}
      </button>
    }
  }

  render = () => {
    let className = 'react-select-box-container'
    if (this.props.className) {
      className += ' ' + this.props.className
    }
    if (this.props.multiple) {
      className += ' react-select-box-multi'
    }
    if (!this.hasValue()) {
      className += ' react-select-box-empty'
    }
    if (this.state.open) {
      className += ' react-select-box-open'
    }
    if (this.props.disabled) {
      className += ' disabled'
    }

    return (
      <div onKeyDown={this.handleKeyDown} className={className}>
        <button
          id={this.state.id}
          ref={this.button}
          className={`react-select-box ${this.props.icon}`}
          onClick={this.toggleOpenClose}
          onBlur={this.handleBlur}
          tabIndex="0"
        >
          <div className="react-select-box-label">{this.label()}</div>
        </button>
        {this.renderOptionMenu()}
        {this.renderClearButton()}
        {this.renderNativeSelect()}
      </div>
    )
  }
}
