import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CollectionDetailSidebar, CollectionTopic, InlineCollectionForm, EditCollectionBtn } from 'features/Collections'
import { SearchResults, SearchToolbar } from 'features/Search'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import {
  addTextSlideToTopic,
  addUpdateTopic,
  deleteTopic,
  getCollectionData,
  removeCollectionItem,
  updateCollectionItem,
  updateCollectionItemSort,
  reoderTopics,
  moveItemToTopic,
  getAllUsersFromCollection
} from 'features/Collections/redux/collectionsActions'
import {
  selectCollectionDataItems,
  selectCollectionDataTopics,
  selectCollectionDataNotFound,
  selectCreateCollectionSubmitting,
  selectIsUserCollectionDataOwner,
  selectCollectionData,
  selectCollectionDataTopicListConfg,
  selectCollectionDataCollectionItems,
  selectCollectionDataPhotoItems,
  selectUserCollectionMember
} from 'features/Collections/redux/collectionsSelectors'
import { NotesModal, ColItemSortList } from 'features/MyCollections'
import { ConfirmationModal } from 'shared/components'
import NotFound from 'features/NotFound'
import { MapContainer } from 'features/Map'
import Metatags from 'shared/components/Metatags'
import Translate from 'shared/components/Translate'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import { debounce } from 'utils/functions'
import CollaborationSection from 'features/Collections/components/ContributorSection'
import { formatItemDate } from 'shared/utils/tools/datetime'

const CollectionDetail = ({ match }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [tab, setTab] = useState('collection')
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null)
  const [selectedNoteItem, setSelectedNoteItem] = useState(null)
  const [formSlideEdit, setFormSlideEdit] = useState({ index: null, edit: false, topic: false })
  // const [itemList, setItemList] = useState(null)
  const [itemTopicList, setItemTopicList] = useState({})
  const items = useSelector(selectCollectionDataItems) || []
  const collectionItems = useSelector(selectCollectionDataCollectionItems)
  const collectionTopics = useSelector(selectCollectionDataTopics) || []
  const topicListConfig = useSelector(selectCollectionDataTopicListConfg)
  const { params: { collectionId } } = match
  const submitting = useSelector(selectCreateCollectionSubmitting)
  const notFound = useSelector(selectCollectionDataNotFound)
  const collectionData = useSelector(selectCollectionData)
  const isCollectionOwner = useSelector(selectIsUserCollectionDataOwner)
  const userMembership = useSelector(selectUserCollectionMember)
  const userLoaded = useSelector(selectUserLoadStatus)
  const photoResults = useSelector(selectCollectionDataPhotoItems)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  useEffect(() => {
    (async () => {
      await dispatch(getCollectionData(collectionId))
      // get contributor information
      dispatch(getAllUsersFromCollection(collectionId))
    })()
    // userLoaded added as a dependency so data is refreshed on sign in/out
  }, [collectionId, dispatch, userLoaded])

  const canEdit = useMemo(() => Boolean(isCollectionOwner || userMembership), [isCollectionOwner, userMembership])
  const deleteFn = useCallback(async () => {
    await dispatch(removeCollectionItem(selectedDeleteItem, collectionId))
    setSelectedDeleteItem(null)
  }, [dispatch, selectedDeleteItem, collectionId, setSelectedDeleteItem])

  const notesFn = useCallback(async notes => {
    await dispatch(updateCollectionItem({ id: selectedNoteItem.id, notes }, collectionId, 'notes'))
    setSelectedNoteItem(null)
  }, [dispatch, selectedNoteItem, setSelectedNoteItem, collectionId])

  const saveNewSort = useCallback((newItemList, inTopic) => {
    dispatch(updateCollectionItemSort(newItemList, inTopic))
  }, [dispatch])

  const saveNewTextSlide = useCallback(debounce(async form => {
    const result = await dispatch(addTextSlideToTopic(form))
    if (result) setFormSlideEdit({ index: null, edit: false, topic: false })
  }, 500, true), [dispatch, setFormSlideEdit])

  const handleSaveTopic = useCallback(debounce(async (form) => {
    const { index, edit } = formSlideEdit
    const topicId = edit ? index : null
    const position = edit
      ? null
      : topicListConfig.findIndex(({ value }) => value === index) < 0
        ? 0
        : topicListConfig.findIndex(({ value }) => value === index) + 1

    const result = await dispatch(addUpdateTopic(form, topicId, position))
    if (result) setFormSlideEdit({ index: null, edit: false, topic: false })
  }, 500, true), [dispatch, formSlideEdit])

  const handleDeleteTopic = useCallback(debounce(async (id) => {
    const { index, edit } = formSlideEdit
    const result = await dispatch(deleteTopic(id))
    if (result) setFormSlideEdit({ index: null, edit: false, topic: false })
  }, 500, true), [dispatch, formSlideEdit])

  const handleTopicReorder = useCallback(debounce(async (topicId, newIndex) => {
    await dispatch(reoderTopics(topicId, newIndex))
  }, 500, true), [dispatch])

  const handleMoveItem = useCallback(async (itemId, topicId) => {
    const result = await dispatch(moveItemToTopic(itemId, parseInt(topicId, 10), collectionData.id))
    if(result) setItemTopicList({})
  }, [dispatch, collectionData])

  const handleEditTopic = (index) => {
    setFormSlideEdit({ index, edit: true, topic: true })
  }

  const renderAddTopicBtn = (index) => (
    <div className="c-collection-topic__add">
      <div className="c-collection-topic__add_wrapper">
        <button className="c-btn c-btn__icon c-btn--bare c-btn__icon--sm" onClick={() => setFormSlideEdit({ index, edit: false, topic: true })}>
          <i className="material-icons">add_circle</i>
          {t('Add New Topic')}
        </button>
      </div>
    </div>
  )

  const renderAddEditForm = (id, canDelete) => {
    const { index, edit, topic: isTopic } = formSlideEdit
    const topic = !edit ? false: collectionTopics.filter(({ entity }) => entity.id === id)
    const topicOptions = topicListConfig.map(x => x)
    topicOptions.splice(0, 0, { "label": t("None"), "value": "" })

    return (
      <InlineCollectionForm
        editTopic={topic[0] && topic[0].entity || false}
        type={!isTopic ? 'slide' : 'topic'}
        isOpen={id === index}
        saveFn={!isTopic ? saveNewTextSlide : handleSaveTopic}
        deleteFn={isTopic && canDelete ? handleDeleteTopic : null}
        closeFn={() => setFormSlideEdit({ index: null, edit: false, topic: false })}
        topics={topicOptions}
        deleteText={t('Remove Topic')}
      />
    )
  }

  const listView = (
    <div className='u-pxs'>
      <ColItemSortList
        canEdit={canEdit}
        topics={topicListConfig}
        items={collectionItems}
        setSelectedNoteItem={setSelectedNoteItem}
        setSelectedDeleteItem={setSelectedDeleteItem}
        moveItemToTopic={handleMoveItem}
        saveNewSort={saveNewSort}
        isOwner={isCollectionOwner}
        view="list"
      />
    </div>
  )


  const collectionView = () => {
    const uncategorizedItems = items.filter(({ fieldCollectionTopic }) => !fieldCollectionTopic || !fieldCollectionTopic.entity || !fieldCollectionTopic.entity.id)
    if(!itemTopicList[0]) setItemTopicList({ ...itemTopicList, 0: [ ...uncategorizedItems ] })
    const { index, edit } = formSlideEdit

    return (
      <>
        <div className='u-pxs'>
          {index === 0 && renderAddEditForm(0)}
          {collectionTopics.length > 0 && collectionTopics.map(({ entity }, arrIndex) => {
            const { id, entityOwner } = entity
            const isTopicOwner = entityOwner?.entityId === userMembership?.uid
            const topicItems = items.filter(({ fieldCollectionTopic }) => {
              return fieldCollectionTopic && fieldCollectionTopic.entity && fieldCollectionTopic.entity.id === id
            })

            if(!itemTopicList[id]) setItemTopicList({ ...itemTopicList, [id]: [ ...topicItems ] })
            
            const canDelete = isCollectionOwner || isTopicOwner
            return (
              <React.Fragment key={id}>
                <CollectionTopic
                  count={topicItems.length}
                  reorderFn={handleTopicReorder}
                  topic={entity}
                  editFn={handleEditTopic}
                  editable={canEdit}
                  position={arrIndex}
                  editing={id === index && edit}
                  isLast={collectionTopics.length - 1 === arrIndex}
                >
                  {(canEdit) && index && id === index && edit ? renderAddEditForm(id, canDelete) : null}
                  <ColItemSortList
                    canEdit={canEdit}
                    topics={topicListConfig}
                    items={topicItems}
                    allItems={items}
                    setSelectedNoteItem={setSelectedNoteItem}
                    setSelectedDeleteItem={setSelectedDeleteItem}
                    saveNewSort={saveNewSort}
                    moveItemToTopic={handleMoveItem}
                    setItemTopicList={setItemTopicList}
                    isOwner={isCollectionOwner}
                    handleRight={false}
                    topicId={entity.id}
                    view="collection"
                  />
                </CollectionTopic>
                {(canEdit) && !index && id !== index ? renderAddTopicBtn(id) : null}
                {(canEdit) && index && id === index && !edit ? renderAddEditForm(id) : null}
              </React.Fragment>
            )
          })}
          {(() => {
            if(isCollectionOwner && collectionTopics.length === 0) {
              if(index !== -1)
                return renderAddTopicBtn(-1)
              else
                return renderAddEditForm(-1)
            }
          })()}
          {uncategorizedItems && uncategorizedItems.length ? (
            <div className="u-mblg">
              <CollectionTopic count={items.length} topic={{ japaneseTitle: t('Uncategorized'), englishTitle: t('Uncategorized') }}>
                <ColItemSortList
                  canEdit={canEdit}
                  topics={topicListConfig}
                  items={uncategorizedItems}
                  allItems={items}
                  setSelectedNoteItem={setSelectedNoteItem}
                  setSelectedDeleteItem={setSelectedDeleteItem}
                  moveItemToTopic={handleMoveItem}
                  saveNewSort={saveNewSort}
                  setItemTopicList={setItemTopicList}
                  isOwner={isCollectionOwner}
                  handleRight={false}
                  topicId={0}
                  view="collection"
                />
              </CollectionTopic>
            </div>
          ) : null}
        </div>
      </>
    )
  }

  const renderPhotoGrid = useCallback(() => {
    return (
      <SearchResults
        type='items'
        view='photo'
        results={photoResults}
        draggableItems={false}
      />
    )
  }, [photoResults])

  const mapView = (
    <div className="c-results c-results--collection">
      <MapContainer page="collectionMap" items={items}/>
    </div>
  )

  const countOverride = tab !== 'map'
    // owner can edit the collection and see contributor section
    ? isCollectionOwner
      ? <>
          <CollaborationSection/>
          <EditCollectionBtn data={collectionData} />
        </>
      // contributors can see contributor section
      : userMembership && <CollaborationSection/>
    // no count override as a random visitor
    : false 
  

  if (notFound) return <NotFound />
  if (!collectionData || !selectCollectionDataItems) return (
    <div className='c-basic-page c-layout__fill'></div>
  )

  const numberOfItems = tab === 'photo' ? photoResults.length : collectionItems.length;

  return (
    <div className='c-basic-page c-layout__fill'>
      {collectionData && collectionData.metatag.length > 0 && (
        <Metatags tags={collectionData.metatag} />
      )}
      <aside className='c-basic-page__sidebar'>
        <CollectionDetailSidebar />
      </aside>
      <div className='c-collection-detail__content u-bg-grey-100'>
        <SearchToolbar
          view={tab}
          hideDropdown
          type='detail'
          onTabChange={setTab}
          count={tab === 'photo' ? photoResults.length : collectionItems.length} countOverride={countOverride}
        />
        {tab !== 'map' && (
          <div className='u-mbmd u-pl u-pr u-pt'>
            <div className='u-flex u-jc-between'>
              <div className="c-heading c-heading--l4">
                <Translate {...collectionData.title} />
                {collectionData?.publishedDate && <div className='c-copy c-copy--sm c-copy--italic c-copy--icon u-color-grey-300'>{`${t('Created')} ${formatItemDate(collectionData.publishedDate)} ${t('via')} ${collectionData.owner}`}</div>}
                <div className="c-heading__subtitle">
                  {numberOfItems > 1 ? t('Number of Items', {'0': numberOfItems}) : t('Number of Item', {'0': numberOfItems})}
                </div>
              </div>
              {canEdit && tab === 'collection' && (
                <div className="c-collection-detail__content_addslide">
                  <button className='c-btn c-btn--bare' onClick={() => setFormSlideEdit({ index: 0, edit: true, topic: false })}>
                    <i className="material-icons">add_circle</i>
                    {t('Add Text Slide')}
                  </button>
                  <button className='c-btn c-btn--bare' onClick={() => setFormSlideEdit({ index: 0, edit: false, topic: true })}>
                    <i className="material-icons">add_circle</i>
                    {t('Add Topic')}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {tab === 'photo'
          ? renderPhotoGrid() : tab === 'map'
            ? mapView : tab === 'collection'
              ? collectionView() : listView
        }
      </div>
      <ConfirmationModal
        isOpen={!!selectedDeleteItem}
        onRequestClose={() => setSelectedDeleteItem(null)}
        onConfirm={deleteFn}
        confirmText={t('Delete Item')}
        confirmDisabled={submitting}
      />
      <NotesModal
        isOpen={!!selectedNoteItem}
        onRequestClose={() => setSelectedNoteItem(null)}
        submitFn={notes => notesFn(notes)}
        submitting={submitting}
        notes={selectedNoteItem && selectedNoteItem.notes}
        canDelete={selectedNoteItem?.canDelete}
      />
    </div>
  )
}

export default CollectionDetail
