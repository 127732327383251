export const USER_ITEMS = 'USER_ITEMS'
export const USER_COLLECTIONS = 'USER_COLLECTIONS'
export const USER_COLLECTIONS_WITH_ITEMS = 'USER_COLLECTIONS_WITH_ITEMS'
export const USER_COLLECTION_COUNT = 'USER_COLLECTION_COUNT'

// item modal types
export const COLLECTION = 'collection'
export const MY_COLLECTIONS_ITEM = 'myCollectionsItem'
export const ITEM = 'item'
export const TEXT_SLIDE = 'text_slide'
export const CREATE_COLLECTION = 'createCollection'
export const EDIT_COLLECTION = 'editCollection'

export const PUBLIC_FOLDER = '/api/sites/default/files/'
export const HOMEPAGE_NODE_ID = process.env.REACT_APP_HOMEPAGE_NODE_ID || 7
export const ABOUT_ID = process.env.REACT_APP_ABOUT_ID || 575
