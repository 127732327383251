import React from 'react'

export default ({ radio = false, id, value, onChange, label, name, checked, icon = false, size = 'sm' }) => (
  <div className="c-checkbox">
    <input
      className="u-visually-hidden"
      name={name}
      id={id}
      value={value}
      type={radio ? "radio" : "checkbox"}
      checked={checked}
      onChange={onChange}
    />
    <label className="c-btn c-btn--lg c-btn--full u-bg-grey-200" htmlFor={id}>
      {icon
        ? <span className={`c-btn__icon c-btn__icon--left ${size === 'sm' ? 'c-btn__icon--sm' : ''}`}>
            <i className="material-icons">{icon}</i>
          </span>
        : null}
      <span className="c-checkbox__text">{label}</span>
    </label>
  </div>
)