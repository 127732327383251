import { execute, parseSearchStringIntoArray } from '../utils'
import { processItems } from '../items/model'
import { processGroupByIdItem, processCollections, processAllMembersOfCollection } from './model'
import { ALL_MEMBERS_OF_COLLECTIONS_QUERY, COLLECTION_BYID_GROUP_QUERY, COLLECTION_BYID_QUERY, COLLECTION_BY_IDS_QUERY, USER_COLLECTIONS_QUERY } from './query'
import { SEARCH_PAGE_SIZE } from 'shared/constants'

export const getUserCollectionCount = async userId => {
  const query = `
    query UserCollectionCount($userId: [String]) {
      groupQuery(limit:50, offset:0, filter: {conditions: {field: "uid", value: $userId}}) {
        totalCollections:count
      }
    }
  `
  // const query = `
  //   {
  //     searchAPISearch(
  //       index_id: "collection",
  //       conditions: {
  //         operator: "=",
  //         name: "collection_username_name",
  //         value: "${username}"
  //       }
  //     ) {
  //       result_count
  //     }
  //   }
  // `
  return execute(query, { userId })
}

export const searchCollections = async (filters = {}, sorting = null, conditions = '', page, returnAll, userId) => {
  const keywordArray = filters && filters.keyword && parseSearchStringIntoArray(filters.keyword)

  const withSort = sorting && sorting.sort && sorting.sort !== 'relevance';
  const sortInput = withSort ? [{
    field: sorting.sort === 'alphabetical' ? 'collection_title' : 'field_collection_media_date_create',
    value: sorting.sort === 'newest' || sorting.order === 'desc' ? 'desc' : 'asc'
  }] : null

  const withLangConditions = filters && filters.language && filters.language.length === 1
  const conditionGroup = {
    conjunction: "AND",
    groups: [
      {
        conjunction: "OR",
        conditions: [
          {operator: "=", name: "collection_status", value: "TRUE"},
          ...(userId ? [{
            operator: "=",
            name: "collection_uid",
            value: typeof userId === 'number' ? userId.toString() : userId
          }] : [])
        ]
      },
      ...(withLangConditions ?
        [{
          conjunction: "AND",
          conditions: [{
            name: `field_collection_${filters.language[0] === 'ja' ? 'japanese' : 'english'}_title`, 
            operator: "<>",
            value: ""
          }]
        }] : [])
    ]
  }

  const query = `query SearchCollections(
    $keywords: [String]!, 
    $range: RangeInput, 
    $sort: [SortInput],
    $conditions: [ConditionInput],
    $conditionGroup: ConditionGroupInput
  ) {
      searchAPISearch(
        index_id: "searchstax_collection_index",
        fulltext: {
          keys: $keywords,
          conjunction: "AND"
          fields: [
            "collection_description",
            "field_collection_english_description",
            "field_collection_english_title",
            "field_collection_japanese_description",
            "field_collection_japanese_title",
            "field_collection_latitude",
            "collection_layer_type_name",
            "field_collection_longitude",
            "field_collection_media_creator_realname",
            "field_collection_media_creator_username",
            "collection_media_type_name",
            "collection_text",
            "collection_title"
          ]
        },
        range: $range,
        sort: $sort,
        conditions: $conditions,
        condition_group: $conditionGroup
      ) {
        result_count
        documents {
          ... on SearchstaxCollectionIndexDoc {
            collection_id
            collection_uuid
            collection_langcode
            collection_title
            collection_created
            collection_description
            collection_file_uri
            collection_media_type_name
            collection_langcode
            collection_username_name
            field_collection_media_date_create
            field_collection_thumbnail_url
            field_collection_translation_approval
            field_featured_collection
            collection_status
            field_collection_media_creator_realname
            field_collection_media_creator_username
            field_collection_english_description
            field_collection_english_title
            field_collection_japanese_description
            field_collection_japanese_title
            groupItemCount
          }
        }
      }
    }
  `

  const res = await execute(query, {
    keywords: keywordArray || [],
    range: !returnAll ? { offset: (page - 1) * SEARCH_PAGE_SIZE, limit: SEARCH_PAGE_SIZE } : null,
    sort: sortInput,
    conditions: conditions || null,
    conditionGroup: !conditions ? conditionGroup : null
  })
  return processItems(res)
}

export const getUserCollections = async (user, withItems, page, sort = 'newest', langField) => {
  const PAGE_SIZE = 20
  const PAGE_MAX_SIZE = 999
  const userId = typeof user.id === 'number' ? user.id.toString() : user.id;
  const variables = { 
    userId,
    withItems,
    offset: page !== null ? page * PAGE_SIZE : 0,
    limit: page !== null ? PAGE_SIZE : PAGE_MAX_SIZE,
    sortField: sort === 'alphabetical' ? langField : 'changed',
    sortOrder: sort === 'newest' ? 'DESC' : 'ASC',
  }
  const res = await execute(USER_COLLECTIONS_QUERY, variables)

  const collections = res && res.groupQuery && processCollections(res.groupQuery.entities)
  const totalCollections = res && res.groupQuery && res.groupQuery.totalCollections

  if(collections && totalCollections) {
    return { totalCollections, collections }
  }
}

export const getCollectionItemById = async id => {
  const res = await execute(COLLECTION_BYID_QUERY, { id: typeof id === 'number' ? id.toString() : id })
  return processGroupByIdItem(res)
}

export const getAllMembersOfCollection = async (groupId) => {
  const res = await execute(ALL_MEMBERS_OF_COLLECTIONS_QUERY, { groupId })
  const members = processAllMembersOfCollection(res)
  return members
}

export const getCollectionByIdGroupsRaw = async (ids, withItems = false) => {
  if (!ids?.length || ids.length < 1) return
  const promises = ids.map(async id => await execute(COLLECTION_BYID_GROUP_QUERY, { id, withItems }))
  const responses = await Promise.all(promises)
  return responses.map(res => processGroupByIdItem(res))
}

export const getCollectionByIds = async ( ids, withItems = false ) => {
  const conditions = ids.map(id => ({
    operator: "=",
    field: "id",
    value: [id]
  }))
  const res = await execute(COLLECTION_BY_IDS_QUERY, { withItems, conditions })
  return res.map(res => processGroupByIdItem(res))
}