import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openAccountModal } from 'features/AccountForm/redux/accountFormActions'
import { LOGIN } from 'shared/constants'
import { UserMenuItem, Tophat, AlertBanner } from './components'
import { selectUserLoadStatus, selectUser } from 'features/authentication/redux/authenticationSelectors'
import { JdaLink } from 'shared/components'
import logo from 'shared/assets/images/ui__logo.png'
import jaLogo from 'shared/assets/images/ui__logo--ja.png'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { resetContributeForm } from 'features/Contribute/redux/contributeActions'
import { useTranslation } from 'react-i18next'
import { EN } from 'shared/constants'
import { selectDefaultResourceLink } from 'features/BasicPage/redux/basicPageSelectors'

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false)
  const defaultResourceLink = useSelector(selectDefaultResourceLink);
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const openModal = useCallback(() => {
    setMobileNav(false)
    dispatch(openAccountModal(LOGIN))
  }, [setMobileNav, dispatch])
  const onContributeClick = useCallback(() => {
    setMobileNav(false)
    dispatch(resetContributeForm())
  }, [setMobileNav, dispatch])
  const user = useSelector(selectUser)
  const userLoaded = useSelector(selectUserLoadStatus)
  const langcode = useSelector(selectLangcodeFlag)

  useEffect(() => {
    if(mobileNav) {
      document.body.classList.add('nav-open')
    } else {
      document.body.classList.remove('nav-open')
    }
  }, [mobileNav])

  return (
    <>
      <Tophat />
      <div className="c-header js-header">
        <JdaLink onClick={() => setMobileNav(false)} className="c-header__link" to="/">
          <img className="c-header__logo" alt="JDA Logo with hyperlink to Homepage" src={langcode === EN ? logo : jaLogo} />
        </JdaLink>
        <nav id="headerMenu" className={`c-nav ${mobileNav ? 'is-open' : ''}`} aria-label="Main" role="navigation">
          <ul className="c-menu">
            <li className="c-menu__item c-btn--hover-fx">
                <JdaLink onClick={() => setMobileNav(false)} to='/activesearch' className="c-menu__link c-link c-link--icon c-link--bold">
                <span className="c-link__icon c-link__icon--left">
                  <i className="material-icons">search</i>
                </span>
                {t('Search')}
              </JdaLink>
            </li>
            <li className="c-menu__item">
                <JdaLink onClick={() => setMobileNav(false)} to='/collectionsearch' className="c-menu__link c-link c-link--icon c-link--bold">
                <span className="c-link__icon c-link__icon--left">
                  <i className="material-icons">layers</i>
                </span>
                {t('Collections')}
              </JdaLink>
            </li>
            <li className="c-menu__item">
              <JdaLink onClick={onContributeClick} to='/contribute' className="c-menu__link c-link c-link--icon c-link--bold">
                <span className="c-link__icon c-link__icon--left">
                  <i className="material-icons">add_circle_outline</i>
                </span>
                {t('Contribute')}
              </JdaLink>
            </li>
            <li className="c-menu__item">
              <JdaLink onClick={() => setMobileNav(false)}
                to={defaultResourceLink}
                className="c-menu__link c-link c-link--icon c-link--bold"
              >
                <span className="c-link__icon c-link__icon--left">
                  <i className="material-icons">info_outline</i>
                </span>
                {t('Resources')}
              </JdaLink>
            </li>
            <li className="c-menu__item u-hidden@md">
              <JdaLink onClick={() => setMobileNav(false)}
                to='/resources/about/about-jda'
                className="c-menu__link c-link c-link--icon c-link--bold"
              >
                <span className="c-link__icon c-link__icon--left">
                </span>
                {t('About')}
              </JdaLink>
            </li>
            {userLoaded ? <UserMenuItem mobileClick={() => setMobileNav(false)} {...user} /> : null}
            <li className="c-menu__item u-hidden@md">
              <Tophat mobile={true} mobileClick={() => setMobileNav(false)} />
            </li>
            {!userLoaded &&
              <li className="c-menu__item">
                <button className="c-header__signup c-btn c-btn--lg c-btn--hover-fx c-btn--full u-color-white u-bg-orange" onClick={openModal}>
                  {t('Sign In')}
                </button>
              </li>
            } 
          </ul>
        </nav>
        <button
          className={`c-header__toggle`}
          onClick={() => setMobileNav(!mobileNav)}
          aria-expanded={mobileNav ? 'true' : 'false'}
          aria-controls="headerMenu"
          aria-label={t(`Click to ${mobileNav ? 'close' : 'open'} mobile menu`)}
        >
          <i className="material-icons">{mobileNav ? 'close' : 'menu'}</i>
        </button>
      </div>
      <AlertBanner />
    </>
  )
}

export default Header
