import React from 'react'
import { CollectionItem } from 'features/Collections'

const MyContributionItem = ({ ...rest }) => {
  return (
    <CollectionItem
      hideHandle
      hideActionButtons
      {...rest}
      className='c-user-profile__contribution'
    />
  )
}

export default MyContributionItem
