import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FormSelect, ReactCKEditor, TextInput } from 'features/Form/components'
import { selectAccountFormSubmitting } from 'features/AccountForm/redux/accountFormSelectors'
import { useTranslation } from 'react-i18next'
const TextSlideForm = ({ form, lang, setForm, topics, saveFn, deleteFn, deleteText }) => {
  const {t} = useTranslation()
  const submitting = useSelector(selectAccountFormSubmitting)
  const updateForm = useCallback((event, useLang) => {
    const field = event.currentTarget.name
    const value = event.currentTarget.value
    setForm(v => ({
      ...v,
      [field]: useLang ? {
        ...v[field],
        [useLang]: value
      } : value
    }))
  }, [setForm])
  const onSubmit = event => {
    event.preventDefault()
  }
  useEffect(() => {
    if (form.topic && topics && topics.length) {
      setForm(v => ({
        ...v,
        topic: `${topics[0].value}`
      }))
    }
  }, [submitting, topics, setForm])

  const titleField = Object.keys(form)[0]

  return (
    <form className='c-form' onSubmit={onSubmit}>
      <div className='c-form__item'>
        <TextInput
          label={t('Title')}
          name={titleField}
          value={form[titleField][lang]}
          onChange={event => updateForm(event, lang)}
          className='c-account-form__input'
        />
      </div>
      <div className='c-form__item'>
        <ReactCKEditor
          id={`topic-ckeditor-${lang}`}
          type='textarea'
          label={t('Description')}
          name='description'
          rows={4}
          value={form.description[lang]}
          onChange={event => updateForm(event, lang)}
          className='c-account-form__input'
        />
      </div>
      {form.hasOwnProperty('topic') && topics && topics.length ? (
        <div className='c-form__item'>
          <FormSelect
            onChange={updateForm}
            name='topic'
            label={t('Topic')}
            value={form.topic}
            options={topics}
          />
        </div>
      ) : null}
      {saveFn && (
        <button
          className={`c-btn c-btn--lg u-bg-orange u-color-white ${submitting ? 'is-disabled' : ''}`}
          disabled={submitting}
          onClick={saveFn}
        >
          {t('SAVE')}
        </button>
      )}
      {deleteFn && deleteText && (
        <button
          className={`c-btn c-btn--lg u-bg-white u-color-black ${submitting ? 'is-disabled' : ''}`}
          disabled={submitting}
          onClick={deleteFn}
        >
          {deleteText}
        </button>
      )}
    </form>
  )
}

export default TextSlideForm
