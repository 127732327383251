export default [
  {
    "compound city prefecture": "岩手県陸前高田市",
    "Prefecture": "岩手県",
    "City": "陸前高田市"
  },
  {
    "compound city prefecture": "岩手県釜石市",
    "Prefecture": "岩手県",
    "City": "釜石市"
  },
  {
    "compound city prefecture": "岩手県山田町",
    "Prefecture": "岩手県",
    "City": "山田町"
  },
  {
    "compound city prefecture": "岩手県宮古市",
    "Prefecture": "岩手県",
    "City": "宮古市"
  },
  {
    "compound city prefecture": "岩手県大船渡市",
    "Prefecture": "岩手県",
    "City": "大船渡市"
  },
  {
    "compound city prefecture": "岩手県野田村",
    "Prefecture": "岩手県",
    "City": "野田村"
  },
  {
    "compound city prefecture": "岩手県盛岡市",
    "Prefecture": "岩手県",
    "City": "盛岡市"
  },
  {
    "compound city prefecture": "岩手県洋野町",
    "Prefecture": "岩手県",
    "City": "洋野町"
  },
  {
    "compound city prefecture": "岩手県久慈市",
    "Prefecture": "岩手県",
    "City": "久慈市"
  },
  {
    "compound city prefecture": "岩手県普代村",
    "Prefecture": "岩手県",
    "City": "普代村"
  },
  {
    "compound city prefecture": "岩手県田野畑村",
    "Prefecture": "岩手県",
    "City": "田野畑村"
  },
  {
    "compound city prefecture": "岩手県岩泉町",
    "Prefecture": "岩手県",
    "City": "岩泉町"
  },
  {
    "compound city prefecture": "岩手県大槌町",
    "Prefecture": "岩手県",
    "City": "大槌町"
  },
  {
    "compound city prefecture": "宮城県仙台市",
    "Prefecture": "宮城県",
    "City": "仙台市"
  },
  {
    "compound city prefecture": "宮城県石巻市",
    "Prefecture": "宮城県",
    "City": "石巻市"
  },
  {
    "compound city prefecture": "宮城県気仙沼市",
    "Prefecture": "宮城県",
    "City": "気仙沼市"
  },
  {
    "compound city prefecture": "宮城県名取市",
    "Prefecture": "宮城県",
    "City": "名取市"
  },
  {
    "compound city prefecture": "宮城県多賀城市",
    "Prefecture": "宮城県",
    "City": "多賀城市"
  },
  {
    "compound city prefecture": "宮城県岩沼市",
    "Prefecture": "宮城県",
    "City": "岩沼市"
  },
  {
    "compound city prefecture": "宮城県東松島市",
    "Prefecture": "宮城県",
    "City": "東松島市"
  },
  {
    "compound city prefecture": "宮城県亘理町",
    "Prefecture": "宮城県",
    "City": "亘理町"
  },
  {
    "compound city prefecture": "宮城県山元町",
    "Prefecture": "宮城県",
    "City": "山元町"
  },
  {
    "compound city prefecture": "宮城県女川町",
    "Prefecture": "宮城県",
    "City": "女川町"
  },
  {
    "compound city prefecture": "宮城県南三陸町",
    "Prefecture": "宮城県",
    "City": "南三陸町"
  },
  {
    "compound city prefecture": "福島県相馬市",
    "Prefecture": "福島県",
    "City": "相馬市 "
  },
  {
    "compound city prefecture": "福島県南相馬市",
    "Prefecture": "福島県",
    "City": "南相馬市"
  },
  {
    "compound city prefecture": "福島県楢葉町",
    "Prefecture": "福島県",
    "City": "楢葉町"
  },
  {
    "compound city prefecture": "福島県富岡町",
    "Prefecture": "福島県",
    "City": "富岡町"
  },
  {
    "compound city prefecture": "福島県川内村",
    "Prefecture": "福島県",
    "City": "川内村"
  },
  {
    "compound city prefecture": "福島県大熊町",
    "Prefecture": "福島県",
    "City": "大熊町"
  },
  {
    "compound city prefecture": "福島県双葉町",
    "Prefecture": "福島県",
    "City": "双葉町"
  },
  {
    "compound city prefecture": "福島県浪江町",
    "Prefecture": "福島県",
    "City": "浪江町"
  },
  {
    "compound city prefecture": "福島県いわき市",
    "Prefecture": "福島県",
    "City": "いわき市"
  },
  {
    "compound city prefecture": "福島県大熊町",
    "Prefecture": "福島県",
    "City": "大熊町"
  },
  {
    "compound city prefecture": "福島県川俣町",
    "Prefecture": "福島県",
    "City": "川俣町"
  },
  {
    "compound city prefecture": "福島県飯舘村",
    "Prefecture": "福島県",
    "City": "飯舘村"
  },
  {
    "compound city prefecture": "福島県広野町",
    "Prefecture": "福島県",
    "City": "広野町"
  },
  {
    "compound city prefecture": "千葉県旭市",
    "Prefecture": "千葉県",
    "City": "旭市"
  },
  {
    "compound city prefecture": "千葉県浦安市",
    "Prefecture": "千葉県",
    "City": "浦安市"
  },
  {
    "compound city prefecture": "熊本県熊本市",
    "Prefecture": "熊本県",
    "City": "熊本市"
  },
  {
    "compound city prefecture": "北海道厚真町",
    "Prefecture": "北海道",
    "City": "厚真町"
  },
  {
    "compound city prefecture": "大阪府大阪市",
    "Prefecture": "大阪府",
    "City": "大阪市"
  },
  {
    "compound city prefecture": "大阪府高槻市",
    "Prefecture": "大阪府",
    "City": "高槻市"
  },
  {
    "compound city prefecture": "大阪府茨木市",
    "Prefecture": "大阪府",
    "City": "茨木市"
  },
  {
    "compound city prefecture": "岡山県倉敷市",
    "Prefecture": "岡山県",
    "City": "倉敷市"
  },
  {
    "compound city prefecture": "愛媛県大洲市",
    "Prefecture": "愛媛県",
    "City": "大洲市"
  },
  {
    "compound city prefecture": "広島県広島市",
    "Prefecture": "広島県",
    "City": "広島市"
  },
  {
    "compound city prefecture": "広島県呉市",
    "Prefecture": "広島県",
    "City": "呉市"
  },
  {
    "compound city prefecture": "広島県東広島市",
    "Prefecture": "広島県",
    "City": "東広島市"
  }
];
