import React from 'react'

const renderImage = (img, imageLeft) => (
  <div className='c-split-content__frame-container'>
    <div className={`c-split-content__frame ${imageLeft ? 'c-split-content__frame--left' : ''}`}>
      <img className='c-split-content__img' src={img.url} alt={img.alt || ''} />
    </div>
  </div>
)

const SplitContent = ({ img, children, imageLeft = false }) => {
  const image = img && renderImage(img, imageLeft)
  return (
    <div className='o-grid c-wysiwyg--split-content'>
      <div className={`o-grid__col ${imageLeft ? 'u-5/12@xs' : 'u-7/12@xs'}`}>
        {imageLeft ? image : children}
      </div>
      <div className={`o-grid__col ${imageLeft ? 'u-7/12@xs' : 'u-5/12@xs'}`}>
        {imageLeft ? children : image}
      </div>
    </div>
  )
}

export default SplitContent
