import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const ITEM_TYPE = 'COLLECTION_ITEM_SORTABLE'

const SortItem = ({ id, index, moveItem, children, hoverTopic, isTopic, itemTopic }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
 
      // Don't replace items with themselves
      if ((dragIndex === hoverIndex && hoverTopic === itemTopic) || isTopic) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveItem(dragIndex, hoverIndex, item, false, hoverTopic)
      item.index = hoverIndex
    },
    drop(item) {
      const dragIndex = item.index
      const hoverIndex = index
      moveItem(dragIndex, hoverIndex, item, true, hoverTopic)
    }
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ITEM_TYPE, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isTopic
  })
  const opacity = isDragging ? 0 : 1
  const classStyle = isDragging ? 'is-dragging' : ''
  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity }} className={classStyle}>
      {children}
    </div>
  )
}

export default SortItem
