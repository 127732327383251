import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_COLLECTION, USER_COLLECTIONS_WITH_ITEMS } from 'shared/constants'
import { selectUserCollections, selectUserCollectionCount } from 'features/Collections/redux/collectionsSelectors'
import { selectLoadingState } from 'shared/redux/sharedSelectors'
import { setItemModalType, loadUserItems, setMcSort } from 'features/Collections/redux/collectionsActions'
import MyCollectionsAccordion from './components/MyCollectionsAccordion'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import CreateCollectionCtaBox from './components/CreateCollectionCtaBox'
import { useTranslation } from 'react-i18next'

const CollectionsSidebar = () => {
  const myCollectionsContainer = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userLoaded = useSelector(selectUserLoadStatus)
  const loading = useSelector(selectLoadingState)
  const collectionCount = useSelector(selectUserCollectionCount)
  const [page, setPage] = useState(0)
  useEffect(() => {
    if (userLoaded) {
      dispatch(setMcSort('newest'))
      dispatch(loadUserItems([USER_COLLECTIONS_WITH_ITEMS], 0))
      setPage(page + 1)
    }
  }, [dispatch, userLoaded])
  const collections = useSelector(selectUserCollections)
  const openCreateCollectionModal = useCallback(() => dispatch(setItemModalType(CREATE_COLLECTION)), [dispatch])
  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = myCollectionsContainer.current

    if(clientHeight + scrollTop === scrollHeight) {
      if(!loading && collectionCount / 20 > page) {
        dispatch(loadUserItems([USER_COLLECTIONS_WITH_ITEMS], page))
        setPage(page + 1)
      }
    }
  }

  return (
    <aside className={`c-collections__sidebar ${collections.length ? 'u-bg-grey-200' : 'u-bg-teal'}`}>
      <div className="c-collections__sidebar-header">
        <div className="c-copy c-copy--icon">
          <span className="c-copy__icon c-copy__icon--left c-copy__icon--lg">
            <i className="material-icons">layers</i>
          </span>
          {t('My Collections')}
        </div>
        <button className="c-link c-link--icon c-btn--hover-fx" onClick={openCreateCollectionModal}>
          <span className="c-link__icon c-link__icon--right c-link__icon--lg">
            <i className="material-icons">add_circle</i>
          </span>
        </button>
      </div>
      {collections.length ? (
        <div className='c-collections__sidebar-main'
          ref={myCollectionsContainer}
          onScroll={onScroll}
        >
          <MyCollectionsAccordion collections={collections} />
        </div>
      ) : (
        <div className="c-collections__sidebar-main">
          <CreateCollectionCtaBox />
        </div>
      )}
    </aside>
  )
}

export default CollectionsSidebar
