
import { fields } from 'features/Search/helpers/fields'
import { EN, JA } from 'shared/constants'

const select = document.querySelector.bind(document)
const selectAll = document.querySelectorAll.bind(document)

const getParameter = (name = null) => {
  if (!name) return null
  const url = window.location.href.toLowerCase()
  const regStr = name.toLowerCase().replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + regStr + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const updateUrlParameters = (history, base, values, sorting = {}, view = false) => {
  let params = ''

  Object.keys(fields).forEach(key => {
    if (values[key] && values[key] !== '' && fields[key].param) {
      if (Array.isArray(values[key])) {
        if (values[key].length > 0) {
          params += `&${fields[key].param}=${encodeURIComponent(values[key].join(','))}`
        }
      } else {
        params += `&${fields[key].param}=${encodeURIComponent(values[key])}`
      }
    }
  })
  params += sorting.sort ? `&sort=${sorting.sort}` : ''
  params += sorting.order ? `&order=${sorting.order}` : ''
  params = params.substr(1)

  let url = params !== ''
    ? `?${params}`
    : ['/ja', '/en'].includes(window.location.pathname)
      ? ''
      : window.location.pathname

  url += view && view !== 'list' ? `#${view}`:''

  if(base && history) {
    history.push(base + url)
  } else {
    window.history.pushState(null, null, url)
  }

}

const debounce = (func, delay, immediate) => {
  let timer;
  return function () {
    const context = this;
    const args = arguments;

    const later = function () {
      timer = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timer;

    clearTimeout(timer);

    timer = setTimeout(later, delay);

    if (callNow) func.apply(context, args);
  };
}

const getLangValue = (siteLang, obj) => {
  if (!obj) return ''
  if (!siteLang) return obj.en || obj.ja || ''
  return obj[siteLang] || obj[siteLang === EN ? JA : EN] || ''
}

/**
 * Taken from {@link https://regexr.com/2rhq7 here}
 * @param {string} email 
 * @returns {boolean} true if email is valid (i.e. <example@domain.com>)
 */
function validateEmail(email)
{
  const RFC2822_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if(email.match(RFC2822_REGEX)) return true
  return false
}

export {
  select,
  selectAll,
  validateEmail,
  getParameter,
  updateUrlParameters,
  debounce,
  getLangValue
}