import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import toast from 'shared/utils/toast'
import { Header, Footer, PrivateRoute } from './features/Global/components'
import Homepage from './screens/Homepage'
import Collections from './screens/Collections'
import BasicPage from './screens/BasicPage'
import UserProfile from './screens/UserProfile'
import LoginScreen from './screens/Login'
import ResetScreen from './screens/Reset'
import MyCollections from './screens/MyCollections'
import Contribute from './screens/Contribute'
import CollectionDetail from './screens/CollectionDetail'
import ItemDetail from './screens/ItemDetail'
import AccountModal from 'features/AccountModal'
import { loadUser } from 'features/authentication/redux/authenticationAction'
import { ItemModal } from 'features/Collections'
import { Loader } from 'shared/components'
import { selectUserLangcode } from 'features/authentication/redux/authenticationSelectors'
import { setLangcodeFlag } from 'shared/redux/sharedActions'
import NotFound from 'features/NotFound'
import { EN, JA } from 'shared/constants'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import i18next from 'i18next'
import { loadResources } from 'features/BasicPage/redux/basicPageActions'

const BaseLayout = (props) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const preferredLangcode = useSelector(selectUserLangcode)
  const langCode = useSelector(selectLangcodeFlag);
  const urlLangcode = props.match.params.langcode
  const validLangcode = [EN, JA].includes(urlLangcode)
  const noScroll = ['/activesearch', '/collectionsearch', '/mycollections'].some(word => pathname.includes(word))
  useEffect(() => {
    dispatch(loadUser())
    dispatch(loadResources())
  }, [dispatch])

  useEffect(() => {
    i18next.changeLanguage(langCode)
  }, [langCode])

  useEffect(() => {
    preferredLangcode && dispatch(setLangcodeFlag(preferredLangcode))
  }, [dispatch, preferredLangcode])

  useEffect(() => {
    urlLangcode && validLangcode && dispatch(setLangcodeFlag(urlLangcode))
  }, [dispatch, urlLangcode, validLangcode])

  if (pathname === '/') return <Redirect to={`/${preferredLangcode || EN}`} />
  return (
    <div className={`c-layout ${noScroll ? 'c-layout--no-scroll' : ''}`}>
      <div className='c-layout__header'>
        <Header />
      </div>
      <div className="c-layout__main">
        <div className='c-layout__content'>
          <Switch>
            {!validLangcode && <Route component={NotFound} />}
            <Route exact path={['/:langcode', '/']} component={Homepage} />
            <Route exact path='/:langcode/activesearch' component={(props) => <Collections search="items" {...props} />} />
            <Route exact path='/:langcode/user/login' component={props => <LoginScreen {...props} />} />
            <Route exact path='/:langcode/user/reset' component={ResetScreen} />
            <Route
              exact
              path={[
                '/:langcode/resources/:category',
                '/:langcode/resources/:category/:topic',
                '/:langcode/resources/:category/:topic/:page'
              ]}
              component={BasicPage}
            />
            <PrivateRoute exact path='/:langcode/user' component={UserProfile} />
            <PrivateRoute exact path='/:langcode/contribute' component={Contribute} />
            <PrivateRoute exact path='/:langcode/mycollections' component={MyCollections} />
            <Route exact path='/:langcode/collection/:collectionId' component={CollectionDetail} />
            <Route exact path='/:langcode/item/:itemId' component={ItemDetail} />
            <Route exact path='/:langcode/collectionsearch' component={() => <Collections search="collections" {...props} />} />
            <Route component={NotFound} status={404} />
          </Switch>
        </div>
      </div>
      <Footer />
      <AccountModal />
      <ItemModal />
      <Loader />
      <ToastContainer {...toast.options} />
    </div>
  )
}

export default BaseLayout
