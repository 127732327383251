import React, { useCallback } from 'react'
import Modal from 'features/Modal'
import {
  MyCollectionsList,
  CollectionPreview,
  CreateCollection,
  ItemPreview,
  TextSlideFlyout
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectItemModalActiveTab,
  selectItemModalOpen,
  selectItemModalType
} from 'features/Collections/redux/collectionsSelectors'
import { closeItemModal, itemModalClosed, setItemModalTab } from 'features/Collections/redux/collectionsActions'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import {
  COLLECTION,
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  ITEM,
  MY_COLLECTIONS_ITEM,
  TEXT_SLIDE
} from 'shared/constants'

const ItemModal = () => {
  const dispatch = useDispatch()
  const language = useSelector(selectItemModalActiveTab)
  const onTabChange = useCallback(value => { dispatch(setItemModalTab(value)) }, [dispatch])
  const itemModalType = useSelector(selectItemModalType)
  const isOpen = useSelector(selectItemModalOpen)
  const authenticatedUser = useSelector(selectUserLoadStatus)
  const onRequestClose = useCallback(() => dispatch(closeItemModal()), [dispatch])
  const onAfterClose = useCallback(() => dispatch(itemModalClosed()), [dispatch])
  const wideRightTypes = [CREATE_COLLECTION, EDIT_COLLECTION, MY_COLLECTIONS_ITEM]

  const renderColumns = () => {
    let leftContent, rightContent
    switch (itemModalType) {
      case COLLECTION:
        leftContent = <CollectionPreview onTabChange={onTabChange} activeTab={language} closeModal={onRequestClose} />
        break
      case CREATE_COLLECTION:
        rightContent = <CreateCollection />
        break
      case EDIT_COLLECTION:
        rightContent = <CreateCollection edit />
        break
      case ITEM:
        leftContent = <ItemPreview onTabChange={onTabChange} activeTab={language} />
        if (authenticatedUser) rightContent = <MyCollectionsList />
        break
      case TEXT_SLIDE:
        leftContent = <TextSlideFlyout />
        if (authenticatedUser) rightContent = <MyCollectionsList />
        break
      case MY_COLLECTIONS_ITEM:
        rightContent = <MyCollectionsList />
        break
      default:
        break
    }
    return { leftContent, rightContent }
  }
  const columns = renderColumns()

  return (
    <Modal
      isOpen={isOpen}
      className='c-item-modal'
      closeTimeoutMS={150}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
    >
      {columns.leftContent && (
        <div className='c-item-modal__column c-item-modal__column--left c-item-modal__column--wide'>
          {columns.leftContent}
        </div>
      )}
      {columns.rightContent && (
        <div
          className={`c-item-modal__column c-item-modal__column--right c-item-modal__column--${wideRightTypes.includes(itemModalType) ? 'wide' : 'narrow'}`}
        >
          {columns.rightContent}
        </div>
      )}
    </Modal>
  )
}

export default ItemModal
