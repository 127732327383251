import { get } from 'utils/axios'
import { processMenus } from './model'
import { JA } from 'shared/constants'

export const getBasicPageMenu = async () => {
  const url = process.env.NODE_ENV === 'development' ? '/api/menu_items/main' : '/api/menu_items/main';
  const res = await get(url)
  const jaMenus = await getJaBasicPageMenu()
  const menu = processMenus(res[0].below, [{ lang: JA, menu: jaMenus }])
  return menu
}

export const getJaBasicPageMenu = async () => {
  const url = process.env.NODE_ENV === 'development' ? '/ja/api/menu_items/main' : '/ja/api/menu_items/main';
  const res = await get(url)
  return res
}
