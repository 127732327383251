import React from 'react'
import { SmartInput } from 'features/Form/components'
import { useTranslation } from 'react-i18next'
import { createPDFImage } from 'shared/utils/tools/pdfThumbnail'
const ContributeFormStepTwo = ({ config = [], form, onChange, onChangeWithLang, activeTab, tabIndex }) => {
  const { t } = useTranslation()
  const onFileChange = async (value) => {
    if (value?.preview && value?.file?.type == 'application/pdf' && value.file) {
      const url = await createPDFImage(value.file);
      onChange(url, 'pdfThumbnail')
    } else {
      onChange('', 'pdfThumbnail')
    }
    onChange('', 'uri')
    onChange(value, 'file')
  }
  const onUrlChange = event => {
    onChange({}, 'file')
    onChange(event)
  }
  
  const inputFields = config.fields.map(({ type, label, labelEx, placeholder, ...rest }, index) => {
    let onChangeFn = onChange
    if (rest.name === 'description') onChangeFn = onChangeWithLang
    if (rest.name === 'file') onChangeFn = onFileChange
    if (rest.name === 'uri') onChangeFn = onUrlChange
    if (type === 'date' || type === 'field') onChangeFn = val => onChange(val, rest.name)
    const formValue = rest.name === 'description' ? form[rest.name][activeTab] : form[rest.name]
    return (
      <SmartInput
        key={index}
        type={type}
        onChange={onChangeFn}
        value={formValue}
        tabIndex={tabIndex}
        label={t(label)}
        labelEx={t(labelEx)}
        placeholder={t(placeholder)}
        {...rest}
      />
    )
  })
  return inputFields
}

export default ContributeFormStepTwo
