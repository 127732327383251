import { addMoreWebsite, updateWebsiteField } from 'features/Contribute/redux/contributeActions';
import { selectContributeMultiFormData } from 'features/Contribute/redux/contributeSelectors';
import { TextInput } from 'features/Form/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

const AddMultiWebsiteSection = () => {
  const {t} = useTranslation();
  const websitesData = useSelector(selectContributeMultiFormData);
  const dispatch = useDispatch();
  const addMoreClicked = () => {
    const payload = Array(5).fill(0).map(d => {return {uuid: uuid(), uri: ''}})
    dispatch(addMoreWebsite(payload))
  }

  const handleChange = (uuid, event) => {
    const value = event.currentTarget.value;
    const payload = {uuid: uuid, uri: value}
    dispatch(updateWebsiteField(payload))
  }

  const hideAddMore = websitesData.length == 10;

  return (
    <div>
      <div className='websites-head'>
          <div className='drag-add-items'>{t('Add Website URL')}</div>
          <div className='drag-max-items'>{t("Maximum: 10 items")}</div>
      </div>
      <div className='websites-input-wrapper'>
          {websitesData.map((d) => {
            const {uuid} = d;
            return (
                <TextInput key={uuid} formatErrorMessage={d?.error} onChange={(event) => handleChange(uuid, event)} defaultValue={d?.uri} placeholder="https://jdarchive.org/"/>
            )
          })}
      </div>
      {!hideAddMore && <div className='add-more-button' onClick={addMoreClicked}>
          <div className='material-icons'>add_circle</div>
          <div className='add-more'>{t('Add More')}</div>
      </div>}
  </div>
  )
}

export default AddMultiWebsiteSection;