import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import { ContributeForm, ContributePreview, ContributeSuccess } from 'features/Contribute'
import { useSelector } from 'react-redux'
import { selectContributeSuccesful } from 'features/Contribute/redux/contributeSelectors'
import { parseUrlSearch } from 'shared/utils/tools/parse'
import Metatags from 'shared/components/Metatags'
import { pageMeta } from 'shared/data/meta'

const Contribute = props => {
  const submitSuccessful = useSelector(selectContributeSuccesful)
  const search = props.location.search
  const params = parseUrlSearch(search)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <div className='o-grid c-layout__narrow'>
      <Metatags tags={pageMeta['contribute']} />
      <div className='o-grid__col u-6/12@md u-12/12@xs u-bg-white' style={{ height: '100%' }}>
        {submitSuccessful ? <ContributeSuccess /> : <ContributeForm params={params} />}
      </div>
      <div className='o-grid__col u-6/12@md u-hidden u-block@md' style={{ height: '100%' }}>
        <ContributePreview />
      </div>
    </div>
  )
}

export default Contribute
