import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const {t} = useTranslation();
  return (
    <div className="c-404 c-layout__fill">
      <h1>{t('404: Page not found')}</h1>
    </div>
  )
}

export default NotFound
