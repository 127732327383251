import { createAction } from '@reduxjs/toolkit'
import sharedTypes from './sharedTypes'
import * as itemService from 'shared/utils/graphql'

export const setLoadingState = createAction(sharedTypes.SET_LOADING)
export const setLangcodeFlag = createAction(sharedTypes.SET_LANGCODE_FLAG)

export const updateLangCodeFlag = newLangCode => (dispatch, getState) => {
  const { shared: { langcode } } = getState()
  if (langcode !== newLangCode) {
    dispatch(setLangcodeFlag(newLangCode))
    const pathname = window.location.pathname.replace(langcode, newLangCode)
    window.history.pushState(null, null, pathname)
  }
}

const getHomepageItemsBegin = createAction(sharedTypes.GET_HOMEPAGE_ITEMS_BEGIN)
const getHomepageItemsSuccess = createAction(sharedTypes.GET_HOMEPAGE_ITEMS_SUCCESS)
const getHomepageItemsFail = createAction(sharedTypes.GET_HOMEPAGE_ITEMS_FAIL)

export const loadHomepageItems = () => async dispatch => {
  dispatch(getHomepageItemsBegin())
  try {
    const res = await itemService.getHomepageItems()
    if (res && res.featuredResources) {
      dispatch(getHomepageItemsSuccess(res))
    }
  } catch (error) {
    console.log('loadHomepageItems error: ', error)
    dispatch(getHomepageItemsFail())
  }
}
