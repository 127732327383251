import React, { useCallback, useState } from 'react';
import { selectItemData } from "features/Collections/redux/collectionsSelectors";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LanguageTabber } from "shared/components";
import { selectLangcodeFlag, selectLoadingState } from "shared/redux/sharedSelectors";
import { selectTagSearchResults } from 'features/Contribute/redux/contributeSelectors';
import { ReactCKEditor, TagManager, TextInput } from 'features/Form/components';
import toast from 'shared/utils/toast';
import * as restItemService from 'shared/utils/api/item'
import { updateItem } from 'features/Contribute/redux/contributeActions';

const ContributionItemEditView = ({ onEditClicked }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const siteLangcode = useSelector(selectLangcodeFlag);
  const data = useSelector(selectItemData);
  const tagResults = useSelector(selectTagSearchResults);
  const { title, description, tags, id } = data;
  const [form, setForm] = useState({title, description, tags, id});
  const [activeTab, setActiveTab] = useState(siteLangcode);
  const submitting = useSelector(selectLoadingState)

  const addNewTag = async (tag) => {
    const tagExists = !!(form.tags || []).find(t => t.name === tag)
    let selectedTagResult = tagResults.find(t => t.name === tag)
    if (tagExists) {
      toast.error(`Tag already exists`)
    } else {
      if (!selectedTagResult) selectedTagResult = await restItemService.createTag(tag)
      setForm(form => ({ ...form, tags: [...form.tags, selectedTagResult]}))
    }
  }

  const deleteTag = (tag) => {
    setForm(form => ({ ...form, tags: (form?.tags||[]).filter(t => tag.tid != t.tid)}))
  }

  const onUpdateItem = () => {
    dispatch(updateItem(form, () => onEditClicked(false)))
  }

  const isChanged = () => {
    const keys = Object.keys(form);
    for (let key of keys) {
      const initialValue = JSON.stringify(data[key]);
      const modifiedValue = JSON.stringify(form[key]);
      if (initialValue != modifiedValue) {
        return true;
      }
    }
    return false;
  }

  const onCancel = () => {
    onEditClicked && onEditClicked(false);
  }

  const updateForm = useCallback((event, useLang) => {
    const field = event.currentTarget.name
    const value = event.currentTarget.value
    setForm(v => ({
      ...v,
      [field]: useLang ? {
        ...v[field],
        [useLang]: value
      } : value
    }))
  })

  const onSubmit = event => {
    event.preventDefault()
  }

  const enableSave = isChanged();

  return (
    <LanguageTabber
      className='c-contribution-item'
      contentClassName='c-contribution-item__content'
      activeTab={activeTab}
      setActiveTab={setActiveTab}>
        <div className='u-p32'>
          <form className='c-form' onSubmit={onSubmit}>
            <div className='c-heading c-heading--l4'>{t('Edit')}</div>
            <div className='c-form__item'>
              <TextInput
                label={t('Title')}
                name={'title'}
                value={form['title'][activeTab]}
                onChange={event => updateForm(event, activeTab)}
                className='c-account-form__input'
              />
            </div>
            <div className='c-form__item'>
              <ReactCKEditor
                id={`contribution-edit-ckeditor-${activeTab}`}
                type='textarea'
                label={t('Description')}
                name='description'
                rows={4}
                value={form.description[activeTab]}
                onChange={event => updateForm(event, activeTab)}
                className='c-account-form__input'
              />
            </div>
            <TagManager tags={form.tags || []} addNewFn={addNewTag} deleteFn={deleteTag} editable={true} disabled={submitting}/>
            <button
              className={`c-btn c-btn--lg u-bg-orange u-color-white ${submitting || !enableSave ? 'is-disabled' : ''}`}
              disabled={submitting || !enableSave}
              onClick={onUpdateItem}
            >
              {t('SAVE')}
            </button>
            <button
              className={`c-btn c-btn--lg u-bg-white u-color-black ${submitting ? 'is-disabled' : ''}`}
              disabled={submitting}
              onClick={onCancel}
            >
              {t('CANCEL')}
            </button>
          </form>
        </div>
      </LanguageTabber>
  )
}

export default ContributionItemEditView;