import React from 'react'
import { useTranslation } from 'react-i18next'
import InputWrapper from '../InputWrapper'

const LanguagesManager = ({
  name,
  caption,
  required,
  label,
  labelClassname = '',
  languages = {},
  toggleFn,
  tabIndex = '0',
  index,
}) => {
  
  const entries = Object.entries(languages)
  const { t } = useTranslation()
  const onTab = (event, fn) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault()
      fn()
    }
  }
  return (
    <InputWrapper
      name={name}
      caption={caption}
      required={required}
      label={label}
      labelClassname={labelClassname}
    >
      <ul className='c-tag-manager c-tag-manager__list o-grid'>
        {entries.map(([language, checked]) => {
          const closure = () => toggleFn(language, index)
          return (
            <li key={language} className='o-grid__col u-3/12@md u-6/12@xs u-mb'>
              <input
                // onChange={closure}
                id={`lm-${language}`}
                type='checkbox' value={checked}
                className='u-hidden'
              />
              <label
                htmlFor={`lm-${language}`}
                className={`c-tag c-tag--lang ${checked ? 'u-bg-teal' : 'u-bg-grey-200 u-color-black'}`}
                tabIndex={tabIndex}
                onKeyPress={event => onTab(event, closure)}
                onClick={() =>toggleFn(language, index)}
              >
                {t(`${language[0].toUpperCase()}${language.slice(1)}`)}
                </label>
            </li>
          )
        })}
      </ul>
    </InputWrapper>
  )
}

export default LanguagesManager
