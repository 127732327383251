import { createReducer } from '@reduxjs/toolkit'
import collectionsTypes from './collectionsTypes'
import authenticatonTypes from 'features/authentication/redux/authenticatonTypes'
import contributeTypes from 'features/Contribute/redux/contributeTypes'
import { EN } from 'shared/constants'

const defaultState = {
  meta: {
    itemModalOpen: false,
    itemModalType: null,
    defaultItemLangcode: EN,
    myCollectionsSort: 'newest',
    submitting: false,
    collectionDataNotFound: false,
    itemModalDataNotFound: false,
    itemModalActiveTab: EN
  },
  collectionForm: {
    title: { en: '', ja: '' },
    description: { en: '', ja: '' },
    published: false,
    image: {},
    langcode: EN,
    defaultLangcode: EN
  },
  selectedItemData: null,
  selectedCollectionData: null,
  userCollections: [],
  userItems: [],
  userCollectionCount: 0
}

export default createReducer(defaultState, {
  [collectionsTypes.SET_ITEM_MODAL_TYPE]: (state, action) => {
    state.meta.itemModalType = action.payload
    state.meta.itemModalOpen = true
  },
  [collectionsTypes.CREATE_COLLECTION_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.CREATE_COLLECTION_SUCCESS]: (state, action) => {
    state.meta.itemModalOpen = false
    state.userCollections.unshift(action.payload)
    state.userCollectionCount = state.userCollectionCount + 1
    state.meta.submitting = false
  },
  [collectionsTypes.CREATE_COLLECTION_FAIL]: state => {
    state.meta.submitting = false
  },
  [collectionsTypes.CLOSE_ITEM_MODAL]: state => {
    state.meta.itemModalOpen = false
  },
  [collectionsTypes.ITEM_MODAL_CLOSED]: state => {
    state.collectionForm = defaultState.collectionForm
  },
  [collectionsTypes.SET_FORM]: (state, action) => {
    state.collectionForm = action.payload
  },
  [collectionsTypes.GET_USER_ITEMS_SUCCESS]: (state, action) => {
    const { items, collections, collectionCount } = action.payload
    if (items) state.userItems = items
    if (collections) state.userCollections = collections
    if (collectionCount) state.userCollectionCount = collectionCount
  },
  [authenticatonTypes.CLEAR_USER]: state => {
    state.userItems = []
    state.userCollections = []
  }, 
  [collectionsTypes.SET_MC_SORT]: (state, action) => {
    state.meta.myCollectionsSort = action.payload
  },
  [collectionsTypes.DELETE_COLLECTION_BEGIN]: (state, action) => {
    state.meta.submitting = true;
  },
  [collectionsTypes.DELETE_COLLECTION_SUCCESS]: (state, action) => {
    state.meta.submitting = false;
    state.userCollections = state.userCollections.filter(col => col.id !== action.payload)
    if (state.userCollectionCount) state.userCollectionCount = state.userCollectionCount - 1
  },
  [collectionsTypes.DELETE_COLLECTION_FAIL]: (state, action) => {
    state.meta.submitting = false;
  },
  [collectionsTypes.GET_ITEM_DATA_BEGIN]: state => {
    state.meta.itemModalDataNotFound = false
  },
  [collectionsTypes.GET_ITEM_DATA_SUCCESS]: (state, action) => {
    state.selectedItemData = action.payload
    if (!action.payload) state.meta.itemModalDataNotFound = true
  },
  [collectionsTypes.GET_ITEM_DATA_FAIL]: state => {
    state.meta.itemModalDataNotFound = true
  },
  [collectionsTypes.GET_COLLECTION_DATA_BEGIN]: (state, action) => {
    state.meta.collectionDataNotFound = false
    if (state.selectedCollectionData && state.selectedCollectionData.id !== action.payload) {
      state.selectedCollectionData = null
    }
  },
  [collectionsTypes.GET_COLLECTION_DATA_SUCCESS]: (state, action) => {
    state.selectedCollectionData = action.payload
    if (!action.payload) state.meta.collectionDataNotFound = true
  },
  [collectionsTypes.EDIT_COLLECTION_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.EDIT_COLLECTION_SUCCESS]: (state, action) => {
    const { id, img, field_collection_topic } = action.payload
    state.selectedCollectionData = {
      ...state.selectedCollectionData,
      ...action.payload,
      ownerId: state.selectedCollectionData.ownerId,
      img: img || state.selectedCollectionData.img,
      fieldCollectionTopic: field_collection_topic && field_collection_topic[0] && field_collection_topic[0].entity
        ? field_collection_topic
        : state.selectedCollectionData.fieldCollectionTopic
    }
    state.userCollections = state.userCollections.map(col => {
      if (id === col.id) return action.payload
      return col
    })
    state.meta.submitting = false
    state.meta.itemModalOpen = false
  },
  [collectionsTypes.EDIT_COLLECTION_FAIL]: state => {
    state.meta.submitting = false
  },
  [collectionsTypes.ADD_COLLECTION_ITEM_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.ADD_COLLECTION_ITEM_SUCCESS]: (state, action) => {
    const updates = action.payload
    updates.forEach(collection => {
      state.userCollections = state.userCollections.map(userCol => {
        if (collection.id === userCol.id) {
          const newItems = userCol.items ? [ ...userCol.items, ...collection.changes] : collection.changes
          return {
            ...userCol,
            items: newItems,
            itemCount: userCol.itemCount + collection.changes.length
          }
        }
        return userCol
      })
    })
    state.meta.submitting = false
  },
  [collectionsTypes.ADD_COLLECTION_ITEM_FAIL]: state => {
    state.meta.submitting = false
  },
  [collectionsTypes.LOAD_ITEM_MODAL_BEGIN]: state => {
    state.meta.itemModalOpen = false
  },
  [collectionsTypes.REMOVE_COLLECTION_ITEM_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.REMOVE_COLLECTION_ITEM_SUCCESS]: (state, action) => {
    state.selectedCollectionData.items = state.selectedCollectionData.items.filter(item => item.id !== action.payload)
    state.meta.submitting = false
  },
  [collectionsTypes.REMOVE_COLLECTION_ITEM_FAIL]: state => {
    state.meta.submitting = false
  },
  [collectionsTypes.UPDATE_COLLECTION_ITEM_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.UPDATE_COLLECTION_ITEM_SUCCESS]: (state, action) => {
    state.selectedCollectionData.items = state.selectedCollectionData.items.map(item => {
      if (action.payload.id === item.id) return { ...item, ...action.payload }
      return item
    })
    state.meta.submitting = false
  },
  [collectionsTypes.UPDATE_COLLECTION_ITEM_SORT_SUCCESS]: (state, action) => {
    state.selectedCollectionData.items = action.payload
    state.meta.submitting = false
  },
  [collectionsTypes.MOVE_COLLECTION_ITEM_SUCCESS]: (state, action) => {
    const { id, topic } = action.payload
    const topicObj = topic ? { entity: { id: topic } } : null

    state.selectedCollectionData.items = state.selectedCollectionData.items.map(item => {
      if (item.id === id) return { ...item, fieldCollectionTopic: topicObj }
      return item
    })
    state.meta.submitting = false
  },
  [collectionsTypes.UPDATE_COLLECTION_ITEM_FAIL]: state => {
    state.meta.submitting = false
  },
  [contributeTypes.ADD_TAG_TO_SELECTED_ITEM_SUCCESS]: (state, action) => {
    state.selectedItemData.tags.push(action.payload)
  },
  [contributeTypes.REMOVE_TAG_FROM_SELECTED_ITEM_SUCCESS]: (state, action) => {
    state.selectedItemData.tags = state.selectedItemData.tags.filter(tag => tag.tid !== action.payload.tid)
  },
  [authenticatonTypes.REQUEST_SIGN_IN]: state => {
    state.meta.itemModalOpen = false
  },
  [contributeTypes.UPDATE_SELECTED_ITEM_SUCCESS]: (state, action) => {
    if (state.selectedItemData && state.selectedItemData.id === action.payload.id) {
      state.selectedItemData = {
        ...state.selectedItemData,
        ...action.payload
      }
    }
  },
  [collectionsTypes.EDIT_TEXT_SLIDE_SUCCESS]: (state, action) => {
    if (state.selectedItemData && state.selectedItemData.id === action.payload.id) {
      state.selectedItemData = {
        ...state.selectedItemData,
        ...action.payload
      }
    }
    state.selectedCollectionData.items = state.selectedCollectionData.items.map(item => {
      if (action.payload.id === item.itemData?.id) {
        return {
          ...item,
          itemData: {
            ...item.itemData,
            ...action.payload
          }
        }
      }
      return item
    })
  },
  [collectionsTypes.ADD_TEXT_SLIDE_TO_TOPIC_SUCCESS]: (state, action) => {
    state.selectedCollectionData.items.push(action.payload)
  },
  [collectionsTypes.ADD_UPDATE_TOPIC_SUCCESS]: (state, action) => {
    const { update, topic, position } = action.payload
    const { selectedCollectionData: { fieldCollectionTopic } } = state

    if(update) {
      const indexToUpdate = fieldCollectionTopic.findIndex(({ entity }) => entity.id === topic.entity.id)
      if(indexToUpdate > -1) {
        fieldCollectionTopic.splice(indexToUpdate, 1, topic)
      }
    } else {
      fieldCollectionTopic.splice(position, 0, topic)
    }
    state.meta.submitting = false
  },
  [collectionsTypes.DELETE_TOPIC_SUCCESS]: (state, action) => {
    const { selectedCollectionData: { fieldCollectionTopic } } = state
    const indexToDelete = fieldCollectionTopic.findIndex(({ entity }) => entity.id === action.payload)

    if(indexToDelete > -1) {
      fieldCollectionTopic.splice(indexToDelete, 1)
    }
    state.meta.submitting = false
  },
  [collectionsTypes.ADD_UPDATE_TOPIC_BEGIN]: state => {
    state.meta.submitting = true
  },
  [collectionsTypes.ADD_UPDATE_TOPIC_FAIL]: state => {
    state.meta.submitting = false
  },
  [collectionsTypes.ADD_TEXT_SLIDE_TO_COLLECTIONS_SUCCESS]: (state, action) => {
    const { collectionIds, textSlideId } = action.payload
    state.userCollections = state.userCollections.map(col => {
      if (collectionIds.includes(col.id)) {
        return {
          ...col,
          items: [...col.items, { itemData: { id: textSlideId } }]
        }
      }
      return col
    })
  },
  [collectionsTypes.SET_ITEM_MODAL_TAB]: (state, action) => {
    state.meta.itemModalActiveTab = action.payload
  },
  [collectionsTypes.INVITE_USER_SUCCESS]: (state, action) => {
    if (action.payload?.mid &&
      state.selectedCollectionData?.members &&
      // if user is not already in collections, add user
      !state.selectedCollectionData.members.includes(member => member.mid !== action.payload.mid)) {
        state.selectedCollectionData.members.push(action.payload)
    }
  },
  [collectionsTypes.DELETE_USER_SUCCESS]: (state, action) => {
    // comparing entity id and not uid, this only happens for deletion
    const index = state.selectedCollectionData.members.findIndex(member => member.mid === action.payload)
    if (index !== -1) state.selectedCollectionData.members.splice(index, 1)
  },
  [collectionsTypes.GET_ALL_USERS_SUCCESS]: (state, action) => {
    state.selectedCollectionData.members = action.payload
  }
})
