import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { selectUser, selectUserIsAdmin } from 'features/authentication/redux/authenticationSelectors'
import { Captcha } from 'features/Form/components'
import { submitAccountForm } from 'features/AccountForm/redux/accountFormActions'
import { REQUEST_PASS } from 'shared/constants'
import toast from 'shared/utils/toast'
import { parseHtmlToReact } from 'shared/utils/tools/parse'

const RequestNewPass = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [captchaSuccessful, setCaptchaSuccessful] = useState(false)
  const user = useSelector(selectUser)
  const isAdmin = useSelector(selectUserIsAdmin)
  const requestPass = useCallback(() => dispatch(submitAccountForm(REQUEST_PASS)), [dispatch])
  const onSubmit = event => {
    event.preventDefault()
    if (isAdmin || captchaSuccessful) {
      requestPass()
      toast.success(t('password requested'))
    } else {
      toast.error(t('The answer you entered for the CAPTCHA was not correct'))
    }
  }
  
  return (
    <form className='c-form' onSubmit={onSubmit}>
      <h6 className='c-heading c-heading--l6'>{t('Update Password')}</h6>
      <p className='u-mv'>
        {parseHtmlToReact(t('userMailInstructions', { who: user.mail }))}
      </p>
      {!isAdmin && <div className='c-form__item'>
        <Captcha onChange={() => setCaptchaSuccessful(!captchaSuccessful)} />
      </div>}
      <button className="c-btn c-btn--sm u-color-white u-bg-orange c-copy--uppercase" type='submit'>
        {t('E-mail new password')}
      </button>
    </form>
  )
}

export default RequestNewPass
