import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { logoutUser } from 'features/authentication/redux/authenticationAction'
import { JdaLink } from 'shared/components'
import { debounce } from 'utils/functions'

const UserMenuItem = ({ username, avatar, mobileClick = () => { } }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  const [visible, setVisible] = useState(false)
  const onLogout = useCallback(debounce(() => dispatch(logoutUser()), 1000, true), [dispatch])
  const buttonRef = useRef()
  const listRef = useRef()
  const toggleVisibility = useCallback(visibility => {
    if (!visibility) {
      setActive(false)
      setTimeout(() => {
        setVisible(false)
      }, 300)
    } else {
      setVisible(true)
      setTimeout(() => {
        setActive(true)
      }, 10)
    }
  }, [setActive])
  useEffect(() => {
    const onClickOutside = event => {
      const clickedButton = buttonRef.current && buttonRef.current.contains(event.target)
      if (listRef.current && !listRef.current.contains(event.target) && !clickedButton) {
        toggleVisibility(false)
      }
    }
    const onEscClose = event => {
      if (event.key === 'Escape') toggleVisibility(false)
    }
    window.addEventListener('mousedown', onClickOutside)
    window.addEventListener('keydown', onEscClose)
    return () => {
      window.removeEventListener('mousedown', onClickOutside)
      window.removeEventListener('keydown', onEscClose)
    }
  }, [toggleVisibility])

  const onMenuItemClick = () => {
    setVisible(false)
    setActive(false)
    mobileClick()
  }
  
  const handleLogout = () => {
    onLogout()
    mobileClick()
  }

  return (
    <li className='c-menu__item c-menu__item--user'>
      <button className='c-menu__link c-link c-link--icon c-link--bold'
        onClick={() => toggleVisibility(!visible)}
        aria-haspopup={true}
        aria-expanded={active}
        ref={buttonRef}
      >
        <img className='c-menu__avatar' src={avatar} alt='' />
        {username}
      </button>

      <ul className={`c-menu__dropdown ${visible ? 'is-open' : ''} ${active ? 'active' : ''}`} ref={listRef}>
        <li className='c-menu__dropdown-item'>
          <JdaLink to='/mycollections' onClick={onMenuItemClick} className='c-link c-menu__dropdown-sub-item'>
            {t('My Collections')}
          </JdaLink>
        </li>
        <li className='c-menu__dropdown-item'>
          <JdaLink to='/user' onClick={onMenuItemClick} className='c-link c-menu__dropdown-sub-item'>
            {t('Profile')}
          </JdaLink>
        </li>
        <li className='c-menu__dropdown-item is-last'>
          <button className='c-link c-menu__dropdown-sub-item c-btn--hover-fx' style={{ cursor: 'pointer'}} onClick={handleLogout}>
            {t('Sign Out')}
          </button>
        </li>
      </ul>
    </li>
  )
}

export default UserMenuItem
