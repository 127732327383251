import { createAction } from '@reduxjs/toolkit'
import collectionsTypes from './collectionsTypes'
import * as collectionService from 'shared/utils/api/collection'
import * as itemService from 'shared/utils/graphql'
import {
  COLLECTION,
  ITEM,
  MY_COLLECTIONS_ITEM,
  TEXT_SLIDE,
  USER_COLLECTIONS,
  USER_COLLECTIONS_WITH_ITEMS,
  USER_COLLECTION_COUNT,
  USER_ITEMS,
  EN,
  JA
} from 'shared/constants'
import { setLoadingState } from 'shared/redux/sharedActions'
import toast from 'shared/utils/toast'
import { getCollectionByIdGroupsRaw } from 'shared/utils/graphql/collections'
import i18n from 'i18n'
import { searchSuccess } from 'features/Search/redux/searchActions'

export const setItemModalTypeSuccess = createAction(collectionsTypes.SET_ITEM_MODAL_TYPE)
export const closeItemModal = createAction(collectionsTypes.CLOSE_ITEM_MODAL)
export const setCollectionForm = createAction(collectionsTypes.SET_FORM)
export const itemModalClosed = createAction(collectionsTypes.ITEM_MODAL_CLOSED)
export const setMcSort = createAction(collectionsTypes.SET_MC_SORT)
export const setItemModalTab = createAction(collectionsTypes.SET_ITEM_MODAL_TAB)

export const setItemModalType = type => (dispatch, getState) => {
  const { auth: { user: { roles } } } = getState()
  try {
    if (type === 'createCollection' && !roles.length) {
      toast.error(`You must log in to create a new collection.`)
      throw new Error('Unauthenticated client attempted to create a collection')
    }
    dispatch(setItemModalTypeSuccess(type))
  } catch (error) {  }
}

const createCollectionBegin = createAction(collectionsTypes.CREATE_COLLECTION_BEGIN)
const createCollectionSuccess = createAction(collectionsTypes.CREATE_COLLECTION_SUCCESS)
const createCollectionFail = createAction(collectionsTypes.CREATE_COLLECTION_FAIL)

export const createCollection = () => async (dispatch, getState) => {
  dispatch(createCollectionBegin())
  try {
    const { auth: { user }, collections: { collectionForm, meta: {myCollectionsSort} } } = getState()
    const res = await collectionService.createCollection(collectionForm, user)
    await itemService.getUserCollections(user, true, 0, myCollectionsSort)
    if (res) {
      dispatch(createCollectionSuccess(res))
      toast.success(`New Collection Created`)
    }
  } catch (error) {
    console.log('create collection error: ', error)
    dispatch(createCollectionFail())
    toast.error(`Failed to Add Collection`)
  }
}

const fetchUserItemsBegin = createAction(collectionsTypes.GET_USER_ITEMS_BEGIN)
const fetchUserItemsSuccess = createAction(collectionsTypes.GET_USER_ITEMS_SUCCESS)
const fetchUserItemsFail = createAction(collectionsTypes.GET_USER_ITEMS_FAIL)

export const loadUserItems = (loadOptions = [], page = null) => async (dispatch, getState) => {
  dispatch(fetchUserItemsBegin())
  try {
    const {
      auth: { user },
      collections: { meta: { myCollectionsSort }, userCollections },
      shared: { langcode }
    } = getState()
    if (!user) throw new Error('User not found.')
    // local state
    let items, collections, collectionCount, contribCollections

    // get contributor collections list (3/4 cases will need this)
    let contribCollectionIds = await collectionService.getContributorCollectionsForUser(user.id)

    // local state setters
    function setCollectionCount(count) { collectionCount = collectionCount ? collectionCount + count : collectionCount }
    function setCollections(array) { collections = Array.isArray(collections) ? [ ...collections, ...array ] : array }

    // runs appropriate action
    async function runOption(option) {
      let res
      switch(option) {
        case USER_ITEMS:
          res = await itemService.getUserItems(user.id)
          items = res[0]
          break;
        case USER_COLLECTION_COUNT:
          // collections as owner
          res = await itemService.getUserCollectionCount(user.id)
          if (res?.groupQuery?.totalCollections) setCollectionCount(parseInt(res.groupQuery.totalCollections))
          if (contribCollectionIds?.length) setCollectionCount(contribCollectionIds.length)
          break;
        case USER_COLLECTIONS:
          const langField = langcode === JA ? 'field_japanese_title' : 'field_english_title'
          // get collections
          if (Array.isArray(contribCollectionIds)) contribCollections = await getCollectionByIdGroupsRaw(contribCollectionIds)
          res = await itemService.getUserCollections(user, false, page, myCollectionsSort, langField)

          // set collections
          if (Array.isArray(res?.collections)) setCollections(res.collections)
          if (page > 0) collections = [ ...userCollections, ...collections ] // contribCollections already in collections
          else if (contribCollections) setCollections(contribCollections) // only set contribCollections on page 0

          // set collections length
          if (page === null) { // initial fetch
            if (collections?.length) setCollectionCount(collections.length)
            if (Array.isArray(contribCollectionIds)) setCollectionCount(contribCollectionIds.length)
          } else { // use total if exists
            if (res?.totalCollections) setCollectionCount(res.totalCollections) // res only shows collections user owns
            if (Array.isArray(contribCollectionIds)) setCollectionCount(contribCollectionIds.length)
          }
          break;
        case USER_COLLECTIONS_WITH_ITEMS:
          // get collections
          if (Array.isArray(contribCollectionIds)) contribCollections = await getCollectionByIdGroupsRaw(contribCollectionIds, true)
          res = await itemService.getUserCollections(user, true, page, myCollectionsSort)

          // set collections
          if (Array.isArray(res?.collections)){ setCollections(res.collections)}
          if (page > 0) collections = [ ...userCollections, ...collections ] // contribCollections already in collections
          else if (contribCollections) setCollections(contribCollections) // only set contribCollections on page 0

          // set collections length
          if (page === null) { // initial fetch
            if (collections?.length) setCollectionCount(collections.length)
            if (Array.isArray(contribCollectionIds)) setCollectionCount(contribCollectionIds.length)
          } else { // use total if exists
            if (res?.totalCollections) setCollectionCount(res.totalCollections) // res only shows collections user owns
            if (Array.isArray(contribCollectionIds)) setCollectionCount(contribCollectionIds.length)
          }
          break;
        default:
          break;
      }
    }

    // run loop of all options
    await Promise.all(loadOptions.map(async opt => await runOption(opt)))
    dispatch(fetchUserItemsSuccess({ items, collections, collectionCount }))
  } catch (error) {
    console.log(error)
    dispatch(fetchUserItemsFail())
  }
}

const deleteCollectionBegin = createAction(collectionsTypes.DELETE_COLLECTION_BEGIN)
const deleteCollectionSuccess = createAction(collectionsTypes.DELETE_COLLECTION_SUCCESS)
const deleteCollectionFail = createAction(collectionsTypes.DELETE_COLLECTION_FAIL)

export const deleteCollection = id => async dispatch => {
  dispatch(deleteCollectionBegin())
  try {
    await collectionService.deleteCollection(id)
    dispatch(deleteCollectionSuccess(id))
  } catch (error) {
    dispatch(deleteCollectionFail())
  }
}

const getItemModalDataBegin = createAction(collectionsTypes.GET_ITEM_DATA_BEGIN)
const getItemModalDataSuccess = createAction(collectionsTypes.GET_ITEM_DATA_SUCCESS)
const getItemModalDataFail = createAction(collectionsTypes.GET_ITEM_DATA_FAIL)

export const getItemModalData = (id, type, updateList = false) => async (dispatch, getState) => {
  dispatch(getItemModalDataBegin())
  dispatch(setLoadingState(true))
  try {
    let data
    switch (type) {
      case COLLECTION:
        data = await itemService.getCollectionItemById(id)
        break;
      case MY_COLLECTIONS_ITEM:
      case ITEM:
        data = await itemService.getNodeItemById(id)
        break;
      case TEXT_SLIDE:
        data = await collectionService.getTextSlide(id)
        break;
      default:
        break;
    }
    if (!data) throw new Error(`Error retrieving data for ${type} ${id}`)
    if (updateList && type == ITEM) {
      const searchResult = getState()?.search;
      let items = [...(searchResult?.items || [])]
      items = items.map(item => item.nid == data.nid ? {...item, ...data} : item)
      await dispatch(searchSuccess({...searchResult, items}))
    }
    dispatch(getItemModalDataSuccess(data))
    dispatch(setLoadingState(false))
  } catch (error) {
    console.log('getItemModalData error', error)
    dispatch(getItemModalDataFail())
    dispatch(setLoadingState(false))
  }
}

const getCollectionDataBegin = createAction(collectionsTypes.GET_COLLECTION_DATA_BEGIN)
const getCollectionDataSuccess = createAction(collectionsTypes.GET_COLLECTION_DATA_SUCCESS)
const getCollectionDataFail = createAction(collectionsTypes.GET_COLLECTION_DATA_FAIL)

export const getCollectionData = id => async dispatch => {
  dispatch(getCollectionDataBegin(id))
  dispatch(setLoadingState(true))
  try {
    const data = await itemService.getCollectionItemById(id)
    dispatch(getCollectionDataSuccess(data))
    dispatch(setLoadingState(false))
  } catch (error) {
    console.log('getCollectionData error', error)
    dispatch(getCollectionDataFail())
    dispatch(setLoadingState(false))
  }
}

const loadItemModalBegin = createAction(collectionsTypes.LOAD_ITEM_MODAL_BEGIN)

export const loadItemModal = ({ id, type }) => async (dispatch, getState)=> {
  dispatch(loadItemModalBegin())
  try {
    await dispatch(getItemModalData(id, type))
    const { auth: { user: { id: userId } }, collections: { selectedItemData }, shared: { langcode } } = getState()
    if (!selectedItemData || (selectedItemData.id !== id)) {
      toast.error(`Error fetching data for item ${id}`)
      throw new Error('Item modal data not loaded')
    }
    let activeLang = langcode
    // if no title/description exists for the current site langcode, default to the other language instead
    if (!selectedItemData.title[langcode] && !selectedItemData.description[langcode]) activeLang = langcode === EN ? JA : EN
    dispatch(setItemModalTab(activeLang))
    if ((type === 'item' || type === TEXT_SLIDE || type === 'myCollectionsItem') && userId) {
      await dispatch(loadUserItems([USER_COLLECTIONS_WITH_ITEMS]))
    }
    dispatch(setItemModalType(type))
  } catch (error) { 
    console.log(error)
  }
}

const editCollectionBegin = createAction(collectionsTypes.EDIT_COLLECTION_BEGIN)
const editCollectionSuccess = createAction(collectionsTypes.EDIT_COLLECTION_SUCCESS)
const editCollectionFail = createAction(collectionsTypes.EDIT_COLLECTION_FAIL)

export const editCollection = (updateOrder) => async (dispatch, getState) => {
  dispatch(editCollectionBegin())
  try {
    const { auth: { user }, collections: { collectionForm, meta: {myCollectionsSort} }, shared: { langcode} } = getState()
    const res = await collectionService.editCollection(collectionForm, langcode)
    await itemService.getUserCollections(user, true, 0, myCollectionsSort)
    dispatch(editCollectionSuccess(res))
    toast.success('Collection Updated.')
  } catch (error) {
    dispatch(editCollectionFail())
    toast.error('Failed to Update Collection')
  }
}

export const reoderTopics = (topicId, newIndex) => async (dispatch, getState) => {
  dispatch(editCollectionBegin())
  try {
    let { collections: { selectedCollectionData: { id, fieldCollectionTopic, published } } } = getState()
    let data = { id, published }
    let currentIndex = fieldCollectionTopic.findIndex(({ entity }) => entity.id === topicId)
    let topics = fieldCollectionTopic.map(topic => topic)
    const temp = topics[newIndex]
    topics[newIndex] = topics[currentIndex]
    topics[currentIndex] = temp
    let topicsWeight = topics.map(({ entity }) => entity.id)

    data = { ...data, topicsWeight }

    let res = await collectionService.editCollection(data)

    res.field_collection_topic = topics

    dispatch(editCollectionSuccess(res))
    // toast.success('Collection Updated.')
  } catch (error) {
    dispatch(editCollectionFail())
    toast.error('Failed to Update Collection')
  }
}

const addCollectionItemBegin = createAction(collectionsTypes.ADD_COLLECTION_ITEM_BEGIN)
const addCollectionItemSuccess = createAction(collectionsTypes.ADD_COLLECTION_ITEM_SUCCESS)
const addCollectionItemFail = createAction(collectionsTypes.ADD_COLLECTION_ITEM_FAIL)

export const addCollectionItem = (collection, item) => async (dispatch, getState) => {
  dispatch(addCollectionItemBegin())
  dispatch(setLoadingState(true))
  try {
    if (!collection || !item) throw new Error('Collection and item must be provided')
    const { auth: { user } } = getState()
    // builds an array of items to make requests from a combination of multiple or single collections/items 
    const requestPromiseConfigs = (collection.length ? collection : [collection]).reduce((requestConfigs, nextCol) => {
      const lastSortValue = (nextCol.items && nextCol.items.length) || 1
      const configs = (item.length ? item : [item]).filter(it => {
        // check if collection does not already have an item to avoid a 422 on post
        return !nextCol.items.find(colItem => colItem.itemData.id === it.id)
      }).map((it, index) => {
        return ({
          col: nextCol,
          requestItem: { ...it, sortValue: lastSortValue + index }
        })
      })
      return [...requestConfigs, ...configs]
    }, [])
    const results = await collectionService.bulkCreateCollectionItem(user, requestPromiseConfigs)
    const updates = results.reduce((acc, nextRes) => {
      if (nextRes.id) {
        const colIndex = acc.findIndex(c => c.id === nextRes.collectionId)
        if (colIndex >= 0) {
          acc[colIndex].changes.push(nextRes)
        } else {
          acc.push({
            id: nextRes.collectionId,
            changes: [nextRes]
          })
        }
      }
      return acc
    }, [])
    if (updates && updates.length) {
      dispatch(addCollectionItemSuccess(updates))
      if (collection.length && !item.length) {
        toast.success('Item added to collections.')
      } 
      if (collection.length && item.length) {
        toast.success('Contributions successfully added to collections')
      } 
      dispatch(setLoadingState(false))
    } else {
      throw new Error('bulkCreateCollectionItem failed')
    }
  } catch (error) {
    console.log(error)
    if (collection.length && !item.length) {
      toast.error('Failed to add item to collections.')
    }
    dispatch(setLoadingState(false))
    dispatch(addCollectionItemFail())
  }
}

const removeCollectionItemBegin = createAction(collectionsTypes.REMOVE_COLLECTION_ITEM_BEGIN)
const removeCollectionItemSuccess = createAction(collectionsTypes.REMOVE_COLLECTION_ITEM_SUCCESS)
const removeCollectionItemFail = createAction(collectionsTypes.REMOVE_COLLECTION_ITEM_FAIL)

export const removeCollectionItem = (id, collectionId) => async dispatch => {
  dispatch(setLoadingState(true))
  dispatch(removeCollectionItemBegin())
  try {
    await collectionService.deleteCollectionItem(id, collectionId)
    dispatch(removeCollectionItemSuccess(id))
    dispatch(setLoadingState(false))
    toast.success('Item successfully removed')
  } catch (error) {
    dispatch(removeCollectionItemFail())
    dispatch(setLoadingState(false))
    toast.error('')
  }
}

const updateCollectionItemBegin = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_BEGIN)
const updateCollectionItemSuccess = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_SUCCESS)
const updateCollectionItemFail = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_FAIL)

export const updateCollectionItem = (item, collectionId, updateType) => async dispatch => {
  dispatch(updateCollectionItemBegin())
  dispatch(setLoadingState(true))
  try {
    await collectionService.editCollectionItem(item, collectionId)
    dispatch(updateCollectionItemSuccess(item))
    if (updateType === 'notes') {
      toast.success('Notes Updated.')
    }
    dispatch(setLoadingState(false))
  } catch (error) {
    dispatch(updateCollectionItemFail())
    dispatch(setLoadingState(false))
    if (updateType === 'notes') {
      toast.error('Failed to Update Notes.')
    }
  }
}

const updateCollectionItemSortBegin = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_SORT_BEGIN)
const updateCollectionItemSortSuccess = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_SORT_SUCCESS)
const updateCollectionItemSortFail = createAction(collectionsTypes.UPDATE_COLLECTION_ITEM_SORT_FAIL)

export const updateCollectionItemSort = (newList, inTopic) => async (dispatch, getState) => {
  dispatch(updateCollectionItemSortBegin())
  try {
    let topicId = null
    let otherItems = []
    const { auth: { user }, collections: { selectedCollectionData: { id: colId, items } } } = getState()
    const sort_field = inTopic ? 'field_topic_weight':'field_sort_order'
    const sortField = inTopic ? 'fieldTopicWeight':'fieldSortOrder'
    const payloadConfigs = newList.map((item, index) => {
      topicId = item.fieldCollectionTopic && item.fieldCollectionTopic.entity && item.fieldCollectionTopic.entity.id
      return {
        ...item,
        sortValue: index + 1,
        [sortField]: index + 1,
        [sort_field]: index + 1
      }
    })

    await collectionService.bulkEditCollectionItem(payloadConfigs, colId, user, inTopic)

    if(inTopic) {
      otherItems = items.filter(({ fieldCollectionTopic: topic }) => {
        if(topicId) return !topic || !topic.entity || !topic.entity.id || topic.entity.id !== topicId
        return topic && topic.entity && topic.entity.id
      })
    } else {
      otherItems = items.filter(({ type }) => {
        return type === TEXT_SLIDE
      })
    }
    const payload = [...otherItems, ...payloadConfigs]
    dispatch(updateCollectionItemSortSuccess(payload))
  } catch (error) {
    dispatch(updateCollectionItemSortFail())
  }
}

export const addCollectionToUserCollection = (collectionToAddId, userCollectionId) => async (dispatch, getState) => {
  try {
    const { collections: { userCollections } } = getState()
    if (!userCollections || !userCollections.length) throw new Error('No user collections found')
    const selectedUserCollection = userCollections.find(c => c.id === userCollectionId)
    if (!selectedUserCollection) throw new Error(`User collection with id: ${userCollectionId} not found`)
    const collectionRes = await itemService.getCollectionItemById(collectionToAddId)
    if (!collectionRes) throw new Error(`Error retrieving collection ${collectionToAddId}`)
    if (collectionRes.items.length) {
      const itemsToAdd = collectionRes.items.map(it => it.itemData)
      dispatch(addCollectionItem(selectedUserCollection, itemsToAdd))
      toast.success('Collection Items Added')
    }
  } catch (error) {
    console.error(error)
    toast.error('Failed to Add Collection')
  }
}

export const addItemToUserCollection = (item, userCollectionId) => async (dispatch, getState) => {
  try {
    const { collections: { userCollections } } = getState()
    if (!userCollections || !userCollections.length) throw new Error('No user collections found')
    const selectedUserCollection = userCollections.find(c => c.id === userCollectionId)
    if (!selectedUserCollection) throw new Error(`User collection with id: ${userCollectionId} not found`)
    if (!selectedUserCollection.items.find(it => it.itemData.id === item.id)) {
      dispatch(addCollectionItem(selectedUserCollection, item))
      toast.success('Item added')
    } else {
      const titleEN = item.title?.en;
      const titleJA = item.title?.ja;
      const language = i18n.language;
      let titleText = language == 'en' ? titleEN : titleJA;
      titleText = titleText ? titleText : titleEN ? titleEN : titleJA;
      toast.error(`is already a part of this collection.`, {transParams: {'0': titleText}})
    }
  } catch (error) {
    toast.error('Failed to add item.')
  }
}

const addTextSlideToTopicBegin = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_TOPIC_BEGIN)
const addTextSlideToTopicSuccess = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_TOPIC_SUCCESS)
const addTextSlideToTopicFail = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_TOPIC_FAIL)

export const addTextSlideToTopic = formData => async (dispatch, getState) => {
  dispatch(addTextSlideToTopicBegin())
  try {
    const { collections: { selectedCollectionData }, auth: { user: { csrfToken } } } = getState()
    let res

    if (formData && selectedCollectionData) {
      if (!formData.title.en && !formData.title.ja) {
        toast.error('Text slides require a title.')
        const err = new Error('Text slide must contain at least one title.')
        err.formData = formData
        throw(err)
      }
      res = await collectionService.createAddAssignTextSlide(formData, selectedCollectionData.id, csrfToken)
      if (res) {
        dispatch(addTextSlideToTopicSuccess(res))
        toast.success(`Text slide added`)
        return true
      }
    }
  } catch (error) {
    dispatch(addTextSlideToTopicFail())
    toast.error('Failed to add text slide')
    return false
  }
}

const editTextSlideBegin = createAction(collectionsTypes.EDIT_TEXT_SLIDE_BEGIN)
const editTextSlideSuccess = createAction(collectionsTypes.EDIT_TEXT_SLIDE_SUCCESS)
const editTextSlideFail = createAction(collectionsTypes.EDIT_TEXT_SLIDE_FAIL)

export const editTextSlide = formData => async dispatch => {
  dispatch(editTextSlideBegin())
  try {
    if (!formData.title.en && !formData.title.ja) {
      toast.error('Text slides require a title.')
      const err = new Error('Text slide must contain at least one title.')
      err.formData = formData
      throw (err)
    }
    const res = await collectionService.editTextSlide(formData)
    dispatch(editTextSlideSuccess(res))
    return true
  } catch (error) {
    dispatch(editTextSlideFail())
    return false
  }
}

const addTextSlideToCollectionsBegin = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_COLLECTIONS_BEGIN)
const addTextSlideToCollectionsSuccess = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_COLLECTIONS_SUCCESS)
const addTextSlideToCollectionsFail = createAction(collectionsTypes.ADD_TEXT_SLIDE_TO_COLLECTIONS_FAIL)

export const addTextSlideToCollections = (collections, textSlide) => async (dispatch, getState)=> {
  dispatch(addTextSlideToCollectionsBegin())
  try {
    const { auth: { user: { csrfToken } } } = getState()
    const collectionIds = collections.map(v => v.id)
    await collectionService.addExistingTextSlideToCollections(textSlide.id, collectionIds, csrfToken)
    toast.success(`Added text slide to collections`)
    dispatch(addTextSlideToCollectionsSuccess({ collectionIds, textSlideId: textSlide.id }))
  } catch (error) {
    dispatch(addTextSlideToCollectionsFail())
  }
}

const editTopicBegin = createAction(collectionsTypes.ADD_UPDATE_TOPIC_BEGIN)
const addUpdateTopicSuccess = createAction(collectionsTypes.ADD_UPDATE_TOPIC_SUCCESS)
const editTopicFail = createAction(collectionsTypes.ADD_UPDATE_TOPIC_FAIL)

export const addUpdateTopic = (formData, topicId, position) => async (dispatch, getState) => {
  dispatch(editTopicBegin())
  try {
    const { collections: { selectedCollectionData }, auth: { user: { csrfToken } } } = getState()
    let res

    if (formData && selectedCollectionData) {
      if (!formData.topic_title.en && !formData.topic_title.ja) {
        toast.error('Topic requires a topic title.')
        const err = new Error('Topic must contain at least one title.')
        err.formData = formData
        throw(err)
      }

      if(topicId) {
        res = await collectionService.updateTopic(formData, topicId, csrfToken)
        toast.success(`Topic Updated`)
      } else {
        let topicsIds = selectedCollectionData.fieldCollectionTopic.map(topic => topic.entity.id)
        topicsIds.splice(position, 0, 0)
        res = await collectionService.createAddAssignTopic(formData, topicsIds, selectedCollectionData.id, csrfToken)
        toast.success(`New Topic Created`)
      }

      dispatch(addUpdateTopicSuccess(res))
      return true
    }
  } catch (error) {
    dispatch(editTopicFail())
    return false
  }
}

const moveItemSuccess = createAction(collectionsTypes.MOVE_COLLECTION_ITEM_SUCCESS)

export const moveItemToTopic = (item, topic, group, weight = null) => async (dispatch, getState) => {
  dispatch(editTopicBegin())
  const { collections: { selectedCollectionData: { items } } } = getState()
  const oldItems = items.map(x => x)
  try {
    let moveTo = topic === '' ? null : topic
    dispatch(moveItemSuccess({ id: item, topic }))
    await collectionService.moveItemToTopic(item, moveTo, group, weight)

    return true

  } catch (error) {
    toast.error('Could not move item')
    // Return to old items
    dispatch(updateCollectionItemSortSuccess(oldItems))
    return false
  }
}

const deleteTopicSuccess = createAction(collectionsTypes.DELETE_TOPIC_SUCCESS)

export const deleteTopic = (id) => async (dispatch) => {
  dispatch(editTopicBegin())
  try {
    await collectionService.deleteTopic(id)
    dispatch(deleteTopicSuccess(id))
    toast.success(`Topic has been deleted`)
    return true

  } catch (error) {
    dispatch(editTopicFail())
    toast.error(`Topic dould not be deleted`)
    return false
  }
}

// CRUD - Membership / Collaborators in Collections
const inviteUserToCollectionSuccess = createAction(collectionsTypes.INVITE_USER_SUCCESS)
export const inviteUserToCollection = (groupId, email, userId) => async (dispatch) => {
  try {
    const response = await collectionService.inviteUser(groupId, email, userId)
    if (!response) throw new Error('User already exists in group.')
    dispatch(inviteUserToCollectionSuccess(response))
    toast.success(`Added Item to collection.`, { transParams: {'0': email}})
    return true
  } catch (e) {
    if (e instanceof Error) {
      console.log('inviteUserToCollection failed', e)
      toast.error(e?.message || 'Cannot invite user to collection.')
    }
    return false
  }
}

const deleteUserFromCollectionSuccess = createAction(collectionsTypes.DELETE_USER_SUCCESS)
export const deleteUserFromCollection = (groupId, entityId, nameForMessage = '') => async (dispatch) => {
  try {
    const response = await collectionService.deleteUser(groupId, entityId)
    if (response.message) throw new Error(response.message)
    dispatch(deleteUserFromCollectionSuccess(entityId))
    toast.success(`Removed ${nameForMessage} from collection.`)
    return true
  } catch (e) {
    if (e instanceof Error) {
      console.log('deleteUserToCollection failed', e)
      toast.error('Cannot delete user from collection.')
    }
    return false
  }
}

const getAllUsersFromCollectionSuccess = createAction(collectionsTypes.GET_ALL_USERS_SUCCESS)
export const getAllUsersFromCollection = (groupId) => async (dispatch) => {
  try {
    const response = await itemService.getAllMembersOfCollection(groupId)
    dispatch(getAllUsersFromCollectionSuccess(response))
    return true
  } catch (e) {
    if (e instanceof Error) {
      console.log('getAllUsersFromCollection failed', e)
      toast.error('Cannot retrieve list of collection users.')
    }
    return false
  }
}