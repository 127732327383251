import React from 'react'
import DateInput from '../DateInput'
import FileInput from '../FileInput'
import FormSelect from '../FormSelect'
import TextInput from '../TextInput'
import ReactCKEditor from './CKEditor'

const SmartInput = ({ type, ...rest }) => {
  let input
  if (type === 'textarea') {
    input = <ReactCKEditor {...rest} />
  }else if (type === 'text' || type === 'password') {
    input = <TextInput type={type} {...rest} />
  } else if (type === 'date') {
    input = <DateInput {...rest} />
  } else if (type === 'select') {
    input = <FormSelect {...rest} />
  } else if (type === 'file') {
    input = <FileInput {...rest} />
  } else {
    return null
  }
  return (
    <div className='c-form__item'>
      {input}
    </div>
  )
}

export default SmartInput
