import { createReducer } from '@reduxjs/toolkit'
import accountFormTypes from './accountFormTypes'
import authTypes from 'features/authentication/redux/authenticatonTypes'
import { LOGIN } from 'shared/constants'

const defaultState = {
  data: { 
    name: '',
    mail: '',
    password: '',
    newPass: '',
    newPassConfirm: '',
    displayName: '',
    accountName: '',
    roles: [],
    language: '',
    picture: {}
  },
  username_duplicated: false,
  email_duplicated: false,
  meta: { submitting: false, checkingDuplicate: false, checkingDuplicateEmail: false },
  modalType: null,
  modalOpen: false,
  errors: {}
}

export default createReducer(defaultState, {
  [accountFormTypes.SET_DATA]: (state, action) => {
    state.data = {
      ...state.data,
      ...action.payload
    }
  },
  [accountFormTypes.CHECK_USERNAME_BEGIN]: state => {
    state.meta.checkingDuplicate = true;
    state.username_duplicated = false;
  },
  [accountFormTypes.CHECK_USERNAME_SUCCESS]: (state, action) => {
    if (state.meta.checkingDuplicate) {
      state.meta.checkingDuplicate = false;
      state.username_duplicated = action.payload
    }
  },
  [accountFormTypes.CHECK_USERNAME_FAIL]: state => {
    state.meta.checkingDuplicate = false;
    state.username_duplicated = false;
  },
  [accountFormTypes.CHECK_EMAIL_BEGIN]: state => {
    state.meta.checkingDuplicateEmail = true;
    state.email_duplicated = false;
  },
  [accountFormTypes.CHECK_EMAIL_SUCCESS]: (state, action) => {
    if (state.meta.checkingDuplicateEmail) {
      state.meta.checkingDuplicateEmail = false;
      state.email_duplicated = action.payload
    }
  },
  [accountFormTypes.CHECK_EMAIL_FAIL]: state => {
    state.meta.checkingDuplicateEmail = false;
    state.email_duplicated = false;
  },
  [accountFormTypes.CLEAR_DATA]: state => {
    state.data = defaultState.data
  },
  [accountFormTypes.SET_MODAL_TYPE]: (state, action) => {
    state.username_duplicated = false
    state.email_duplicated = false
    state.modalType = action.payload
  },
  [accountFormTypes.OPEN_MODAL]: (state, action) => {
    state.modalType = action.payload
    state.username_duplicated = false
    state.email_duplicated = false
    state.modalOpen = true
  },
  [accountFormTypes.CLOSE_MODAL]: (state, action) => {
    state.username_duplicated = false
    state.modalOpen = false
    state.email_duplicated = false
  },
  [accountFormTypes.SUBMIT_FORM_SUCCESS]: (state, action) => {
    state.data = defaultState.data
    state.modalOpen = false
  },
  [accountFormTypes.SUBMIT_FORM_FAIL]: (state, action) => {
    state.errors = { ...state.errors, ...action.payload }
  },
  [accountFormTypes.TOGGLE_ROLE]: (state, action) => {
    const role = action.payload
    if (state.data.roles.includes(role)) {
      state.data.roles = state.data.roles.filter(r => r !== role)
    } else {
      state.data.roles.push(role)
    }
  },
  [authTypes.LOGIN_SUCCESS]: (state) => {
    state.modalType = null
  },
  [accountFormTypes.SET_ERRORS]: (state, action) => {
    state.errors = action.payload
  },
  [accountFormTypes.RESET_PASSWORD_FIELD]: state => {
    state.data.password = ''
  },
  [accountFormTypes.UPDATE_ACCOUNT_BEGIN]: state => {
    state.meta.submitting = true
  },
  [accountFormTypes.UPDATE_ACCOUNT_SUCCESS]: state => {
    state.password = ''
    state.meta.submitting = false
  },
  [accountFormTypes.UPDATE_ACCOUNT_FAIL]: state => {
    state.password = ''
    state.meta.submitting = false
  },
  [authTypes.REQUEST_SIGN_IN]: state => {
    state.modalType = LOGIN
  }
})
