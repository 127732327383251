import { createSelector } from "@reduxjs/toolkit"
import { ADMINISTRATOR, EN } from "shared/constants"
import { selectLangcodeFlag } from "shared/redux/sharedSelectors"

export const selectUser = state => state.auth.user
export const selectUserRoles = state => state.auth.user.roles
export const selectAlertBanner = state => state.auth.alertBanner

export const selectUserLoadStatus = createSelector(
  [selectUser],
  (user) => {
    return user && user.roles.length
  }
)

export const selectUserIsAdmin = createSelector(
  [selectUserRoles],
  (roles) => {
    return roles.includes(ADMINISTRATOR)
  }
)

export const selectUserLangcode = createSelector(
  [selectUser],
  (user) => {
    return user ? user.preferredLangcode : EN
  }
)

export const selectAlertContent = createSelector(
  [selectAlertBanner, selectLangcodeFlag],
  (alertBanner, lang) => {
    if (alertBanner && alertBanner.id) {
      const { content, viewed } = alertBanner[lang]
      if (content && !viewed) {
        return content
      }
    }
    return null
  }
)

export const selectShowIcon = createSelector(
  [selectAlertBanner],
  (alertBanner) => {
    if (alertBanner && alertBanner.id) {
      return alertBanner.showIcon
    }
    return false
  }
)
