import toast from "shared/utils/toast"
import { AUDIO, DOCUMENT, IMAGE, TESTIMONIAL, TWEET, VIDEO, WEBSITE } from "shared/constants"

export default (form, index, isWebsiteType = false) => {
  const { title, date } = form
  let errorMsg = []
  if (title.en.length == 0 && title.ja.length == 0) errorMsg.push({key: isWebsiteType ? 'Webiste Title is required.': 'Upload Title is required.', data: {'0': index + 1}})
  if (!date) errorMsg.push({key: isWebsiteType ? 'Website Publish Date is required.': 'Upload Publish Date is required.', data: {'0': index + 1}})
  if (errorMsg.length) {
    errorMsg.forEach(e => {
      toast.error(e.key, {transParams: e.data})
    })
    return false
  }
  return true
}
