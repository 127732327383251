import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { persistConfig } from './persist'
import { reduxMiddleware } from './middleware'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import shared from 'shared/redux/sharedReducer'
import accountForm from 'features/AccountForm/redux/accountFormReducer'
import auth from 'features/authentication/redux/authenticationReducer'
import search from 'features/Search/redux/searchReducer'
import contribute from 'features/Contribute/redux/contributeReducer'
import basicPage from 'features/BasicPage/redux/basicPageReducer'
import collections from 'features/Collections/redux/collectionsReducer'

const createRootReducer = (history) => (
  combineReducers({
    router: connectRouter(history),
    accountForm,
    auth,
    search,
    contribute,
    basicPage,
    collections,
    shared
  })
)

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureAppStore = (preloadedState = {}) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: [...reduxMiddleware, routerMiddleware(history)]
  })
  return store
}

const store = configureAppStore()
export const persistor = persistStore(store)

export default store
