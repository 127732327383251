import React, { useState, useEffect} from 'react'
import { useSelector} from 'react-redux'
import { selectLoadingState } from 'shared/redux/sharedSelectors'

const Loader = () => {

  const [value, setValue] = useState(0)
  const [visible, setVisibility] = useState(true)
  const loading = useSelector(selectLoadingState)

  useEffect(() => {
    setVisibility(true)
    if (loading) {
      setTimeout(() => {
        setValue(100)
      }, 100)
    } else {
      setTimeout(() => {
        setValue(0)
        setVisibility(false)
      }, 1500)
    }


  }, [setVisibility, loading])

  return (
    <div className="c-progress" style={{width: `${value}%`, display: visible ? 'block':'none'}}>
        <div className="c-progress__bar"/>
    </div>
  )
}

export default Loader