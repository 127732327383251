import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectItemData, selectIsUserCollectionDataOwner } from 'features/Collections/redux/collectionsSelectors'
import { LanguageTabber } from 'shared/components'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import TextSlideForm from 'features/Collections/components/InlineCollectionForm/components/TextSlideForm'
import { editTextSlide } from 'features/Collections/redux/collectionsActions'
import { debounce } from 'utils/functions'
import { useTranslation } from 'react-i18next'
import { parseHtmlToReact } from 'shared/utils/tools/parse'

const TextSlideFlyout = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const siteLangcode = useSelector(selectLangcodeFlag)
  const isOwner = useSelector(selectIsUserCollectionDataOwner)
  const data = useSelector(selectItemData)
  const { title, description, owner, id } = data
  const [activeTab, setActiveTab] = useState(siteLangcode)
  const [form, setForm] = useState({})
  const [edit, setEdit] = useState(false)
  const onEdit = useCallback(() => {
    setEdit(true)
    setForm({
      title,
      description,
      id
    })
  }, [setEdit, setForm, title, description, id])
  const onSave = useCallback(debounce(async () => {
    const result = await dispatch(editTextSlide(form))
    if (result) setEdit(false)
  }, 500, true), [dispatch, form, setEdit])

  return (
    <LanguageTabber
      className='c-contribution-item'
      contentClassName='c-contribution-item__content'
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {edit ? (
        <div className='u-p32'>
          <div className='c-heading c-heading--l4'>{t('Edit Text Slide')}</div>
          <TextSlideForm
            form={form}
            lang={activeTab}
            setForm={setForm}
            saveFn={onSave}
            deleteFn={() => setEdit(false)}
            deleteText={t('CANCEL')}
          />
        </div>
      ) : (
        <>
          <div className="u-pb u-pt32 u-pl32 u-pr32">
            <div className='u-flex u-jc-between'>
              <div className='c-heading c-heading--l4'>{title[activeTab]}</div>
              {isOwner && <button className='c-btn c-btn--bare' onClick={onEdit}>
                <div className='c-copy c-copy--bold c-copy--icon'>
                  <span className='c-copy__icon c-copy__icon--md'>
                    <i className='material-icons u-color-teal'>edit</i>
                    {t('Edit')}
                  </span>
                </div>
              </button>}
            </div>
            <div className='c-collection-item__subtitle c-copy c-copy--sm c-copy--italic c-copy--icon'>
              <span className='c-collection-item__subitem c-copy__icon'>
                <i className="material-icons">person</i>{owner}
              </span>
            </div>
          </div>
          <div className="u-pb32 u-pt u-ph32 c-contribution-item__scroll c-contribution-item__text-slide">
            {parseHtmlToReact(description[activeTab])}
          </div>
        </>
      )}
    </LanguageTabber>
  )
}

export default TextSlideFlyout
