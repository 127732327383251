export const colors = {
  white: '#fff',
  'grey-100': '#ebebeb',
  'grey-200': '#ccc',
  'grey-300': '#9ea9b0',
  'grey-400': '#555',
  teal: '#416679',
  navy: '#11212c',
  black: '#000',
  orange: '#f47e23',
  red: '#c53e3e',
  green: '#4ea740'
}
