import React from 'react'
import JdaLink from '../JdaLink'

const ArrowLink = ({ url, text }) => (
  <JdaLink to={url} className='c-link c-link--icon'>
    <span className='c-search-container__learn'>{text}</span>
    <span className='c-link__icon c-link__icon--right u-color-orange'>
      <i className='material-icons'>chevron_right</i>
    </span>
  </JdaLink>
)

export default ArrowLink