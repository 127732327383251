import {
  WEBSITE,
  DOCUMENT,
  AUDIO,
  IMAGE,
  TESTIMONIAL,
  TWEET,
  VIDEO
} from 'shared/constants'

export const mediaOptions = [
  { value: WEBSITE, label: 'Website' },
  { value: DOCUMENT, label: 'Document' },
  { value: AUDIO, label: 'Audio' },
  { value: IMAGE, label: 'Image' },
  { value: TESTIMONIAL, label: 'Testimonial' },
  { value: TWEET, label: 'Tweet' },
  { value: VIDEO, label: 'Video' }
]

export const stepTwoConfigs = [
  {
    mediaType: WEBSITE,
    fields: [
      { type: 'date', name: 'date', label: 'Website Date', labelEx: '(Date webpage was created, as best you can determine.)', required: true },
      { type: 'text', name: 'uri', label: 'Url', placeholder: "http://www.website.com/", required: true }
    ]
  },
  {
    mediaType: DOCUMENT,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      {
        type: 'file',
        accept: '.doc,.docx,.pdf,.jpg,.jpeg,.gif,.png,.md',
        name: 'file',
        label: 'File'
      },
      {
        type: 'text',
        name: 'uri',
        label: 'File Url',
        placeholder: 'http://tech.mit.edu/v130/PDF/V130-N7.pdf'
      }
    ]
  },
  {
    mediaType: AUDIO,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      { type: 'text', name: 'uri', label: 'Url: Must use Soundcloud', placeholder: "https://soundcloud.com/nocopyrightsounds/lensko-cetus-ncs-release" }
    ]
  },
  {
    mediaType: IMAGE,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      { type: 'file', accept: 'image/*', name: 'file', label: 'Image' },
      { type: 'text', name: 'uri', label: 'Image Url' }
    ]
  },
  {
    mediaType: TESTIMONIAL,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      { type: 'file', accept: 'image/*', name: 'file', label: 'Image' },
      { type: 'text', name: 'author', label: 'Author' },
      { type: 'textarea', name: 'description', label: 'Testimonial' },
      { type: 'date', name: 'from', label: 'From:' },
      { type: 'date', name: 'to', label: 'To:' },
      { type: 'text', name: 'residence', label: 'Place of residence' },
      { type: 'text', name: 'occupation', label: 'Occupation' },
      { type: 'text', name: 'birthYear', label: 'Year of Birth' }
    ]
  },
  {
    mediaType: TWEET,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      { type: 'text', name: 'realName', label: 'Real Name' },
      { type: 'text', name: 'twitterUsername', placeholder: '@username', label: 'Twitter Username' },
      { type: 'text', name: 'uri', placeholder: 'https://twitter.com/hwtnv/status/723804589405949952', label: 'Url' }
    ]
  },
  {
    mediaType: VIDEO,
    fields: [
      { type: 'date', name: 'date', label: 'Original Publish Date' },
      { type: 'text', name: 'uri', placeholder: 'https://www.youtube.com/watch?v=HMRq1Z_WyTc', label: 'URL: Must use Youtube or Vimeo' }
    ]
  }
]

export const getMediaTypes = ({ url, type }) => {
  if (!url) {
    return WEBSITE
  }
  const vimeo = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g
  const youtube = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  if (vimeo.test(url) || youtube.test(url)) {
    return VIDEO
  }
  const types = [WEBSITE, DOCUMENT, AUDIO, IMAGE, TESTIMONIAL, TWEET, VIDEO]
  const mediaType = type && types.find(t => t.toLowerCase() === type.toLowerCase())
  if (mediaType) {
    return mediaType
  }
  const docTypes = ['doc', 'docx', 'pdf', 'md']
  const imgTypes = ['jpg', 'jpeg', 'gif', 'png']
  const urlParts = url.split('.')
  const urlType = urlParts[urlParts.length - 1]
  if (urlType && docTypes.includes(urlType.toLowerCase())) {
    return DOCUMENT
  }
  if (urlType && imgTypes.includes(urlType.toLowerCase())) {
    return IMAGE
  }
  if (url.includes('twitter')) {
    return TWEET
  }
  if (url.includes('soundcloud') || url.includes('audio')) {
    return AUDIO
  }
  return WEBSITE
}
