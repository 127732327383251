import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseHtmlToReact } from 'shared/utils/tools/parse'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { getAlertBanner, closeAlertBanner } from 'features/authentication/redux/authenticationAction'
import { selectAlertContent, selectShowIcon } from 'features/authentication/redux/authenticationSelectors'
import InfoIcon from './InfoIcon'
import CloseIcon from './CloseIcon'

const AlertBanner = () => {
  const dispatch = useDispatch()
  const alertContent = useSelector(selectAlertContent)
  const showIcon = useSelector(selectShowIcon)
  const lang = useSelector(selectLangcodeFlag)

  const handleCloseIcon = () => {
    dispatch(closeAlertBanner(lang))
  }

  useEffect(() => {
    dispatch(getAlertBanner());
  }, [dispatch]);

  if (!alertContent) {
    return null
  }

  return (
    <div className='c-alert-banner'>
      <div className='c-alert-banner__left'>
        <InfoIcon show={showIcon} />
        {parseHtmlToReact(alertContent)}
      </div>
      <CloseIcon onClick={handleCloseIcon} />
    </div>
  )
}

export default AlertBanner
