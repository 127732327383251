import {
  IMAGE,
  WEBSITE,
  DOCUMENT,
  TWEET,
  TESTIMONIAL,
  VIDEO,
  AUDIO,
  WEBSITE_MEDIA_ID,
  WEBSITE_LAYER_ID,
  AUDIO_MEDIA_ID,
  AUDIO_LAYER_ID,
  DOCUMENT_MEDIA_ID,
  TWEET_MEDIA_ID,
  TWEET_LAYER_ID,
  IMAGE_MEDIA_ID,
  IMAGE_LAYER_ID,
  VIDEO_MEDIA_ID,
  VIDEO_LAYER_ID,
  TESTIMONIAL_MEDIA_ID,
  TESTIMONIAL_LAYER_ID,
  YOUTUBE_REGEXP,
  SOUNDCLOUD_REGEXP,
  JA
} from "shared/constants"
import { getApiDate } from "shared/utils/tools/datetime"

export const buildCreateItemPayload = (formData, fid) => {
  const {
    user: { username, displayName },
    title: { en: enTitle, ja: jaTitle },
    description: { en: enDescription, ja: jaDescription },
    mediaType,
    date,
    tags,
    languages,
    uri,
    author,
    realName,
    twitterUsername,
    location: { latitude, longitude, address },
    from,
    to,
    residence,
    occupation,
    birthYear,
    submittedLangcode
  } = formData
  const langArr = Object.entries(languages).reduce((acc, [lang, isIncluded]) => {
    if (isIncluded) {
      acc.push({ value: `${lang[0].toUpperCase()}${lang.slice(1)}` })
    }
    return acc
  }, [])
  const archive = returnArchive(mediaType, uri)
  const [mediaTypeId, layerTypeId] = returnMediaLayerTypeIds(mediaType)
  let payload = {
    "type": [{
        "target_id": "item",
        "target_type": "content_type"
    }],
    "field_frequency": [{
        "value": "Once"
    }],
    "field_internet_archive_status": [{
        "value": "Not Submitted"
    }],
    "field_media_type": [{
        "target_id": mediaTypeId,
        "target_type": "taxonomy_term"
    }],
    "field_layer_type": [{
        "target_id": layerTypeId,
        "target_type": "taxonomy_term"
    }],
    "field_archive": [{ "value": archive }],
    "title": [{ "value": submittedLangcode === JA ? jaTitle : enTitle }],
    "field_japanese_title": [{ "value": jaTitle }],
    "field_japanese_description": [{
        "value": jaDescription ? `${jaDescription}` : "",
        "format": "basic_html"
    }],
    "field_english_title": [{ "value": enTitle }],
    "field_english_description": [{
        "value": enDescription ? `${enDescription}` : "",
        "format": "basic_html"
    }],
    "field_description": [{
        "value": `${submittedLangcode === JA ? jaDescription : enDescription}`,
        "format": "basic_html"
    }],
    "field_media_creator_realname": [{ "value": (author || realName || displayName || username) }],
    "field_media_creator_username": [{ "value": (twitterUsername || displayName || username) }],
    "field_media_date_create": [{ "value": getApiDate(date) }],
    "field_new_uri": [{ "value": uri }],
    "field_new_attribution_uri": [{ "value": uri }],
    "field_scope": [{ "value": "Page" }]
  }
  if (tags.length) {
    payload["field_tags"] = tags.map(tag => ({ "target_type": "taxonomy_term", "target_id": tag.tid  }))
  }
  if (langArr.length) payload["field_language"] = langArr
  if (to) payload["field_to"] = [{ "value": getApiDate(to) }]
  if (from) payload["field_from"] = [{ "value": getApiDate(from) }]
  if (residence) payload["field_place_of_residence"] = [{ "value": residence }]
  if (birthYear) payload["field_year_of_birth"] = [{ "value": birthYear }]
  if (occupation) payload["field_occupation"] = [{ "value": occupation }]
  if (latitude && longitude) {
    payload = {
      ...payload,
      "field_geolocation": [{
          "lat": latitude,
          "lng": longitude,
          "value": `${latitude},${longitude}`
      }],
      "field_location": [{ "value": `${latitude},${longitude}` }],
      "field_latitude": [{ "value": latitude }],
      "field_longitude": [{ "value": longitude }]    }
    if (address) payload["field_text_location"] = [{ "value": address }]
  }
  if (mediaType === IMAGE) {
    if (uri) payload["field_new_thumbnail_url"] = [{ "value": uri }]
  }
  if (fid) payload["field_file"] = [{ "target_id": fid }]
  return payload
}
const returnArchive = (mediaType, url) => {
  switch (mediaType) {
    case WEBSITE:
      return 'Seeds'
    case VIDEO:
      if (url && (url.toLowerCase().includes('vimeo.com') || url.match(YOUTUBE_REGEXP))) {
        return 'YouTube'
      } else {
        return 'Absolute'
      }
    case AUDIO:
      if (url && url.match(SOUNDCLOUD_REGEXP)) {
        return 'SoundCloud'
      } else {
        return 'Absolute'
      }
    case TESTIMONIAL:
      return 'Testimonial'
    case DOCUMENT:
    case IMAGE:
    case 'Headline':
    default: //others
      return 'Absolute'
  }
}

const returnMediaLayerTypeIds = mediaType => {
  switch (mediaType) {
    case AUDIO:
      return [AUDIO_MEDIA_ID, AUDIO_LAYER_ID]
    case DOCUMENT:
      return [DOCUMENT_MEDIA_ID, WEBSITE_LAYER_ID]
    case TWEET:
      return [TWEET_MEDIA_ID, TWEET_LAYER_ID]
    case TESTIMONIAL:
      return [TESTIMONIAL_MEDIA_ID, TESTIMONIAL_LAYER_ID]
    case IMAGE:
      return [IMAGE_MEDIA_ID, IMAGE_LAYER_ID]
    case VIDEO:
      return [VIDEO_MEDIA_ID, WEBSITE_LAYER_ID]
    case WEBSITE:
    default:
      return [WEBSITE_MEDIA_ID, WEBSITE_LAYER_ID]
  }
}

export const buildUpdateItemPayload = data => {
  if (!data) return null
  const { description, title, langcode, tags } = data
  let payload = {
    "type": [
      {
        "target_id": "item",
        "target_type": "node_type"
      }
    ]
  }
  if (description) {
    if (description.en) payload["field_english_description"] = [{ value: description.en }]
    if (description.ja) payload["field_japanese_description"] = [{ value: description.ja }]
    if (description[langcode]) payload["field_description"] = [
      {
        "value": description[langcode],
        "format": "basic_html"
      }
    ]
  }
  if (title) {
    if (title.en) payload["field_english_title"] = [{ value: title.en }]
    if (title.ja) payload["field_japanese_title"] = [{ value: title.ja }]
    if (title[langcode]) payload["title"] = [{ value: title[langcode] }]
  }
  if (tags) {
    payload["field_tags"] = tags.map(tag => ({ "target_type": "taxonomy_term", "target_id": tag.tid  }))
  }
  return payload
}
