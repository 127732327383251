import React from 'react'
import InputWrapper from '../InputWrapper'

const TextInput = ({ 
  name,
  caption,
  required,
  label,
  labelEx,
  labelClassname = '',
  type = 'text',
  error,
  hideInput,
  className = '',
  rows = 5,
  onBlur = null,
  duplicateUsername,
  duplicateChecking,
  signup,
  errorMessage,
  formatErrorMessage,
  ...rest
}) => {
  return (
   <InputWrapper
    name={name}
    caption={caption}
    required={required}
    label={label}
    labelClassname={labelClassname}
    labelEx={labelEx}
    errorMessage={errorMessage}
    formatErrorMessage={formatErrorMessage}
    duplicateUsername={signup && duplicateUsername}
    duplicateChecking={signup && duplicateChecking}
   >
      {type === 'textarea' ?
        <textarea
          name={name}
          className={`c-text-input ${error ? 'c-input-wrapper__error' : ''} ${className}`}
          rows={rows}
          {...rest}
        />
        :
        <input
          className={`c-text-input ${hideInput ? 'u-color-white' : ''} ${error || duplicateUsername ? 'c-input-wrapper__error' : ''} ${className}`}
          type={type}
          name={name}
          onBlur={onBlur || null}
          {...rest}
        />
      }
   </InputWrapper>
  )
}

export default TextInput
