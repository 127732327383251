import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import StyleGuide from './features/StyleGuide'
import BaseLayout from './BaseLayout'
class App extends React.Component {
  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/styleguide" component={StyleGuide} />
          <Route path={['/:langcode/*', '/:langcode', '/']} component={BaseLayout} />
        </Switch>
      </Router>
    )
  }
}

export default App
