import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GreyContributor} from '../asset/Icons_Icon-contributors-none.svg'

export default function ContributorIcon({
  onClick,
  disabled = false
}) {
  const { t } = useTranslation()
  
  return (
    <div className='c-contributor-icon__container'>
      <button 
        onClick={onClick} 
        className='c-contributor-icon' 
        disabled={disabled} 
        // react console will warn but this is legal, coming in react 18
        // https://github.com/facebook/react/issues/21035#issuecomment-913818467
        aria-description={t('view all contributors')}
      >
        <GreyContributor />
      </button>
    </div>
    
  )
}