import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { JdaLink } from 'shared/components'
import InputWrapper from '../InputWrapper'
import { selectTagSearching, selectTagSearchResults } from 'features/Contribute/redux/contributeSelectors'
import { searchTags } from 'features/Contribute/redux/contributeActions'
import { debounce } from 'utils/functions'
import NewTagBox from './subcomponents/NewTagBox'
import { selectUser } from 'features/authentication/redux/authenticationSelectors'

const TagManager = ({
  editable = false,
  disabled = false,
  disableDelete = false,
  name,
  caption,
  required,
  label,
  labelClassname = '',
  tags = [],
  addNewFn,
  removeFn,
  tabIndex,
  hideAdd,
  deleteFn,
  onTagLinkClick,
  applyToAll = false,
  applyToAllValue = false,
  onApplyToAllValueChanged,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const tagResults = useSelector(selectTagSearchResults)
  const user = useSelector(selectUser);
  const [newTag, setNewTag] = useState('')
  const [addMode, setAddmode] = useState(false)
  const searching = useSelector(selectTagSearching)
  const onTagSubmit = useCallback(value => {
    if (!searching) {
      addNewFn && addNewFn(value)
      setNewTag('')
      setAddmode(false)
    }
  }, [addNewFn, setAddmode, searching])
  const onChange = useCallback(value => { 
    if (value.length < 61) setNewTag(value)
  }, [setNewTag])
  const debouncedSearch = useCallback(
    debounce(value => dispatch(searchTags(value)), 400),
    [dispatch]
  );
  const handleSearch = useCallback(value => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const userLoggedIn = user?.id && user?.roles?.length > 0;
  return (
    <InputWrapper
      name={name}
      caption={caption}
      required={required}
      label={label}
      labelClassname={labelClassname}
      applyToAll={applyToAll}
      applyToAllValue={applyToAllValue}
      onApplyToAllValueChanged={onApplyToAllValueChanged}
    >
      <div className='c-tag-manager'>
        <ul className='c-tag-manager__list'>
          {tags.map((tag, index) => (
            <li className='c-tag-manager__tag' key={index}>
              {!disableDelete && tag?.allowDelete || editable && !disabled ? (
                <button
                  className='c-btn c-btn--bare c-tag-manager__tag-remove-btn'
                  onClick={() => deleteFn(tag)}
                >
                    X
                </button>
              ) : null}
              {removeFn ? (
                <button
                  className={`c-tag c-tag--default u-bg-${tag?.addedByPartner ? 'teal' : 'grey-300'}`}
                  onClick={() => removeFn(tag)} tabIndex={tabIndex} type="button"
                >
                  {tag?.name ? tag?.name : tag}
                </button>
              ) : (
                <JdaLink
                  className={`c-tag c-tag--default u-bg-${tag?.addedByPartner ? 'teal' : 'grey-300'}`}
                  to={`/activesearch?keyword=${tag?.name ? tag?.name : tag}&sort=published&order=desc`}
                  tabIndex={tabIndex}
                  onClick={onTagLinkClick}
                >
                  {tag?.name ? tag?.name : tag}
                </JdaLink>
              )}
            </li>
          ))}
          {!hideAdd && userLoggedIn && !disabled && (
            <li className='c-tag-manager__tag'>
              {addMode ?
                <NewTagBox
                  newTag={newTag}
                  tabIndex={tabIndex}
                  onChange={onChange}
                  onTagSubmit={onTagSubmit}
                  handleSearch={handleSearch}
                  searchResults={tagResults}
                />
                : <button className='c-tag c-tag--add' type='button' onClick={() => setAddmode(true)} tabIndex={tabIndex}>
                  <span className='c-tag__icon c-tag__icon--add'>
                    <i className='material-icons'>add</i>
                  </span>
                    {t('Add')}
                </button>
              }
            </li>
          )}
        </ul>
      </div>
    </InputWrapper>
  )
}

export default TagManager
