import React from 'react'

export default ({ show }) => {
  if (!show) return null
  return (
    <svg width="16px" height="18px" viewBox="0 0 16 18" version="1.1">
        <title>A6869098-4EB7-4899-80D9-64224C0A100E</title>
        <g id="Alerts-Notifications" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Home-Notification-info-1" transform="translate(-36.000000, -106.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="icons8-info_popup" transform="translate(36.000000, 106.000000)">
                    <path d="M1.77777778,0 C0.795555556,0 0,0.795555556 0,1.77777778 L0,14.2222222 C0,15.2044444 0.795555556,16 1.77777778,16 L6.22222222,16 L8,17.7777778 L9.77777778,16 L14.2222222,16 C15.2044444,16 16,15.2044444 16,14.2222222 L16,1.77777778 C16,0.795555556 15.2044444,0 14.2222222,0 L1.77777778,0 Z M7.11111111,3.55555556 L8.88888889,3.55555556 L8.88888889,5.33333333 L7.11111111,5.33333333 L7.11111111,3.55555556 Z M7.11111111,7.11111111 L8.88888889,7.11111111 L8.88888889,12.4444444 L7.11111111,12.4444444 L7.11111111,7.11111111 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
