import React from 'react'
import { colors } from 'shared/constants'

const Facebook = ({ width = 32, color = 'white' }) => {
  return (
    <svg width={`${width}px`} xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 9 18">
      <path d="M2.667 16V8H0V5.333h2.667V3.901C2.667 1.19 3.987 0 6.24 0 7.32 0 7.89.08 8.16.117v2.55H6.624c-.957 0-1.29.504-1.29 1.527v1.14h2.803L7.756 8H5.333v8H2.667z" fill={colors[color]} fillRule="nonzero" />
    </svg>
  )
}

export default Facebook
