import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Captcha, TextInput } from 'features/Form/components'
import {
  loginFields,
  signupFields,
  requestPassFields,
  modalTypes,
  config
} from './helpers/fields'
import { LOGIN, SIGNUP, REQUEST_PASS } from 'shared/constants'
import { getParameter } from 'utils/functions'
import { useSelector, useDispatch, connect } from 'react-redux'
import {
  setAccountFormData,
  clearAccountFormData,
  submitAccountForm,
  setAccountFormErrors,
  resetAccountFormPass,
  checkDuplicatedUsername,
  checkDuplicatedEmail,
} from 'features/AccountForm/redux/accountFormActions'
import { selectAccountCheckingUsernameOrEmail, selectAccountFormData, selectAccountFormErrors, selectAccountModalType } from 'features/AccountForm/redux/accountFormSelectors'
import toast from 'shared/utils/toast'
import { useHistory, useLocation } from 'react-router-dom'

const AccountForm = ({ formType, setOpenModal, hideModalLinks = false, hideHeaderText = false, hideInput, langcode, checking_duplicate, duplicate_username, duplicate_email, checking_duplicate_email }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [fields, setFields] = useState([])
  
  const [captchaSuccessful, setCaptchaSuccessful] = useState(false)
  const modalType = useSelector(selectAccountModalType)
  const formData = useSelector(selectAccountFormData)
  const captchaRef = useRef()
  const setErrors = useCallback(e => { dispatch(setAccountFormErrors(e)) }, [dispatch])
  const checkUsername = useCallback((name) => {dispatch(checkDuplicatedUsername(name))}, [dispatch])
  const checkEmail = useCallback((email) => {dispatch(checkDuplicatedEmail(email))}, [dispatch])
  const errors = useSelector(selectAccountFormErrors)
  const formChecking = useSelector(selectAccountCheckingUsernameOrEmail);
  const isAdminRedirect = getParameter('redirect')


  useEffect(() => {
    // wipes password and resets captcha if any error exists
    if (Object.values(errors).length) {
      dispatch(resetAccountFormPass())
      captchaRef.current && captchaRef.current.reset()
    }
  }, [errors, captchaRef, dispatch])

  useEffect(() => {
    switch (formType) {
      case LOGIN:
        setFields(loginFields)
        break;
      case SIGNUP:
        setFields(signupFields)
        break;
      case REQUEST_PASS:
        setFields(requestPassFields)
        break;
      default:
        break;
    }
    dispatch(clearAccountFormData())
    // resets captcha on reopen and on a modal switch
    captchaRef.current && captchaRef.current.reset()
    setCaptchaSuccessful(false)
  }, [formType, setFields, dispatch, setCaptchaSuccessful])

  const handleChange = event => {
    dispatch(setAccountFormData({
      [event.currentTarget.name]: event.currentTarget.value
    }))
  }
  const checkErrors = useCallback(() => {
    const submitErrors = {}
    fields.forEach(({ name, required, label }) => {
      if (required && !formData[name].length) {
        submitErrors[name] = `${label || name} field required`
      }
    })
    if (!captchaSuccessful) {
      submitErrors.captcha = t('The answer you entered for the CAPTCHA was not correct')
    }
    if (duplicate_username) {
      submitErrors.signup = t('Username already exists, please choose a different one');
    }
    if (duplicate_email) {
      submitErrors.signup = t('Email already exists, please choose a different one')
    }
    setErrors(submitErrors)
    return Object.values(submitErrors)
  }, [formData, fields, captchaSuccessful, setErrors, duplicate_username])

  const onSubmit = event => {
    event.preventDefault()
    const errorsArray = checkErrors()
    if (!errorsArray.length) {
      dispatch(submitAccountForm(formType, isAdminRedirect))
    } else {
      errorsArray.forEach(error => toast.error(error))
    }
  }

  useEffect(() => {
    // window.location.reload()

  }, [langcode])

  return (
    <div className='c-account-form'>
      <div className={`c-account-form__header ${hideHeaderText ? 'c-account-form__header--empty' : ''}`}>
        {!hideHeaderText &&
          <span className='u-color-white c-heading c-heading--l6'>
            {formType && config[formType] ? t(config[formType].header) : ''}
          </span>
        }
      </div>
      <div className='c-account-form__content'>
        {!hideModalLinks &&
          <ul className='c-account-form__registration-links'>
            {modalTypes.filter(type => type !== formType).map(type => (
              <li key={type}>
                <button className="c-link c-link--default c-account-form__reg-link" onClick={() => setOpenModal(type)}>
                  {t(type === LOGIN ? 'Login' : type === SIGNUP ? 'Create new account' : 'Request new password')}
                </button>
              </li>
            ))}
          </ul>
        }
        <form onSubmit={onSubmit} className='c-form'>
          {fields.map(({ required, caption, name, label, type, signup, errorMessage }) => (
            <div key={name} className='c-form__item'>
              <TextInput
                onBlur={() => {
                  if (!!signup && name == 'name'){
                    checkUsername(formData[name], setErrors);
                  }
                  if (!!signup && name == 'mail'){
                    checkEmail(formData[name], setErrors);
                  }
                }}
                signup={!!signup}
                errorMessage={errorMessage}
                duplicateChecking={!!signup && name == 'name' && checking_duplicate || !!signup && name == 'mail' && checking_duplicate_email}
                duplicateUsername={!!signup && name == 'name' && duplicate_username || !!signup && name == 'mail' && duplicate_email}
                hideInput={modalType && hideInput}
                required={required}
                name={name}
                label={t(label)}
                value={formData[name]}
                error={errors[name]}
                type={type}
                onChange={handleChange}
                caption={t(caption)}
                className='c-account-form__input'
              />
            </div>
          ))}
          <div className='c-form__item'>
            <Captcha key={langcode} hl={langcode} captchaRef={captchaRef} onChange={() => setCaptchaSuccessful(!captchaSuccessful)} />
          </div>
          <button className="c-btn c-btn--lg c-btn--full c-btn--hover-fx u-color-white u-bg-orange c-copy--uppercase" disabled={formChecking}>
            {formType && config[formType] ? t(config[formType].buttonText) : ''}
          </button>
        </form>
      </div>
    </div>
  )
}

function mapStateToProps({ accountForm, shared,  }) {
  return {
    langcode: shared.langcode,
    duplicate_username: accountForm.username_duplicated,
    duplicate_email: accountForm.email_duplicated,
    checking_duplicate: accountForm.meta.checkingDuplicate,
    checking_duplicate_email: accountForm.meta.checkingDuplicateEmail,
  }
}

export default connect(mapStateToProps)(AccountForm)
