import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import AccountForm from 'features/AccountForm'
import { Redirect, useLocation } from 'react-router-dom'
import { LOGIN } from 'shared/constants'
import { selectUserLoadStatus } from 'features/authentication/redux/authenticationSelectors'
import { useSelector } from 'react-redux'

const Login = () => {
  const { state } = useLocation()
  const redirectPath = state && state.redirectPath
  const userLoaded = useSelector(selectUserLoadStatus)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  if (userLoaded) return <Redirect to={redirectPath || '/en/user'} />
  return (
    <div className='u-p c-layout__centered c-layout__fill'>
      <AccountForm formType={LOGIN} hideHeaderText hideModalLinks hideInput />
    </div>
  )
}

export default Login
