import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'BASIC_PAGE'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'GET_RESOURCES',
  'GET_SINGLE_PAGE'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
