import React from 'react'

const MasonryGridItem = ({ image, drag, opacity, onClick, mobileAddBtnFn }) => {
  const { description, src } = image

  return (
    <div ref={drag} style={{ opacity }} className='c-masonry-grid__item'>
      <button
        className='c-masonry-grid__btn'
        type="button"
        onClick={onClick}
      >
        <img className='c-masonry-grid__img' src={src} alt={description} />
      </button>
      {mobileAddBtnFn && (
        <button className='c-btn c-btn--bare u-hidden@md c-masonry-grid__plus-btn u-bg-orange' onClick={mobileAddBtnFn}>
          <span className='c-btn__icon c-btn__icon--lg'>
            <i className='material-icons u-color-white'>add</i>
          </span>
        </button>
      )}
    </div>
  )
}

export default MasonryGridItem
