import { createReducer } from '@reduxjs/toolkit'
import contributeTypes from 'features/Contribute/redux/contributeTypes'
import types from './searchTypes'
import collectionsTypes from 'features/Collections/redux/collectionsTypes';

const defaultState = {
  items: [],
  preloadItems: [],
  resultCount: 0,
  page: 1,
  partners: [],
  partnerBanner: false,
  filtersDropdown: false,
  filters: null,
  sort: {
    sort: "published",
    order: "desc",
  },
  searchType: null,
  meta: {
    loading: false,
    preloadClear: false,
    error: false
  }
}

export default createReducer(defaultState, {
  [types.GET_ITEMS_BEGIN]: (state, action) => {
    const loadmore = action.payload
    state.items = loadmore ? [ ...state.items, ...state.preloadItems ] : []
    state.meta.preloadClear = loadmore
      && !state.preloadItems.length
      && state.resultCount > state.items.length

    state.meta.loading = true
    state.preloadItems = []
  },
  [types.GET_ITEMS_SUCCESS]: (state, action) => {
    const { items, resultCount, preload } = action.payload
    if (preload) {
      state.preloadItems = [...state.preloadItems, ...items]
    } else {
      state.items = items
    }
    state.resultCount = resultCount
    state.meta.loading = false
  },
  [types.GET_ITEMS_FAIL]: state => {
    state.meta.loading = false
  },
  [types.GET_PARTNERS_SUCCESS]: (state, action) => {
    state.partners = [
      ...action.payload
    ]
  },
  [types.SET_SORTING]: (state, action) => {
    state.sort = {
      ...action.payload
    }
  },
  [types.SET_FILTERS]: (state, action) => {
    state.filters = {
      ...action.payload
    }
  },
  [types.SET_SEARCH_TYPE]: (state, action) => {
    state.searchType = action.payload
  },
  [types.TOGGLE_FILTERS_DROPDOWN]: (state, action) => {
    state.filtersDropdown = action.payload
  },
  [types.SET_PAGINATION]: (state, action) => {
    state.page = action.payload
  },
  [types.TOGGLE_PARTNER_BANNER]: (state, action) => {
    state.partnerBanner = action.payload
  },
  [collectionsTypes.CREATE_COLLECTION_SUCCESS]: (state, action) => {
    if (action.payload?.published && state.searchType == 'collections')
    {
      state.items.unshift(action.payload)
      state.resultCount = state.resultCount + 1
    }
  },
  [contributeTypes.UPDATE_SELECTED_ITEM_SUCCESS]: (state, action) => {
    const updateItem = action.payload
    state.items = state.items.map(item => {
      if (item.id === updateItem.id && item.langcode === updateItem.langcode) {
        return {
          ...item,
          ...updateItem
        }
      }
      return item
    })
  }
})
