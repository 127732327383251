export const SEARCH_PAGE_SIZE = 50

export const SEARCH_ITEMS = 'items'
export const SEARCH_COLLECTIONS = 'collections'
export const SEARCH_DETAIL = 'detail'

// sort
export const NEWEST = 'newest'
export const NEW_PUBLISHED = 'published_desc'

// query paramters
export const SORT = 'sort'
export const ORDER = 'order'
