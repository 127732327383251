import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormSelect, LanguagesManager, TextInput, TagManager, ReactCKEditor } from 'features/Form/components'
import { getMediaTypes, mediaOptions, stepTwoConfigs } from 'features/Contribute/helpers/contributeFormConfig'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { debounce } from 'utils/functions'

import MapContainer from 'features/Map/components/MapContainer'
import { geolocate } from 'features/Map/utils/functions'
import {
  selectContributeFormData,
  selectContributeFormLanguage,
  selectContributeFormPage,
  selectContributeSubmitting,
  selectIsMultipleUploadMode,
  selectMultipleType
} from 'features/Contribute/redux/contributeSelectors'
import {
  setContributeFormData,
  setContributeFormLanguage,
  setContributeFormStep,
  addContributeFormTag,
  removeContributeFormTag,
  toggleContributeFormLanguage,
  setContributeFormMediaType,
  contributeFormSubmit,
  setContributeLocation,
  setContributeUser,
  setIsMultipleUpload,
  setMultipleType,
  setContributeMultiFormData,
} from 'features/Contribute/redux/contributeActions'
import { LanguageTabber } from 'shared/components'
import { Link } from 'react-router-dom'
import { selectUser } from 'features/authentication/redux/authenticationSelectors'
import ContributeFormStepTwo from './components/ContributeFormStepTwo'
import validateForm from './helpers/validateForm'
import ContributeDragAndDrop from '../ContributeDragAndDrop'
import ContributeMultiform from '../ContributeMultiform'
import { createFile } from 'shared/utils/api/item'
import validateMultipleForm from './helpers/validateMultipleForm'
import { ContributeWebsitesForm } from 'features/Contribute'
import uuid from 'react-uuid'
import toast from 'shared/utils/toast'
import { authenticationWrapper } from 'features/authentication/redux/authenticationAction'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { EN } from 'shared/constants'

const ContributeForm = (props) => {
  const { params, langcode } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const siteLangcode = useSelector(selectLangcodeFlag)
  const isMultipleUpload = useSelector(selectIsMultipleUploadMode);
  const form = useSelector(selectContributeFormData)
  const multiUploadType = useSelector(selectMultipleType);
  const selectedMediaType = form.mediaType
  const activeTab = useSelector(selectContributeFormLanguage)
  const activePage = useSelector(selectContributeFormPage)
  const user = useSelector(selectUser)
  const submitting = useSelector(selectContributeSubmitting)
  const [currentMultiUploadStep, setCurrentMultiUploadStep] = useState(1)
  const multiTypes = useSelector(selectMultipleType);
  const setActiveTab = useCallback(lang => dispatch(setContributeFormLanguage(lang)), [dispatch])
  const setForm = useCallback(data => dispatch(setContributeFormData(data)), [dispatch])
  const setStep = useCallback(step => dispatch(setContributeFormStep(step)), [dispatch])
  const addTag = useCallback(tag => dispatch(authenticationWrapper(() => addContributeFormTag(tag))), [dispatch])
  const removeTag = useCallback(tag => dispatch(removeContributeFormTag(tag)), [dispatch])
  const toggleLanguage = useCallback(language => dispatch(toggleContributeFormLanguage(language)), [dispatch])
  const setMediaType = useCallback(type => dispatch(setContributeFormMediaType(type)), [dispatch])
  const goToNextPage = useCallback(() => {
    if (validateForm(form, activePage)) {
      setStep(activePage + 1)
    }
  }, [form, activePage, setStep])
  const translatedMediaOptions = mediaOptions.map(o => ({ ...o, label: t(o.label) }))
  const maxSteps = 2
  const pageOneIndex = activePage !== 1 ? '-1' : undefined
  const pageTwoIndex = activePage !== 2 ? '-1' : undefined
  const location = form.location.longitude && form.location.latitude
    ? [form.location.longitude, form.location.latitude]
    : false
  const prevParamsRef = useRef() // used to preserve form data when using the language toggle

  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [rejectedFiles, setRejectedFiles] = useState([])
  const [pdfThumbnails, setPdfThumbnails] = useState({});

  useEffect(() => {
    dispatch(setContributeUser(user))

    if (params) {

      if (!prevParamsRef.current) {
        prevParamsRef.current = params
      }

      setForm({
        title: { en: (langcode == "en" && params.title) ? params.title : '', ja: (langcode == "ja" && params.title) ? params.title : '' },
        description: { en: (langcode == "en" && params.description) ? params.description : '', ja: (langcode == "ja" && params.description) ? params.description : '' },
        uri: params.url || '',
        mediaType: getMediaTypes(params)
      })
    }
  }, [langcode, dispatch, user, params, prevParamsRef, setForm])
  useEffect(() => {
    loadInitialWebsiteData(multiUploadType)
  }, [multiUploadType])
  const setLocation = useCallback(location => {
    dispatch(setContributeLocation(location))
  }, [dispatch])
  const onChange = useCallback((eventOrValue, fieldName) => {
    let field, value
    if (eventOrValue && eventOrValue.currentTarget) {
      field = eventOrValue.currentTarget.name
      value = eventOrValue.currentTarget.value
    } else {
      field = fieldName
      value = eventOrValue
    }
    if (!form.user.username) setForm({ user })
    setForm({ [field]: value })
  }, [setForm, form.user.username, user])
  const onChangeWithLang = useCallback(event => {
    setForm({
      [event.currentTarget.name]: {
        ...form[event.currentTarget.name],
        [activeTab]: event.currentTarget.value
      }
    })
  }, [form, activeTab, setForm])
  const config = selectedMediaType && stepTwoConfigs.find(config => config.mediaType.toLowerCase() === selectedMediaType?.toLowerCase())
  const onSubmit = useCallback(() => {
    let valid = true;
    if (!isMultipleUpload){
      valid = validateForm(form, activePage);
    } else {
      const data = isWebsiteType ? form?.multiForm.filter(d => d?.uri) : form?.multiForm;
      for (let i = 0; i < data.length; i++) {
        if (!validateMultipleForm(data[i],i, isWebsiteType)) {
          valid = false;
        }
      }
    }
    if(valid) {
      dispatch(contributeFormSubmit())
    }
  }, [dispatch, form, activePage])

  const getLocation = async (value) => {
    if (value.length > 0) {
      const location = await geolocate(value)
      setLocation(location)
    }
  }

  const validateUrls = () => {
    const data = [...form?.multiForm] ?? [];
    let valid = true;
    const regex = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
    const errorMessages = []
    const newData = data.map((d, index) => {
      const nd = {...d}
      if (nd?.uri) {
        if (!regex.test(d?.uri)) {
          valid = false;
          nd.error = "Please enter a valid website url";
          errorMessages.push(`Website ${index + 1} URL is not valid.`)
        } else {
          nd.error = '';
        }
      }
      return nd
    })
    if (errorMessages.length) {
      toast.error(errorMessages)
    }
    dispatch(setContributeMultiFormData(newData));
    return valid;
  }

  const debouncedGetLocation = debounce(value => getLocation(value), 1000)

  const handleAddressChange = useCallback(e => debouncedGetLocation(e.currentTarget.value), [debouncedGetLocation])

  const handleKeyDown = async (e) => {
    if (e.which === 13) {
      getLocation(e.currentTarget.value)
    }
  }

  const onChangeUploadType = (value) => {
    dispatch(setIsMultipleUpload(value))
  }

  const isImagesDocumentsType = multiTypes == 1;
  const isWebsiteType = multiTypes == 2;

  const loadInitialWebsiteData = (value) => {
    let payload = [];
    if (value == 2) {
      const data = Array(5).fill(0);
      const websites = data.map(d => {
        return {uuid: uuid(), uri: ''};
      })
      payload = websites;
    }
    dispatch(setContributeMultiFormData(payload));
  }

  // 1 for Images and Documents, 2 for Websites
  const onChangeMultipleType = (value) => {
    dispatch(setMultipleType(value))
  }

  const filesUploading = acceptedFiles.findIndex(f => f.loading) > -1;

  const hasValidWebsites = form.multiForm.filter(d => (d.uri)).length > 0;
  const multiNextDisabled = (isImagesDocumentsType && (acceptedFiles.length === 0 || filesUploading)) || (isWebsiteType && !hasValidWebsites);

  const CONTRIBUTE_HELP_LINK = siteLangcode == EN ? "/en/resources/explore/contribute-jda" : "/ja/resources/explore/contribute-jda/contribute-webform";

  return (
    <div className='c-form c-contribute-form'>
      <div className='c-contribute-form__header'>
        <div>
          <span className='c-copy c-copy--bold'>{t('Contribute to the Archive')} </span>
          ( <Link to={CONTRIBUTE_HELP_LINK} className='c-link c-link--default'>{t('Need help?')}</Link> )
        </div>
        {!isMultipleUpload ? (
          <div>
            {t('Steps of 2', {'0': activePage})}
          </div>
        ) : (
          <div>
            {t('Steps of 2', {'0': currentMultiUploadStep})}
          </div>
        )}

      </div>
      <LanguageTabber
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className='c-contribute-form__tabber'
      >

        <div className='c-contribute-form__content-wrapper'>
          <form className={`c-contribute-form__content js-step-${activePage}`} onSubmit={event => event.preventDefault()}>

            <div className='c-form c-contribute-form__step'>
              <div>
                <fieldset className='upload-type'>
                  <legend className='c-heading c-heading--l6'>{t('Contribute')}</legend>

                  <label className='c-form__option upload-type-items'>
                    <input
                      type='radio'
                      name='upload_type'
                      className='radio-btn'
                      onChange={() => { onChangeUploadType(false) }}
                      checked={!isMultipleUpload}
                    />
                    {t('Single Item')}
                  </label>
                  <label className='c-form__option'>
                    <input
                      type='radio'
                      name='upload_type'
                      className='radio-btn'
                      onChange={(e) => { onChangeUploadType(true) }}
                      checked={isMultipleUpload}
                    />
                    {t('Multiple Items')}
                  </label>
                </fieldset>
                {isMultipleUpload && currentMultiUploadStep < 2 && (<fieldset className='upload-type'>
                  <legend className='c-heading c-heading--l6'>{t('What would you like to contribute?')}</legend>

                  <label className='c-form__option upload-type-items'>
                    <input
                      type='radio'
                      name='multi_upload_type'
                      className='radio-btn'
                      onChange={() => { onChangeMultipleType(1) }}
                      checked={isImagesDocumentsType}
                    />
                    {`${t('Images')}/${t('Document')}`}
                  </label>
                  <label className='c-form__option'>
                    <input
                      type='radio'
                      name='multi_upload_type'
                      className='radio-btn'
                      onChange={(e) => { onChangeMultipleType(2) }}
                      checked={isWebsiteType}
                    />
                    {t('Website')}
                  </label>
                </fieldset>)}
              </div>

              {!isMultipleUpload ? (
                <>
                  <div className='c-form__item'>
                    <TextInput
                      required
                      onChange={onChangeWithLang}
                      value={form.title[activeTab]}
                      name='title'
                      label={t('Title')}
                      placeholder={t('Title')}
                      tabIndex={pageOneIndex}
                    />
                  </div>
                  <div className='c-form__item'>
                    <ReactCKEditor
                      id={`contribute-item-${activeTab}`}
                      onChange={onChangeWithLang}
                      value={form.description[activeTab]}
                      name='description'
                      label={t('Description')}
                      type='textarea'
                      placeholder={t('Description')}
                      tabIndex={pageOneIndex}
                    /> 
                  </div>
                  <div className='c-form__item'>
                    <FormSelect
                      required
                      onChange={event => setMediaType(event.currentTarget.value)}
                      name='mediaType'
                      label={t('Media Type')}
                      value={form.mediaType}
                      options={translatedMediaOptions}
                      tabIndex={pageOneIndex}
                    />
                  </div>
                </>
              ) : (
                <div style={{ marginTop: '10px' }}>
                  {currentMultiUploadStep == 1 && isImagesDocumentsType ? <ContributeDragAndDrop setAcceptedFiles={setAcceptedFiles} acceptedFiles={acceptedFiles} setRejectedFiles={setRejectedFiles} rejectedFiles={rejectedFiles} /> : currentMultiUploadStep == 1 && isWebsiteType ? <ContributeWebsitesForm /> : null}
                  {currentMultiUploadStep == 2 && <><ContributeMultiform acceptedFiles={acceptedFiles} activeTab={activeTab} setAcceptedFiles={setAcceptedFiles} goBack={() => setCurrentMultiUploadStep(1)}/></>}
                </div>
              )}

            </div>
            <div className='c-form c-contribute-form__step c-contribute-form__step--scroll' id='step-2'>
              {config && (
                <ContributeFormStepTwo config={config}
                  form={form}
                  onChange={onChange}
                  onChangeWithLang={onChangeWithLang}
                  activeTab={activeTab}
                  tabIndex={pageTwoIndex}
                />
              )}
              <div className='c-form__item'>
                <LanguagesManager
                  label={t('Languages')}
                  name='languages'
                  languages={form.languages}
                  toggleFn={toggleLanguage}
                  tabIndex={pageTwoIndex}
                />
              </div>
              <div className='c-form__item'>
                <TagManager
                  label={t('Tags')}
                  name='tags'
                  tags={form.tags}
                  addNewFn={addTag}
                  removeFn={removeTag}
                  tabIndex={pageTwoIndex}
                />
              </div>
              <div className='c-form__item'>
                <TextInput
                  name='address'
                  label={t('Address')}
                  placeholder={t('Enter a location or click the map to place a marker...')}
                  tabIndex={activePage !== 2 ? '-1' : undefined}
                  onChange={handleAddressChange}
                  onKeyDown={handleKeyDown}
                />
                <MapContainer page="contributeMap" setLocation={setLocation} location={location}></MapContainer>
              </div>
            </div>
          </form>
        </div>
      </LanguageTabber>
      <div className='o-grid'>
        {!isMultipleUpload ?
          (
            <>
              <div className='o-grid_col u-6/12'>
                {activePage > 1 &&
                  <button
                    className='c-btn c-btn--lg c-btn--sharp u-bg-white c-btn--full c-contribute-form__btn'
                    onClick={() => setStep(activePage - 1)}
                  >
                    <span className='c-btn__icon c-btn__icon--left'>
                      <i className='material-icons'>chevron_left</i>
                    </span>
                    {t('Back')}
                  </button>
                }
              </div>
              <div className='o-grid_col u-6/12'>
                {activePage < maxSteps
                  ? <button
                    onClick={goToNextPage}
                    className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn'
                  >
                    {t('Next Step')}
                  </button>
                  : <button
                    type='submit'
                    disabled={submitting}
                    onClick={onSubmit}
                    className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn'
                  >
                    {t('Submit')}
                  </button>
                }
              </div>
            </>
          )
          :
          (
            <>
              <div className='o-grid_col u-6/12'>
                {currentMultiUploadStep > 1 &&
                  <button
                    className='c-btn c-btn--lg c-btn--sharp u-bg-white c-btn--full c-contribute-form__btn'
                    onClick={() => setCurrentMultiUploadStep(currentMultiUploadStep - 1)}
                  >
                    <span className='c-btn__icon c-btn__icon--left'>
                      <i className='material-icons'>chevron_left</i>
                    </span>
                    {t('Back')}
                  </button>
                }
              </div>
              <div className='o-grid_col u-6/12'>
                {currentMultiUploadStep < maxSteps
                  ? <button
                    disabled={multiNextDisabled}
                    onClick={() => {
                      if (isWebsiteType) {
                        const valid = validateUrls();
                        if (valid) setCurrentMultiUploadStep(currentMultiUploadStep + 1);
                      } else {
                        setCurrentMultiUploadStep(currentMultiUploadStep + 1)
                      }
                      setForm({})
                    }}
                    className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn'
                  >
                    {t('Next Step')}
                  </button>
                  : <button
                    type='submit'
                    disabled={submitting || form?.multiForm?.length == 0}
                    onClick={onSubmit}
                    className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn'
                  >
                    {t('Submit')}
                  </button>
                }
              </div>
            </>
          )}


      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { shared: { langcode } } = state;
  return { langcode };
};

export default connect(mapStateToProps)(ContributeForm)
