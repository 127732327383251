import { selectCollectionMembers, selectIsUserCollectionDataOwner } from 'features/Collections/redux/collectionsSelectors'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ContributorIcon from './components/ContributorIcon'
import ManageContributorButton from './components/ManageContributorButton'
import ManageContributorModal from './components/ManageContributorModal'
import ViewContributorModal from './components/ViewContributorModal'

export default function ContributorSection() {
  const { t } = useTranslation()
  const [ showViewContributorModal, setShowViewContributorModal] = useState(false)
  const [ showManageContributorModal, setShowManageContributorModal] = useState(false)
  const collectionMembers = useSelector(selectCollectionMembers)
  const isCollectionOwner = useSelector(selectIsUserCollectionDataOwner)
  const numberOfContributor = !Array.isArray(collectionMembers) ? 0 : collectionMembers.length
  const ContributorText = !Array.isArray(collectionMembers) ? '' : numberOfContributor > 1 ? t('Number of Contributors', {'0': numberOfContributor}) : t('Number of Contributors', {'0': numberOfContributor});
  

  return (
    <div className='c-contributor-section'>
      <ContributorIcon 
        onClick={() => setShowViewContributorModal(true)}
      />
      <p className='c-contributor-text'>{ContributorText}</p>
      {isCollectionOwner && <ManageContributorButton 
        onClick={() => setShowManageContributorModal(true)}
        hasContributor={true}
      />}
      <ViewContributorModal
        isOpen={showViewContributorModal}
        onClose={() => setShowViewContributorModal(false)}
      />
      {isCollectionOwner && <ManageContributorModal
        isOpen={showManageContributorModal}
        onClose={() => setShowManageContributorModal(false)}
      />}
    </div>
  )
}