import React, { useState, useCallback } from 'react'
import { CollectionItemMini } from 'features/Collections'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCreateCollectionSubmitting,
  selectItemData,
  selectItemModalType,
  selectUserCollections
} from 'features/Collections/redux/collectionsSelectors'
import { addCollectionItem, addTextSlideToCollections } from 'features/Collections/redux/collectionsActions'
import toast from 'shared/utils/toast'
import { getLangValue } from 'utils/functions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { useTranslation } from 'react-i18next'
import { TEXT_SLIDE } from 'shared/constants'

const MyCollectionsList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [checkedCollections, setCheckedCollections] = useState([])
  const submitting = useSelector(selectCreateCollectionSubmitting)
  const collections = useSelector(selectUserCollections)
  const itemModalType = useSelector(selectItemModalType)
  const item = useSelector(selectItemData)
  const siteLang = useSelector(selectLangcodeFlag)
  const validateSelection = useCallback(checkedCollection => {
    if (!checkedCollection) return false
    const foundItem = checkedCollection.items.find(it => it.itemData.id === item.id)
    if (foundItem) return false
    return true
  }, [item])
  const toggleItem = useCallback(checkedCollection => {
    const valid = validateSelection(checkedCollection)
    if (valid) {
      setCheckedCollections(cols => {
        if (cols.find(col => checkedCollection.id === col.id)) {
          return cols.filter(col => col.id !== checkedCollection.id)
        } else {
          return [...cols, checkedCollection]
        }
      })
    } else {
      toast.error('already contains', {transParams: {'0': getLangValue(siteLang, checkedCollection.title), '1': getLangValue(siteLang, item.title)}})
    }
  }, [setCheckedCollections, validateSelection, item, siteLang])
  const addToCollections = useCallback(async () => {
    if (itemModalType === TEXT_SLIDE) {
      await dispatch(addTextSlideToCollections(checkedCollections, item))
    } else {
      await dispatch(addCollectionItem(checkedCollections, item))
    }
    setCheckedCollections([])
  }, [dispatch, checkedCollections, item, itemModalType])
  const addToCollectionsDisabled = submitting || !checkedCollections.length
  return (
    <div className='c-item-modal__sub-col'>
      <div className='u-p u-color-white u-bg-navy c-copy c-copy--bold'>
        {t('My Collections')}
      </div>
      <div className='c-item-modal__scrollable-content'>
        {collections.map(col => {
          const checked = !!checkedCollections.find(v => v.id === col.id)
          return (
            <CollectionItemMini
              key={`${col.id}-${col.langcode}`}
              checkboxLeft
              privateItem={true}
              className='c-item-modal__collection-item'
              onCheck={toggleItem}
              checked={checked}
              {...col}
            />
          )
        })}
      </div>
      <button
        className={`c-btn c-btn--full c-btn--lg c-btn--hover-fx c-btn--sharp u-color-white u-bg-orange c-contribute-form__btn ${addToCollectionsDisabled ? 'is-disabled' : ''}`}
        disabled={addToCollectionsDisabled}
        onClick={addToCollections}
      >
        {t('Add To Collections')}
      </button>
    </div>
  )
}

export default MyCollectionsList
