import React from "react"

const ColItemMiniCheckbox = ({ checked, onChange }) => {
  return ( 
    <label className='c-collection-item__mini-checkbox'>
      <input
        checked={checked}
        onChange={onChange}
        type='checkbox'
        className='u-visually-hidden'
      />
      <div className='c-copy c-copy__icon c-copy__icon--lg'>
        <i className='material-icons'>{checked ? 'check_box' : 'check_box_outline_blank'}</i>
      </div>
    </label>
  )
}

export default ColItemMiniCheckbox
