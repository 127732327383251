import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import { useTranslation } from 'react-i18next';
registerLocale('ja', ja);
const DatePickerWrapper = ({ className = '', ...rest }) => {
  const {i18n} = useTranslation()
  const currentLanguage = i18n.language;
  return (
    <div className={`c-date-picker ${className}`}>
      <DatePicker {...rest} locale={currentLanguage}/>
    </div>
  )
}

export default DatePickerWrapper
