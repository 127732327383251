import React, { useState, useEffect } from 'react'
import { LanguageTabber } from 'shared/components'
import TextSlideForm from './components/TextSlideForm'
import { EN } from 'shared/constants'

const InlineCollectionForm = ({ type, saveFn, deleteFn, deleteText, closeFn, topics, isOpen, editTopic }) => {
  const forms = {
    topic: {
      topic_title: {
        en: (editTopic && editTopic.englishTitle) || '',
        ja: (editTopic && editTopic.japaneseTitle) || ''
      },
      description: {
        en: (editTopic && editTopic.englishDescription) || '',
        ja: (editTopic && editTopic.japaneseDescription) || ''
      }
    },
    slide: {
      title: { en: '', ja: '' },
      description: { en: '', ja: '' },
      topic: ''
    }
  }
  const [lang, setLang] = useState(EN)
  const [form, setForm] = useState(forms[type])
  useEffect(() => {
    setForm(forms[type])
  }, [type])
  return (
    <div className={`u-bg-white u-mb c-inline-col-form ${isOpen ? '' : 'is-closed'} ${type === 'slide' ? '' : 'u-mblg'}`}>
      <LanguageTabber
        activeTab={lang}
        setActiveTab={setLang}
        className='c-language-tabber--fill c-inline-col-form__main'
        contentClassName='u-p'
      >
        <TextSlideForm
          form={form}
          lang={lang}
          setForm={setForm}
          topics={topics}
          saveFn={() => saveFn && saveFn(form)}
          deleteFn={() => editTopic && deleteFn(editTopic.id)}
          deleteText={editTopic && deleteText}
        />
      </LanguageTabber>
      <button className='c-btn u-bg-white c-inline-col-form__close-btn' onClick={closeFn}>
        <i className="material-icons">close</i>
      </button>
    </div>
  )
}

export default InlineCollectionForm
