import { PUBLIC_FOLDER, TESTIMONIAL } from "shared/constants"
import { processGroupByIdItem } from "../collections/model"

const getImageUrl = item => {
  let img = (item['field_thumbnail_url'] && item['field_thumbnail_url'].uri) || 
  item['field_collection_thumbnail_url'] || 
  item['field_thumbnail_url'] ||
  item.fieldNewThumbnailUrl ||
  (item.fieldFile && item.fieldFile.entity && item.fieldFile.entity.url) ||
  item['collection_file_uri']
  img = img && img.match(/\.(jpeg|jpg|gif|png)/) != null ? img : '';
  if (img) img = img.replace('public://', PUBLIC_FOLDER)
  return img
}

// used to process activeSearch and collectionSearch items
export const processItem = item => {
  const id = item.nid || item['collection_id'] || item['drupal_internal__id']
  if (!item || !id) return null
  const langcode = (item.langcode && item.langcode.value) || item.langcode || item['collection_langcode']
  const  title = {
    en: item['field_english_title'] || item['field_collection_english_title'],
    ja: item['field_japanese_title'] || item['field_collection_japanese_title'] 
  }
  const description = {
    en: item['field_english_description'] || item['field_collection_english_description'],
    ja: item['field_japanese_description'] || item['field_collection_japanese_description']
  }
  let type = item['media_type_name'] || item['collection_media_type_name']
    || (item.fieldMediaType?.entity?.name)
  if (type && type.toLowerCase() === 'text') type = TESTIMONIAL
  return {
    ...item,
    id,
    title,
    description,
    langcode,
    publishedDate: item['field_media_date_create'] || item['field_collection_media_date_create'],
    lon: item['field_geolocation_lng'] || item['field_longitude'] || item.fieldLongitude,
    lat: item['field_geolocation_lat'] || item['field_latitude'] || item.fieldLatitude,
    location: item['field_text_location'] || item.fieldTextLocation,
    updatedDate: item.changed,
    img: getImageUrl(item),
    owner: item['field_partner_dropdown_name'] || item['field_media_creator_realname'] || item['field_media_creator_username'] // item fields
      || item['field_collection_media_creator_realname'] || item['field_collection_media_creator_username'] || item['collection_username_name'], // collection fields
    partnerName: item.entityOwner && (item.entityOwner.name || item.entityOwner.fieldPartnerDropdownName),
    type,
    link: item['field_attribution_uri'] || item.fieldNewAttributionUri,
    published: item['collection_status'] || item.status,
    isCollection: !!item['collection_id'],
    itemCount: item.groupItemCount
  }
}

export const processItems = data => {
  if (!data || !data.searchAPISearch) return false
  const { searchAPISearch: { documents, result_count } } = data
  const items = !documents ? [] : documents.reduce((acc, nextItem) => {
    if (nextItem && (nextItem.nid || nextItem['collection_id'])) {
      return acc.concat(processItem(nextItem))
    } else {
      return acc
    }
  }, [])
  return [items, result_count]
}

export const processRelatedCollections = data => {
  const entities = data?.nodeById?.reverseEntityIdGroupContent?.entities
  if (!entities) return null
  return entities.filter(v => !!v.queryGid.entities[0]).map(v => processGroupByIdItem(v.queryGid.entities[0]))
}
