import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './dragAndDrop.scss'
import toast from 'shared/utils/toast'
import uuid from 'react-uuid';
import { createPDFImage } from 'shared/utils/tools/pdfThumbnail'

import dropZoneImg from './icons/drag-and-drop.svg'
import reload from './icons/icon-refresh.svg'
import close from './icons/icon-close.svg'
import attachment from './icons/attachment.svg'
import { createFile } from 'shared/utils/api/item'
import { file } from '@babel/types'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeContributeMultiFormItem, setContributeMultiFormData } from 'features/Contribute/redux/contributeActions';

const ContributeDragAndDrop = ({ acceptedFiles, setAcceptedFiles, setRejectedFiles, rejectedFiles }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
    // const [uploadedFiles, setUploadedFiles] = useState([])
    const [percentages, setPercentages] = useState(0)

    const updateFileStatus = (file, loading = true, accepted = true) => {
      setAcceptedFiles((prev) => prev.map((f) => {
          if (f.uuid == file.uuid) {
              f.loading = accepted ? loading : f.loading;
              f.error = !accepted;
          }
          return f;
        }))
    }

    const uploadSingleItem = (file) => {
      try {
        updateFileStatus(file, true);
        createFile(file).then((fileId) => {
          file.fid = fileId;
          updateFileStatus(file, false);
        })
      } catch (error) {
        updateFileStatus(file, false, false);
      }
    }

    const onDrop = async (acceptedFilesTemp, rejectedFilesTemp) => {
        let newAcceptedFiles = [...acceptedFiles, ...acceptedFilesTemp];
        let newRejectedFiles = [...rejectedFiles, ...rejectedFilesTemp];

        let acceptedLength = newAcceptedFiles.length;
        let rejectedLength = newRejectedFiles.length;

        if ((acceptedLength + rejectedLength) > 10) {
          toast.info('Only 10 Items can be uploaded at once');
          if (acceptedLength > 10) {
            newAcceptedFiles = newAcceptedFiles.slice(0, 10);
            newRejectedFiles = [];
          } else {
            newRejectedFiles = newRejectedFiles.slice(0, 10 - acceptedLength)
          }
        }

        for (let index = 0; index < acceptedFilesTemp.length; index++) {
          const file = acceptedFilesTemp[index];
          const result = {
            name: file.name,
            path: file.path,
          }
          result.data = URL.createObjectURL(file);
          if (!result.uuid) result.uuid = uuid();
          if (file.type.includes('image')) {
            result.mediaType = "Image";
          }
          if (file.type == 'application/pdf') {
            const image = await createPDFImage(file);
            result.pdfThumbnail = image
          }
          acceptedFilesTemp[index] = result;
        }

        newAcceptedFiles = [...acceptedFiles, ...acceptedFilesTemp];
        newRejectedFiles = [...rejectedFiles, ...rejectedFilesTemp];
        setAcceptedFiles(newAcceptedFiles)
        setRejectedFiles(newRejectedFiles)
        dispatch(setContributeMultiFormData(newAcceptedFiles));
        if (rejectedFilesTemp.length) {
            toast.error(`Invalid extension type. This field only accepts .jpg, .jpeg, .png, .doc, .docx, .pdf. Max Size is 8MB.`)
        }
        
        // if (acceptedLength > 0) {
        //   acceptedFilesTemp.map((file) => {
        //     uploadSingleItem(file);
        //   })
        // }
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop, maxFiles: 0, disabled: (acceptedFiles.length + rejectedFiles.length) >= 10, maxSize: "8000000", accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'application/pdf': [],
            'application/msword': ['.doc', '.docx']
        }
    })

    const handleRemoveAcceptedFile = (i) => {
      const item = acceptedFiles[i]?.uuid ?? '';
      item && dispatch(removeContributeMultiFormItem(item))
      setAcceptedFiles((prev) => [...prev.filter((item, index) => index !== i)])
    }
    const handleRemoveRejectedFile = (i) => {
        setRejectedFiles((prev) => [...prev.filter((item, index) => index !== i)])

    }

    const rejectedFilesMap = useCallback(rejectedFiles.map((file, i) => (
        
            <li key={i} className='file-list'>
                <div className='file-list-container'>
                    <div className='file-attachment'><i style={{ fontSize: '20px' }} className="material-icons">attach_file</i></div>
                    <div className='file-container'>
                      <div className={`file-name-container ${file?.file?.path ? "file-list-error" : ""}`}>
                          <div className='file-name'>{file.path ? file?.path : file?.file?.path}</div>
                          <div className='file-list-actions'>
                              {
                                  file?.file?.path &&
                                  <>
                                      {/* <div><img src='/icons/icon-refresh.svg' /></div> */}
                                      <div>
                                          <i style={{ fontSize: '20px' }} onClick={() => handleRemoveRejectedFile(i)} className="material-icons">close</i>
                                      </div>
                                  </>
                              }
                          </div>
                      </div>
                    </div>
                </div>
            </li>
        
    )), [rejectedFiles]);
    const acceptedFilesMap = useCallback(acceptedFiles.map((file, i) => (
        
            <li key={i} className='file-list'>
                <div className='file-list-container'>
                    <div className='file-attachment'><i style={{ fontSize: '20px' }} className="material-icons">attach_file</i></div>
                    <div className='file-container'>
                      <div className={`file-name-container`}>
                          <div className='file-name'>{file.path ? file?.path : file?.file?.path}</div>
                          <div className='file-list-actions'>
                              <div>
                                  {/* {file.error ? <i style={{ fontSize: '20px' }} onClick={() => uploadSingleItem(file)} className="material-icons">refresh</i> : null} */}
                                  <i style={{ fontSize: '20px' }} onClick={() => handleRemoveAcceptedFile(i)} className="material-icons">close</i>
                              </div>
                          </div>
                      </div>
                      {/* <div className={`list-item-status ${file.error ? 'error' : file.loading ? 'loading' : 'success'}`} ><div className='bg' /></div> */}
                    </div>
                </div>
            </li>
        
    )), [acceptedFiles]);

    return (
        <div>
            <div className='drag-head'>
                <div className='drag-add-items'>{t("Add Items")}</div>
                <div className='drag-max-items'>{t("Maximum: 10 items")}</div>
            </div>
            <div className='drag-and-drop'>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        <div className='text-center'>

                            <i style={{ fontSize: '60px', color: '#416579' }} className="material-icons-outlined">move_to_inbox</i>
                            <div className='drag-text'>{t('Drag & drop your files here')}</div>
                            <div className='drag-accepted-text'>{`${t('Accepted files')}: .rar .zip .doc .docx .pdf .jpg...`}</div>
                        </div>
                    }
                </div>
            </div>
            <div className='file-list-wrapper'>
                {acceptedFilesMap}
                {rejectedFilesMap}
            </div>
        </div>
    )
}

export default ContributeDragAndDrop