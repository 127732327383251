import React, { useCallback } from 'react'
import { Collapse } from 'antd'
import { CollectionBox } from 'features/Collections'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, getLangValue } from 'utils/functions'
import { loadItemModal } from 'features/Collections/redux/collectionsActions'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import { ITEM } from 'shared/constants'
import { JdaLink } from 'shared/components'
import { useTranslation } from 'react-i18next'
const { Panel } = Collapse

const MyCollectionsAccordion = ({ collections }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const openModal = useCallback(debounce(id => dispatch(loadItemModal({ id, type: ITEM })), 500, true), [dispatch])
  const siteLangcode = useSelector(selectLangcodeFlag)
  const renderItemTile = ({ title, id, itemData }) => (
    <li key={itemData.id} className='c-cs-accordion__item u-color-black c-heading c-heading--l6' onClick={() => openModal(itemData.id)}>
      {getLangValue(siteLangcode, itemData.title)}
    </li> 
  )
  
  const renderHeader = ({ title, itemCount, published, id }) => (
    <CollectionBox collection={id}>
      <div className='u-color-white'>
        <div className='c-heading c-heading--l6'>{getLangValue(siteLangcode, title)}</div>
        <div className='c-copy c-copy--bold'>{`${itemCount} ${t('Items')} | ${t(published ? 'Public' : 'Private')}`}</div>
      </div>
    </CollectionBox>
  )
  return (
    <Collapse
      className='c-cs-accordion'
      bordered={false}
      expandIconPosition='right'
      expandIcon={() => <i className='material-icons'>expand_more</i>}
    >
      {collections.map(collection => (
          <Panel
            key={`${collection.id}-${collection.langcode}`}
            header={renderHeader(collection)}
            className={`c-cs-accordion__panel c-cs-accordion__panel--l1`}
          >
          <CollectionBox collection={collection.id}>
            {collection.items && (
              <ul className='c-cs-accordion__list u-bg-grey-100'>
                {collection.items.filter(({ type }) => type === 'collectionItem').map(renderItemTile)}
              </ul>
            )}
            <JdaLink
              to={`/collection/${collection.id}`}
              className='c-btn c-btn--full c-btn--lg c-btn--sharp u-color-white u-bg-orange'
            >
              {t('View Collection')}
            </JdaLink>
          </CollectionBox>
        </Panel>
      ))}
    </Collapse>
  )
}

export default MyCollectionsAccordion
