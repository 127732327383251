import React from 'react'
import { ContributionItem } from 'features/Collections'
import { selectContributeFormData, selectContributeFormLanguage, selectContributePreviewVisible, selectIsMultipleUploadMode } from 'features/Contribute/redux/contributeSelectors'
import { useSelector } from 'react-redux'
import Bookmarlet from '../Bookmarklet'

const ContributePreview = () => {
  const data = useSelector(selectContributeFormData)
  const isMultiple = useSelector(selectIsMultipleUploadMode);
  const activeTab = useSelector(selectContributeFormLanguage)
  const previewVisible = useSelector(selectContributePreviewVisible)
  return (
    <div className='c-contribute-preview'>
      {!previewVisible ? <Bookmarlet />
      : isMultiple ? null : <ContributionItem preview activeTab={activeTab} data={{...data, url: data.uri}} />}
    </div>
  )
}

export default ContributePreview
