import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'ACCOUNT_FORM'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
  'SET_DATA',
  'CLEAR_DATA',
  'SET_MODAL_TYPE',
  'TOGGLE_ROLE',
  'SET_ERRORS',
  'RESET_PASSWORD_FIELD',
  'OPEN_MODAL',
  'CLOSE_MODAL',
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'SUBMIT_FORM',
  'UPDATE_ACCOUNT',
  'UPDATE_PASSWORD',
  'CHECK_USERNAME',
  'CHECK_EMAIL'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
