import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setMcSort, setItemModalType, loadUserItems } from 'features/Collections/redux/collectionsActions'
import SortDropdown from './SortDropdown'
import { selectUserCollectionCount } from 'features/Collections/redux/collectionsSelectors'
import { CREATE_COLLECTION, USER_COLLECTIONS } from 'shared/constants'

const McSubNav = ({ toggleSettingsMode }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const collectionCount = useSelector(selectUserCollectionCount)
  const openCreateCollectionModal = useCallback(() => dispatch(setItemModalType(CREATE_COLLECTION)), [dispatch])
  const setSort = useCallback(sort => {
    dispatch(setMcSort(sort))
    dispatch(loadUserItems([USER_COLLECTIONS]))
  }, [dispatch])
  const displayClass = 'u-hidden u-block@sm'
  const sortOptions = [
    { label: 'Newest', value: 'newest' },
    { label: 'Oldest', value: 'oldest' },
    { label: 'Alphabetical', value: 'alphabetical' }
  ]
  return (
    <nav className='c-toolbar c-toolbar--my-collections'>
      <div className='c-toolbar__header-col'>
        <h1 className='c-heading c-heading--l1'>
          {t('My Collections')}
        </h1>
        <button
          className='c-btn c-btn--bare u-mlxs u-ml@sm c-toolbar__settings-btn u-hidden u-block@md'
          onClick={toggleSettingsMode}
          type='button'
        >
          <span className="c-btn__icon c-btn__icon--lg">
            <i className="material-icons">settings</i>
          </span>
        </button>
     </div>
      <div className='c-toolbar__header-col'>
        <div className={displayClass}>
          {collectionCount ? Number(collectionCount).toLocaleString() : 0} {t(`Collection${collectionCount === 1 ? '' : 's'}`)}
        </div>
        <div className='c-toolbar__header-col c-toolbar__header-col--full-bl u-mlxs u-ml@sm'>
          <SortDropdown borders options={sortOptions} onChange={setSort} />
        </div>
        <div className='c-toolbar__header-col c-toolbar__header-col--full-bl u-pl'>
          <button
            className='c-btn c-btn--sm c-btn--hover-fx u-color-white u-bg-orange c-toolbar__create-new-btn'
            type='button'
            aria-labelledby='mc-subnav-create-new'
            onClick={openCreateCollectionModal}
          >
            <span id='mc-subnav-create-new' className={displayClass}>{t('CREATE NEW COLLECTION')}</span>
            <span className="c-btn__icon u-ml@sm">
              <i className="material-icons">add_circle_outline</i>
            </span>
          </button>
        </div>
     </div>
    </nav>
  )
}

export default McSubNav
