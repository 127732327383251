import React from 'react'
import InputWrapper from '../InputWrapper'
import { useTranslation } from 'react-i18next'

const FormSelect = ({
  name,
  caption,
  required,
  label,
  labelClassname = '',
  options = [],
  blankDefault = false,
  blankDefaultText,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      name={name}
      caption={caption}
      required={required}
      label={label}
      labelClassname={labelClassname}
    >
      <select name={name} {...rest}>
        {blankDefault && <option hidden disabled value="">{blankDefaultText || `-- ${t('select an option')} --`}</option>}
        {options.map(({ label, value }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </select>
    </InputWrapper>
  )
}

export default FormSelect
