import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'

const Bookmarlet = () => {
  const { t } = useTranslation()
  const siteLang = useSelector(selectLangcodeFlag)
  const host = window.location.host
  const bookmarkletFn = `javascript:(function()%7Bserialize%20%3D%20function(a)%20%7Bvar%20b%20%3D%20%5B%5D%3Bfor%20(var%20c%20in%20a)%20a.hasOwnProperty(c)%20%26%26%20b.push(encodeURIComponent(c)%20%2B%20%22%3D%22%20%2B%20encodeURIComponent(a%5Bc%5D))%3Breturn%20b.join(%22%26%22)%7D%3Bvar%20title%20%3D%20document.title%2Cdescription%20%3D%20document.querySelector(%22meta%5Bname%3D'description'%5D%22)%2Ctype%20%3D%20document.querySelector(%22meta%5Bproperty%3D'og:type'%5D%22)%2Curl%20%3D%20document.location.href%3Bquery%20%3D%20serialize(%7Btitle%3A%20title%2Cdescription%3A%20description%20%3F%20description.content%20%3A%20%22%22%2Ctype%3A%20type%20%3F%20type.content%20%3A%20%22%22%2Curl%3A%20url%7D)%3Bvar%20regex%20%3D%20%2F%5B%5Cu3000-%5Cu303F%5D%7C%5B%5Cu3040-%5Cu309F%5D%7C%5B%5Cu30A0-%5Cu30FF%5D%7C%5B%5CuFF00-%5CuFFEF%5D%7C%5B%5Cu4E00-%5Cu9FAF%5D%7C%5B%5Cu2605-%5Cu2606%5D%7C%5B%5Cu2190-%5Cu2195%5D%7C%5Cu203B%2Fg%3Bif(regex.test(decodeURIComponent(query)))%7Bwindow.open(%22http%3A%2F%2F${host}%2Fja%2Fcontribute%3F%22%20%2B%20query%2C%20%22JDA%20Bookmarklet%22)%3B%7Delse%20%7Bwindow.open(%22http%3A%2F%2F${host}%2F${siteLang}%2Fcontribute%3F%22%20%2B%20query%2C%20%22JDA%20Bookmarklet%22)%3B%7D%7D)()`
  return (
    <div className='c-bookmarklet'>
      <div className='c-heading c-heading--l3'>{t('JDA Bookmarklet')}</div>
      <p>
        {t(`In an effort to help you build more robust collections, our bookmarklet tool will enable you to easily add new items to collections that don't currently exist in our archive.`)}
      </p>
      <p>
        {t('This tool can be placed in your bookmark bar for easy access. As you come across sites you would like to include, just click on the JDA Bookmarklet tab in your browser and fill out the form that pops up. Some fields will be automatically filled for your convenience.')}
      </p>
      <p className='c-copy c-copy--bold u-mb'>{t('Drag to your bookmark bar')}</p>
      <a href={bookmarkletFn} className='c-btn c-btn--full c-btn--lg u-color-white u-bg-grey-300 c-bookmarklet__btn'>
        {t('JDA Bookmarklet')}
      </a>
    </div>
  )
}

export default Bookmarlet
