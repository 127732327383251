import React from 'react'
import { useDrop } from 'react-dnd'

const CollectionBox = ({ children, collection }) => {
  const selectOpacity = (isActive, canDrop) => {
    if (isActive) {
      return 1
    }
    else if (canDrop) {
      return 0.4
    }
    else {
      return 1
    }
  }

  const [{ canDrop, isOver }, drop] = useDrop({
      accept: 'box',
      drop: () => ({ collection }),
      collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
      }),
  })
  const isActive = canDrop && isOver
  const opacity = selectOpacity(isActive, canDrop)

  return (
    <div ref={drop} style={{ opacity }}>
      {children}
    </div>
  )
}

export default CollectionBox