import React from 'react'
import { DragPreviewImage, useDrag } from 'react-dnd'

const ItemBox = ({ data, children, onDrop, type, customPreview }) => {
  const item = { data, type: 'box' }
  const itemType = children.type?.name
  const [{ opacity }, drag, preview] = useDrag({
      item,
      end(item, monitor) {
          const dropResult = monitor.getDropResult()
          if (item && dropResult && dropResult.type !== 'ctaBox') {
            onDrop && onDrop(item.data, dropResult.collection)
          }
      },
      collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
      }),
  })
  const gridItem = React.Children.map(children, child => {
    const props = { drag, opacity }
    if (React.isValidElement(child)) {
        return (
          <>
            {customPreview && <DragPreviewImage connect={preview} src={customPreview} />}
            {React.cloneElement(child, props)}
          </>
        )
    }
    return child
  })

  if(itemType === 'CollectionItemGrid' || type === 'image-grid') {
    return gridItem
  }

  const classStyle = opacity === 0.4 ? 'is-dragging' : ''

  return (
    <>
      {customPreview && <DragPreviewImage connect={preview} src={customPreview} />}
      <div ref={drag} style={{ opacity }} className={classStyle}>
        {children}
      </div>
    </>
  )
}

export default ItemBox