export const pageMeta = {
  search: [
    {
      "tag": "meta",
      "attributes": {
        "name": "title",
        "content": "Japan Disaster Archive - Search Results | Japan Disasters Archive"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:site_name",
        "content": "JDA"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:title",
        "content": "Japan Disaster Archive - Search Results | Japan Disasters Archive"
      }
    }
  ],
  collections: [
    {
      "tag": "meta",
      "attributes": {
        "name": "title",
        "content": "Japan Disaster Archive - Collections Results | Japan Disasters Archive"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:site_name",
        "content": "JDA"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:title",
        "content": "Japan Disaster Archive - Collections Results | Japan Disasters Archive"
      }
    }
  ],
  contribute: [
    {
      "tag": "meta",
      "attributes": {
        "name": "title",
        "content": "Japan Disaster Archive - Contribute | Japan Disasters Archive"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:site_name",
        "content": "JDA"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:title",
        "content": "Japan Disaster Archive - Contribute | Japan Disasters Archive"
      }
    }
  ],
  mycollections: [
    {
      "tag": "meta",
      "attributes": {
        "name": "title",
        "content": "Japan Disaster Archive - My Collections | Japan Disasters Archive"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:site_name",
        "content": "JDA"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:title",
        "content": "Japan Disaster Archive - My Collections | Japan Disasters Archive"
      }
    }
  ],
  profile: [
    {
      "tag": "meta",
      "attributes": {
        "name": "title",
        "content": "Japan Disaster Archive - Profile | Japan Disasters Archive"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:site_name",
        "content": "JDA"
      }
    },
    {
      "tag": "meta",
      "attributes": {
        "property": "og:title",
        "content": "Japan Disaster Archive - Profile | Japan Disasters Archive"
      }
    }
  ]
}
