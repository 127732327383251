import React from 'react'
import { useTranslation } from 'react-i18next';
import DotsLoader from 'shared/components/DotsLoader'
const InputWrapper = ({
  name,
  caption,
  required,
  label,
  labelEx,
  labelClassname = '',
  children,
  className = '',
  applyToAll = false,
  applyToAllValue = false,
  onApplyToAllValueChanged,
  errorMessage,
  duplicateUsername,
  duplicateChecking,
  formatErrorMessage,
}) => {
  const onCheckboxClicked = (event) => {
    onApplyToAllValueChanged && onApplyToAllValueChanged(event.target.checked);
  }

  const {t} = useTranslation();

  return (
    <div className={`c-input-wrapper ${className}`}>
      <div className='c-input-wrapper_label-container'>
        <label className={`c-input-wrapper__label ${labelClassname}`} htmlFor={name}>
          {label || name}
          {required && <span className='c-input-wrapper__required'>*</span>}
          {labelEx && <span className='c-input-wrapper__label-ex'>{labelEx}</span>}
        </label>

        {applyToAll ?
          <label>
            <input type='checkbox' name='apply_to_all' className='c-input-wrapper_checkbox' value={applyToAllValue} onChange={onCheckboxClicked}/>
            {t('Apply tags to all items.')}
          </label> : null}
      </div>
      {children}
      {duplicateChecking ? <div className="c-form__loading"><DotsLoader loading={duplicateChecking} /></div> : (duplicateUsername && <div className="c-form__errorMessage">{errorMessage}</div>)}
      {formatErrorMessage &&<div className="c-form__errorMessage">{formatErrorMessage}</div>}
      {}
      {caption &&
        <div className='c-form__caption'>
          {caption}
        </div>
      }
    </div>
  )
}

export default InputWrapper
