import React from 'react'
import {useTranslation} from 'react-i18next'
const StyleGuide = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div className="o-container">
        <h1 className="c-heading c-heading--l1 u-mb">Style Guide</h1>
        <hr/>
      </div>

      <br/>

      <section id="fonts" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Fonts</h2>
          <p style={{fontWeight: '400'}}>Lato Regular</p>
          <p style={{fontWeight: '700'}}>Lato Bold</p>
          <p style={{fontWeight: '900'}}>Lato Heavy</p>
        </div>
      </section>

      <br/>

      <section id="container" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Container</h2>
        </div>
        <div className="o-container">
          Default Container:
          <br/>
          <p>JDA doesn't really utilize containers across the site because of the full screen layout. 
          There is a container on the homepage which will utilize the default container with 32px of padding 
          on screens less than 1440px. Above 1440px the container will git a max width of 1376px. If this needs
          to be wider, I would suggest using the container padding up until the 1600px breakpoint and then setting a 
          max width of 1536px. Screens with the sidebar layout will likely just use the grid (sidebar and main have 
          own padding possibly set with util) and components that have paragraph text that could get long should have 
          a readable max width set for larger screens (ex. resources).</p>
        </div>
      </section>

      <br/>

      <section id="grid" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Grid</h2>
          <div className="o-grid">
            <div className="o-grid__col u-12/12">Default Grid (16px gutter)</div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
          </div>

          <br/>

          <div className="o-grid o-grid--sm">
            <div className="o-grid__col u-12/12">Small Grid (16px mobile 32px desktop gutter)</div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
            <div className="o-grid__col u-2/12@md">
              <div className="u-bg-black u-color-white">2/12 col</div>
            </div>
          </div>
        </div>
      </section>

      <br/>

      <section id="headings" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Headings</h2>
          <p>Heading styles should not need to be associated with the html tags, except when inside of a wysi component.</p>
          <div className="c-heading c-heading--l1">Heading Level 1</div>
          <div className="c-heading c-heading--l2">Heading Level 2</div>
          <div className="c-heading c-heading--l3">Heading Level 3</div>
          <div className="c-heading c-heading--l3 c-heading--uppercase">Heading Level 3 Uppercase</div>
          <div className="c-heading c-heading--l4">Heading Level 4</div>
          <div className="c-heading c-heading--l5">Heading Level 5</div>
          <div className="c-heading c-heading--l6">Heading Level 6</div>
        </div>
      </section>

      <br/>

      <section id="colors" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Colors</h2>
          <p>Add regularly reused colors into colors object. Do not add one-off colors, just use hex or rgb/rgba.</p>
          <br/>
          <div className="u-bg-white">White</div>
          <div className="u-bg-grey-100">Grey-100</div>
          <div className="u-bg-grey-200">Grey-200</div>
          <div className="u-bg-grey-300">Grey-300</div>
          <div className="u-bg-grey-400">Grey-400</div>
          <div className="u-bg-teal">Teal</div>
          <div className="u-bg-navy u-color-white">Navy</div>
          <div className="u-bg-black u-color-white">Black</div>
          <div className="u-bg-orange u-color-white">Orange</div>
        </div>
      </section>

      <br/>

      <section id="copy" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Copy</h2>
          <p>Should encompass regularly reused text styles, do not add modifiers for one-off or rarely used copy styles.
             The most reused copy fonts sizes/ styles I found while looking through the live site were 12px and 16px. If 13px
             is seen in the design, consolidate to 12px.
          </p>
          <br/>
          <div className="c-copy">Copy Default</div>
          <div className="c-copy c-copy--bold">Copy Default Bold</div>
          <div className="c-copy c-copy--xs">Copy Subtitle</div>
          <div className="c-copy c-copy--xs c-copy--italic">Copy Subtitle</div>
          <div className="c-copy c-copy--sm">Copy Subtitle</div>
          <div className="c-copy c-copy--icon"><span className="c-copy__icon c-copy__icon--left"><i className="material-icons">layers</i></span>Copy Default Icon Left</div>
          <div className="c-copy c-copy--icon">Copy Default Icon Right<span className="c-copy__icon c-copy__icon--right"><i className="material-icons">layers</i></span></div>
        </div>
      </section>

      <br/>

      <section id="wysi" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Wysi</h2>
          <div className="c-wysiwyg" style={{maxWidth: '800px'}}>
            <h1>About JDA</h1>
            <p>A project of Harvard University’s Reischauer Institute of Japanese Studies, the Japan Disasters Digital Archive (JDA) is an evolving, collaborative space for citizens, researchers, students, and policy makers, and a site of shared memory for those most directly affected by these events. The digital archive is an advanced search engine for archived materials from all over the web, individuals’ testimonials, tweets, prominently including content from international partners who are building digital repositories about the disasters. The site not only facilitates searching of materials, but allows users to create curated collections and interactive presentations on topics of interest to them. <a href="http://jdarchive.org/en/collectionsearch?sort=archivedate&amp;order=desc">Collections </a>and presentations can be shared publicly and enhance the value of the various items accessible through the archive. The archive also features <a href="http://jdarchive.org/en/archivesearch#map">an innovative map</a> feature that visualizes all materials that are tagged with geographic information in real time.</p>
            <p>The JDA project relies on the support of partner organizations around the world to supply digital contents, including websites, tweets, video, audio, news articles, and much more<sup>1</sup>. The JDA does not store copies of the data; rather it seamlessly links to digital materials archived by partner projects, allowing you to search, view, and sort items across separate archives and collections in one interface. This page provides information on the general infomation of the project.&nbsp;For information on how to use the archive, please visit the <a href="http://jdarchive.org/en/about">"How to Use" page</a>. Also more infomraiton on how to contribute information to the archive, please visit the <a href="http://jdarchive.org/en/about/how-to-contribute">“How to Contribute” page.</a>&nbsp;You can find more information about JDA at <a href="http://guides.nccjapan.org/jdarchive?hs=a">North American Coordinating Council on Japanese Library Resources's website</a>.</p>
            <p><em>1 Conceptual Diagram of the Japan Disasters Digital Archive Project</em></p>
            <p><img alt="" src="https://placehold.it/300x300"/></p>
            <h2>Heading Level 2</h2>
            <h3>Heading Level 3</h3>
            <h4>Heading Level 4</h4>
            <h5>Heading Level 5</h5>
            <h6>Heading Level 6</h6>
            <h4>JDA Video Introduction</h4>
            <p>Below is a conceptual introduction to the Japanese Disasters Digital Archive, prepared in March 2015 on the fourth anniversary of the disaster.</p>
            <p><iframe title="video iframe" frameborder="0" height="480" scrolling="yes" src="https://www.youtube.com/embed/yruKjJoWrbk" width="854"></iframe></p>
          </div>
        </div>
      </section>

      <br/>

      <section id="icons" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Icons</h2>
          <p>JDA appears to use Material Icons. If you find an icon in the designs or on the old site that is not material, just try to find a comparable material one. You can find more icons <a href='https://materializecss.com/icons.html'>here</a></p>
          <br/>
          <i className="material-icons">search</i>
          <i className="material-icons">layers</i>
          <i className="material-icons">add_circle_outline</i>
          <i className="material-icons">info_outline</i>
          <i className="material-icons">view_list</i>
          <i className="material-icons">photo</i>
          <i className="material-icons">place</i>
          <i className="material-icons">add_circle</i>
          <i className="material-icons">autorenew</i>
          <i className="material-icons">library_books</i>
          <i className="material-icons">drag_handle</i>
        </div>
      </section>

      <br/>

      <section id="links-buttons" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Links &amp; Buttons</h2>
          <div style={{maxWidth: '800px'}}>
            <p>There are a variety of reused link and button <em>styles</em> on JDA, but be careful to decipher between link and button for styling and semantic purposes.
            Additionally, within a lot of form/interactive pieces of the design there are checkboxes, submits and other elements that are also styled to <em>look like</em> buttons or links</p>
            <br/>
            <p className="c-copy c-copy--bold">Anchor Tag</p>
            <p>See MDN Docs for roles: <a className="c-link" href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a">https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a</a></p>
            <p>See W3C Docs for more information and specifically the "NOTE": <a className="c-link" href="https://w3c.github.io/aria/#link">https://w3c.github.io/aria/#link</a></p>
            <br/>
            <p className="c-copy c-copy--bold">Button Tag</p>
            <p>See MDN Docs for roles: <a className="c-link" href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button">https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button</a></p>
            <p>See W3C Docs for more information and specifically the "NOTE": <a className="c-link" href="https://w3c.github.io/aria/#button">https://w3c.github.io/aria/#button</a></p>
            <br/>
          </div>
          <h3 className="c-heading c-heading--l4">Link Styles</h3>
          <a href="www.google.com" className="c-link c-link--default">Default Link Anchor Tag</a>
          <button href="www.google.com" className="c-link c-link--default">Default Link Button Tag</button>
          <br/>
          <a href="www.google.com" className="c-link c-link--default c-link--bold">Default Link Anchor Tag</a>
          <button href="www.google.com" className="c-link c-link--default c-link--bold">Default Link Button Tag</button>
          <br/>
          <a href="www.google.com" className="c-link c-link--icon">
            <span className="c-link__icon c-link__icon--left">
              <i className="material-icons">info_outline</i>
            </span>
            Anchor Tag
          </a>
          <br/>
          <a href="www.google.com" className="c-link c-link--icon">
            Anchor Tag
            <span className="c-link__icon c-link__icon--right">
              <i className="material-icons">info_outline</i>
            </span>
          </a>
          <br/>
          <button className="c-link c-link--icon">
            <span className="c-link__icon c-link__icon--left">
              <i className="material-icons">info_outline</i>
            </span>
            Button Tag
          </button>
          <br/>
          <button className="c-link c-link--icon">
            Button Tag
            <span className="c-link__icon c-link__icon--right">
              <i className="material-icons">info_outline</i>
            </span>
          </button>
          <br/>
          <br/>
          <h3 className="c-heading c-heading--l4">Button Styles</h3>
          <a href="www.google.com" className="c-btn c-btn--sm c-btn--transparent">
            Small Button Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--sm u-color-white u-bg-orange">
            Small Button Button Tag
          </button>
          <br/>
          <a href="www.google.com" className="c-btn c-btn--md c-btn--transparent">
            <span className="c-btn__icon c-btn__icon--left">
              <i className="material-icons">info_outline</i>
            </span>
            Medium Button Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--md c-btn--transparent">
            Medium Button Button Tag
            <span className="c-btn__icon c-btn__icon--right">
              <i className="material-icons">info_outline</i>
            </span>
          </button>
          <br/>
          <a href="www.google.com" className="c-btn c-btn--lg c-btn--transparent">
            <span className="c-btn__icon c-btn__icon--left">
              <i className="material-icons">info_outline</i>
            </span>
            Large Button Full Width Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--lg c-btn--transparent">
            Large Button Full Width Button Tag
            <span className="c-btn__icon c-btn__icon--right">
              <i className="material-icons">info_outline</i>
            </span>
          </button>
          <br/>
          <a href="www.google.com" className="c-btn c-btn--lg c-btn--full u-color-white u-bg-orange">
            Large Button Full Width Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--lg c-btn--full u-color-white u-bg-teal">
            Large Button Full Width Button Tag
          </button>
          <br/>
          <a href="www.google.com" className="c-btn c-btn--lg u-color-white u-bg-orange">
            Large Button Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--lg u-color-white u-bg-teal">
            Large Button Button Tag
          </button>
          <br/>
          <a href="www.google.com" className="c-btn is-disabled c-btn--lg u-color-white u-bg-orange">
            Large Disabled Button Anchor Tag
          </a>
          <br/>
          <button className="c-btn c-btn--lg is-disabled u-color-white u-bg-teal">
            Large Disabled Button Tag
          </button>
          <br/>
          <br/>
          <br/>
          <div style={{maxWidth: '560px', display: 'flex', justifyContent: 'space-between'}}>
            {/* not a realistic indication of actual form/filter markup or parent div styles DO NOT JUST COPY INLINE STYLES THEY ARE FOR DEMO PURPOSES */}
            <label for="input-1" className="c-btn c-btn--lg u-color-black u-bg-grey-100">
              <span className="c-btn__icon c-btn__icon--left c-btn__icon--sm">
                <i className="material-icons">autorenew</i>
              </span>
              Show Retweets
            </label>
            <label for="input-2" className="c-btn c-btn--lg u-color-black u-bg-grey-100">
              <span className="c-btn__icon c-btn__icon--left c-btn__icon--sm">
                <i className="material-icons">library_books</i>
              </span>
              Show Headlines
            </label>
            <button className="c-btn c-btn--lg u-color-white u-bg-orange">
              Search
            </button>
            <button className="c-btn c-btn--lg u-color-white u-bg-teal">
              More Filters
            </button>
          </div>
          * consolidated styles (notice search and filters are no longer larger)
        </div>
        <br/>
      </section>

      <br/>

      <section id="tags" className="u-mv">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Tags</h2>
          <div style={{maxWidth: '800px'}}>
            Similarly to buttons and links, tags need to be approaced from a sematic perspective when deciding what html tag is best suited for the purpose of the tag. For example,
            if the tag is being used as a anchor link or a button, or a checkbox label if it is a form element being used to set the associated tag.
          </div>

          <br/>
          <button className="c-tag c-tag--default u-bg-orange">
            Add Tag Button Tag
          </button>
          <br/>
          <a href="www.google.com" className="c-tag c-tag--default u-bg-grey-300">
            Add Tag Anchor Tag
          </a>
          <br/>
          <button className="c-tag c-tag--default u-bg-teal">
            Add Tag Button Tag
          </button>

          <br/>
          <button className="c-tag c-tag--add">
            <span className="c-tag__icon c-tag__icon--add">
              <i className="material-icons">add</i>
            </span>
            Add Tag Button Tag
          </button>
          <br/>
          <a href="www.google.com" className="c-tag c-tag--add">
            <span className="c-tag__icon c-tag__icon--add">
              <i className="material-icons">add</i>
            </span>
            Add Tag Anchor Tag
          </a>
        </div>
      </section>

      <br/>

      <section id="collection-item" className="u-pv u-bg-grey-100">
        <div className="o-container">
          <h2 className="c-heading c-heading--l3">Collection Item</h2>
          <br/>

          Default Collection Item
          <div className="c-collection-item u-mbxs">
            <a href="www.google.com" className="c-collection-item__wrapper">

              <div className="c-collection-item__drag c-collection-item__drag--default">
                <i className="material-icons">drag_handle</i>
              </div>

              <div className="c-collection-item__img" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
              
              <div className="c-collection-item__content c-collection-item__content--default">
                <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                  <span className="c-collection-item__subitem">Published 11/25/2019</span>
                  <span className="c-collection-item__subitem c-copy__icon">
                    <i className="material-icons">person</i>
                    ADK Group
                  </span>
                  <span className="c-collection-item__subitem">Website</span>
                  <span className="c-collection-item__subitem">https://adkgroup.com</span>
                </div>
              </div>
            </a>

            <div className="c-collection-item__actions">
              {/* not sure what these actions are doing -- label them appropriately with their function */}
              <button className="c-collection-item__btn u-bg-grey-300" type="button" aria-label="Move?">
                <i className="material-icons">redo</i>
              </button>
              <button className="c-collection-item__btn u-bg-orange" type="button" aria-label="Duplicate?">
                <i className="material-icons">library_add</i>
              </button>
              <button className="c-collection-item__btn u-bg-red" type="button" aria-label="Remove">
                <i className="material-icons">close</i>
              </button>
            </div>
          </div>

          Default Collection Item Move State (disabled until selection)
          <div className="c-collection-item u-mbxs">
            <a href="www.google.com" className="c-collection-item__wrapper">

              <div className="c-collection-item__drag c-collection-item__drag--default">
                <i className="material-icons">drag_handle</i>
              </div>

              <div className="c-collection-item__img" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
              
              <div className="c-collection-item__content c-collection-item__content--default">
                <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                  <span className="c-collection-item__subitem">Published 11/25/2019</span>
                  <span className="c-collection-item__subitem c-copy__icon">
                    <i className="material-icons">person</i>
                    ADK Group
                  </span>
                  <span className="c-collection-item__subitem">Website</span>
                  <span className="c-collection-item__subitem">https://adkgroup.com</span>
                </div>
              </div>
            </a>

            <div className="c-collection-item__actions is-shown">
              <div className="c-collection-item__select">
                <label className="c-collection-item__select-label c-copy c-copy--sm c-copy--bold" for="move-item">{t('Move to')}:</label>
                <select className="c-collection-item__select-input" name="move-item">
                  <option value="">Select Topic</option>
                  <option value="videos">Videos</option>
                  <option value="other">Other Option</option>
                </select>
              </div>
              <button className="c-collection-item__btn u-bg-green" disabled type="button" aria-label="Complete">
                <i className="material-icons">done</i>
              </button>
              <button className="c-collection-item__btn u-bg-red" type="button" aria-label="Cancel">
                <i className="material-icons">block</i>
              </button>
            </div>
          </div>

          Default Collection Item Move State
          <div className="c-collection-item u-mbxs">
            <a href="www.google.com" className="c-collection-item__wrapper">

              <div className="c-collection-item__drag c-collection-item__drag--default">
                <i className="material-icons">drag_handle</i>
              </div>

              <div className="c-collection-item__img" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
              
              <div className="c-collection-item__content c-collection-item__content--default">
                <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                  <span className="c-collection-item__subitem">Published 11/25/2019</span>
                  <span className="c-collection-item__subitem c-copy__icon">
                    <i className="material-icons">person</i>
                    ADK Group
                  </span>
                  <span className="c-collection-item__subitem">Website</span>
                  <span className="c-collection-item__subitem">https://adkgroup.com</span>
                </div>
              </div>
            </a>

            <div className="c-collection-item__actions is-shown">
              <div className="c-collection-item__select">
                <label className="c-collection-item__select-label c-copy c-copy--sm c-copy--bold" for="move-item">{t('Move to')}:</label>
                <select className="c-collection-item__select-input" name="move-item" value="videos">
                  <option value="">Select Topic</option>
                  <option value="videos">Videos</option>
                  <option value="other">Other Option</option>
                </select>
              </div>
              <button className="c-collection-item__btn u-bg-green" type="button" aria-label="Complete">
                <i className="material-icons">done</i>
              </button>
              <button className="c-collection-item__btn u-bg-red" type="button" aria-label="Cancel">
                <i className="material-icons">block</i>
              </button>
            </div>
          </div>

          <br/>
          Default Collection Item w/ Blurb (Significance)
          <div className="c-collection-item u-mbxs">
            <a href="www.google.com" className="c-collection-item__wrapper">

              <div className="c-collection-item__drag c-collection-item__drag--default">
                <i className="material-icons">drag_handle</i>
              </div>
        
              <div className="c-collection-item__content c-collection-item__content--default">
                <h3 className="c-heading c-heading--l6">The Significance of the websites in this collection</h3>
                <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                  <span className="c-collection-item__subitem">Published 11/25/2019</span>
                  <span className="c-collection-item__subitem c-copy__icon">
                    <i className="material-icons">person</i>
                    ADK Group
                  </span>
                  <span className="c-collection-item__subitem">Website</span>
                  <span className="c-collection-item__subitem">https://adkgroup.com</span>
                </div>

                <div className="c-collection-item__blurb">
                  The significance of the websites in this collection is that 
                  they’re powerful reminders of the people and history of Japan, especially in the face of adversity. 
                  The significance of the websites in this collection is that they’re powerful reminders of the people 
                  and history of Japan, especially in the face of adversity. The significance of the websites in this 
                  collection is that they’re powerful...
                </div>
              </div>
            </a>

            <div className="c-collection-item__actions">
              {/* not sure what these actions are doing -- label them appropriately with their function */}
              <button className="c-collection-item__btn u-bg-grey-300" type="button" aria-label="Move">
                <i className="material-icons">redo</i>
              </button>
              <button className="c-collection-item__btn u-bg-orange" type="button" aria-label="Duplicate?">
                <i className="material-icons">library_add</i>
              </button>
              <button className="c-collection-item__btn u-bg-red" type="button" aria-label="Remove">
                <i className="material-icons">close</i>
              </button>
            </div>
          </div>

          <br/>
          Default Collection Item Vertical
          <div style={{maxWidth: '1040px'}}>{/* ignore just for styleguide */}
            <div className="o-grid o-grid--xs o-grid--equal-height">
              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <div>
                        <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                        <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                          <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                          <span className="c-collection-item__subitem c-copy__icon">
                            <i className="material-icons">layers</i>
                            9 Items
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item with Really Long Title That Makes the other Grid Items Taller Too</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>

              <div className="o-grid__col u-6/12@xs u-4/12@md u-3/12@lg u-mbxs">

                <div className="c-collection-item">
                  <a href="www.google.com" className="c-collection-item__wrapper c-collection-item__wrapper--vertical">

                    <div className="c-collection-item__img c-collection-item__img--vertical" style={{backgroundImage: 'url("https://placehold.it/200x200/416679")'}}></div>
                    
                    <div className="c-collection-item__content">
                      <h3 className="c-heading c-heading--l6">Title of the Collection Item</h3>
                      <div className="c-collection-item__subtitle c-copy c-copy--xs c-copy--italic c-copy--icon">
                        <span className="c-collection-item__subitem">11/25/2019 by ADK Group</span>
                        <span className="c-collection-item__subitem c-copy__icon">
                          <i className="material-icons">layers</i>
                          9 Items
                        </span>
                      </div>
                    </div>

                    <div className="c-collection-item__drag c-collection-item__drag--vertical">
                      <i className="material-icons">drag_handle</i>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>

        </div>
        <br/>
      </section>

    </div>
  )
}

export default StyleGuide
