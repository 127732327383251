import { EN, JA } from 'shared/constants'

const fields = {
  keyword: {
    name: 'title',
    param: 'keyword',
    label: 'Search',
    type: 'text',
    value: '',
    conjunction: 'AND'
  },
  partners: {
    name: 'uid',
    param: 'partners',
    label: 'Partners',
    type: 'select',
    value: [],
    conjunction: 'OR',
    options: []
  },
  media: {
    name: 'media_type_name',
    param: 'media',
    label: 'Media Type',
    type: 'checkbox',
    value: [],
    conjunction: 'OR',
    options: [
      {
        name: 'website',
        label: 'Website'
      },
      {
        name: 'image',
        label: 'Image'
      },
      {
        name: 'audio',
        label: 'Audio'
      },
      {
        name: 'broadcast',
        label: 'Broadcast'
      },
      {
        name: 'document',
        label: 'Document'
      },
      {
        name: 'video',
        label: 'Video'
      },
      {
        name: 'tweet',
        label: 'Tweet'
      },
      {
        name: 'testimonial',
        label: 'Testimonial'
      },
      {
        name: 'article',
        label: 'News Article'
      }
    ]
  },
  retweets: {
    name: 'retweets',
    param: 'rt',
    label: 'Retweets Only',
    type: 'checkbox',
    value: false,
    icon: "autorenew",
    conjunction: 'AND'
  },
  headlines: {
    name: 'headlines',
    param: 'headlines',
    label: 'Show Headlines',
    type: 'checkbox',
    value: false,
    conjunction: 'AND',
    icon: 'library_books'
  },
  startDate: {
    name: 'field_media_date_create',
    param: 'date_start',
    label: 'Date Published (Start)',
    type: 'date',
    value: false,
    conjunction: 'AND'
  },
  endDate: {
    name: 'field_media_date_create',
    param: 'date_end',
    label: 'Date Published (End)',
    type: 'date',
    value: false,
    conjunction: 'AND'
  },
  startTime: {
    name: 'startTime',
    param: 'time_start',
    label: 'Time Published (Start)',
    type: 'time',
    value: false,
    conjunction: 'AND'
  },
  endTime: {
    name: 'endTime',
    param: 'time_end',
    label: 'Time Published (End)',
    type: 'time',
    value: false,
    conjunction: 'AND'
  },
  language: {
    name: 'langcode',
    param: 'language',
    label: 'Language',
    type: 'checkbox',
    value: [],
    conjunction: 'OR',
    options: [
      {
        name: 'english',
        label: 'English',
        short: EN
      },
      {
        name: 'japanese',
        label: 'Japanese',
        short: JA
      }
    ]
  },
}

const sortOptions = {
  items: {
    relevance: 'Relevance',
    published_asc: 'Oldest',
    published_desc: 'Newest'
  },
  collections: {
    oldest: 'Oldest',
    newest: 'Newest',
    relevance: 'Relevance',
  }
}

const viewOptions = {
  collection: {
    name: 'collection',
    icon: 'layers',
    label: 'Collection',
    pages: ['detail']
  },
  list: {
    name: 'list',
    icon: 'view_list',
    label: 'List',
    viewLabel: "List View",
    pages: ['detail', 'items']
  },
  photo: {
    name: 'photo',
    icon: 'photo',
    label: 'Photo',
    viewLabel: "Photo View",
    pages: ['detail', 'items']
  },
  map: {
    name: 'map',
    icon: 'place',
    label: 'Map',
    viewLabel: "Map View",
    pages: ['detail', 'items']
  }
}

export {
  fields,
  sortOptions,
  viewOptions
}