/* eslint-disable */
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import parse, { domToReact } from 'html-react-parser';
import { decode } from 'he'

export const sanitize = html => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'span', 'iframe']),
    allowedAttributes: {
      'a': ['href'],
      'iframe': ['src'],
      img: ['src', 'height', 'width', 'alt'],
      span: ['class']
    }
  })
}

export const parseHtmlToReact = (html, { parseOptions = {} } = {}) => {
  const htmlStr = html
  const decoded = decode(htmlStr)
  const sanitized = sanitize(decoded)
  return (
    <>
      {parse(sanitized, parseOptions)}
    </>
  )
}

export const parseCollectionPreview = preview => {
  if (!preview) return null
  const options = {
    replace: ({ attribs = {}, children, name, type }) => {
      if (name === 'p' && type === 'tag') {
        return (
          <span {...attribs}>
            {domToReact(children, options)}
          </span>
        );
      }else if (name === 'blockquote' && type === 'tag') {
        return (domToReact(children, options));
      } 
    }
  }
  const html = `${preview.substr(0, 70)}${preview.length > 70 ? '...' : ''}`
  return parseHtmlToReact(html, { parseOptions: options })
}

export const extractNodeId = (string = '') => {
  const rgx = /entity:node\/([0-9]*)/
  const match = string.match(rgx)
  return match && match.length && match[1]
}

export const parseUrlSearch = search => {
  if (!search || typeof search !== 'string' || !search.length) return null
  const params = new URLSearchParams(search)
  const entries = params.entries()
  const paramObj = {}
  for (let entry of entries) {
    paramObj[entry[0]] = entry[1]
  }
  return paramObj
}

export const getBackgroundImageStyle = (url) => {
  if (!url) return {}
  const img = url.indexOf(' ') > -1 ? encodeURI(url) : url
  const imgUrl = img.replace(/\(/g, '%28').replace(/\)/g, '%29');
  return { backgroundImage: `url(${imgUrl})` }
}
