import { selectCollectionMembers } from 'features/Collections/redux/collectionsSelectors'
import Modal from 'features/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ContributorItem from './ContributorItem'

export default function ViewContributorModal({
  isOpen = false,
  onClose = () => {}
}) {
  const { t } = useTranslation()
  const members = useSelector(selectCollectionMembers)

  return (
    <Modal
      isOpen={!!isOpen}
      className='c-modal--centered c-modal--authentication c-modal--confirmation c-modal-view-contributor'
      onRequestClose={onClose}
      shouldReturnFocusAfterClose={true}
    >
      <div className='c-account-form'>
        <div className='c-account-form__header'>
          <span className='c-copy c-copy--bold u-color-white'>{t('View Contributors')}</span>
        </div>
        <div className='u-p c-account-form__content--confirm'>
          <h2 className='c-view-contributor__title'>{t('Current Contributors')}</h2>
          <ul className='c-view-contributor__ul'>
            {members && members.length > 0 && members.map((user, index) => <li key={index} className='c-view-contributor__li'>
              <ContributorItem
                user={user}
                key={index + user.name}
              />
            </li>)}
          </ul>
        </div>
      </div>
    </Modal>
  )
}