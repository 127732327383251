import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'AUTH'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
  'CLEAR_USER',
  'REQUEST_SIGN_IN',
  'CLOSE_ALERT_BANNER'
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'LOGIN',
  'REQUEST_ALERT_CONTENT'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
