import {
  getActionTypes,
  getMultiActionTypes
} from 'bootstrap/redux/action'

const prefix = 'CONTRIBUTE'

/**
 * Actions won't call api
 **/
const unitTypes = getActionTypes([
  'SET_DATA',
  'CLEAR_DATA',
  'SET_LANGUAGE',
  'SET_STEP',
  'REMOVE_TAG',
  'REMOVE_MULTIPLE_TAG',
  'TOGGLE_LANGUAGE',
  'TOGGLE_MULTIPLE_LANGUAGE',
  'REMOVE_MULTIFORM_ITEM',
  'RESET_FORM',
  'SET_MEDIA_TYPE',
  'SET_LOCATION',
  'SET_LOCATION_FOR_MULTIPLE',
  'SET_USER',
  'SET_MULTIFORM_DATA',
  'ADD_MULTIPLE_TAG_SUCCESS',
  'SET_MULTIPLE_UPLOAD',
  'SET_APPLY_TO_ALL',
  'SET_MULTIPLE_TYPE',
  'ADD_MORE_WEBSITES',
  'UPDATE_WEBSITE_FIELD',
], prefix)

/**
 * Actions call api,
 * Thunk middleware allow action creators
 * return a function receives store method dispatch and getState
 **/
const multiTypes = getMultiActionTypes([
  'FORM_SUBMIT',
  'SEARCH_TAGS',
  'ADD_TAG',
  'CREATE_TAG',
  'ADD_TAG_TO_SELECTED_ITEM',
  'UPDATE_SELECTED_ITEM',
  'REMOVE_TAG_FROM_SELECTED_ITEM'
], prefix)

export default {
  ...unitTypes,
  ...multiTypes
}
