import React, { useEffect, useCallback, Fragment } from 'react'
import { FeatureLink, SplitContent, Video, BasicPageSidebar } from 'features/BasicPage'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga'

import { loadResources, loadSinglePage } from 'features/BasicPage/redux/basicPageActions'
import {
  selectBasicPageLoaded,
  selectCurrentPage,
  selectResourceData,
  selectResourcesLoaded
} from 'features/BasicPage/redux/basicPageSelectors'
import { parseHtmlToReact } from 'shared/utils/tools/parse'
import { useLocation } from 'react-router-dom'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'
import Metatags from 'shared/components/Metatags'
import DotsLoader from 'shared/components/DotsLoader'
import { EN } from 'shared/constants'

const BasicPage = ({ pageId }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const siteLang = useSelector(selectLangcodeFlag)
  const data = useSelector(selectResourceData)
  const currentPage = useSelector(selectCurrentPage)
  const activeLangcode = useSelector(selectLangcodeFlag)
  const resourcesLoaded = useSelector(selectResourcesLoaded)
  const currentPageLoaded = useSelector(selectBasicPageLoaded)
  const findPage = useCallback(() => {
    const pathAlias = pathname.slice(siteLang.length + 1)
    return data.length && data.find(d => {
      if (!d) return false
      const { en, ja } = d
      if (pageId) {
        return (en && pageId === en.nid) || (ja && pageId === ja.nid)
      } else {
        return (en && pathAlias === en.path.alias) || (ja && pathAlias === ja.path.alias)
      }
    })
  }, [pathname, data, siteLang, pageId])
  const page = pageId ? currentPage : findPage()
  // defaults to english page if ja page is not found
  const pageLang = page && page[activeLangcode] ? activeLangcode : EN
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    if (pageId) {
      dispatch(loadSinglePage(pageId))
    } else {
      // dispatch(loadResources())
    }
  }, [dispatch, pageId])
  const renderComponent = useCallback((component, compIndex, lang) => {
    if (component.entity && (component.entity[lang] || component.entity.fieldVideo)) {
      const { fieldTitle, fieldFeaturedLinks, fieldSplitContent, fieldBody } = component.entity[lang] || {}
      const fieldVideo = component.entity.fieldVideo
      const videoSrc = fieldVideo && (fieldVideo.entity.fieldMediaOembedVideo || fieldVideo.entity.fieldMediaVideoFile.entity.url)
      return (
        <Fragment key={`bpfrag-${compIndex}`}>
          {fieldTitle && <h4>{fieldTitle}</h4>}
          {fieldFeaturedLinks && fieldFeaturedLinks.map(({ entity }, index) => entity[lang] && (
            <FeatureLink key={`feature-link-${compIndex}-${index}`}
              title={entity[lang].fieldTitle}
              description={entity[lang].fieldShortDescription}
              link={entity[lang].fieldLink}
            />
          ))}
          {fieldSplitContent && fieldSplitContent.map(({ entity }, index) => {
            const imageLeft = (index + 2) % 2 !== 0
            return entity[lang] && (
              <SplitContent
                key={`split-content-${compIndex}-${index}`}
                imageLeft={imageLeft}
                img={entity[lang].fieldImage && entity[lang].fieldImage.entity && entity[lang].fieldImage.entity.fieldMediaImage}
              >
                {entity[lang].fieldBody && parseHtmlToReact(entity[lang].fieldBody.processed)}
              </SplitContent>
            )
          })}
          {fieldBody && parseHtmlToReact(fieldBody.processed)}
          {fieldVideo && <Video src={videoSrc} />}
        </Fragment>
      )
    }
    return null
  }, [])
  return (
    <div className='c-basic-page c-layout__fill'>
      {(pageId ? !currentPageLoaded : !resourcesLoaded) ? <DotsLoader cover bgColor='white' /> : !pageId && resourcesLoaded && <BasicPageSidebar page={page && page.en} />}
      <div className={`c-basic-page__content ${pageId ? 'c-basic-page__content--center' : ''} c-wysiwyg`}>
        {page && page[pageLang] ? (
          <>
            {page[pageLang].metatag.length > 0 && (
              <Metatags tags={page[pageLang].metatag} />
            )}
            {<h1>{page[pageLang].title}</h1>}
            {page[pageLang].fieldComponents &&
                page[pageLang].fieldComponents.map((component, index) => renderComponent(component, index, pageLang))
            }
          </>
        ) : (data.length || pageId) ? (
          <h1>Page Not Found</h1>
          ) : null }
      </div>
    </div>
  )
}

export default BasicPage
