import React, { useRef } from 'react'
import Modal from 'features/Modal';
import { useTranslation } from 'react-i18next';

export default function DeleteConfirmationModal({
  isOpen = false,
  onClose = () => {},
  onDelete = () => {},
  style = {}
}) {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={!!isOpen}
      overlayClassName='c-delete-contributor__container'
      className='c-modal c-modal--confirmation c-modal--delete'
      onRequestClose={onClose}
      shouldReturnFocusAfterClose={true}
      contentStyle={style}
    >
      <div aria-describedby='c-delete-contributor-desc' className='c-delete-contributor__confirmation c-account-form'>
        <div className='c-account-form__header'>
          <p id='c-delete-contributor-desc' className='c-delete-contributor__confirmation--description c-copy c-copy--bold u-color-white'>{t('Are you sure?')}</p>
        </div>
        <div className='c-delete-contributor__confirmation--button-container c-account-form__content-confirm'>
          <button 
            onClick={onDelete} 
            className='u-mbxs u-bg-orange u-color-white c-btn c-btn--hover-fx c-btn--sm c-btn--small-p c-btn--full c-delete-contributor__confirmation--button c-delete-contributor__confirmation--button-yes'
          >
            {t('YES')}
          </button>
          <button 
            onClick={onClose} 
            className='c-btn c-btn--sm c-btn--small-p c-btn--full u-bg-teal c-btn--hover-fx u-color-white c-delete-contributor__confirmation--button c-delete-contributor__confirmation--button-no'
          >
            {t('NO')}
          </button>
        </div>
      </div>
    </Modal>
  )
}