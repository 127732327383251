import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'

const Video = ({ src, className = '', fallbackComponent, ...rest }) => {
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!ReactPlayer.canPlay(src)) setError(true)
  }, [src, setError])
  return (
    <>
      {error && fallbackComponent ? fallbackComponent
      : (
        <div className={`c-video ${className}`}>
          <ReactPlayer controls url={src} onError={() => setError(true)} {...rest} />
        </div>
      )}
    </>
  )
}

export default Video
