import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectLangcodeFlag } from 'shared/redux/sharedSelectors'

const JdaLink = ({ children, to = '', ...rest }) => {
  const langCode = useSelector(selectLangcodeFlag)
  return (
    <Link to={`/${langCode}${to.length > 1 ? `${to}` : ''}`} {...rest}>
      {children}
    </Link>
  )
}

export const renderLinkWithMessage = (msg, link, linkMsg, options) => {
  return (
    <>
      {msg}
      <JdaLink to={link} className='u-color-white c-copy c-copy--underline' {...options}>
        {linkMsg}{' '}
      </JdaLink>
    </>
  )
}

export default JdaLink
