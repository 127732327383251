import { createAction } from '@reduxjs/toolkit'
import basicPageTypes from './basicPageTypes'
import * as resourceServices from 'shared/utils/graphql/resources'
import * as bpServices from 'shared/utils/api/basicPage'
import { setLoadingState } from 'shared/redux/sharedActions'

const getResourcesBegin = createAction(basicPageTypes.GET_RESOURCES_BEGIN)
const getResourcesSuccess = createAction(basicPageTypes.GET_RESOURCES_SUCCESS)
const getResourcesFail = createAction(basicPageTypes.GET_RESOURCES_FAIL)

export const loadResources = () => async dispatch => {
  dispatch(getResourcesBegin())
  try {
    const menuRes = await bpServices.getBasicPageMenu()
    const res = await resourceServices.getMultiLangResources()
    const data = res?.nodeQuery?.entities ?? [];
    let validDataItem = data.find(d => {
      const lowercaseTitle = (d?.en?.title ?? '').toLowerCase();
      return (lowercaseTitle && lowercaseTitle.includes('about jda'))
    });
    if (!validDataItem) validDataItem = data.find(d => d?.en?.path?.alias);
    const defaultResourceLink = validDataItem?.en?.path?.alias;
    if (res.nodeQuery && !res.errors) {
      dispatch(getResourcesSuccess({
        data: data,
        menus: menuRes,
        defaultResourceLink:  defaultResourceLink
      }))
    } else if (res.errors) {
      throw new Error(res.errors[0].message)
    }
  } catch (error) {
    dispatch(getResourcesFail())
  }
}

const getSinglePageBegin = createAction(basicPageTypes.GET_SINGLE_PAGE_BEGIN)
const getSinglePageSuccess = createAction(basicPageTypes.GET_SINGLE_PAGE_SUCCESS)
const getSinglePageFail = createAction(basicPageTypes.GET_SINGLE_PAGE_FAIL)

export const loadSinglePage = id => async dispatch => {
  dispatch(getSinglePageBegin())
  try {
    const res = await resourceServices.getSinglePageResource(id)
    dispatch(getSinglePageSuccess(res?.nodeById))
  } catch (error) {
    dispatch(getSinglePageFail(error))
  }
}