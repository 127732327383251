import React from 'react'
import { useTranslation } from 'react-i18next'
import { JdaLink } from 'shared/components'
import { getBackgroundImageStyle, parseCollectionPreview } from 'shared/utils/tools/parse'

const FeaturedCard = ({ title, description, link, img }) => {
  const {t} = useTranslation()
  return (
    <li className='o-grid__col u-3/12@md u-12/12@sm'>
      <div className='c-featured-card u-bg-white'>
        <div className='c-featured-card__img' style={getBackgroundImageStyle(img)} />
        <div className='c-featured-card__info-block'>
          <h3 className='c-featured-card__title'>{t(title)}</h3>
          {/* <p className='c-featured-card__description'>{parseCollectionPreview(description)}</p> */}
          <JdaLink to={link} className='c-link c-link--icon c-featured-card__link'>
            {t('Get Started')}
            <span className='c-link__icon c-link__icon--right'>
              <i className='material-icons'>arrow_forward</i>
            </span>
          </JdaLink>
        </div>
      </div>
    </li>
  )
}

export default FeaturedCard
