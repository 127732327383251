import React from 'react'
import { useTranslation } from 'react-i18next'
import { EN, JA } from 'shared/constants'

const Tab = ({ language, active, setActive }) => {
  return (
    <li
      className={`c-language-tabber__tab ${!active ? 'u-bg-teal u-color-white' : ''} ${!setActive ? 'c-language-tabber__tab--content' : ''}`}
    >
      {!setActive && language}
      {setActive && <button className='c-btn c-btn--bare c-language-tabber__tab--content' type='button' onClick={setActive}>
        {language}
      </button>}
    </li>
  )
}

const LanguageTabber = ({
  languages = [{ value: EN, label: 'English' }, { value: JA, label: 'Japanese' }],
  activeTab,
  className = '',
  contentClassName = '',
  setActiveTab,
  children
}) => {
  const { t } = useTranslation() 
  return (
    <div className={`c-language-tabber ${className}`}>
      <ul className='c-language-tabber__tab-list'>
        {languages.map((language, index) => (
          <Tab
            key={index}
            language={t(language.label)}
            active={language.value === activeTab}
            setActive={setActiveTab && (() => setActiveTab(language.value))}
          />
        ))}
      </ul>
      <div className={`c-language-tabber__content ${contentClassName}`}>
        {children}
      </div>
    </div>
  )
}

export default LanguageTabber
