import React from 'react'

const PasswordStrength = ({ score = 0 }) => {
  return (
    <div style={{ maxWidth: '30rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          Password Strength:
          </span>
        <span className='c-copy c-copy--bold'>
          {score < 60 ? 'Weak' : score < 75 ? 'Fair' : score < 87.5 ? 'Good' : 'Strong'}
        </span>
      </div>
      <div style={{ background: '#C4C4C4' }}>
        <div style={{ background: '#47C965', height: '0.48rem', width: `${score}%` }} />
      </div>
    </div>
  )
}

export default PasswordStrength
